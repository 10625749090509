package main.kotlin.data

import kotlin.js.Json

class Placeholder
{
    companion object
    {
        val shared = Placeholder()
    }

    private val destJSON = """
			{
  "additional_info": {
    "flushing/purging": "Purging N2",
    "hose_pickup_sector_limits": "N/A",
    "line_markings": "N/A",
    "restricted_laydown_sectors": "N/A",
    "shooting_position": "65-100m"
  },
  "channels": [
    "global"
  ],
  "communication": {
    "field_coordinator": "Installation control",
    "helicopter_frequency": "118.050 MHz",
    "uhf_channel": "Alvheim",
    "vhf_channel": "Ch 77"
  },
  "full_name": "Installation Full Name",
  "id": "alvheim",
  "info": {
    "distance_turret_to_stern": "",
    "heading_control": "Yes",
    "loa": "295m",
    "normal_discharge_capacity": "5200 m3/h",
    "production_capacity": "154 000 Bbls/day",
    "storage_capacity": "569 000 Bbls",
    "vsog_name": "VSOG (FM1221) applicable",
    "vsog_value": "No"
  },
  "loading_system": "Loading System",
  "mooring": {
    "chaffing_chain": "9,5m x 84mm",
    "hawser": "80m",
    "hev": "",
    "hose": "120m x 16 \"",
    "hose_messenger": "150m x 40mm",
    "hose_storage": "Reel",
    "lower_polyester_rope": "",
    "messenger_line": "150m x Ø108 - Ø56 mm",
    "pickup_line": "100m x 24mm",
    "upper_polyester_rope": "",
    "weak_shackle": "",
    "weaklink": "390t"
  },
  "name": "Installation Name",
  "position_limits": {
    "crude_oil_pressure_high": "",
    "crude_oil_pressure_high_high_asd1": "7 Bar",
    "hawser_tension_high_alarm": "",
    "hawser_tension_high_high_alarm_asd1": "100t",
    "heading_limitation_red": "+/- 45 deg",
    "heading_limitation_yellow": "+/-35 deg",
    "hose_tension_high_alarm": "",
    "hose_tension_high_high_alarm_asd1": "",
    "inner_red": "60m",
    "inner_yellow": "65m",
    "normal_green": "70-90m",
    "outer_red": "105",
    "outer_yellow": "100",
    "pasd_1": "No",
    "pasd_2": "No"
  },
  "reference_system": {
    "addr_code": "Addr code 15",
    "cyscan/fanbeam": "N/A",
    "darps": {
      "darps1": {
        "name": "DARPS 1",
        "value": "Yes"
      },
      "darps2": {
        "name": "DARPS 2",
        "value": "Yes"
      },
      "tdma1": {
        "name": "DARPS 1 TDMA",
        "value": "869,4625 MHz"
      },
      "tdma2": {
        "name": "DARPS 2 TDMA",
        "value": "869,6125 MHz"
      }
    },
    "freq_pair": "Freq pair 1",
    "hipap": "N/A",
    "name": "Artemis MK5 (Reference origin)",
    "note": "",
    "radius": "ID 150 & ID 160"
  },
  "standby_vessel": {
    "bollardpull": "",
    "communication": "",
    "name": "",
    "towing_equip": ""
  },
  "telemetry": {
    "channel": "Channel 12 (NOR)",
    "rx": "RX: 459.250 MHz",
    "tx": "TX: 469.250 MHz"
  }
}
		""".trimIndent()

    val ship = Ship("?")
    var destination : Installation
    var trip : ShipTrip

    init
    {
        ship.name = "Ship"

        val obj = JSON.parse<Json>(destJSON)
        val dyn = obj.asDynamic()
        destination = Installation(dyn=dyn)
        trip = ShipTrip(ship, "", destination)
    }
}
