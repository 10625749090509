package main.kotlin.models

import main.kotlin.comms.Requests
import main.kotlin.data.*
import main.kotlin.ui.*
import org.w3c.dom.Document
import kotlin.browser.document

class ManageScreen(document: Document) : TopNavBarScreen(document), TableViewDelegate
{
    val alert = Alert(document)

    override val activeTab: TopNavBarTab get() = TopNavBarTab.Manage
    override val content =  Div(document, "page_content", "flex")
    val breadcrumbs = Breadcrumbs(document, "page_breadcrumbs", arrayOf(Breadcrumb("Manage", "manage.html")))

    private val typeTitle = Div(document, "type_navbar_title")
    private val classTitle = Div(document, "class_navbar_title")
    private val regionTitle = Div(document, "region_navbar_title")
    private val loadingSystemTitle = Div(document, "loading_system_navbar_title")
    private val handbookOperationTitle = Div(document, "handbook_operation_navbar_title")

    private val addVesselTypeView = Div(document, "vessel_type_add")
    private val addVesselClassView = Div(document, "vessel_class_add")
    private val addRegionView = Div(document, "region_add")
    private val addLoadingSystemView = Div(document, "loading_system_add")
    private val addHandbookOperationView = Div(document, "handbook_operation_add")

    private val typeCreateButton = Button(document,"create_type_button",tooltip="Create a new vessel type")
    private val typeSaveButton = Button(document, "save_type_button", tooltip = "Save vessel type")
    private val typeDeleteButton = Button(document, "delete_type_button", tooltip = "Delete vessel type")

    private val classCreateButton = Button(document, "class_create_button", tooltip = "Create a new vessel class")
    private val classSaveButton = Button(document, "class_save_button", tooltip = "Save vessel class")
    private val classDeleteButton = Button(document, "class_delete_button", tooltip = "Delete vessel class")

    private val regionCreateButton = Button(document, "region_create_button", tooltip = "Create a new region")
    private val regionSaveButton = Button(document, "region_save_button", tooltip = "Save region")
    private val regionDeleteButton = Button(document, "region_delete_button", tooltip = "Delete region")

    private val loadingSystemCreateButton = Button(document, "loading_system_create_button", tooltip = "Create a new loading system")
    private val loadingSystemSaveButton = Button(document, "loading_system_save_button", tooltip = "Save loading system")
    private val loadingSystemDeleteButton = Button(document, "loading_system_delete_button", tooltip = "Delete loading system")

    private val handbookOperationCreateButton = Button(document, "handbook_operation_create_button", tooltip = "Create a new operation")
    private val handbookOperationSaveButton = Button(document, "handbook_operation_save_button", tooltip = "Save operation")
    private val handbookOperationDeleteButton = Button(document, "handbook_operation_delete_button", tooltip = "Delete operation")

    var vesselTypeAdd : Boolean = false
    var vesselClassAdd : Boolean = false
    var regionAdd : Boolean = false
    var loadingSystemAdd: Boolean = false
    var handbookOperationAdd : Boolean = false

    private val tabsList : Array<Tab> = arrayOf(
        Tab("tab-vessel-type","Vessel Types"),
        Tab("tab-vessel-class","Vessel Classes"),
        Tab("tab-region","Regions"),
        Tab("tab-loading-system", "Loading Systems"),
        Tab("tab-handbook-operation", "Operations")
    )

    val sections = TableView(document,this,"sections")
    var currentSelection : String = "tab-vessel-type"
    var cells : MutableList<TabCell> = mutableListOf()
    var types : Array<ShipType> = arrayOf()
    var classes : Array<ShipClass> = arrayOf()
    var shipRegions : Array<ShipRegion> = arrayOf()
    var loadingSystems : Array<LoadingSystem> = arrayOf()
    var handbookOperations : Array<HandbookOperation> = arrayOf()

    private val vesselTypeList = TilesView(document, ManageVesselType(this),"vessel_type_tiles")
    private val vesselClassList = TilesView(document, ManageVesselClass(this),"vessel_class_tiles")
    private val regionList = TilesView(document, ManageRegion(this),"region_tiles")
    private val loadingSystemList = TilesView(document, ManageLoadingSystem(this), "loading_system_tiles")
    private val handbookOperationList = TilesView(document, ManageHandbookOperation(this), "handbook_operation_tiles")

    companion object {
        lateinit var instance: ManageScreen;
    }

    init
    {
        instance = this
        loading = false

        typeCreateButton.onclick = { 
            addVesselType()
            this.typeText.setValid(true)
        }
        classCreateButton.onclick = { 
            addVesselClass()
            this.classText.setValid(true)
        }
        regionCreateButton.onclick = { 
            addRegion()
            this.regionText.setValid(true) 
        }
        loadingSystemCreateButton.onclick = { 
            addLoadingSystem()
            this.loadingSystemText.setValid(true)
        }
        handbookOperationCreateButton.onclick = { 
            addHandbookOperation()
            this.handbookOperationText.setValid(true)
        }
    }

    override fun start()
    {
        super.start()

        for (cell in cells)
            cell.li.onclick = null
        cells.clear()

        sections.reloadData()

        for (tab in tabsList)
        {
            val tabId = tab.id
            val cell = TabCell(tabId)
            cell.li.onclick = { clickedTab(tabId) }
            cells.add(cell)
        }

        refreshType()

        refreshClass()

        refreshRegion()

        refreshLoadingSystem()

        refreshHandbookOperation()

        reloadCurrentTab()
    }

    class TabCell(val id:String)
    {
        val li = Li(document,"cell-$id")
        val label = Span(document, "cell-$id-label")
    }

    fun clickedTab(id:String)
    {
        currentSelection = id
        reloadCurrentTab()
    }

    private fun reloadCurrentTab()
    {
        for (cell in cells)
        {
            val shouldShow = currentSelection == cell.id
            if (shouldShow)
                cell.label.addClasses(arrayOf("current-selection"))
            else
                cell.label.removeClasses(arrayOf("current-selection"))

            val div = Div(document,cell.id)
            div.hidden = !shouldShow
        }
    }

    private fun refreshType() {
        loading = false
        Requests.listShipTypes2(this) {
            types = it.shipTypes
            vesselTypeList.reloadData()
            vesselTypeList.show()
        }
    }

    private fun refreshClass() {
        loading = false
        Requests.listShipClasses2(this) {
            classes = it.shipClasses
            vesselClassList.reloadData()
            vesselClassList.show()
        }
    }

    private fun refreshRegion() {
        loading = false
        Requests.listRegions2(this) {
            shipRegions = it.shipRegions
            regionList.reloadData()
            regionList.show()
        }
    }

    private fun refreshLoadingSystem() {
        loading = false
        Requests.listLoadingSystems(this) {
            loadingSystems = it.loadingSystems
            loadingSystemList.reloadData()
            loadingSystemList.show()
        }
    }

    private fun refreshHandbookOperation() {
        loading = false
        Requests.listHandbookOperations(this) {
            handbookOperations = it.handbookOperations
            handbookOperationList.reloadData()
            handbookOperationList.show()
        }
    }

    override val numberOfRows: Int get() { return tabsList.count() }

    override fun cellForRow(at: Int): String
    {
        val newTab = tabsList[at]
        return """
        <li class="mdc-list-item" id="cell-${newTab.id}">
            <span class="mdc-list-item__text" id="cell-${newTab.id}-label">${newTab.name}</span>
        </li>
        """
    }

    val typeText = EditText(document,"type","Vessel Type",required=true)

    val classText = EditText(document,"class","Vessel Class",required=true)

    private val regionText = EditText(document, "region", "Region", required = true)

    private val loadingSystemText = EditText(document, "loading_system", "Loading System", required = true)

    private val handbookOperationText = EditText(document, "handbook_operation", "Operation", required = true)

   @JsName("clickedVesselTypeAt")
    fun clickedVesselType(at: Int) {
        addVesselTypeView.hidden = false
        typeTitle.text = titleForVesselType(at).vessel_type
        typeCreateButton.hidden = true
        vesselTypeList.hide()
        typeText.text = titleForVesselType(at).vessel_type
        typeSaveButton.hidden = false
        typeDeleteButton.hidden = false
        typeSaveButton.onclick = { updateVesselType(at) }
        typeDeleteButton.onclick = { deleteVesselType(at) }
    }

    private fun updateVesselType(at:Int) {
        val id = titleForVesselType(at).id
        this.typeText.text = this.typeText.text.split(" ").joinToString(" ") { it.capitalize() }

        if(this.typeText.text.isEmpty()) {
            showToast("Name is required")
            clickedVesselType(at)
        }
        else if( types.map{it.vessel_type}.contains(this.typeText.text) ) {
            showToast("Type already exists!")
            clickedVesselType(at)
        }
        else {
            var data = Any().asDynamic()
            data["vessel_type"] = this.typeText.text

            loading = true

            Requests.updateShipType2(this, id, data) {
                loading = false
                if (it.error != null) {
                    showToast(it, "Failed to change vessel type")
                } else {
                    showToast(it, "Vessel Type changed!")
                }
                refreshType()
            }

            addVesselTypeView.hidden = true
            typeTitle.text = "Vessel Type"
            typeCreateButton.hidden = false
        }
    }

    private fun deleteVesselType(at:Int, confirmed:Boolean=false) {
        if(confirmed) {
            val id = titleForVesselType(at).id

            Requests.deleteShipType2(this, id) {
                loading = false
                if (it.error != null)
                {
                    showToast(it,"Failed to delete vessel type")
                }
                else
                {
                    showToast(it,"Vessel type deleted!")
                }
                refreshType()
            }
            this.typeText.text = ""
            addVesselTypeView.hidden = true
            typeTitle.text = "Vessel Type"
            typeCreateButton.hidden = false
        }
        else {
            alert.open("Delete Vessel Type", "Are you sure you would like to delete this vessel type?", arrayOf(AlertAction("cancel", "Cancel"), AlertAction("delete", "Delete")))
            {
                if (it == "delete")
                {
                    deleteVesselType(at,true)
                }
            }
        }
    }

    private fun addVesselType() {
        typeSaveButton.onclick = { saveVesselType() }
        typeTitle.text = "Create Vessel Type"
        typeCreateButton.hidden = true
        typeDeleteButton.hidden = true
        vesselTypeAdd = true
        vesselTypeList.hide()
        addVesselTypeView.hidden = false
    }

    fun saveVesselType() {
        loading = true

        this.typeText.text = this.typeText.text.split(" ").joinToString(" ") { it.capitalize() }

        if(this.typeText.text.isEmpty()) {
            showToast("Name is required")
            loading = false
            addVesselType()
        }
        else if( types.map { it.vessel_type}.contains(this.typeText.text) ) {
            showToast("Type already exists!")
            loading = false
            addVesselType()
        }
        else {
            vesselTypeAdd = false
            addVesselTypeView.hidden = true
            typeTitle.text = "Vessel Type"
            typeCreateButton.hidden = false
            var data = Any().asDynamic()
            data["vessel_type"] = this.typeText.text
            this.typeText.text = ""

            Requests.createShipType2(this, data) {
                loading = false
                if (it.error != null) {
                    showToast(it, "Failed to create vessel type")
                } else {
                    showToast(it, "Vessel Type created!")
                }
                refreshType()
            }
        }
    }

    @JsName("clickedVesselClassAt")
    fun clickedVesselClass(at: Int) {
        addVesselClassView.hidden = false
        classTitle.text = titleForVesselClass(at).vessel_class
        classCreateButton.hidden = true
        vesselClassList.hide()
        classText.text = titleForVesselClass(at).vessel_class
        classSaveButton.hidden = false
        classDeleteButton.hidden = false
        classSaveButton.onclick = { updateVesselClass(at) }
        classDeleteButton.onclick = { deleteVesselClass(at) }
    }

    private fun updateVesselClass(at:Int) {
        val id = titleForVesselClass(at).id
        this.classText.text = this.classText.text.split(" ").joinToString(" ") { it.capitalize() }

        if(this.classText.text.isEmpty()) {
            showToast("Name is required")
            clickedVesselClass(at)
        }
        else if( classes.map{it.vessel_class}.contains(this.classText.text) ) {
            showToast("Class already exists!")
            clickedVesselClass(at)
        }
        else {
            var data = Any().asDynamic()
            data["vessel_class"] = this.classText.text

            loading = true

            Requests.updateShipClass2(this, id, data) {
                loading = false
                if (it.error != null) {
                    showToast(it, "Failed to change vessel class")
                } else {
                    showToast(it, "Vessel Class changed!")
                }
                refreshClass()
            }

            addVesselClassView.hidden = true
            classTitle.text = "Vessel Class"
            classCreateButton.hidden = false
        }
    }

    private fun deleteVesselClass(at:Int, confirmed: Boolean=false) {
        if(confirmed) {
            val id = titleForVesselClass(at).id

            Requests.deleteShipClass2(this, id) {
                loading = false
                if (it.error != null) {
                    showToast(it, "Failed to delete vessel class")
                } else {
                    showToast(it, "Vessel class deleted!")
                }
                refreshClass()
            }
            this.classText.text = ""
            addVesselClassView.hidden = true
            classTitle.text = "Vessel Class"
            classCreateButton.hidden = false
        }
         else {
            alert.open("Delete Vessel Class", "Are you sure you would like to delete this vessel class?", arrayOf(AlertAction("cancel", "Cancel"), AlertAction("delete", "Delete")))
            {
                if (it == "delete")
                {
                    deleteVesselClass(at,true)
                }
            }
        }
    }

    private fun addVesselClass() {
        classSaveButton.onclick = { saveVesselClass() }
        classTitle.text = "Create Vessel Class"
        classCreateButton.hidden = true
        classDeleteButton.hidden = true
        vesselClassAdd = true
        vesselClassList.hide()
        addVesselClassView.hidden = false
    }

    private fun saveVesselClass() {
        loading = true
        this.classText.text = this.classText.text.split(" ").joinToString(" ") { it.capitalize() }

        if(this.classText.text.isEmpty()) {
            showToast("Name is required")
            loading = false
            addVesselClass()
        }
        else if(classes.map { it.vessel_class}.contains(this.classText.text)) {
            showToast("Class already exists!")
            loading = false
            addVesselClass()
        }
        else {
            vesselClassAdd = false
            addVesselClassView.hidden = true
            classTitle.text = "Vessel Class"
            classCreateButton.hidden = false

            var data = Any().asDynamic()
            data["vessel_class"] = this.classText.text

            this.classText.text = ""

            Requests.createShipClass2(this, data) {
                loading = false
                if (it.error != null) {
                    showToast(it, "Failed to create vessel class")
                } else {
                    showToast(it, "Vessel Class created!")
                }
                refreshClass()
            }
        }
    }

    @JsName("clickedRegionAt")
    fun clickedRegion(at: Int) {
        addRegionView.hidden = false
        regionTitle.text = titleForRegion(at).vessel_region
        regionCreateButton.hidden = true
        regionList.hide()
        regionText.text = titleForRegion(at).vessel_region
        regionSaveButton.hidden = false
        regionDeleteButton.hidden = false
        regionSaveButton.onclick = { updateRegion(at) }
        regionDeleteButton.onclick = { deleteRegion(at) }
    }

    private fun updateRegion(at:Int) {
        val id = titleForRegion(at).id
        this.regionText.text = this.regionText.text.split(" ").joinToString(" ") { it.capitalize() }

        if(this.regionText.text.isEmpty()) {
            showToast("Name is required")
            clickedRegion(at)
        }
        else if( shipRegions.map{it.vessel_region}.contains(this.regionText.text) ) {
            showToast("Region already exists!")
            clickedRegion(at)
        }
        else {
            var data = Any().asDynamic()
            data["vessel_region"] = this.regionText.text

            loading = true

            Requests.updateRegion2(this, id, data) {
                loading = false
                if (it.error != null) {
                    showToast(it, "Failed to change region")
                } else {
                    showToast(it, "Region changed!")
                }
                refreshRegion()
            }

            addRegionView.hidden = true
            regionTitle.text = "Regions"
            regionCreateButton.hidden = false
        }
    }

    private fun deleteRegion(at: Int, confirmed: Boolean=false) {
        if(confirmed) {
            val id = titleForRegion(at).id

            Requests.deleteRegion2(this, id) {
                loading = false
                if (it.error != null) {
                    showToast(it, "Failed to delete region")
                } else {
                    showToast(it, "Region deleted!")
                }
                refreshRegion()
            }
            this.regionText.text = ""
            addRegionView.hidden = true
            regionTitle.text = "Regions"
            regionCreateButton.hidden = false
        }
        else {
            alert.open("Delete Region", "Are you sure you would like to delete this region?", arrayOf(AlertAction("cancel", "Cancel"), AlertAction("delete", "Delete")))
            {
                if (it == "delete")
                {
                    deleteRegion(at,true)
                }
            }
        }
    }

    private fun addRegion() {
        regionSaveButton.onclick = { saveRegion() }
        regionTitle.text = "Create Region"
        regionCreateButton.hidden = true
        regionDeleteButton.hidden = true
        regionAdd = true
        regionList.hide()
        addRegionView.hidden = false
    }

    private fun saveRegion() {
        loading = true
        this.regionText.text = this.regionText.text.split(" ").joinToString(" ") { it.capitalize() }

        if(this.regionText.text.isEmpty()) {
            showToast("Name is required")
            loading = false
            addRegion()
        }
        else if(shipRegions.map { it.vessel_region}.contains(this.regionText.text)) {
            showToast("Region already exists!")
            loading = false
            addRegion()
        }
        else {
            regionAdd = false
            addRegionView.hidden = true
            regionTitle.text = "Regions"
            regionCreateButton.hidden = false

            var data = Any().asDynamic()
            data["vessel_region"] = this.regionText.text

            this.regionText.text = ""

            Requests.createRegion2(this, data) {
                loading = false
                if (it.error != null) {
                    showToast(it, "Failed to create region")
                } else {
                    showToast(it, "Region created!")
                }
                refreshRegion()
            }
        }
    }

    @JsName("clickedLoadingSystemAt")
    fun clickedLoadingSystem(at: Int) {
        addLoadingSystemView.hidden = false
        loadingSystemTitle.text = titleForLoadingSystem(at).name
        loadingSystemCreateButton.hidden = true
        loadingSystemList.hide()
        loadingSystemText.text = titleForLoadingSystem(at).name
        loadingSystemSaveButton.hidden = false
        loadingSystemDeleteButton.hidden = false
        loadingSystemSaveButton.onclick = { updateLoadingSystem(at) }
        loadingSystemDeleteButton.onclick = { deleteLoadingSystem(at) }
    }

    private fun updateLoadingSystem(at:Int) {
        val id = titleForLoadingSystem(at).id
        this.loadingSystemText.text = this.loadingSystemText.text.split(" ").joinToString(" ") { it.capitalize() }

        if(this.loadingSystemText.text.isEmpty()) {
            showToast("Name is required")
            clickedLoadingSystem(at)
        }
        else if( loadingSystems.map{it.name}.contains(this.loadingSystemText.text) ) {
            showToast("Loading system already exists!")
            clickedLoadingSystem(at)
        }
        else {
            var data = Any().asDynamic()
            data["name"] = this.loadingSystemText.text

            loading = true

            Requests.updateLoadingSystem(this, id, data) {
                loading = false
                if (it.error != null) {
                    showToast(it, "Failed to change loading system")
                } else {
                    showToast(it, "Loading system changed!")
                }
                refreshLoadingSystem()
            }

            addLoadingSystemView.hidden = true
            loadingSystemTitle.text = "Loading Systems"
            loadingSystemCreateButton.hidden = false
        }
    }

    private fun deleteLoadingSystem(at: Int, confirmed: Boolean=false) {
        if(confirmed) {
            val id = titleForLoadingSystem(at).id

            Requests.deleteLoadingSystem(this, id) {
                loading = false
                if (it.error != null) {
                    showToast(it, "Failed to delete loading system")
                } else {
                    showToast(it, "Loading system deleted!")
                }
                refreshLoadingSystem()
            }
            this.loadingSystemText.text = ""
            addLoadingSystemView.hidden = true
            loadingSystemTitle.text = "Loading Systems"
            loadingSystemCreateButton.hidden = false
        }
        else {
            alert.open("Delete Loading System", "Are you sure you would like to delete this loading system?", arrayOf(AlertAction("cancel", "Cancel"), AlertAction("delete", "Delete")))
            {
                if (it == "delete")
                {
                    deleteLoadingSystem(at,true)
                }
            }
        }
    }

    private fun addLoadingSystem() {
        loadingSystemSaveButton.onclick = { saveLoadingSystem() }
        loadingSystemTitle.text = "Create Loading System"
        loadingSystemCreateButton.hidden = true
        loadingSystemDeleteButton.hidden = true
        loadingSystemAdd = true
        loadingSystemList.hide()
        addLoadingSystemView.hidden = false
    }

    private fun saveLoadingSystem() {
        loading = true
        this.loadingSystemText.text = this.loadingSystemText.text.split(" ").joinToString(" ") { it.capitalize() }

        if(this.loadingSystemText.text.isEmpty()) {
            showToast("Name is required")
            loading = false
            addLoadingSystem()
        }
        else if(loadingSystems.map { it.name}.contains(this.loadingSystemText.text)) {
            showToast("Loading system already exists!")
            loading = false
            addLoadingSystem()
        }
        else {
            loadingSystemAdd = false
            addLoadingSystemView.hidden = true
            loadingSystemTitle.text = "Loading Systems"
            loadingSystemCreateButton.hidden = false

            var data = Any().asDynamic()
            data["name"] = this.loadingSystemText.text

            this.loadingSystemText.text = ""

            Requests.createLoadingSystem(this, data) {
                loading = false
                if (it.error != null) {
                    showToast(it, "Failed to create loading system")
                } else {
                    showToast(it, "Loading system created!")
                }
                refreshLoadingSystem()
            }
        }
    }

    @JsName("clickedHandbookOperationAt")
    fun clickedHandbookOperation(at: Int)
    {
        addHandbookOperationView.hidden = false
        handbookOperationTitle.text = titleForHandbookOperation(at).name
        handbookOperationCreateButton.hidden = true
        handbookOperationList.hide()
        handbookOperationText.text = titleForHandbookOperation(at).name
        handbookOperationSaveButton.hidden = false
        handbookOperationDeleteButton.hidden = false
        handbookOperationSaveButton.onclick = { updateHandbookOperation(at) }
        handbookOperationDeleteButton.onclick = { deleteHandbookOperation(at) }
    }

    private fun updateHandbookOperation(at:Int) {
        val id = titleForHandbookOperation(at).id
        this.handbookOperationText.text = this.handbookOperationText.text.split(" ").joinToString(" ") { it.capitalize() }

        if(this.handbookOperationText.text.isEmpty()) {
            showToast("Name is required")
            clickedHandbookOperation(at)
        }
        else if( handbookOperations.map{it.name}.contains(this.handbookOperationText.text) ) {
            showToast("Operation already exists!")
            clickedHandbookOperation(at)
        }
        else {

            var data = Any().asDynamic()
            data["name"] = this.handbookOperationText.text

            loading = true

            Requests.updateHandbookOperation(this, id, data) {
                loading = false
                if (it.error != null) {
                    showToast(it, "Failed to change operation")
                } else {
                    showToast(it, "Operation changed!")
                }
                refreshHandbookOperation()
            }

            addHandbookOperationView.hidden = true
            handbookOperationText.text = "Operations"
            handbookOperationCreateButton.hidden = false
        }
    }

    private fun deleteHandbookOperation(at: Int, confirmed: Boolean=false) {
        if(confirmed) {
            val id = titleForHandbookOperation(at).id

            Requests.deleteHandbookOperation(this, id) {
                loading = false
                if (it.error != null) {
                    showToast(it, "Failed to delete operation")
                } else {
                    showToast(it, "Operation deleted!")
                }
                refreshHandbookOperation()
            }
            this.handbookOperationText.text = ""
            addHandbookOperationView.hidden = true
            handbookOperationTitle.text = "Operations"
            handbookOperationCreateButton.hidden = false
        }
        else {
            alert.open("Delete Operation", "Are you sure you would like to delete this operation?", arrayOf(AlertAction("cancel", "Cancel"), AlertAction("delete", "Delete")))
            {
                if (it == "delete")
                {
                    deleteHandbookOperation(at,true)
                }
            }
        }
    }

    private fun addHandbookOperation() {
        handbookOperationSaveButton.onclick = { saveHandbookOperation() }
        handbookOperationTitle.text = "Create Operation"
        handbookOperationCreateButton.hidden = true
        handbookOperationDeleteButton.hidden = true
        handbookOperationAdd = true
        handbookOperationList.hide()
        addHandbookOperationView.hidden = false
    }

    private fun saveHandbookOperation() {
        loading = true
        this.handbookOperationText.text = this.handbookOperationText.text.split(" ").joinToString(" ") { it.capitalize() }

        if(this.handbookOperationText.text.isEmpty()) {
            showToast("Name is required")
            loading = false
            addHandbookOperation()
        }
        else if(handbookOperations.map { it.name}.contains(this.handbookOperationText.text)) {
            showToast("Operation already exists!")
            loading = false
            addHandbookOperation()
        }
        else {
            handbookOperationAdd = false
            addHandbookOperationView.hidden = true
            handbookOperationTitle.text = "Operations"
            handbookOperationCreateButton.hidden = false

            var data = Any().asDynamic()
            data["name"] = this.handbookOperationText.text

            this.handbookOperationText.text = ""

            Requests.createHandbookOperation(this, data) {
                loading = false
                if (it.error != null) {
                    showToast(it, "Failed to create operation")
                } else {
                    showToast(it, "Operation created!")
                }
                refreshHandbookOperation()
            }
        }
    }

    open fun vesselTypeCount() = types.count()
    open fun titleForVesselType(at: Int) = types[at]

    open fun vesselClassCount() = classes.count()
    open fun titleForVesselClass(at: Int) = classes[at]

    open fun regionCount() = shipRegions.count()
    open fun titleForRegion(at: Int) = shipRegions[at]

    open fun loadingSystemCount() = loadingSystems.count()
    open fun titleForLoadingSystem(at: Int) = loadingSystems[at]

    open fun handbookOperationCount() = handbookOperations.count()
    open fun titleForHandbookOperation(at: Int) = handbookOperations[at]

}

class ManageVesselType(screen:ManageScreen) : TilesViewDelegate
{
    val screen = screen

    override val numberOfItems: Int
        get() = screen.vesselTypeCount()

    override fun titleForItem(at: Int): String { return screen.titleForVesselType(at).vessel_type }

    override fun iconForItem(at:Int) : String { return "local_offer" }

    override fun onclickForItem(at: Int) : String { return "cms.main.kotlin.models.ManageScreen.Companion.instance.clickedVesselTypeAt($at)" }
}

class ManageVesselClass(screen: ManageScreen) : TilesViewDelegate
{
    val screen = screen

    override val numberOfItems: Int
        get() = screen.vesselClassCount()

    override fun titleForItem(at: Int): String { return screen.titleForVesselClass(at).vessel_class }

    override fun iconForItem(at:Int) : String { return "local_offer" }

    override fun onclickForItem(at: Int) : String { return "cms.main.kotlin.models.ManageScreen.Companion.instance.clickedVesselClassAt($at)" }
}

class ManageRegion(screen: ManageScreen) : TilesViewDelegate
{
    val screen = screen

    override val numberOfItems: Int
        get() = screen.regionCount()

    override fun titleForItem(at: Int): String { return screen.titleForRegion(at).vessel_region }

    override fun iconForItem(at:Int) : String { return "local_offer" }

    override fun onclickForItem(at: Int) : String { return "cms.main.kotlin.models.ManageScreen.Companion.instance.clickedRegionAt($at)" }
}

class ManageLoadingSystem(screen: ManageScreen) : TilesViewDelegate
{
    val screen = screen

    override val numberOfItems: Int
        get() = screen.loadingSystemCount()

    override fun titleForItem(at: Int): String { return screen.titleForLoadingSystem(at).name }

    override fun iconForItem(at:Int) : String { return "local_offer" }

    override fun onclickForItem(at: Int) : String { return "cms.main.kotlin.models.ManageScreen.Companion.instance.clickedLoadingSystemAt($at)" }
}

class ManageHandbookOperation(screen: ManageScreen) : TilesViewDelegate
{
    val screen = screen

    override val numberOfItems: Int
        get() = screen.handbookOperationCount()

    override fun titleForItem(at: Int): String { return screen.titleForHandbookOperation(at).name }

    override fun iconForItem(at:Int) : String { return "local_offer" }

    override fun onclickForItem(at: Int) : String { return "cms.main.kotlin.models.ManageScreen.Companion.instance.clickedHandbookOperationAt($at)" }
}