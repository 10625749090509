package main.kotlin.ui

import org.w3c.dom.Document
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement
import kotlin.browser.document

interface TabBarDelegate
{
    fun clickedTab(id:String)
}

class TabBar(val document: Document, val id:String, val tabs:Array<Tab>, activeTabIndex:Int=0,val cssClass:String="")
{
    private val container : HTMLDivElement = document.getElementById(id) as HTMLDivElement

    private val content : HTMLDivElement

    var delegate : TabBarDelegate? = null

     private fun getView(id:String) : dynamic
     {
         val obj = js("""
     (function(){
         return new mdc.tabBar.MDCTabBar(document.getElementById(id));
     }())
     """)
         return obj
     }

     private val view : dynamic

    val markup : String
    get()
    {
        return """
        <div class="mdc-tab-bar $cssClass" id="$id-tab-bar" role="tablist">
            <div class="mdc-tab-scroller" id="$id-tab-scroller">
                <div class="mdc-tab-scroller__scroll-area">
                    <div class="mdc-tab-scroller__scroll-content" id="$id-tab-content">

                    </div>
                </div>
            </div>
        </div>
        """
    }

    init
    {
        container.innerHTML = markup

        var html = ""
        var i = 0
        for (tab in tabs)
        {
            html += tab.markup(i==activeTabIndex)
            i+= 1
        }

        content = document.getElementById("$id-tab-content") as HTMLDivElement
        content.innerHTML = html

        for (tab in tabs)
        {
            val btn = tab.getButton()
            btn.onclick = { setActiveTab(tab.id) }
        }

        view = getView(id)
    }

    fun setActiveTab(id:String)
    {
        val delg = delegate
        if (delg != null)
        {
            delg.clickedTab(id)
        }
        else
        {
            for (tab in tabs)
            {
                tab.viewHidden = tab.id != id
            }
        }
    }

    var hidden : Boolean
    get()
    {
        return container.style.display == "none"
    }
    set(value)
    {
        container.style.display = if (value) "none" else "block"
    }
}

class Tab(val id: String,val name: String)
{
    val view : HTMLDivElement? = document.getElementById(id) as? HTMLDivElement

    var viewHidden : Boolean
    get()
    {
        return view?.style?.display == "none"
    }
    set(value)
    {
        view?.style?.display = if (value) "none" else "block"
    }

    fun getButton() : HTMLButtonElement
    {
        return document.getElementById("$id-button") as HTMLButtonElement
    }

    fun markup(selected:Boolean) : String
    {
        val ariaSelected = if (selected) "true" else "false"
        val activeCss = if (selected) " mdc-tab-indicator--active" else ""
        val activeTabCss = if (selected) " mdc-tab--active" else ""
        return """<button id="$id-button" class="mdc-tab$activeTabCss" role="tab" aria-selected="$ariaSelected" tabindex="0">
            <span class="mdc-tab__content">
                <span class="mdc-tab__text-label">$name</span>
            </span>
            <span class="mdc-tab-indicator$activeCss">
                <span class="mdc-tab-indicator__content mdc-tab-indicator__content--underline"></span>
            </span>
            <span class="mdc-tab__ripple"></span>
        </button>"""
    }
}
