package main.kotlin.ui

class Icons
{
    companion object
    {
        const val regions = "public"
        const val destinations = "place"
        const val ships = "directions_boat"
        const val classes = "category"
        const val types = "local_offer"
        const val procedureSettings = "settings"
    }
}