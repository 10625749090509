package ui

import main.kotlin.ui.Selector
import main.kotlin.ui.SelectorItem
import main.kotlin.ui.SelectorNotSelectedMode
import org.w3c.dom.Document

open class MapSelector(document: Document, id: String, hint: String, var items: Map<String, String>, val mode: SelectorNotSelectedMode = SelectorNotSelectedMode.All) : Selector(document, id, hint, items.selectorItems(mode)) {
    var selected: String?
        get() {
            val v = view.value as? String ?: return null
            if (v.isEmpty()) return null
            if (v == "-1") return null
            return v
        }
        set(value) {
            if (value != null) {
                view.name = items[value].toString()
                view.value = value.toString()
            } else {
                view.value = "-1"
            }
        }

    fun getValue(key: String) : String? {
        return items[key]
    }

    fun setItems(items: Map<String, String>) {
        this.items = items
        setSelectorItems(items.selectorItems(mode))
    }
}

fun Map<String, String>.selectorItems(mode: SelectorNotSelectedMode = SelectorNotSelectedMode.All): Array<SelectorItem> {
    val selectors: MutableList<SelectorItem> = mutableListOf()

    when (mode) {
        SelectorNotSelectedMode.All -> selectors.add(SelectorItem("all", "All", "-1"))
        SelectorNotSelectedMode.None -> selectors.add(SelectorItem("none", "None", "-1"))
        SelectorNotSelectedMode.Empty -> {}
    }

    this.forEach { (id, value) ->
        selectors.add(SelectorItem("selector-$id", value, id))
    }

    return selectors.toTypedArray()
}
