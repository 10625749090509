package main.kotlin.ui

import main.kotlin.data.TemplateType
import org.w3c.dom.Document

private val Array<TemplateType>.selectorItems : Array<SelectorItem>
get()
{
    val list = mutableListOf<SelectorItem>()

    for (type in this)
    {
        list.add(SelectorItem("${type.toString().toLowerCase()}","${type.title}","${type.value}"))
    }

    return list.toTypedArray()
}

open class TemplateSelector(document: Document, id:String, hint:String) : Selector(document,id,hint, TemplateType.values().selectorItems)
{
    var selected : TemplateType
    get()
    {
        val s = view.value as String
        return TemplateType.from(s)
    }
    set(value)
    {
        view.value = value.value
    }

    fun setTypes(types:Array<TemplateType>)
    {
        setSelectorItems(types.selectorItems)
    }
}
