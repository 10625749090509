package main.kotlin.ui

import main.kotlin.data.HandbookIcon
import org.w3c.dom.Document

private val Array<HandbookIcon>.selectorItems : Array<SelectorItem>
get()
{
    val list = mutableListOf<SelectorItem>()

    for (type in this)
    {
        list.add(SelectorItem("${type.toString().toLowerCase()}","${type.title}","${type.value}"))
    }

    return list.toTypedArray()
}

class HandbookIconSelector(document:Document,id:String,hint:String,tooltip:String?=null) : Selector(document,id,hint,HandbookIcon.values().selectorItems,tooltip=tooltip)
{
    var selected : HandbookIcon
    get()
    {
        val s = view.value as String
        return HandbookIcon.from(s.toInt())
    }
    set(value)
    {
        view.value = value.value.toString()
    }
}
