package main.kotlin.models

import main.kotlin.comms.Requests
import main.kotlin.data.*
import main.kotlin.ui.*
import org.w3c.dom.*
import kotlin.browser.document
import kotlin.browser.window

interface InstallationScreenSectionContainer {
    fun hint(id: String): String {
        return when (id) {
            "description" -> "Description"
            "location" -> "Location"
            "pressure_test" -> "Pressure test"
            "backflushing" -> "Water for backflushing"
            "distance_turret_to_stern" -> "Distance Turrent to Stern"
            "heading_control" -> "Heading Control"
            "messenger_markings" -> "Messenger markings"
            "loa" -> "LOA"
            "fsog" -> "FSOG provided by operator"
            "asog" -> "ASOG"
            "spread_turret_moored" -> "Spread / turret moored"
            "normal_discharge_capacity" -> "Normal Discharge Capacity"
            "production_capacity" -> "Production Capacity"
            "storage_capacity" -> "Storage Capacity"
            "vsog_name" -> "VSOG Name"
            "vsog_value" -> "VSOG Value"
            "vhf_channel" -> "VHF Channel"
            "vhf_note" -> "VHF Note"
            "uhf_channel" -> "UHF Channel"
            "uhf_note" -> "UHF Note"
            "field_coordinator" -> "Field Coordinator"
            "field_operator" -> "Field / FPSO Operator"
            "field_manual" -> "Field Manual"
            "sbv_w_emergency_towing_capability" -> "SBV w/emergency towing capability"
            "selcall" -> "Selcall"
            "fairlead_shackle" -> "Fairlead shackle"
            "transitions" -> "Transitions"
            "hose_bridle" -> "Hose bridle"
            "helicopter_frequency" -> "Helicopter Frequency"
            "channel" -> "Channel"
            "rx" -> "Base station RX Frequency"
            "tx" -> "Base station TX Frequency"
            "chaffing_chain" -> "Chafe chain tanker end"
            "hawser" -> "Hawser"
            "hose" -> "Hose"
            "hose_messenger" -> "Hose messenger line"
            "hose_storage" -> "Hose Storage"
            "messenger_line" -> "Messenger Line"
            "pickup_line" -> "Pick up line / Forerunner"
            "weaklink" -> "Weaklink"
            "lower_polyester_rope" -> "Lower Polyester Rope"
            "upper_polyester_rope" -> "Upper Polyester Rope"
            "hev" -> "HEV flange"
            "bollardpull" -> "Bollardpull"
            "communication" -> "Communication"
            "towing_equip" -> "Towing Equipment"
            "name" -> "Name"

            "crude_oil_pressure_yellow" -> "Crude Oil Pressure Warning"
            "crude_oil_pressure_red" -> "Crude Oil Pressure Alarm"
            "crude_oil_pressure_yellow_name" -> "Label (Defaults to Crude Oil Pressure High - Warning)"
            "crude_oil_pressure_red_name" -> "Label (Defaults to Crude Oil Pressure High High - Alarm)"

            "hawser_tension_yellow" -> "Hawser Tension Warning"
            "hawser_tension_yellow_name" -> "Label (Defaults to Hawser Tension High - Warning)"
            "hawser_tension_red" -> "Hawser Tension Alarm"
            "hawser_tension_red_name" -> "Label (Defaults to Hawser Tension High High - Alarm)"

            "hose_tension_yellow" -> "Hose Tension Warning"
            "hose_tension_yellow_name" -> "Label (Defaults to Hose Tension High - Warning)"
            "hose_tension_red" -> "Hose Tension Alarm"
            "hose_tension_red_name" -> "Label (Defaults to Hose Tension High High - Alarm)"

            "heading_limitation_yellow" -> "Bow Base Heading Deviation Warning (Yellow)"
            "heading_limitation_red" -> "Bow Base Heading Deviation Warning (Red)"
            "inner_yellow" -> "Inner Distance Limit Warning (Yellow)"
            "inner_red" -> "Inner Distance Limit Alarm (Red)"
            "normal_green" -> "Normal Green"
            "outer_yellow" -> "Outer Distance Limit Warning (Yellow)"
            "outer_red" -> "Outer Distance Limit Alarm (Red)"
            "sector_yellow" -> "Sector Limit (Yellow)"
            "sector_red" -> "Sector Limit (Red)"
            "pasd_1" -> "PASD 1"
            "pasd_2" -> "PASD 2"
            "flushing/purging" -> "Hose Purging / Flushing"
            "hose_pickup_sector_limits" -> "Hose Pickup Sector Limits"
            "line_markings" -> "Line Markings"
            "restricted_laydown" -> "Restricted Laydown Sectors"
            "shooting_position" -> "Shooting Position"
            "fpso_fanbeam" -> "FPSO Fanbeam"
            "restricted" -> "Restricted Sectors"
            "prohibited" -> "Prohibited Areas"
            "addr_code" -> "Address Code"
            "cyscan/fanbeam" -> "CyScan / Fanbeam"
            "freq_pair" -> "Frequency Pair"
            "hipap" -> "HIPAP"
            "note" -> "Notes"
            "radius" -> "RADius"
            "cargo_export_vlv_shutdown_timing" -> "Cargo export vlv shutdown timing"
            "wind" -> "Wind"
            "sea_state" -> "Sea state"
            "visibility" -> "Visibility (approach / mooring)"
            "hpr" -> "HPR"
            "radius_transponders" -> "RADius transponders"
            "artemis_name" -> "Artemis"
            "artemis_freq_pair" -> "Artemis Frequency Pair"
            "artemis_addr_code" -> "Artemis Address code"
            else -> ""
        }
    }
}

interface UnsavedChanges {
    var hasUnsavedChanges: Boolean
        get() {
            return window.onbeforeunload != null
        }
        set(value) {
            if (value)
                window.onbeforeunload = { "There are unsaved changes. Are you sure that you want to leave this page?" }
            else
                window.onbeforeunload = null
        }
}

open class InstallationScreen(document: Document, private val iid: String) : LeftSectionedScreen(document),
    TableViewDelegate, UnsavedChanges {
    override val activeTab: TopNavBarTab get() = TopNavBarTab.Installations

    val breadcrumbs =
        Breadcrumbs(document, "page_breadcrumbs", arrayOf(Breadcrumb("Installations", "installations.html")))

    protected val navbar = NavBar(document, "page_navbar")
    private val saveButton = Button(document, "save_button", tooltip = "Save changes to this installation")
    val deleteButton = Button(document, "delete_button", tooltip = "Delete this installation")

    val alert = Alert(document)

    private val chaptersList: Array<Tab> = arrayOf(
        Tab("tab-basics", "Basics"),
        Tab("tab-info", "General"),
        Tab("tab-environmental", "Environmental"),
        Tab("tab-communication", "Communication"),
        Tab("tab-telemetry", "Telemetry"),
        Tab("tab-reference-system", "Reference Systems"),
        Tab("tab-mooring", "Mooring"),
        Tab("tab-standby-vessel", "Standby Vessel"),
        Tab("tab-position-limits", "Warning & Alarm Limits"),
        Tab("tab-areas", "Sensitive Areas"),
        Tab("tab-additional-info", "Additional Info")
    )

    val chapters = TableView(document, this, "chapters")
    private var currentChapterId: String? = "tab-basics"

    val basics = InstallationScreenBasicsSection(document, "basics")
    val info = InstallationScreenWithHeaderAndFooter(
        document, "info", arrayOf(
            "location",
            "distance_turret_to_stern",
            "heading_control",
            "loa",
            "fsog",
            "asog",
            "spread_turret_moored",
            "normal_discharge_capacity",
            "production_capacity",
            "storage_capacity",
            "vsog_name",
            "vsog_value",
            "field_operator",
            "field_manual",
            "sbv_w_emergency_towing_capability"
        )
    )
    val communication = InstallationScreenSection(
        document, "communication", arrayOf(
            "vhf_channel",
            "uhf_channel",
            "field_coordinator",
            "helicopter_frequency",
            "uhf_note",
            "vhf_note"
        )
    )
    val environmental = InstallationScreenSection(
        document, "environmental", arrayOf(
            "wind",
            "sea_state",
            "visibility"
        )
    )
    val telemetry = InstallationScreenSection(
        document, "telemetry", arrayOf(
            "channel",
            "rx",
            "tx",
            "selcall"
        )
    )
    val areas = InstallationScreenSection(
        document, "areas", arrayOf(
            "restricted",
            "prohibited",
            "restricted_laydown"
        ), arrayOf(
            "restricted",
            "prohibited",
            "restricted_laydown"
        )
    )
    val mooring = InstallationScreenSection(
        document, "mooring", arrayOf(
            "chaffing_chain",
            "messenger_markings",
            "hawser", "hose",
            "hose_messenger",
            "hose_storage",
            "messenger_line",
            "pickup_line",
            "weaklink",
            "lower_polyester_rope",
            "upper_polyester_rope",
            "hev",
            "fairlead_shackle",
            "transitions",
            "hose_bridle"
        ), arrayOf("messenger_markings")
    )
    val standbyVessel = InstallationScreenSection(
        document, "standby_vessel", arrayOf(
            "bollardpull",
            "communication",
            "towing_equip",
            "name"
        )
    )
    val positionLimits = InstallationScreenPositionLimitsSection(document, "position_limits")

    val additionalInfo = InstallationScreenSection(
        document, "additional_info", arrayOf(
            "flushing/purging",
            "pressure_test",
            "backflushing",
            "line_markings",
            "shooting_position",
            "fpso_fanbeam",
            "cargo_export_vlv_shutdown_timing"
        )
    )
    val referenceSystem = InstallationScreenReferenceSystemSection(
        document, "reference_system", arrayOf(
            "addr_code",
            "cyscan/fanbeam",
            "freq_pair",
            "hipap",
            "name",
            "note",
            "radius",
            "radius_transponders",
            "hpr",
            "artemis_name",
            "artemis_freq_pair",
            "artemis_addr_code"
        ), arrayOf("note")
    )

    init {
        saveButton.onclick = { save() }
        deleteButton.onclick = { delete() }
        basics.name.onblur = { basics.validateName() }
    }

    override fun start() {
        super.start()

        for (cell in cells)
            cell.li.onclick = null
        cells.clear()

        chapters.reloadData()

        for (chapter in chaptersList) {
            val chapterId = chapter.id
            val cell = ChapterCell(chapterId)
            cell.li.onclick = { clickedChapter(chapterId) }
            cells.add(cell)
        }

        reloadCurrentChapter()

        basics.region.start(this)
        {
            if (it)
                refresh()
            else {
                content.hidden = true
                error.hidden = false
                saveButton.hidden = true
                error.text = "Failed to load region info"
            }
        }

        basics.loadingSystem.start(this)
        {
            if (it)
                refresh()
            else {
                content.hidden = true
                error.hidden = false
                saveButton.hidden = true
                error.text = "Failed to load loading system info"
            }
        }
    }

    open fun refresh() {
        val id = iid

        loading = true
        saveButton.hidden = true

        Requests.getInstallation(this, id)
        {
            loading = false
            val installation = it.installation
            if (installation != null) {
                saveButton.hidden = false

                refreshed(installation)

                hasUnsavedChanges = false
            } else {
                content.hidden = true
                error.hidden = false
                saveButton.hidden = true
                error.text = "Failed to load installation info"
            }
        }
    }

    var installation: Installation? = null

    fun refreshed(data: Installation) {
        installation = data

        navbar.title = data.name

        basics.update(data)
        info.update(data.info)
        environmental.update(data.environmental)
        communication.update(data.communication)
        telemetry.update(data.telemetry)
        mooring.update(data.mooring)
        standbyVessel.update(data.standbyVessel)
        positionLimits.update(data)
        areas.update(data.areas)
        additionalInfo.update(data.additionalInfo)
        referenceSystem.update(data)
    }

    fun updates(installation: Installation): ItemUpdates {
        val updates = Any().asDynamic()
        var hasChanges = false

        fun addChanged(named: String, current: String) {
            updates[named] = current
        }

        fun addIfChanged(named: String, current: Boolean, change: Boolean) {
            if (current != change) {
                updates[named] = change
                hasChanges = true
            }
        }

        fun addIfChanged(named: String, current: String, change: String) {
            if (current != change) {
                updates[named] = change
                hasChanges = true
            }
        }

        fun addIfChanged(named: String, current: String?, change: String?) {
            if (current != change) {
                updates[named] = change
                hasChanges = true
            }
        }

        fun addIfChanged(current: InstallationSection, change: InstallationScreenSection) {
            var usedKeys: MutableList<String> = mutableListOf()
            for (cur in current.data) {
                val currentValue = cur.value
                val changeValue = change.fields[cur.key]?.text ?: ""
                addIfChanged("${current.name}.${cur.key}", currentValue, changeValue)
                usedKeys.add(cur.key)
            }

            val others = change.fields.keys.subtract(usedKeys)
            for (key in others) {
                val named = "${current.name}.$key"
                val changedValue = change.fields[key]?.text ?: ""
                updates[named] = changedValue
                hasChanges = true
            }

            val headerAndFooter = change as? InstallationScreenWithHeaderAndFooter
            if (headerAndFooter != null) {
                val named = "${current.name}.headers"
                var headers: MutableList<dynamic> = mutableListOf()
                val currentHeader = current.headers.firstOrNull()
                if (change.headerText.text.isNotBlank()) {
                    if (currentHeader?.type != change.headerType.selected || currentHeader.text != change.headerText.text) {
                        headers.add(Blurb(change.headerType.selected, change.headerText.text).toDynamic())

                        updates[named] = headers.toTypedArray()
                        hasChanges = true
                    }
                } else if (change.headerText.text.isBlank()) {
                    updates[named] = headers.toTypedArray()
                    hasChanges = true
                }

                // TODO: Footers
            }
        }

        fun addIfChanged(
            named: String,
            current: InstallationDARPSEntry,
            change: InstallationScreenReferenceSystemSection.DarpsField
        ) {
            if (current.name != change.name.text || current.value != change.value.text) {
                val data = Any().asDynamic()
                data.name = change.name.text
                data.value = change.value.text
                updates[named] = data
                hasChanges = true
            }
        }

        fun hasChanged(current: List<InstallationTransponder>, change: List<InstallationTransponder>): Boolean {
            if (current.count() != change.count()) return true
            if (current != change) return true
            return false
        }

        fun addIfChangedR(current: InstallationReferenceSystem, change: InstallationScreenReferenceSystemSection) {
            addIfChanged(current, change)

            val hasPosChanges = hasChanged(current.transponders.position, change.position.list)
            val hasOthChanges = hasChanged(current.transponders.other, change.other.list)

            if (hasPosChanges || hasOthChanges) {
                val data = Any().asDynamic()
                data.position = change.position.list.map { it.toDynamic() }
                data.other = change.other.list.map { it.toDynamic() }
                updates["${current.name}.transponders"] = data
                hasChanges = true
            }

            addIfChanged("${current.name}.darps.darps1", current.darps.darps1, change.darps1)
            addIfChanged("${current.name}.darps.darps2", current.darps.darps2, change.darps2)
            addIfChanged("${current.name}.darps.tdma1", current.darps.tdma1, change.tdma1)
            addIfChanged("${current.name}.darps.tdma2", current.darps.tdma2, change.tdma2)
        }

        fun hasChanged(
            current: List<InstallationPositionLimits.Section>,
            change: List<InstallationScreenPositionLimitsSection.Section>
        ): Boolean {
            if (current.count() != change.count()) return true
            for (i in current.withIndex()) {
                if (change[i.index].hasChanged(i.value))
                    return true
            }
            return false
        }

        fun addIfChangedPL(current: InstallationPositionLimits, change: InstallationScreenPositionLimitsSection) {
            addIfChanged(current, change)

            val hasLimitsChanged = hasChanged(current.sections, change.sections)

            if (hasLimitsChanged) {
                updates["${current.name}.sections"] = change.sections.map { it.toDynamic() }.toTypedArray()
                hasChanges = true
            }
        }

        basics.validateData()

        if (!basics.name.isValid)
            return ItemUpdates(error = basics.name.validationMessage)

        addChanged("document_type", installation.documentType)

        addIfChanged("disabled", installation.disabled, basics.disabled.checked)

        addIfChanged("name", installation.name, basics.name.text)
        addIfChanged("full_name", installation.fullName ?: "", basics.fullName.text)
        addIfChanged("region", installation.region, basics.region.selected)
        addIfChanged("loading_system", installation.loadingSystem, basics.loadingSystem.selected)

        addIfChanged(installation.info, info)
        addIfChanged(installation.environmental, environmental)
        addIfChanged(installation.additionalInfo, additionalInfo)
        addIfChanged(installation.communication, communication)
        addIfChanged(installation.telemetry, telemetry)
        addIfChanged(installation.areas, areas)
        addIfChangedPL(installation.positionLimits, positionLimits)
        addIfChanged(installation.mooring, mooring)
        addIfChanged(installation.standbyVessel, standbyVessel)
        addIfChangedR(installation.referenceSystem, referenceSystem)

        if (hasChanges)
            return ItemUpdates(data = updates)
        return ItemUpdates(error = "No changes to save")
    }

    open fun save() {
        val id = iid
        val installation = installation ?: return

        val updates = updates(installation)
        val data = updates.data
        if (data == null) {
            showToast(updates.error ?: "No changes to save")
            return
        }

        loading = true

        Requests.updateInstallation(this, id, data) {
            loading = false
            if (it.error != null) {
                showToast(it, "Failed to change installation info")
            } else {
                showToast(it, "Installation info changed!")
                refresh()
            }
        }
    }

    fun delete(confirmed: Boolean = false) {
        if (confirmed) {
            loading = true
            Requests.deleteInstallation(this, iid)
            {
                loading = false
                if (it.error != null) {
                    showToast(it, "Failed to delete installation")
                } else {
                    pushTo("installations", "Installation deleted!")
                }
            }
        } else {
            alert.open(
                "Delete Installation",
                "Are you sure you would like to delete this installation?",
                arrayOf(AlertAction("cancel", "Cancel"), AlertAction("delete", "Delete"))
            )
            {
                if (it == "delete") {
                    delete(true)
                }
            }
        }
    }

    var cells: MutableList<ChapterCell> = mutableListOf()

    class ChapterCell(val id: String) {
        val li = Li(document, "cell-$id")
        val label = Span(document, "cell-$id-label")
    }

    fun clickedChapter(id: String) {
        println("Clicked $id")
        currentChapterId = id
        reloadCurrentChapter()
    }

    fun reloadCurrentChapter() {
        for (cell in cells) {
            val shouldShow = currentChapterId == cell.id
            if (shouldShow)
                cell.label.addClasses(arrayOf("current-selection"))
            else
                cell.label.removeClasses(arrayOf("current-selection"))

            val div = Div(document, cell.id)
            div.hidden = !shouldShow
        }
    }

    override val numberOfRows: Int
        get() {
            return chaptersList.count()
        }

    override fun cellForRow(at: Int): String {
        val chapter = chaptersList[at]
        return """
        <li class="mdc-list-item" id="cell-${chapter.id}">
            <span class="mdc-list-item__text" id="cell-${chapter.id}-label">${chapter.name}</span>
        </li>
        """
    }
}

class InstallationScreenBasicsSection(document: Document, val id: String) : UnsavedChanges {
    val name = EditText(document, "name", "Name", required = true, helperTextId = "name_helper_text")
    val fullName = EditText(document, "fullName", "Full Name")
    val loadingSystem: InstallationLoadingSystemSelector =
        InstallationLoadingSystemSelector(document, "loading_system", "Loading System", listOf(), SelectorNotSelectedMode.None)
    val region = InstallationRegionSelector(document, "region", "Region", SelectorNotSelectedMode.None)
    val disabled = Switch(
        document, "disabled", "Disabled", tooltip = "Disabled installations will not show up when " +
                "selecting which installation the SeaPad application is assigned to"
    )

    fun update(data: Installation) {
        name.textChanged = null
        fullName.textChanged = null
        loadingSystem.valueChanged = null
        region.valueChanged = null
        disabled.valueChanged = null

        name.text = data.name
        fullName.text = data.fullName ?: ""
        region.selected = data.region
        loadingSystem.selected = data.loadingSystem
        disabled.checked = data.disabled

        name.textChanged = { validateName(); fieldChanged(it) }
        fullName.textChanged = { fieldChanged(it) }
        loadingSystem.valueChanged = { hasUnsavedChanges = true }
        region.valueChanged = { hasUnsavedChanges = true }
        disabled.valueChanged = { hasUnsavedChanges = true }
    }

    private fun fieldChanged(text: String) {
        hasUnsavedChanges = true
    }

    fun validateName() {
        if (name.text.isEmpty()) name.setValidation("Name is required")
        else if (!name.text.matches("^[a-zA-Z.ÅåØøÆæ0-9\\- ]+$".toRegex())) name.setValidation("Enter name in correct format e.g. Hebron NSAL");
        else name.setValidation("");
    }

    fun validateData() {
        validateName()
    }
}

open class InstallationScreenWithHeaderAndFooter(document: Document, name: String, ids: Array<String>) :
    InstallationScreenSection(document, name, ids) {
    val headerText = EditText(document, "$name-section_header", "Header Text", "text")
    val headerType = SectionHeaderTypeSelector(document, "$name-section_header_type", "Header Type")
    private val headerPreview = Div(document, "$name-section_header_preview")

    var section: InstallationSection? = null

    init {
        headerType.selected = BlurbType.Text
    }

    override fun update(section: InstallationSection) {
        super.update(section)

        this.section = section

        hookupChangeListeners(false)

        val header = section.headers.firstOrNull()
        if (header == null) {
            hookupChangeListeners(true)
            reloadHeaderPreview()
            return
        }
        headerText.text = header.text
        headerType.selected = header.type

        reloadHeaderPreview(header.text, header.type)

        hookupChangeListeners(true)
    }

    private fun hookupChangeListeners(hookup: Boolean) {
        if (hookup) {
            val callback = {
                hasUnsavedChanges = true
                reloadHeaderPreview(headerText.text, headerType.selected)
            }
            headerText.textChanged = { _ -> callback()}
            headerType.valueChanged = { callback() }
        } else {
            headerText.textChanged = null
            headerType.valueChanged = null
        }
    }

    private fun reloadHeaderPreview(text: String = "", type: BlurbType = BlurbType.Text) {
        if (text == "") {
            headerPreview.html = """<div style="margin-bottom:16px;"><center>No Header</center></div>"""
            return
        }
        val header = Blurb(type, text)
        headerPreview.html = BlurbsView.markup(this, listOf(header), BlurbPlacement.Headers())
    }
}

open class InstallationScreenSection(
    document: Document,
    val name: String,
    val ids: Array<String>,
    areas: Array<String> = arrayOf()
) : ProcedureConfig, InstallationScreenSectionContainer, UnsavedChanges {
    var fields: MutableMap<String, TextField> = mutableMapOf()

    override val handbook = Handbook("whatever", "Whatever", true)

    init {
        for (id in ids) {
            val field = if (areas.contains(id)) EditTextArea(document, "$name.$id", hint(id)) else EditText(
                document,
                "$name.$id",
                hint(id)
            )
            fields[id] = field
        }
    }

    open fun update(section: InstallationSection) {
        for (item in fields) {
            item.value.textChanged = null
            val v = section.data[item.key]
            item.value.text = v ?: ""
            item.value.textChanged = { fieldChanged(it) }
        }
    }

    private fun fieldChanged(text: String) {
        hasUnsavedChanges = true
    }
}

class InstallationScreenReferenceSystemSection(
    document: Document,
    name: String,
    ids: Array<String>,
    areas: Array<String> = arrayOf()
) : InstallationScreenSection(document, name, ids, areas) {
    class DarpsField(document: Document, name: String, field_name: String, msg: String) : UnsavedChanges {
        val name = EditText(document, "$name.$field_name.name", "$msg Label")
        val value = EditText(document, "$name.$field_name.value", "$msg Value")

        fun update(data: InstallationDARPSEntry) {
            name.textChanged = null
            value.textChanged = null

            name.text = data.name
            value.text = data.value

            name.textChanged = { hasUnsavedChanges = true }
            value.textChanged = { hasUnsavedChanges = true }
        }
    }

    class TranspondersField(document: Document, val name: String, val field_name: String) : TableViewDelegate,
        UnsavedChanges {
        val table = TableView(document, this, "$name.transponders.$field_name")
        val list: MutableList<InstallationTransponder> = mutableListOf()
        val cells: MutableList<TransponderCell> = mutableListOf()

        val transponderName = EditText(document, "$name.transponders.$field_name.name", "New Transponder APOS Name")
        val channel = EditText(document, "$name.transponders.$field_name.channel", "New Transponder Channel")
        val number = EditText(document, "$name.transponders.$field_name.number", "New Transponder Serial Number")
        val createButton = Button(document, "$name.transponders.$field_name.create_button")

        init {
            createButton.onclick = { createTransponder() }
        }

        fun createTransponder() {
            val no = number.text
            val ch = channel.text
            val n = transponderName.text

            if (no.isEmpty() || ch.isEmpty()) return

            val transponder = InstallationTransponder(n, ch, no)
            list.add(transponder)

            reloadData()

            hasUnsavedChanges = true

            number.text = ""
            channel.text = ""
            transponderName.text = ""
        }

        fun update(data: List<InstallationTransponder>) {
            list.clear()
            list.addAll(data)

            reloadData()
        }

        fun reloadData() {
            table.reloadData()
            reloadCells()
        }

        fun clickedTransponder(at: Int) {
            for ((index, cell) in cells.withIndex()) {
                if (index != at)
                    cell.content.hidden = true
                else
                    cell.content.hidden = !cell.content.hidden
            }
        }

        fun openTransponder(at: Int) {
            for ((index, cell) in cells.withIndex()) {
                cell.content.hidden = index != at
            }
        }

        fun clickedDelete(at: Int) {
            list.removeAt(at)
            reloadData()
            hasUnsavedChanges = true
        }

        fun clickedUp(at: Int) {
            val section = list.removeAt(at)
            list.add(at - 1, section)
            reloadData()
            openTransponder(at - 1)
            hasUnsavedChanges = true
        }

        fun clickedDown(at: Int) {
            val section = list.removeAt(at)
            list.add(at + 1, section)
            reloadData()
            openTransponder(at + 1)
            hasUnsavedChanges = true
        }

        fun reloadCells() {
            cells.map { it.cleanup() }
            cells.clear()

            for ((index, _) in list.withIndex()) {
                val cell = TransponderCell(name, field_name, index)
                cell.cell.onclick = { clickedTransponder(index) }
                cell.deleteButton.onclick = { clickedDelete(index) }
                if (index != 0) {
                    cell.upButton.onclick = { clickedUp(index) }
                } else {
                    cell.upButton.hidden = true
                }
                if (index != list.lastIndex) {
                    cell.downButton.onclick = { clickedDown(index) }
                } else {
                    cell.downButton.hidden = true
                }
                cells.add(cell)
            }
        }

        override fun cellForRow(at: Int): String {
            val transponder = list[at]
            return TransponderCell.markup(transponder, name, field_name, at)
        }

        override val numberOfRows: Int
            get() {
                return list.count()
            }
    }

    val darps1 = DarpsField(document, name, "darps1", "DARPS 1")
    val darps2 = DarpsField(document, name, "darps2", "DARPS 2")
    val tdma1 = DarpsField(document, name, "tdma1", "TDMA 1")
    val tdma2 = DarpsField(document, name, "tdma2", "TDMA 2")

    val position = TranspondersField(document, name, "position")
    val other = TranspondersField(document, name, "other")

    fun update(data: Installation) {
        update(data.referenceSystem)

        darps1.update(data.referenceSystem.darps.darps1)
        darps2.update(data.referenceSystem.darps.darps2)
        tdma1.update(data.referenceSystem.darps.tdma1)
        tdma2.update(data.referenceSystem.darps.tdma2)

        position.update(data.referenceSystem.transponders.position)
        other.update(data.referenceSystem.transponders.other)
    }
}

class TransponderCell(name: String, field_name: String, at: Int) {
    val cellId: String = "cell-$name.transponders.$field_name-$at"
    val cell = Li(document, "$cellId-cell")
    val content = Div(document, "$cellId-content", "inline-block")
    val deleteButton = Button(document, "$cellId-delete_button")
    val upButton = Button(document, "$cellId-up_button")
    val downButton = Button(document, "$cellId-down_button")

    fun cleanup() {
        deleteButton.onclick = null
        upButton.onclick = null
        downButton.onclick = null
        cell.onclick = null
    }

    companion object {
        fun markup(transponder: InstallationTransponder, name: String, field_name: String, at: Int): String {
            val channel = "Channel: ${transponder.ch}"
            var number = "Serial Number: ${transponder.no}"
            if (transponder.name.isNotBlank())
                number += " APOS name: ${transponder.name}"
            val contentStyle = "style='display:none;'"

            return """

            <li class="mdc-list-item" id="cell-$name.transponders.$field_name-$at-cell">
                <span class="mdc-list-item__text">
                    <span class="mdc-list-item__primary-text">$channel</span>
                    <span class="mdc-list-item__secondary-text">$number</span>

                    <div id="cell-$name.transponders.$field_name-$at-content" $contentStyle>
                        <div class="transponder-cell-buttons">
                            <button class="mdc-button" id="cell-$name.transponders.$field_name-$at-delete_button"><i class="material-icons mdc-button__icon" aria-hidden="true">delete</i>Delete</button>
                            <button class="mdc-button" id="cell-$name.transponders.$field_name-$at-up_button"><i class="material-icons mdc-button__icon" aria-hidden="true">arrow_upward</i>Up</button>
                            <button class="mdc-button" id="cell-$name.transponders.$field_name-$at-down_button"><i class="material-icons mdc-button__icon" aria-hidden="true">arrow_downward</i>Down</button>
                        </div>
                    </div>

                </span>
            </li>
            """
        }
    }
}

class InstallationScreenPositionLimitsSection(document: Document, name: String) :
    InstallationScreenSection(document, name, arrayOf()) {
    private val createButton = Button(document, "$name.create_button")

    val view = Div(document, "position_limits")
    val list: MutableList<InstallationPositionLimits.Section> = mutableListOf()
    val sections: MutableList<Section> = mutableListOf()

    val alert = Alert(document)

    init {
        createButton.onclick = {
            val isChanged = sections.any {
                val index = sections.indexOf(it)
                it.hasChanged(list[index])
            }
            if (isChanged) {
                alert.open(
                    "Unsaved changes", "There are unsaved changes. Are you sure you want to continue?",
                    arrayOf(AlertAction("cancel", "CANCEL"), AlertAction("ok", "OK"))
                ) {
                    if (it == "cancel") hasUnsavedChanges = true else createLimits()
                }
            } else {
                createLimits()
            }
        }
    }

    fun update(data: Installation) {
        list.clear()
        list.addAll(data.positionLimits.sections)
        reloadData()
    }

    private fun createLimits() {
        list.add(InstallationPositionLimits.Section())
        reloadData()
        hasUnsavedChanges = true
    }

    private fun removeLimits(limits: InstallationPositionLimits.Section) {
        list.remove(limits)
        reloadData()
        hasUnsavedChanges = true
    }

    fun reloadData() {
        for (section in sections)
            section.cleanup()
        sections.clear()

        var html = ""
        var index = 0
        for (limit in list) {
            html += Section.markup(index)
            index += 1
        }

        view.html = html

        index = 0
        for (limit in list) {
            val cell = Section(limit, index)
            cell.deleteButton.onclick = { removeLimits(limit) }
            sections.add(cell)
            index += 1
        }
    }

    class Section(data: InstallationPositionLimits.Section, var at: Int) : InstallationScreenSectionContainer {
        fun textfield(key: String): EditText {
            return EditText(document, "position_limits.$at.$key", hint(key))
        }

        fun cleanup() {
            deleteButton.onclick = null
            description.textChanged = null
//            upButton.onclick = null
//            downButton.onclick = null
//            cell.onclick = null
        }

        val title = Div(document, "position_limits.$at.title")
        val deleteButton = Button(document, "position_limits.$at.delete_button")

        val description = textfield("description")
        val hose_pickup_sector_limits = textfield("hose_pickup_sector_limits")

        val crude_oil_pressure_yellow = textfield("crude_oil_pressure_yellow")
        val crude_oil_pressure_yellow_name = textfield("crude_oil_pressure_yellow_name")
        val crude_oil_pressure_red = textfield("crude_oil_pressure_red")
        val crude_oil_pressure_red_name = textfield("crude_oil_pressure_red_name")

        val hawser_tension_yellow = textfield("hawser_tension_yellow")
        val hawser_tension_yellow_name = textfield("hawser_tension_yellow_name")
        val hawser_tension_red = textfield("hawser_tension_red")
        val hawser_tension_red_name = textfield("hawser_tension_red_name")

        val hose_tension_yellow = textfield("hose_tension_yellow")
        val hose_tension_yellow_name = textfield("hose_tension_yellow_name")
        val hose_tension_red = textfield("hose_tension_red")
        val hose_tension_red_name = textfield("hose_tension_red_name")

        val heading_limitation_red = textfield("heading_limitation_red")
        val heading_limitation_yellow = textfield("heading_limitation_yellow")
        val inner_red = textfield("inner_red")
        val inner_yellow = textfield("inner_yellow")
        val normal_green = textfield("normal_green")
        val outer_red = textfield("outer_red")
        val outer_yellow = textfield("outer_yellow")
        val pasd_1 = textfield("pasd_1")
        val pasd_2 = textfield("pasd_2")
        val sector_yellow = textfield("sector_yellow")
        val sector_red = textfield("sector_red")

        fun hasChanged(current: InstallationPositionLimits.Section): Boolean {
            if (current.description != description.text) return true
            if (current.hose_pickup_sector_limits != hose_pickup_sector_limits.text) return true

            if (current.crude_oil_pressure_yellow != crude_oil_pressure_yellow.text) return true
            if (current.crude_oil_pressure_yellow_name != crude_oil_pressure_yellow_name.text) return true
            if (current.crude_oil_pressure_red != crude_oil_pressure_red.text) return true
            if (current.crude_oil_pressure_red_name != crude_oil_pressure_red_name.text) return true

            if (current.hawser_tension_yellow != hawser_tension_yellow.text) return true
            if (current.hawser_tension_yellow_name != hawser_tension_yellow_name.text) return true
            if (current.hawser_tension_red != hawser_tension_red.text) return true
            if (current.hawser_tension_red_name != hawser_tension_red_name.text) return true

            if (current.hose_tension_yellow != hose_tension_yellow.text) return true
            if (current.hose_tension_yellow_name != hose_tension_yellow_name.text) return true
            if (current.hose_tension_red != hose_tension_red.text) return true
            if (current.hose_tension_red_name != hose_tension_red_name.text) return true

            if (current.heading_limitation_yellow != heading_limitation_yellow.text) return true
            if (current.heading_limitation_red != heading_limitation_red.text) return true
            if (current.inner_yellow != inner_yellow.text) return true
            if (current.inner_red != inner_red.text) return true
            if (current.normal_green != normal_green.text) return true
            if (current.outer_yellow != outer_yellow.text) return true
            if (current.outer_red != outer_red.text) return true
            if (current.pasd_1 != pasd_1.text) return true
            if (current.pasd_2 != pasd_2.text) return true
            if (current.sector_yellow != sector_yellow.text) return true
            if (current.sector_red != sector_red.text) return true
            return false
        }

        init {
            description.text = data.description
            hose_pickup_sector_limits.text = data.hose_pickup_sector_limits

            crude_oil_pressure_yellow.text = data.crude_oil_pressure_yellow
            crude_oil_pressure_yellow_name.text = data.crude_oil_pressure_yellow_name
            crude_oil_pressure_red.text = data.crude_oil_pressure_red
            crude_oil_pressure_red_name.text = data.crude_oil_pressure_red_name

            hawser_tension_yellow.text = data.hawser_tension_yellow
            hawser_tension_yellow_name.text = data.hawser_tension_yellow_name
            hawser_tension_red.text = data.hawser_tension_red
            hawser_tension_red_name.text = data.hawser_tension_red_name

            hose_tension_yellow.text = data.hose_tension_yellow
            hose_tension_yellow_name.text = data.hose_tension_yellow_name
            hose_tension_red.text = data.hose_tension_red
            hose_tension_red_name.text = data.hose_tension_red_name

            heading_limitation_red.text = data.heading_limitation_red
            heading_limitation_yellow.text = data.heading_limitation_yellow
            inner_red.text = data.inner_red
            inner_yellow.text = data.inner_yellow
            normal_green.text = data.normal_green
            outer_red.text = data.outer_red
            outer_yellow.text = data.outer_yellow
            pasd_1.text = data.pasd_1
            pasd_2.text = data.pasd_2
            sector_yellow.text = data.sector_yellow
            sector_red.text = data.sector_red

            reloadTitle()
            description.textChanged = { reloadTitle() }
        }

        private fun reloadTitle() {
            title.text = description.text
        }

        companion object {
            fun markup(at: Int): String {
                return """
<div class="page_fields_improved" style="margin-bottom:16px;">
 
    <div class="page_fields-position_limits-title" id="position_limits.$at.title"></div>

    <div class="mdc-text-field edit" id="position_limits.$at.description"></div>
    <div class="mdc-text-field edit" id="position_limits.$at.hose_pickup_sector_limits"></div>
    <div class="mdc-text-field edit" id="position_limits.$at.heading_limitation_yellow"></div>
    <div class="mdc-text-field edit" id="position_limits.$at.heading_limitation_red"></div> 
    <div class="mdc-text-field edit" id="position_limits.$at.inner_yellow"></div>
    <div class="mdc-text-field edit" id="position_limits.$at.inner_red"></div>
    <div class="mdc-text-field edit" id="position_limits.$at.normal_green"></div> 
    <div class="mdc-text-field edit" id="position_limits.$at.outer_yellow"></div>
    <div class="mdc-text-field edit" id="position_limits.$at.outer_red"></div>
    <div class="mdc-text-field edit" id="position_limits.$at.pasd_1"></div>
    <div class="mdc-text-field edit" id="position_limits.$at.pasd_2"></div>
    <div class="mdc-text-field edit" id="position_limits.$at.sector_yellow"></div>
    <div class="mdc-text-field edit" id="position_limits.$at.sector_red"></div>

    <div class="full_width">
        <p>Crude Oil Pressure</p>
        <div style="margin-bottom:8px;">
            <div class="mdc-text-field edit" id="position_limits.$at.crude_oil_pressure_yellow_name"></div>
            <div class="mdc-text-field edit" id="position_limits.$at.crude_oil_pressure_yellow"></div>
        </div>
        <div> 
            <div class="mdc-text-field edit" id="position_limits.$at.crude_oil_pressure_red_name"></div>
            <div class="mdc-text-field edit" id="position_limits.$at.crude_oil_pressure_red"></div>
        </div>
    </div>
    
    <div class="full_width">
        <p>Hawser Tension</p>
        <div style="margin-bottom:8px;">
            <div class="mdc-text-field edit" id="position_limits.$at.hawser_tension_yellow_name"></div>
            <div class="mdc-text-field edit" id="position_limits.$at.hawser_tension_yellow"></div> 
        </div>
        <div>
            <div class="mdc-text-field edit" id="position_limits.$at.hawser_tension_red_name"></div>
            <div class="mdc-text-field edit" id="position_limits.$at.hawser_tension_red"></div>
        </div>             
    </div>
    
    <div class="full_width">
        <p>Hose Tension</p>
        <div style="margin-bottom:8px;">
            <div class="mdc-text-field edit" id="position_limits.$at.hose_tension_yellow_name"></div>
            <div class="mdc-text-field edit" id="position_limits.$at.hose_tension_yellow"></div> 
        </div>
        <div>
            <div class="mdc-text-field edit" id="position_limits.$at.hose_tension_red_name"></div>        
            <div class="mdc-text-field edit" id="position_limits.$at.hose_tension_red"></div>
         </div>
    </div>
    
    <div class="full_width">
        <button class="mdc-button" id="position_limits.$at.delete_button"><i class="material-icons mdc-button__icon" aria-hidden="true">delete</i>Remove this Position Limits Section</button>
    </div>
</div>
            """
            }
        }

        fun toDynamic(): dynamic {
            val updates = Any().asDynamic()
            updates.description = description.text
            updates.hose_pickup_sector_limits = hose_pickup_sector_limits.text

            updates.crude_oil_pressure_yellow = crude_oil_pressure_yellow.text; updates.crude_oil_pressure_yellow_name =
                crude_oil_pressure_yellow_name.text
            updates.crude_oil_pressure_red = crude_oil_pressure_red.text; updates.crude_oil_pressure_red_name =
                crude_oil_pressure_red_name.text
            updates.hawser_tension_yellow = hawser_tension_yellow.text; updates.hawser_tension_yellow_name =
                hawser_tension_yellow_name.text
            updates.hawser_tension_red = hawser_tension_red.text; updates.hawser_tension_red_name =
                hawser_tension_red_name.text
            updates.hose_tension_yellow = hose_tension_yellow.text; updates.hose_tension_yellow_name =
                hose_tension_yellow_name.text
            updates.hose_tension_red = hose_tension_red.text; updates.hose_tension_red_name = hose_tension_red_name.text

            updates.heading_limitation_yellow = heading_limitation_yellow.text
            updates.heading_limitation_red = heading_limitation_red.text
            updates.inner_yellow = inner_yellow.text
            updates.inner_red = inner_red.text
            updates.normal_green = normal_green.text
            updates.outer_yellow = outer_yellow.text
            updates.outer_red = outer_red.text
            updates.pasd_1 = pasd_1.text
            updates.pasd_2 = pasd_2.text
            updates.sector_yellow = sector_yellow.text
            updates.sector_red = sector_red.text
            return updates
        }
    }
}

class CreateInstallationScreen(document: Document) : InstallationScreen(document, "") {
    init {
        deleteButton.hidden = true
        navbar.title = "Create Installation"
        //basics.loadingSystem.selected = InstallationLoadingSystem.Unknown
    }

    override fun refresh() {
        loading = false
    }

    override fun save() {
        val installation = Installation()
        val updates = updates(installation)
        val data = updates.data
        if (data == null) {
            showToast(updates.error ?: "No changes to save")
            return
        }

        loading = true

        Requests.createInstallation(this, data)
        {
            loading = false
            if (it.error != null) {
                showToast(it, "Failed to create installation")
            } else {
                hasUnsavedChanges = false
                pushTo("installations", it, "Installation created!")
            }
        }
    }
}