package main.kotlin.data

import main.kotlin.ui.Div
import main.kotlin.ui.Icons

interface NamedItem
{
    val name : String
}

interface IdentifiedItem : NamedItem
{
    val id : String
}

interface FilterByNameModel
{
    fun matchesSearch(text:String,item:NamedItem) : Boolean
    {
        return item.name.contains(text, ignoreCase = true)
    }
}

interface ContainerPanel
{
    val container : Div

    var hidden : Boolean
    get()
    {
        return container.hidden
    }
    set(value)
    {
        container.hidden = value
    }
}

enum class ContainerEditorPanelType
{
    Default,
    Settings,
    Step,
    Blurb,
    Requirements,
    Images,
    ChoiceBlurb,
    SingleSelectionItemBlurb,
    MultipleSelectionItemBlurb
}

interface ContainerEditorPanel : ContainerPanel
{
    val panelType : ContainerEditorPanelType
}

interface Specificable
{
    var ships : List<String>
    var classes : List<String>
    var destinations : List<String>
    var types : List<String>
    var regions : List<String>

    fun updateSpecifics(dyn:dynamic)
    {
        ships = (dyn.ships as? Array<String>)?.toList() ?: ships
        destinations = (dyn.destinations as? Array<String>)?.toList() ?: destinations
        classes = (dyn.classes as? Array<String>)?.toList() ?: classes
        types = (dyn.types as? Array<String>)?.toList() ?: types
        regions = (dyn.regions as? Array<String>)?.toList() ?: regions
    }
}

interface SpecificableInfoProvider
{
    fun getShip(id:String) : Ship?
    fun getInstallation(id:String) : InstallationCard?

    fun getVesselType(id: String): IdentifiedItem?
    fun getVesselClass(id: String): IdentifiedItem?
    fun getRegion(id: String): IdentifiedItem?
}

fun Specificable.regionRequirementsTooltip(thing:String, provider:SpecificableInfoProvider) : String
{
    val requiresRegion = regions.isNotEmpty()
    if (!requiresRegion)
        return """<div class="tooltip-title">Regions</div><div class="tooltip-description">This $thing is visible for all available regions</div>"""

    var regionsMarkup = """<div class="tooltip-items">"""
    for (regionId in regions) {
        val region = provider.getRegion(regionId)?.name ?: regionId
        regionsMarkup += """<div class="tooltip-item"><i class="material-icons">public</i><div class="tooltip-item-text">$region</div></div>"""
    }
    regionsMarkup += "</div>"

    return """
    <div class="tooltip-title">Regions</div><div class="tooltip-description">This $thing is only visible for the following regions:</div>
    $regionsMarkup
"""
}

fun Specificable.shipRequirementsTooltip(thing:String,provider:SpecificableInfoProvider) : String
{
    val requiresShip = ships.isNotEmpty() || classes.isNotEmpty() || types.isNotEmpty()
    if (!requiresShip)
        return """<div class="tooltip-title">Vessels</div><div class="tooltip-description">This $thing is visible for all available vessels, classes and types</div>"""

    var shipsMarkup = """<div class="tooltip-items"><div class="tooltip-items-title">Vessels</div>"""
    if (ships.isNotEmpty())
        for (shipId in ships)
        {
            val ship = provider.getShip(shipId)?.name ?: shipId
            shipsMarkup += """<div class="tooltip-item"><i class="material-icons">${Icons.ships}</i><div class="tooltip-item-text">$ship</div></div>"""
        }
    else
        shipsMarkup += """<div class="tooltip-item"><i class="material-icons">${Icons.ships}</i><div class="tooltip-item-text">All vessels</div></div>"""
    shipsMarkup += "</div>"

    var classesMarkup = """<div class="tooltip-items"><div class="tooltip-items-title">Classes</div>"""
    if (classes.isNotEmpty())
        for (classId in classes) {
            val vesselClass = provider.getVesselClass(classId)?.name ?: classId
            classesMarkup += """<div class="tooltip-item"><i class="material-icons">${Icons.classes}</i><div class="tooltip-item-text">$vesselClass</div></div>"""
        }
    else
        classesMarkup += """<div class="tooltip-item"><i class="material-icons">${Icons.classes}</i><div class="tooltip-item-text">All classes</div></div>"""
    classesMarkup += "</div>"

    var typesMarkup = """<div class="tooltip-items"><div class="tooltip-items-title">Types</div>"""
    if (types.isNotEmpty())
        for (typeId in types) {
            val vesselType = provider.getVesselType(typeId)?.name ?: typeId
            typesMarkup += """<div class="tooltip-item"><i class="material-icons">${Icons.types}</i><div class="tooltip-item-text">$vesselType</div></div>"""
        }
    else
        typesMarkup += """<div class="tooltip-item"><i class="material-icons">${Icons.types}</i><div class="tooltip-item-text">All types</div></div>"""
    typesMarkup += "</div>"

    return """
    <div class="tooltip-title">Vessels</div><div class="tooltip-description">This $thing is only visible for the following:</div>
    $shipsMarkup
    $classesMarkup
    $typesMarkup
"""
}

fun Specificable.destinationRequirementsTooltip(thing:String,provider:SpecificableInfoProvider) : String
{
    val requiresDestination = destinations.isNotEmpty()
    if (!requiresDestination)
        return """<div class="tooltip-title">Installations</div><div class="tooltip-description">This $thing is visible for all available installations</div>"""

    var destinationsMarkup = """<div class="tooltip-items">"""
    for (destinationId in destinations)
    {
        val destination = provider.getInstallation(destinationId)?.name ?: destinationId
        destinationsMarkup += """<div class="tooltip-item"><i class="material-icons">${Icons.destinations}</i><div class="tooltip-item-text">$destination</div></div>"""
    }
    destinationsMarkup += "</div>"

    return """
    <div class="tooltip-title">Installations</div><div class="tooltip-description">This $thing is only visible for the following installations:</div>
    $destinationsMarkup
"""
}