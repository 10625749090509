package main.kotlin.models

import main.kotlin.comms.Requests
import main.kotlin.data.*
import main.kotlin.ui.*
import org.w3c.dom.Document
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import ui.MapSelector
import kotlin.browser.document
import kotlin.js.Date

class ShipHistoryScreen(document: Document, open val ship: BaseShip) : TopNavBarScreen(document), TilesViewDelegate, FilterByNameModel {
    var handbooks: List<Handbook> = listOf()

    override val activeTab: TopNavBarTab get() = TopNavBarTab.Ships

    val breadcrumbs = Breadcrumbs(
        document, "page_breadcrumbs", arrayOf(
            Breadcrumb("Vessels", "ships.html"),
            Breadcrumb(ship.name, "ship.html?id=${ship.id}&name=${ship.name}"),
            Breadcrumb("Operation Archive", "")
        )
    )

    val list = TilesView(document, this, "operation-list")

    private val shipHistoryFilters = ShipHistoryFilters(document, "filters")
    override val searchButton = Button(document, "search_button", tooltip = "Search Operations Archive")
    val sortButton = Button(document, "sort_button", tooltip = "Sorted by completed time")

    override val searchHint: String
        get() = "Search Operation Archive"

    override fun start() {
        super.start()
        sortButton.onclick = { toggleSorting() }
        loading = false
        Requests.getShipHistory(this, ship.id) { shipHistoryResponse ->
            val handbooks = shipHistoryResponse.operations
                .mapNotNull { x -> x.handbookName }
                .distinct()
            shipHistoryFilters.setHandbooks(handbooks)
            val installations = shipHistoryResponse.operations
                .mapNotNull { x -> x.destinationName }
                .distinct()
            shipHistoryFilters.setInstallations(installations)

            Requests.listHandbookOperations(this) { hbOperationsResponse ->
                val operations = hbOperationsResponse.handbookOperations
                shipHistoryFilters.setOperations(operations.toList())
                shipHistoryFilters.start(this)
                {
                    changedFilter()
                }
                refresh()
            }
            
        }

        Requests.listHandbooks(this) {
            handbooks = it.handbooks.toList()
        }
    }

    open fun refresh() {
        loading = true

        Requests.getShipHistory(this, ship.id)
        {
            loading = false
            if (it.error == null) {
                refreshed(it.operations)
            } else {
                content.hidden = true
                error.hidden = false
                error.text = "Failed to load vessel history"
            }
        }
    }

    var sortByCompletion: Boolean = true
    var allOperations: Array<ArchivedOperation> = arrayOf()
    var operations: Array<ArchivedOperation> = arrayOf()

    override fun searchTextChanged(text: String) {
        shipHistoryFilters.query = text
        changedFilter()
    }

    fun refreshed(it: Array<ArchivedOperation>) {
        if (it.isEmpty()) {
            showToast("There are no operations for this ship")
        }
        else {
            allOperations = it
                .sortedByDescending { if (!sortByCompletion) it.initiation.timestamp else it.completion.timestamp }
                .toTypedArray()
            searchTextChanged(if (searching) topnavbar.searchbar.text else "")
        }
    }

    fun toggleSorting() {
        sortByCompletion = !sortByCompletion
        operations = operations
            .sortedByDescending { if (!sortByCompletion) it.initiation.timestamp else it.completion.timestamp }
            .toTypedArray()
        allOperations = allOperations
            .sortedByDescending { if (!sortByCompletion) it.initiation.timestamp else it.completion.timestamp }
            .toTypedArray()
        val sortTooltip = if (!sortByCompletion) "Sorted by start time" else "Sorted by completed time"
        sortButton.setTooltip(sortTooltip)
        list.reloadData()
    }

    fun matchesSearch(text: String, item: ArchivedOperation): Boolean {
        return item.operationName.toLowerCase().startsWith(text)
    }

    private fun changedFilter() {
        val shouldFilter =
            shipHistoryFilters.query.isNotEmpty()
                    || shipHistoryFilters.operation != null
                    || shipHistoryFilters.handbook != null
                    || shipHistoryFilters.installation != null
                    || shipHistoryFilters.durationLabel != null

        if (!shouldFilter)
            operations = allOperations
        else {
            val query = shipHistoryFilters.query.toLowerCase()
            fun includeItem(item: ArchivedOperation): Boolean {
                val hasQ = matchesSearch(query, item)
                val matchesOperation =
                    shipHistoryFilters.operation == null || item.operations.contains(shipHistoryFilters.operation)
                val matchesHandbook =
                    shipHistoryFilters.handbook == null || item.handbookName == shipHistoryFilters.handbook
                val matchesInstallation =
                    shipHistoryFilters.installation == null || item.destinationName == shipHistoryFilters.installation
                val matchesDate =
                    shipHistoryFilters.durationLabel == null ||
                            (item.initiation.timestamp!! >= shipHistoryFilters.startDT
                                    && item.completion.timestamp!! <= shipHistoryFilters.endDT)

                return hasQ && matchesOperation && matchesInstallation && matchesHandbook && matchesDate
            }
            operations = allOperations.filter { includeItem((it)) }.toTypedArray()
            if(operations.isEmpty()) {
                showToast("No operations are found for this selection!")
            }
        }
        list.reloadData()
    }


    override val numberOfItems: Int
        get() {
            return operations.count()
        }

    override fun onclickForItem(at: Int): String {
        val operation = operations[at]
        val tripId = operation.tripId
        val hb = if (operation.handbookId != null) {
            Handbook(operation.handbookId, operation.handbookName ?: "", true)
        } else null

        hb?.shipId = operation.shipId
        hb?.tripId = tripId
        hb?.shipName = ship.name
        hb?.operationName = operation.operationName
        hb?.completed = operation.completion

        //console.log("${tripId}")
        //console.log("${hb?.params}")

        return "window.location.href='procedures.html?trip=${tripId}&handbook=${hb?.params}'"
    }

    override fun cellForItem(at: Int): String {
        val operation = operations[at]
        val handbookName = operation.handbookName
        val icon = handbooks.find { x -> x.name == handbookName }?.icon?.image
        val title = operation.operationName
        val user = operation.completion.by
        val startDate = operation.initiation.timestamp?.displayShortDate
        val endDate = operation.completion.timestamp?.displayShortDate

        if (operation.completion.comment?.isNotEmpty() == true)
            return """      
                <li class="mdc-image-list__item" onClick=${onclickForItem(at)}>
                    <div class="mdc-ship_history-list__image">                        
                        <div class="cell_ship_history_icon" style="background-image:url('$icon');"></div>
                        <div class="display_comment">
                            <span class="title">$title</span>
                            <span class="user"><i class="material-icons" style="font-size:large">account_circle</i> $user</span>
                            <span class="date"><i class="material-icons" style="font-size:large">date_range</i> $startDate - $endDate</span>
                            <span class="comment"><i class="material-icons" style="font-size:xx-large">comment</i></span>
                        </div>
                        <div class="comment_text">${operation.completion.comment}</div>
                    </div>
                </li>
                """
        if (operation.aborted?.comment?.isNotEmpty() == true)
            return """      
                <li class="mdc-image-list__item" onClick=${onclickForItem(at)}>
                    <div class="mdc-ship_history-list__image archive_aborted">                        
                        <div class="cell_ship_history_icon" style="background-image:url('$icon');"></div>
                        <div class="display_comment">
                            <span class="title">$title</span>
                            <span class="user"><i class="material-icons" style="font-size:large">account_circle</i> $user</span>
                            <span class="date"><i class="material-icons" style="font-size:large">date_range</i> $startDate - $endDate</span>
                            <span class="comment"><i class="material-icons" style="font-size:xx-large">comment</i></span>
                        </div>
                        <div class="comment_text">${operation.aborted.comment}</div>
                    </div>
                </li>
            """
        return """
            <li class="mdc-image-list__item" onClick=${onclickForItem(at)}>
                    <div class="mdc-ship_history-list__image">                        
                        <div class="cell_ship_history_icon" style="background-image:url('$icon');"></div>
                        <div>
                            <span class="title">$title</span>
                            <span class="user"><i class="material-icons" style="font-size:large">account_circle</i> $user</span>
                            <span class="date"><i class="material-icons" style="font-size:large">date_range</i> $startDate - $endDate</span>
                        </div>
                    </div>
            </li>
        """
    }
}

class ShipHistoryFilters(document: Document, val id: String) {
    var query: String = ""
    var operation: String? = null
    var handbook: String? = null
    var installation: String? = null
    var durationLabel: String? = null

    val d = Date()
    var startDT: UnixTime = Date.now() / 1000
    var endDT: UnixTime = Date.now() / 1000

    private var operations: List<HandbookOperation> = listOf()
    private var handbooks: List<String> = listOf()
    private var installations: List<String> = listOf()
    private var durationLabels: MutableList<String> = mutableListOf(
        "Last week",
        "Last month",
        "Last year",
        "Last three years",
        "Custom"
    )

    private val operationSelector: MapSelector
    private val handbookSelector: StringSelector
    private val installationSelector: StringSelector
    private val durationLabelSelector: StringSelector
    private val container = Div(document, id)

    fun setOperations(operations: List<HandbookOperation>) {
        this.operations = operations
        operationSelector.setItems(operations.associateBy(HandbookOperation::id,HandbookOperation::name))
        operationSelector.selected = null
    }

    fun setHandbooks(handbooks: List<String>) {
        this.handbooks = handbooks
        handbookSelector.setStrings(this.handbooks)
    }

    fun setInstallations(installations: List<String>) {
        this.installations = installations
        installationSelector.setStrings(this.installations)
    }

    fun start(screen: AuthScreen, changedFilter: () -> Unit) {
        operationSelector.selected = operation
        operationSelector.valueChanged = {
            operation = operationSelector.selected;
            changedFilter.invoke()
        }

        handbookSelector.selected = handbook
        handbookSelector.valueChanged = {
            handbook = handbookSelector.selected;
            changedFilter.invoke()
        }

        installationSelector.selected = installation
        installationSelector.valueChanged = {
            installation = installationSelector.selected;
            changedFilter.invoke()
        }

        durationLabelSelector.selected = durationLabel
        durationLabelSelector.valueChanged = {

            val selected_dates = document.getElementById("selected_dates") as HTMLElement
            selected_dates.style.visibility = "hidden"

            durationLabel = durationLabelSelector.selected;

            val selectedDurationLabel = durationLabel?.toLowerCase()

            if (selectedDurationLabel != null) {
                when {
                    selectedDurationLabel == "last week" -> {
                        startDT = Date(
                            day = d.getDate() - 7,
                            month = d.getMonth(),
                            year = d.getFullYear()
                        )
                            .getTime() / 1000
                    }
                    selectedDurationLabel == "last month" -> {
                        startDT = Date(
                            day = d.getDate(),
                            month = d.getMonth() - 1,
                            year = d.getFullYear()
                        )
                            .getTime() / 1000
                    }
                    selectedDurationLabel == "last year" -> {
                        startDT = Date(
                            day = d.getDate(),
                            month = d.getMonth(),
                            year = d.getFullYear() - 1
                        )
                            .getTime() / 1000
                    }
                    selectedDurationLabel == "last three years" -> {
                        startDT = Date(
                            day = d.getDate(),
                            month = d.getMonth(),
                            year = d.getFullYear() - 3
                        )
                            .getTime() / 1000
                    }
                    selectedDurationLabel.startsWith("custom", ignoreCase = true) -> {
                        selected_dates.style.visibility = "visible"

                        val elem = document.getElementById("datepicker") as HTMLInputElement
                        elem.click()
                        elem.addEventListener("dates_picked", {
                            val sd: Long = elem.value.substringBefore('-').trim().toLong()
                            val ed: Long = elem.value.substringAfter('-').trim().toLong()

                            startDT = Date(sd).getTime() / 1000
                            endDT = Date(ed).getTime() / 1000

                            changedFilter.invoke()
                        })
                    }
                    else -> { }
                }
            }
                changedFilter.invoke()
            }
        }

    var hidden: Boolean
        get() {
            return container.hidden
        }
        set(value) {
            container.hidden = value
            if (!value) {
                operationSelector.selected = operation
                handbookSelector.selected = handbook
                installationSelector.selected = installation
                durationLabelSelector.selected = durationLabel
            }
        }

    val markup: String
        get() {
            return """
            <div class="mdc-select" id="${id}_operation"></div>
            <div class="mdc-select" id="${id}_handbook"></div> 
            <div class="mdc-select" id="${id}_installation"></div>
            <div class="mdc-select" id="${id}_date"></div>
        """
        }

    init {
        container.html = markup
        operationSelector = MapSelector(
            document,
            "${id}_operation", "All Operations", operations.associateBy(HandbookOperation::id, HandbookOperation::name)
        )
        handbookSelector = StringSelector(
            document,
            "${id}_handbook", "All Handbooks", handbooks
        )
        installationSelector = StringSelector(
            document,
            "${id}_installation", "All Installations", installations
        )
        durationLabelSelector = StringSelector(
            document,
            "${id}_date", "All Dates", durationLabels
        )
    }
}