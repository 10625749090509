package main.kotlin.models

import kotlin.browser.window

enum class StartupToastForScreen(val key:String)
{
    HandbookBaseScreen("handbook_base")
}

class Storage
{
    sealed class StorageItem(val key:String)
    {
        class Id : StorageItem("id")
        class Token : StorageItem("token")
        class FirstName : StorageItem("first_name")
        class LastName : StorageItem("last_name")
        class Role : StorageItem("role")
        class HandbookChapter(handbook:String) : StorageItem("chapter_handbook-$handbook")
        class StartupToast(screen:StartupToastForScreen) : StorageItem("startup_toast-${screen.key}")
        class Toast : StorageItem("toast")
    }

    companion object
    {
        fun getItem(item:StorageItem) : String?
        {
            return window.localStorage.getItem(item.key)
        }

        fun setItem(item:StorageItem,value:String?)
        {
            if (value != null)
            {
                window.localStorage.setItem(item.key,value)
            }
            else
            {
                window.localStorage.removeItem(item.key)
            }
        }

        fun clear()
        {
            window.localStorage.clear()
        }

        var id : String? get() { return getItem(StorageItem.Id()) } set(value) { setItem(StorageItem.Id(),value) }
        var token : String? get() { return getItem(StorageItem.Token()) } set(value) { setItem(StorageItem.Token(),value) }
        var firstName : String? get() { return getItem(StorageItem.FirstName()) } set(value) { setItem(StorageItem.FirstName(),value) }
        var lastName : String? get() { return getItem(StorageItem.LastName()) } set(value) { setItem(StorageItem.LastName(),value) }
        var role : String? get() { return getItem(StorageItem.Role()) } set(value) { setItem(StorageItem.Role(),value) }
        var toast: String? get() { return getItem(StorageItem.Toast()) } set(value) { setItem(StorageItem.Toast(),value) }

        fun setHandbookChapter(handbook:String,chapter:String?)
        {
            setItem(StorageItem.HandbookChapter(handbook),chapter)
        }

        fun getHandbookChapter(handbook:String) : String?
        {
            return getItem(StorageItem.HandbookChapter(handbook))
        }

        fun setStartupToast(msg:String?,screen:StartupToastForScreen)
        {
            setItem(StorageItem.StartupToast(screen),msg)
        }

        fun getStartupToast(screen:StartupToastForScreen) : String?
        {
            return getItem(StorageItem.StartupToast(screen))
        }
    }
}
