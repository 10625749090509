package main.kotlin.ui

import main.kotlin.data.ConditionalRequirementType
import org.w3c.dom.Document

class ConditionalRequirementsTypeSelector(document: Document, id:String, hint:String) : Selector(document,id,hint,arrayOf(
        SelectorItem("always","Always","0"),
        SelectorItem("every_x_ship_loading","Every Nth Ship Loading","1")
))
{
    var selectedType : ConditionalRequirementType
    get()
    {
        val s = view.value as String
        return ConditionalRequirementType.from(s.toInt())
    }
    set(value)
    {
        view.value = value.value.toString()
    }
}
