package main.kotlin.data

import main.kotlin.models.ProcedureScreen
import kotlin.js.Json


interface Response {
    val error: Int?
    val msg: String?
}

data class BaseResponse(override val error: Int? = null, override val msg: String? = null) : Response

data class LoginResponse(
    val id: String = "",
    val token: String = "",
    val firstName: String = "",
    val lastName: String = "",
    val role: Int = 0,
    override val error: Int? = null,
    override val msg: String? = null
) : Response

data class IdResponse(
    val id: String = "",
    val code: IdResponseCode = IdResponseCode.Normal,
    override val error: Int? = null,
    override val msg: String? = null
) : Response {
    companion object {
        fun fromJson(js: String): IdResponse? {
            val obj = JSON.parse<Json>(js)

            val msg = obj["msg"] as? String
            val error = obj["error"] as? Int
            val code = obj["code"] as? Int ?: 0
            val id = obj["id"] as? String ?: ""

            return IdResponse(id, IdResponseCode.from(code), error, msg)
        }
    }
}

data class TaskStatusResponse(
    val status: APITaskStatus = APITaskStatus.Unknown,
    val result: dynamic = null,
    override val error: Int? = null,
    override val msg: String? = null
) : Response {
    companion object {
        fun fromJson(js: String): TaskStatusResponse? {
            val obj = JSON.parse<Json>(js)
            val status = APITaskStatus.from(obj["status"] as? Int ?: 0)
            val result = obj["result"]?.asDynamic()
            return TaskStatusResponse(status, result)
        }
    }
}

data class UsersResponse(override val error: Int? = null, override val msg: String? = null) : Response {
    var users: Array<User> = arrayOf()

    companion object {
        fun fromJson(js: String): UsersResponse? {
            val obj = JSON.parse<Json>(js)
            val list = obj["users"] as? Array<Any>

            val users: MutableList<User> = mutableListOf()
            if (list != null) {
                for (it in list) {
                    val dyn = it.asDynamic()
                    val user = User(dyn = dyn)
                    users.add(user)
                }
            }

            users.sortBy { it.name }

            val resp = UsersResponse()
            resp.users = users.toTypedArray()

            return resp
        }
    }
}

data class FeedbackResponse(override val error: Int? = null, override val msg: String? = null) : Response {
    var items: Array<FeedbackItem> = arrayOf()

    companion object {
        fun fromJson(js: String): FeedbackResponse? {
            val obj = JSON.parse<Json>(js)
            val list = obj["feedback"] as? Array<Any>

            val items: MutableList<FeedbackItem> = mutableListOf()
            if (list != null) {
                for (it in list) {
                    val dyn = it.asDynamic()
                    val item = FeedbackItem(dyn = dyn)
                    items.add(item)
                }
            }

            items.sortByDescending { it.timestamp }

            val resp = FeedbackResponse()
            resp.items = items.toTypedArray()

            return resp
        }
    }
}

data class FeedbackDetailsResponse(override val error: Int? = null, override val msg: String? = null) : Response {
    var item: FeedbackDetails? = null

    companion object {
        fun fromJson(js: String): FeedbackDetailsResponse? {
            val obj = JSON.parse<Json>(js)
            val objuser = obj["item"]

            var item: FeedbackDetails? = null
            if (objuser != null) {
                val dyn = objuser.asDynamic()
                item = FeedbackDetails(dyn = dyn)
            }

            val resp = FeedbackDetailsResponse()
            resp.item = item

            return resp
        }
    }
}

data class UserResponse(override val error: Int? = null, override val msg: String? = null) : Response {
    var user: User? = null

    companion object {
        fun fromJson(js: String): UserResponse? {
            val obj = JSON.parse<Json>(js)
            val objuser = obj["user"]

            var user: User? = null
            if (objuser != null) {
                val dyn = objuser.asDynamic()
                user = User(dyn = dyn)
            }

            val resp = UserResponse()
            resp.user = user

            return resp
        }
    }
}

data class ShipResponse(override val error: Int? = null, override val msg: String? = null) : Response {
    var ship: Ship? = null

    companion object {
        fun fromJson(js: String): ShipResponse? {
            val obj = JSON.parse<Json>(js)
            val objship = obj["ship"]

            var ship: Ship? = null
            if (objship != null) {
                val dyn = objship.asDynamic()
                ship = Ship(dyn = dyn)
            }

            val resp = ShipResponse()
            resp.ship = ship

            return resp
        }
    }
}

data class ShipAndActiveOperationHandbooksResponse(override val error: Int? = null, override val msg: String? = null) :
    Response {
    var ship: Ship? = null
    var handbooks: Array<Handbook> = arrayOf()

    companion object {
        fun fromJson(js: String): ShipAndActiveOperationHandbooksResponse? {
            val obj = JSON.parse<Json>(js)
            return fromJson(obj)
        }

        fun fromJson(obj: Json): ShipAndActiveOperationHandbooksResponse? {
            val objship = obj["ship"]

            var ship: Ship? = null
            if (objship != null) {
                val dyn = objship.asDynamic()
                ship = Ship(dyn = dyn)
            }

            val list = obj["handbooks"] as? Array<Any>
            val handbooks: MutableList<Handbook> = mutableListOf()
            if (list != null) {
                for (it in list) {
                    val dyn = it.asDynamic()
                    val user = Handbook(dyn = dyn)
                    handbooks.add(user)
                }
            }

            val resp = ShipAndActiveOperationHandbooksResponse()
            resp.ship = ship
            resp.handbooks = handbooks.toTypedArray()
            return resp
        }
    }
}

data class ShipHistoryResponse(override val error: Int? = null, override val msg: String? = null) : Response {
    var operations: Array<ArchivedOperation> = arrayOf()

    companion object {
        fun fromJson(js: String): ShipHistoryResponse? {
            val obj = JSON.parse<Json>(js)
            return fromJson(obj)
        }

        fun fromJson(obj: Json): ShipHistoryResponse? {
            val list = obj["operations"] as? Array<Any>
            val operations: MutableList<ArchivedOperation> = mutableListOf()
            if (list != null) {
                for (it in list) {
                    val dyn = it.asDynamic()
                    val op = ArchivedOperation(dyn = dyn)
                    operations.add(op)
                }
            }

            val resp = ShipHistoryResponse()
            resp.operations = operations.toTypedArray()
            return resp
        }
    }
}

data class InstallationResponse(override val error: Int? = null, override val msg: String? = null) : Response {
    var installation: Installation? = null

    companion object {
        fun fromJson(js: String): InstallationResponse? {
            val obj = JSON.parse<Json>(js)
            val objinstallation = obj["installation"]

            var installation: Installation? = null
            if (objinstallation != null) {
                val dyn = objinstallation.asDynamic()
                installation = Installation(dyn = dyn)
            }

            val resp = InstallationResponse()
            resp.installation = installation
            return resp
        }
    }
}

data class RegionsResponse(override val error: Int? = null, override val msg: String? = null) : Response {
    var regions: Array<String> = arrayOf()

    companion object {
        fun fromJson(js: String): RegionsResponse? {
            val obj = JSON.parse<Json>(js)

            val regions = obj["regions"] as? Array<String> ?: arrayOf()

            val resp = RegionsResponse()
            resp.regions = regions
            return resp
        }
    }
}

data class RegionsResponse2(override val error: Int? = null, override val msg: String? = null) : Response {
    var shipRegions: Array<ShipRegion> = arrayOf()

    companion object {
        fun fromJson(js: String): RegionsResponse2? {
            val obj = JSON.parse<Json>(js)
            val list = obj["vessel_regions"] as? Array<ShipRegion>

            val shipRegions: MutableList<ShipRegion> = mutableListOf()

            if (list != null) {
                for (it in list) {
                    val dyn = it.asDynamic()
                    val typ = ShipRegion(dyn = dyn)
                    shipRegions.add(typ)
                }
            }

            shipRegions.sortBy { it.id }

            val resp = RegionsResponse2()
            resp.shipRegions = shipRegions.toTypedArray()

            return resp
        }
    }
}

data class ProceduresResponse(override val error: Int? = null, override val msg: String? = null) : Response {
    var chapters: Array<ProcedureChapter> = arrayOf()
    var handbook: HandbookSettingsResponse? = null
    var role: UserRole = UserRole.Inactive
    var parent: String? = null
    var fullProcedures: List<Procedure> = listOf()


    companion object {
        fun chapters(
            procedures: List<ProcedureCard>,
            settings: HandbookSettingsResponse?,
            includeEmptyChapters: Boolean = false,
            includeOld : Boolean = true
        ): List<ProcedureChapter> {
            var chapterCards: MutableMap<String, MutableList<ProcedureCard>> = mutableMapOf()

            for (procedure in procedures) {
                val list = chapterCards[procedure.chapter] ?: mutableListOf()
                list.add(procedure)
                chapterCards[procedure.chapter] = list
            }

            var chapterList: MutableList<ProcedureChapter> = mutableListOf()

            for (cards in chapterCards) {
                chapterList.add(ProcedureChapter(cards.key, cards.value))
            }

            if (includeEmptyChapters) {
                val origChapters = settings?.chapters
                if (origChapters != null) {
                    val chapterNames = chapterList.map { it.name }
                    for (chapter in origChapters) {
                        if (chapterNames.contains(chapter.name))
                            continue
                        val pc = ProcedureChapter(chapter.name)
                        for (section in chapter.sections) {
                            pc.sections.add(ProcedureSection(section.name, mutableListOf()))
                        }
                        chapterList.add(pc)
                    }
                }
            }

            var chapters: List<ProcedureChapter> = chapterList
            if (settings != null)
                chapters = settings.sortChapters(chapterList, includeEmptyChapters, includeOld)

            for (chapter in chapters)
                for (section in chapter.sections)
                    section.procedures.sortBy { it.order }

            return chapters
        }

        fun fromJson(js: String, includeEmptyChapters: Boolean = true): ProceduresResponse? {
            val obj = JSON.parse<Json>(js)
            val list = obj["procedures"] as? Array<Any>

            val procedures: MutableList<ProcedureCard> = mutableListOf()
            var fullProcedures: MutableList<Procedure> = mutableListOf()
            if (list != null) {
                for (it in list) {
                    val dyn = it.asDynamic()
                    val card = ProcedureCard(dyn = dyn)
                    if (includeEmptyChapters)
                        procedures.add(card)
                    else {
                        if (card.progress == null || card.progress == 0F)
                            continue
                        procedures.add(card)
                    }
                    var procedure = Procedure(dyn = dyn)
                    fullProcedures.add(procedure)
                }
            }

            val children: MutableMap<String, MutableList<ProcedureCard>> = mutableMapOf()
            for (procedure in procedures) {
                val pid = procedure.parent ?: continue
                val list = children[pid] ?: mutableListOf()
                list.add(procedure)
                children[pid] = list
            }

            for (procedure in procedures) {
                val kids = children[procedure.id] ?: continue
                procedure.children = kids
            }

            val parentI = obj["parent"]
            val parent = if (parentI != null) parentI as? String else null

            val roleI = obj["role"]
            val role: UserRole = if (roleI != null) UserRole.from(roleI as? Int ?: 0) else UserRole.Inactive

            val settingsI = obj["settings"] as? Json
            val settings = if (settingsI != null) HandbookSettingsResponse.fromJson(obj = settingsI) else null

            val resp = ProceduresResponse()
            resp.chapters = chapters(procedures, settings, includeEmptyChapters = includeEmptyChapters).toTypedArray()
            resp.handbook = settings
            resp.role = role
            resp.parent = parent
            resp.fullProcedures = fullProcedures

            return resp
        }
    }

    val allProcedures: List<ProcedureCard>
        get() {
            val procedures: MutableList<ProcedureCard> = mutableListOf()
            for (chapter in chapters)
                for (section in chapter.sections)
                    for (procedure in section.procedures)
                        procedures.add(procedure)
            return procedures
        }
}

data class ImagesResponse(override val error: Int? = null, override val msg: String? = null) : Response {
    var images: List<String> = listOf()

    companion object {
        fun fromJson(js: String): ImagesResponse? {
            val obj = JSON.parse<Json>(js)
            return fromJson(obj)
        }

        fun fromJson(obj: Json): ImagesResponse? {
            val resp = ImagesResponse()
            val optns = obj["images"] as? Array<String>
            if (optns != null) {
                resp.images = optns.toList()
            }
            return resp
        }
    }
}

data class ProcedureOptionsResponse(override val error: Int? = null, override val msg: String? = null) : Response {
    var options = ProcedureOptions()

    companion object {
        fun fromJson(js: String): ProcedureOptionsResponse? {
            val obj = JSON.parse<Json>(js)
            return fromJson(obj)
        }

        fun fromJson(obj: Json): ProcedureOptionsResponse? {
            val resp = ProcedureOptionsResponse()
            val optns = obj["options"]
            if (optns != null) {
                resp.options = ProcedureOptions(dyn = optns.asDynamic())
            }
            return resp
        }
    }
}

data class HandbooksResponse(override val error: Int? = null, override val msg: String? = null) : Response {
    var handbooks: Array<Handbook> = arrayOf()

    companion object {
        fun fromJson(js: String): HandbooksResponse? {
            val obj = JSON.parse<Json>(js)
            return fromJson(obj)
        }

        private fun fromJson(obj: Json): HandbooksResponse? {
            val list = obj["handbooks"] as? Array<Any>

            val handbooks: MutableList<Handbook> = mutableListOf()

            list?.forEach { it ->
                val dyn = it.asDynamic()
                val handbook = Handbook(dyn = dyn)
                handbooks.add(handbook)
            }

            val resp = HandbooksResponse()

            resp.handbooks = handbooks.toTypedArray()

            return resp
        }
    }
}

data class HandbookSettingsResponse(override val error: Int? = null, override val msg: String? = null) : Response {
    var chapters: List<HandbookChapter> = listOf()
    var chaptersUnchanged: List<HandbookChapter> = listOf()
    var requiresVoyageNumber = false
    var requiresDestination = false
    var sequential = false
    var buttonOnStep = false
    var version = ""
    var name = ""
    var description = ""
    var icon = HandbookIcon.Teekay

    var features: Array<String> = arrayOf()

    var operations: Array<String> = arrayOf()

    var completed: Event? = null
    var aborted: Event? = null
    var events: List<Event> = listOf()

    val completedComment : String
    get() {
        return completed?.comment ?: ""
    }

    val abortedComment : String
    get() {
        return aborted?.comment ?: ""
    }

    val featureFlags: Array<HandbookFeature>
        get() {
            return features.map { HandbookFeature.from(it) }.toTypedArray()
        }

    var startOperationButton: String = ""
    var startedOperationButton: String = ""

    var isPublished: Boolean = false

    val descriptiveName: String
        get() {
            if (description.isEmpty())
                return name
            return "$name <br/> ($description)"
        }

    companion object {
        fun fromJson(js: String): HandbookSettingsResponse? {
            val obj = JSON.parse<Json>(js)
            return fromJson(obj)
        }

        fun fromJson(obj: Json): HandbookSettingsResponse? {
            val list = obj["chapters"] as? Array<Any>
            val eventsJson = obj["events"] as? Array<Any>

            val chapters: MutableList<HandbookChapter> = mutableListOf()
            val snapshot: MutableList<HandbookChapter> = mutableListOf()
            val events : MutableList<Event> = mutableListOf()
            if (list != null) {
                for (it in list) {
                    val dyn = it.asDynamic()
                    chapters.add(HandbookChapter(dyn = dyn))
                    snapshot.add(HandbookChapter(dyn = dyn))
                }
            }
            if (eventsJson != null) {
                for (e in eventsJson) {
                    val dyn = e.asDynamic()
                    events.add(Event(dyn = dyn))
                }
            }

            val resp = HandbookSettingsResponse()
            resp.chapters = chapters
            resp.chaptersUnchanged = snapshot
            resp.name = obj["name"] as? String ?: ""
            resp.description = obj["description"] as? String ?: ""
            resp.icon = HandbookIcon.from(obj["icon"] as? Int ?: 0)
            resp.version = obj["version"] as? String ?: ""
            resp.startOperationButton = obj["start_operation_button"] as? String ?: ""
            resp.startedOperationButton = obj["started_operation_button"] as? String ?: ""
            resp.requiresVoyageNumber = obj["requires_voyage_number"] as? Boolean ?: false
            resp.requiresDestination = obj["requires_destination"] as? Boolean ?: false
            resp.sequential = obj["sequential"] as? Boolean ?: false
            resp.buttonOnStep = obj["complete_step_button_on_side"] as? Boolean ?: false
            resp.isPublished = Handbook.isPublished(obj["channels"] as Array<String>)
            resp.features = obj["features"] as? Array<String> ?: arrayOf()
            resp.operations = obj["operations"] as? Array<String> ?: arrayOf()
            resp.events = events
            val comp = obj["completed"]
            if (comp != null){
                resp.completed = Event(dyn=comp)
            }
            val abort = obj["aborted"]
            if (abort != null){
                resp.aborted = Event(dyn=abort)
            }

            return resp
        }
    }

    fun sortChapters(list: List<ProcedureChapter>, includeEmpty: Boolean = false, includeOld: Boolean = true): List<ProcedureChapter> {
        val map: MutableMap<String, ProcedureChapter> = mutableMapOf()
        for (pr in list)
            map[pr.name.toLowerCase()] = pr

        val sorted: MutableList<ProcedureChapter> = mutableListOf()
        for (orderedName in chapters) {
            val key = orderedName.name.toLowerCase()
            val chapter = map.get(key) ?: continue
            chapter.sections = sortSections(chapter.sections, orderedName.sections, includeEmpty).toMutableList()
            sorted.add(chapter)
            map.remove(key)
        }

        if(includeOld) {
            for (pr in map)
                sorted.add(pr.value)
        }

        return sorted
    }

    fun sortSections(
        list: List<ProcedureSection>,
        order: List<HandbookSection>,
        includeEmpty: Boolean = false
    ): List<ProcedureSection> {
        val map: MutableMap<String, ProcedureSection> = mutableMapOf()
        for (pr in list)
            map.put(pr.name.toLowerCase(), pr)

        if (includeEmpty) {
            for (ord in order) {
                val k = ord.name.toLowerCase()
                if (!map.contains(k)) {
                    map[k] = ProcedureSection(ord.name, mutableListOf(), ord.header, ord.conditional)
                }
            }
        }

        val sorted: MutableList<ProcedureSection> = mutableListOf()
        for (ord in order) {
            val key = ord.name.toLowerCase()
            val chapter = map.get(key) ?: continue
            chapter.header = ord.header
            chapter.conditional = ord.conditional
            sorted.add(chapter)
            map.remove(key)
        }

        for (pr in map)
            sorted.add(pr.value)

        return sorted
    }
}

data class ShipsResponse(override val error: Int? = null, override val msg: String? = null) : Response {
    var ships: Array<Ship> = arrayOf()

    companion object {
        fun fromJson(js: String): ShipsResponse? {
            val obj = JSON.parse<Json>(js)
            val list = obj["ships"] as? Array<Any>

            val ships: MutableList<Ship> = mutableListOf()
            if (list != null) {
                for (it in list) {
                    val dyn = it.asDynamic()
                    val user = Ship(dyn = dyn)
                    ships.add(user)
                }
            }

            ships.sortBy { it.name }

            val resp = ShipsResponse()
            resp.ships = ships.toTypedArray()

            return resp
        }
    }
}

data class ShipClassesResponse(override val error: Int? = null, override val msg: String? = null) : Response {
    var shipClasses: Array<String> = arrayOf()

    companion object {
        fun fromJson(js: String): ShipClassesResponse? {
            val obj = JSON.parse<Json>(js)
            val list = obj["classes"] as? Array<String>

            val resp = ShipClassesResponse()
            if (list != null)
                resp.shipClasses = list

            return resp
        }
    }
}

data class ShipClassesResponse2(override val error: Int? = null, override val msg: String? = null) : Response {
    var shipClasses: Array<ShipClass> = arrayOf()

    companion object {
        fun fromJson(js: String): ShipClassesResponse2? {
            val obj = JSON.parse<Json>(js)
            val list = obj["vessel_class"] as? Array<ShipClass>

            val shipClasses: MutableList<ShipClass> = mutableListOf()

            if (list != null) {
                for (it in list) {
                    val dyn = it.asDynamic()
                    val cls = ShipClass(dyn = dyn)
                    shipClasses.add(cls)
                }
            }
            shipClasses.sortBy { it.id }

            val resp = ShipClassesResponse2()
            resp.shipClasses = shipClasses.toTypedArray()

            return resp
        }
    }
}

data class ShipTypesResponse(override val error: Int? = null, override val msg: String? = null) : Response {
    var shipTypes: Array<String> = arrayOf()

    companion object {
        fun fromJson(js: String): ShipTypesResponse? {
            val obj = JSON.parse<Json>(js)
            val list = obj["types"] as? Array<String>

            val resp = ShipTypesResponse()
            if (list != null)
                resp.shipTypes = list

            return resp
        }
    }
}

data class ShipTypesResponse2(override val error: Int? = null, override val msg: String? = null) : Response {
    var shipTypes: Array<ShipType> = arrayOf()

    companion object {
        fun fromJson(js: String): ShipTypesResponse2? {
            val obj = JSON.parse<Json>(js)
            val list = obj["vessel_type"] as? Array<ShipType>

            val shipTypes: MutableList<ShipType> = mutableListOf()

            if (list != null) {
                for (it in list) {
                    val dyn = it.asDynamic()
                    val typ = ShipType(dyn = dyn)
                    shipTypes.add(typ)
                }
            }

            shipTypes.sortBy { it.id }

            val resp = ShipTypesResponse2()
            resp.shipTypes = shipTypes.toTypedArray()

            return resp
        }
    }
}

data class InstallationsResponse(override val error: Int? = null, override val msg: String? = null) : Response {
    var installations: Array<InstallationCard> = arrayOf()

    companion object {
        fun fromJson(js: String): InstallationsResponse? {
            val obj = JSON.parse<Json>(js)
            val list = obj["installations"] as? Array<Any>

            val installations: MutableList<InstallationCard> = mutableListOf()
            if (list != null) {
                for (it in list) {
                    val dyn = it.asDynamic()
                    val installation = InstallationCard(dyn = dyn)
                    installations.add(installation)
                }
            }

            installations.sortBy { it.name }

            val resp = InstallationsResponse()
            resp.installations = installations.toTypedArray()
            return resp
        }
    }
}

data class ProcedureResponse(override val error: Int? = null, override val msg: String? = null) : Response {
    var procedure: Procedure? = null
    var revisions: Array<ProcedureCard> = arrayOf()
    var canEditOrRevise: Boolean = false
    var canDelete: Boolean = false
    var role: UserRole = UserRole.Inactive

    companion object {
        fun fromJson(js: String): ProcedureResponse? {
            val obj = JSON.parse<Json>(js)
            val objproc = obj["procedure"]

            var procedure: Procedure? = null
            if (objproc != null) {
                val dyn = objproc.asDynamic()
                procedure = Procedure(dyn = dyn)
            }

            val revisions: MutableList<ProcedureCard> = mutableListOf()
            val revs = obj["revisions"] as? Array<Any>
            if (revs != null) {
                for (it in revs) {
                    val dyn = it.asDynamic()
                    val draft = ProcedureCard(dyn = dyn)
                    revisions.add(draft)
                }
            }

            val canEditOrRevise = obj["can_edit"] as? Boolean ?: false
            val canDelete = obj["can_delete"] as? Boolean ?: false
            val roleI = obj["role"]
            val role: UserRole = if (roleI != null) UserRole.from(roleI as? Int ?: 0) else UserRole.Inactive

            val resp = ProcedureResponse()
            resp.procedure = procedure
            resp.revisions = revisions.toTypedArray()
            resp.canEditOrRevise = canEditOrRevise
            resp.canDelete = canDelete
            resp.role = role

            return resp
        }
    }
}

data class AppConfigResponse(override val error: Int? = null, override val msg: String? = null) : Response {
    var config: AppConfig? = null

    companion object {
        fun fromJson(js: String): AppConfigResponse? {
            val obj = JSON.parse<Json>(js)
            val objconf = obj["config"]

            var config: AppConfig? = null
            if (objconf != null) {
                val dyn = objconf.asDynamic()
                config = AppConfig(dyn = dyn)
            }

            val resp = AppConfigResponse()
            resp.config = config

            return resp
        }
    }
}

data class LoadingSystemsResponse(override val error: Int? = null, override val msg: String? = null) : Response {
    var loadingSystems: Array<LoadingSystem> = arrayOf()

    companion object {
        fun fromJson(js: String): LoadingSystemsResponse? {
            val obj = JSON.parse<Json>(js)
            val list = obj["loading_systems"] as? Array<LoadingSystem>

            val loadingSystems: MutableList<LoadingSystem> = mutableListOf()

            if (list != null) {
                for (it in list) {
                    val dyn = it.asDynamic()
                    val typ = LoadingSystem(dyn = dyn)
                    loadingSystems.add(typ)
                }
            }

            loadingSystems.sortBy { it.id }

            val resp = LoadingSystemsResponse()
            resp.loadingSystems = loadingSystems.toTypedArray()

            return resp
        }
    }
}

data class HandbookOperationsResponse(override val error: Int? = null, override val msg: String? = null) : Response {
    var handbookOperations: Array<HandbookOperation> = arrayOf()

    companion object {
        fun fromJson(js: String): HandbookOperationsResponse? {
            val obj = JSON.parse<Json>(js)
            val list = obj["handbook_areas"] as? Array<HandbookOperation>

            val handbookOperations: MutableList<HandbookOperation> = mutableListOf()

            if (list != null) {
                for (it in list) {
                    val dyn = it.asDynamic()
                    dyn.id = dyn.id.toString()
                    val typ = HandbookOperation(dyn = dyn)
                    handbookOperations.add(typ)
                }
            }

            handbookOperations.sortBy { it.id }

            val resp = HandbookOperationsResponse()
            resp.handbookOperations = handbookOperations.toTypedArray()

            return resp
        }
    }
}