package main.kotlin.ui

import main.kotlin.data.NotApplicableMode
import org.w3c.dom.Document

private val Array<NotApplicableMode>.selectorItems : Array<SelectorItem>
get()
{
    val list = mutableListOf<SelectorItem>()

    for (type in this)
    {
        list.add(SelectorItem("${type.toString().toLowerCase()}","${type.title}","${type.value}"))
    }

    return list.toTypedArray()
}

open class NotApplicableModeSelector(document: Document, id:String, hint:String,tooltip:String?=null) : Selector(document,id,hint,NotApplicableMode.values().selectorItems,tooltip=tooltip)
{
    var selected : NotApplicableMode
    get()
    {
        val s = view.value as String
        return NotApplicableMode.from(s.toInt())
    }
    set(value)
    {
        view.value = value.value.toString()
    }

    fun setModes(modes:Array<NotApplicableMode>)
    {
        setSelectorItems(modes.selectorItems)
    }
}
