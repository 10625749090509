package main.kotlin.models.editor

import main.kotlin.data.*

import main.kotlin.ui.*
import org.w3c.dom.Document
import kotlin.browser.document

interface ProcedureSingleSelectionItemsEditorDelegate
{
    fun changedBlurbSingleSelectionItem(item:BlurbSingleSelectionItem)
    fun clickedDeleteSingleSelectionItem(item:BlurbSingleSelectionItem)

}

class ProcedureSingleSelectionItemsEditor(document:Document, val id:String, override val handbook:Handbook) : ContainerPanel,
    ProcedureConfig
{
    override val container = Div(document, id)
    val itemsContainer = Div(document, "$id-items")

    var current : Blurb? = null
    var items : List<BlurbSingleSelectionItem> = listOf()

    var delegate : ProcedureSingleSelectionItemsEditorDelegate? = null

    fun reload(data:Blurb)
    {
        current = data
        items = data.singleSelection
        reloadData()
    }

    class ItemEditor(val id:String, val item:BlurbSingleSelectionItem, val config: ProcedureConfig)
    {
        val container : Div = Div(document, id)
        val nameContainer : Div
        val name : EditText
        val buttons : Div
        val deleteItemButton : Button

        init {
            container.html = markup
            buttons = Div(document,"$id-buttons")
            deleteItemButton = Button(document,"$id-delete_item_button",tooltip="Delete this Item")
            nameContainer = Div(document,"$id-name-container")
            name = EditText(document,"$id-name","Item text",required=true)
            name.text = item.title
            nameContainer.view.onmouseenter = { hovering(true) }
            nameContainer.view.onmouseleave = { hovering(false) }
        }

        private fun hovering(isHovering:Boolean)
        {
            buttons.hidden = !isHovering
        }

        val markup : String
            get()
            {
                var html = """
                <div class="diamond-editor-diamond-container" id="$id-name-container">
                <div class="edit" id="$id-name"></div>
                <div id="$id-buttons" class="diamond-editor-diamond-buttons" style="display:none;">
                    <button class="mdc-button circle_button" id="$id-delete_item_button"><i class="material-icons 
                    mdc-button__icon" aria-hidden="true">close</i></button>
                </div>
                </div>
            """
                val config = BlurbMarkupConfig(config,step=null,placement=null)
                config.disableGotoLinks = true

                html += """<hr id="$id-divider" class="diamond-editor-divider"/>"""

                return html
            }

        fun clear()
        {
            container.onclick = null
            name.textChanged = null
            deleteItemButton.onclick = null
            nameContainer.view.onmouseenter = null
            nameContainer.view.onmouseleave = null
        }
    }

    val itemEditors : MutableList<ItemEditor> = mutableListOf()

    fun reloadData()
    {
        for (editor in itemEditors)
            editor.clear()
        itemEditors.clear()

        var html = ""
        for ((at,item) in items.withIndex())
        {
            html += """<div id="$id-item-editor-$at"></div>"""
        }

        itemsContainer.html = html

        for ((at,item) in items.withIndex())
        {
            val editor = ItemEditor("$id-item-editor-$at",item,this)
            editor.deleteItemButton.onclick = { clickedDeleteItem(at) }
            editor.name.textChanged = { item.title = it; changedBlurbItem(at) }
            //cell.hr.hidden = at == choices.lastIndex

            itemEditors.add(editor)
        }
    }

    fun clickedDeleteItem(at:Int)
    {
        val item = items[at]
        delegate?.clickedDeleteSingleSelectionItem(item)
    }

    fun changedBlurbItem(at:Int)
    {
        val item = items[at]
        delegate?.changedBlurbSingleSelectionItem(item)
    }
}
