package main.kotlin.ui

import org.w3c.dom.*
import org.w3c.dom.events.Event
import kotlin.dom.addClass
import kotlin.dom.removeClass

class Ul(document:Document,id:String) : Element(document,id)
{
    override val view : HTMLUListElement = document.getElementById(id) as HTMLUListElement
}
