package main.kotlin.models

import main.kotlin.ui.*
import org.w3c.dom.Document
import kotlin.browser.document

open class HelpScreen(document:Document, chapter:String, section:String) : TopNavBarScreen(document), TableViewDelegate
{
    override val activeTab: TopNavBarTab get() = TopNavBarTab.Help
    override val content = Div(document,"page_content","flex")

    val navbar = NavBar(document,"page_navbar")

    val chaptersList : Array<Tab> = arrayOf(
            Tab("tab-default","User instructions"),
            Tab("tab-handbooks","Handbooks"),
            Tab("tab-users","Users"),
            Tab("tab-ships","Vessels"),
            Tab("tab-installations","Installations"),
//            Tab("tab-feedback","Feedback"),
            Tab("tab-manuals","Manuals")
    )

    val chapters = TableView(document,this,"chapters")
    var currentChapterId : String? = "tab-default"

    init
    {
        loading = false

        currentChapterId = when(chapter)
        {
            "user_instructions" -> "tab-default"
            "handbooks" -> "tab-handbooks"
            "users" -> "tab-users"
            "ships" -> "tab-ships"
            "vessels" -> "tab-ships"
            "installations" -> "tab-installations"
//            "feedback" -> "tab-feedback"
            "manuals" -> "tab-manuals"
            else -> currentChapterId
        }
    }

    override fun start()
    {
        super.start()

        for (cell in cells)
            cell.li.onclick = null
        cells.clear()

        chapters.reloadData()

        for (chapter in chaptersList)
        {
            val chapterId = chapter.id
            val cell = ChapterCell(chapterId)
            cell.li.onclick = { clickedChapter(chapterId) }
            cells.add(cell)
        }

        reloadCurrentChapter()
    }

    var cells : MutableList<ChapterCell> = mutableListOf()

    class ChapterCell(val id:String)
    {
        val li = Li(document,"cell-$id")
        val label = Span(document, "cell-$id-label")
    }

    fun clickedChapter(id:String)
    {
        currentChapterId = id
        reloadCurrentChapter()
    }

    fun reloadCurrentChapter()
    {
        for (cell in cells)
        {
            val shouldShow = currentChapterId == cell.id
            if (shouldShow)
                cell.label.addClasses(arrayOf("current-selection"))
            else
                cell.label.removeClasses(arrayOf("current-selection"))

            val div = Div(document,cell.id)
            div.hidden = !shouldShow
        }
    }

    override val numberOfRows: Int get() { return chaptersList.count() }

    override fun cellForRow(at: Int): String
    {
        val chapter = chaptersList[at]
        return """
        <li class="mdc-list-item" id="cell-${chapter.id}">
            <span class="mdc-list-item__text" id="cell-${chapter.id}-label">${chapter.name}</span>
        </li>
        """
    }
}
