package main.kotlin.ui

import main.kotlin.comms.Requests
import main.kotlin.data.InstallationLoadingSystem
import main.kotlin.data.LoadingSystem
import main.kotlin.models.AuthScreen
import org.w3c.dom.Document

class InstallationLoadingSystemSelectorOld(document: Document, id:String, hint:String) : Selector(document,id,hint,arrayOf(
        SelectorItem("unknown","None","unknown"),
        SelectorItem("tandem","Tandem","tandem"),
        SelectorItem("ols","OLS","ols"),
        SelectorItem("sal","SAL","sal")

))
{
    var selectedLoadingSystem : InstallationLoadingSystem
    get()
    {
        val s = view.value as String
        return InstallationLoadingSystem.from(s)
    }
    set(value)
    {
        view.value = value.value
    }
}

class InstallationAllLoadingSystemsSelectorOld(document: Document, id:String, hint:String) : Selector(document,id,hint,arrayOf(
        SelectorItem("all","All","unknown"),
        SelectorItem("tandem","Tandem","tandem"),
        SelectorItem("ols","OLS","ols"),
        SelectorItem("sal","SAL","sal")

))
{
    var selected : InstallationLoadingSystem?
    get()
    {
        val s = view.value as String
        if (s.isEmpty() || s == "unknown")
            return null
        val ls = InstallationLoadingSystem.from(s)
        if (ls == InstallationLoadingSystem.Unknown)
            return null
        return ls
    }
    set(value)
    {
        if (value == null)
            view.value = "unknown"
        else
            view.value = value.value
    }
}

class InstallationLoadingSystemSelector(document: Document, id: String, hint: String, loadingSystems: List<LoadingSystem>, mode: SelectorNotSelectedMode) : LoadingSystemSelector(document,id,hint,
    listOf(),mode)
{
    init {
        updateLoadingSystems(loadingSystems);
    }

    fun updateLoadingSystems(loadingSystems: List<LoadingSystem>)
    {
        if (loadingSystems.isNotEmpty())
        {
            val sel = selected
            setLoadingSystems(loadingSystems.toList())
            selected = sel
        }
    }

    fun start(screen: AuthScreen, started:((Boolean)->Unit)?=null)
    {
        Requests.listLoadingSystems(screen)
        { resp ->
            val loadingSystems = resp.loadingSystems.toList()
            updateLoadingSystems(loadingSystems)
            started?.invoke(loadingSystems.isNotEmpty())
        }
    }
}