package main.kotlin.models.picker

import main.kotlin.data.ContainerPanel
import main.kotlin.data.Handbook
import main.kotlin.ui.Button
import main.kotlin.ui.Div
import main.kotlin.ui.TemplateSelector
import org.w3c.dom.Document

class ProcedureTemplatePicker(document: Document, val id:String, handbook: Handbook) : ContainerPanel
{
    override val container = Div(document, id)

    val doneButton : Button
    val closeButton : Button
    var pickedTemplate : ((String) -> Unit)? = null

    val templates : TemplateSelector

    init
    {
        container.html = markup

        templates = TemplateSelector(document,"$id-procedure","Template Type")
        doneButton = Button(document, "$id-done_button")
        closeButton = Button(document, "$id-close_button")
        doneButton.onclick = { clickedDone() }
        closeButton.onclick = { clickedClose() }
    }

    open val markup : String
    get()
    {
        return """

        <button class="mdc-button quill_popover_close_button" id="$id-close_button"><i class="material-icons mdc-button__icon" aria-hidden="true">close</i></button>

<div class="procedure-editing-panel-sub-title">
    <span>Template Texts</span>
</div>

<p>Template texts let you inject voyage or destination specific information to a procedure / checklist</p>

<div class="mdc-select edit" id="$id-procedure"></div>

<button class="mdc-button edit_stuff" id="$id-done_button">Insert</button>
"""
    }

    fun clickedDone()
    {
        pickedTemplate?.invoke(templates.selected.templateString)
    }

    fun clickedClose()
    {
        pickedTemplate?.invoke("")
    }
}
