package main.kotlin.ui

import org.w3c.dom.Document
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement

open class Div(document: Document, id:String,visibleDisplayStyle:String="block",tooltip:String?=null) : Element(document,id,visibleDisplayStyle,tooltip=tooltip)
{
    override val view : HTMLDivElement = document.getElementById(id) as HTMLDivElement

    init
    {
        didInit()
    }

    override val isTooltipEssential : Boolean
    get()
    {
        return false
    }
}
