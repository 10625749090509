package main.kotlin.models.editor

import main.kotlin.data.*
import main.kotlin.ui.*
import org.w3c.dom.Document
import org.w3c.dom.HTMLParagraphElement
import kotlin.browser.document

class ProcedureRequirementsEditor(document: Document, override val handbook: Handbook) : ContainerEditorPanel, ProcedureConfig, TabBarDelegate, ProcedureRequirementsEditorSectionDelegate
{
    val id = "procedure_requirements_editor"
    override val container = Div(document, id)
    override val panelType : ContainerEditorPanelType get() { return ContainerEditorPanelType.Requirements }
    val ships = ProcedureRequirementsEditorSection(document, "$id-ships", "Vessels", "ships")
    val classes = ProcedureRequirementsEditorSection(document, "$id-ship_classes", "Classes", "classes")
    val types = ProcedureRequirementsEditorSection(document, "$id-ship_types", "Types", "types")
    val regions = ProcedureRequirementsEditorSection(document, "$id-ship_regions", "Regions", "regions")
    val destinations = ProcedureRequirementsEditorSection(document, "$id-installations", "Installations", "destinations")
    val sections : List<ProcedureRequirementsEditorSection> = listOf(ships,classes,types,destinations,regions)
    val tabs = TabBar(document, "procedure_requirements_editor-tabs", sections.map { it.tab }.toTypedArray())
    val closeButton = Button(document, "$id-close_button",tooltip="Close requirements panel")

    var delegate : ProcedureEditorDelegate? = null

    private var _options = ProcedureOptions()
    var options : ProcedureOptions
    get() { return _options }
    set (newValue)
    {
        _options = newValue
        ships.allitems = newValue.ships; ships.reloadData()
        destinations.allitems = newValue.installations; destinations.reloadData()
        types.allitems = newValue.types; types.reloadData()
        classes.allitems = newValue.classes; classes.reloadData()
        regions.allitems = newValue.regions; regions.reloadData()
    }

    var current : Specificable? = null

    fun update(data: Specificable)
    {
        dettach()

        current = data

        ships.selected = data.ships.toMutableList(); ships.reloadData()
        destinations.selected = data.destinations.toMutableList(); destinations.reloadData()
        types.selected = data.types.toMutableList(); types.reloadData()
        classes.selected = data.classes.toMutableList(); classes.reloadData()
        regions.selected = data.regions.toMutableList(); regions.reloadData()

        attach()
    }

    fun clear()
    {
        current = null
        dettach()
    }

    fun attach()
    {
        sections.map { it.delegate = this }

        tabs.delegate = this
        clickedTab(sections.first().tab.id)
    }

    fun dettach()
    {
        sections.map { it.delegate = null }
        tabs.delegate = null
    }

    override fun clickedTab(id: String)
    {
        for (section in sections)
            section.tab.viewHidden = id != section.tab.id
    }

    override fun changedSelected(section: ProcedureRequirementsEditorSection)
    {
        val current = current ?: return

        when (section.key)
        {
            "ships" -> { current.ships = ships.selected }
            "classes" -> { current.classes = classes.selected }
            "types" -> { current.types = types.selected }
            "destinations" -> { current.destinations = destinations.selected }
            "regions" -> { current.regions = regions.selected }
        }
        delegate?.changedRequirements(current)
    }
}

interface ProcedureRequirementsPanel : ProcedureConfig
{
    override val handbook: Handbook
    val specifics : ProcedureSpecificsEditor
    val options : ProcedureOptions

    val requirementsAppliesTo : String get() { return "Thing" }

    fun reloadRequirements(data: Specificable)
    {
        val installations = options.installations.filter { data.destinations.contains(it.id) }.map { it.name }
        val ships = options.ships.filter { data.ships.contains(it.id) }.map { it.name }
        val classes = options.classes.filter { data.classes.contains(it.id)}.map { it.name }
        val types = options.types.filter { data.types.contains(it.id)}.map { it.name }
        val regions = options.types.filter { data.types.contains(it.id)}.map { it.name }

        val total = installations.count() + ships.count() + classes.count() + types.count() + regions.count()

        if (total == 0)
        {
            specifics.description.innerHTML = "This ${requirementsAppliesTo.toLowerCase()} has no requirements related to the specifics of a voyage"
        }
        else
        {
            var bits = mutableListOf<String>()
            if (installations.count() > 0)
                bits.add("<li>Destined for: ${installations.joinToString(", ")}</li>")
            if (ships.count() > 0)
                bits.add("<li>On ships named: ${ships.joinToString(", ")}</li>")
            if (classes.count() > 0)
                bits.add("<li>On ships of the following classes: ${classes.joinToString(", ")}</li>")
            if (types.count() > 0)
                bits.add("<li>On ships of the following types: ${types.joinToString(", ")}</li>")
            if (regions.count() > 0)
                bits.add("<li>On ships in the following regions: ${regions.joinToString(", ")}</li>")
            specifics.description.innerHTML = "This ${requirementsAppliesTo.toLowerCase()} will only be visible on voyages matching the following criteria<br/><ul>${bits.joinToString("\n")}</ul>"
        }
    }
}

class ProcedureSpecificsEditor(document: Document, containerId:String, thing:String)
{
    val description = document.getElementById("$containerId.specifics.description") as HTMLParagraphElement
    val editButton = Button(document, "$containerId.specifics.edit_button","Edit requirements to target which vessels, installations or regions can see this $thing")
}

interface ProcedureRequirementsEditorSectionDelegate
{
    fun changedSelected(section: ProcedureRequirementsEditorSection)
}

class ProcedureRequirementsEditorSection(document: Document, val id:String, val name:String, val key:String) : TableViewDelegate
{
    val tab = Tab("$id-tab", name)
    val table = TableView(document, this, id)

    var allitems : List<NamedItem> = listOf()
    var items : List<NamedItem> = listOf()

    var selected : MutableList<String> = mutableListOf()

    var delegate : ProcedureRequirementsEditorSectionDelegate? = null

    fun reloadData()
    {
        for (cell in cells)
            cell.container.onclick = null
        cells.clear()

        val test = allitems.toMutableList()

        val thing = test.firstOrNull() as? IdentifiedItem
        if (thing != null)
        {
            data class DeletedItem(override val id:String,override val name:String) : IdentifiedItem

            val index = allitems.map { (it as? IdentifiedItem)?.id }
            for (sel in selected)
            {
                if (!index.contains(sel))
                    test.add(DeletedItem(sel,sel))
            }
        }

        items = test
        table.reloadData()

        items.forEachIndexed { at, element ->
            val cell = ProcedureRequirementsEditorSectionCell("cell-$id-name-$at")
            cell.container.onclick = { clickedCell(element) }
            cells.add(cell)
        }
    }

    val cells : MutableList<ProcedureRequirementsEditorSectionCell> = mutableListOf()

    fun clickedCell(item: NamedItem)
    {
        val identifiedItem = item as? IdentifiedItem
        if (identifiedItem != null)
        {
            if (selected.contains(identifiedItem.id))
                selected.remove(identifiedItem.id)
            else
                selected.add(identifiedItem.id)
            changed()
            return
        }

        if (selected.contains(item.name))
            selected.remove(item.name)
        else
            selected.add(item.name)
        changed()
    }

    fun changed()
    {
        reloadData()
        delegate?.changedSelected(this)
    }

    fun isSelected(item: NamedItem) : Boolean
    {
        val identifiedItem = item as? IdentifiedItem
        if (identifiedItem != null)
        {
            return selected.contains(identifiedItem.id)
        }
        return selected.contains(item.name)
    }

    class ProcedureRequirementsEditorSectionCell(val id:String)
    {
        val container = Li(document, id)
    }

    override val numberOfRows : Int get() { return items.count() }
    override fun titleForRow(at:Int): String { return items[at].name }

    override fun cellForRow(at:Int) : String
    {
        val item = items[at]
        val name = item.name
        val selected = isSelected(item)
        val details = ""
        if (selected)
        {
            return """
            <li class="mdc-list-item" id="cell-$id-name-$at">
                <span class="mdc-list-item__text">
                    <span class="mdc-list-item__primary-text selected-requirement">$name</span>
                    <span class="mdc-list-item__secondary-text">$details</span>
                </span>
            </li>
            """
        }
        return """
            <li class="mdc-list-item" id="cell-$id-name-$at">
                <span class="mdc-list-item__text">
                    <span class="mdc-list-item__primary-text">$name</span>
                    <span class="mdc-list-item__secondary-text">$details</span>
                </span>
            </li>
            """
    }
}