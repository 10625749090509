package main.kotlin.models.editor

import main.kotlin.data.*
import main.kotlin.ui.Button
import main.kotlin.ui.Div
import main.kotlin.ui.EditText
import main.kotlin.ui.ProcedureSelector
import org.w3c.dom.Document

open class ProcedureLinkEditor(document:Document,val id:String,val handbook:Handbook) : ContainerPanel
{
    override val container = Div(document, id)
    val field : EditText
    val procedures : ProcedureSelector
    val step : EditText

    init
    {
        container.html = markup

        field = EditText(document, "$id-link", "Link")
        procedures = ProcedureSelector(document,"$id-procedure","Procedure/Checklist", arrayOf())
        step = EditText(document, "$id-step", "Step")
    }

    open val markup : String
    get()
    {
        return """
    <div class="mdc-text-field edit" id="$id-link"></div>

    <div class="procedure-editing-panel-sub-title">
        <span>Link to a specific Procedure / Step</span>
    </div>

    <div class="mdc-select edit" id="$id-procedure"></div>
    <div class="mdc-text-field edit" id="$id-step"></div>
    """
    }

    fun reloadProcedures(options:ProcedureOptions,current:Procedure?)
    {
        val id = current?.id
        val list = options.procedures.filter { it.id != id }.toMutableList()
        val card = ProcedureCard(id="",name="None",type=ProcedureType.Procedure)
        list.add(0,card)
        procedures.setProcedures(list.toTypedArray())
        procedures.selected = card
    }

    protected open fun setLink(fieldText:String, buttonTitle:String)
    {
        field.text = fieldText
        field.textChanged?.invoke(fieldText)
    }

    fun clickedCreateLink()
    {
        var stepNumber : Int? = null
        val stepTxt = step.text
        if (stepTxt.isNotEmpty())
        {
            stepNumber = stepTxt.toIntOrNull()
        }

        val procedure = procedures.selected
        if ((procedure == null || procedure.id.isEmpty()) && stepNumber != null)
        {
            setLink("step=$stepNumber","STEP $stepNumber")
        }
        else if (procedure != null && procedure.id.isNotEmpty() && stepNumber == null)
        {
            setLink("handbooks://procedure/${handbook.normalizedId}/${procedure.id}","${procedure.name}")
        }
        else if (procedure != null && procedure.id.isNotEmpty() && stepNumber != null)
        {
            setLink("handbooks://procedure/${handbook.normalizedId}/${procedure.id}?step=$stepNumber","${procedure.name} Step $stepNumber")
        }
        else
        {
            setLink("","")
        }
    }

    open fun attach()
    {

    }

    open fun dettach()
    {

    }
}

class ProcedureGotoEditor(document:Document,id:String,handbook:Handbook) : ProcedureLinkEditor(document,id,handbook)
{
    val buttonText : EditText
    val createLinkButton : Button
    val previousStepButton : Button
    val nextStepButton : Button

    init
    {
        buttonText = EditText(document, "$id-button_text", "Button Text")
        createLinkButton = Button(document, "$id-create_link_button")
        nextStepButton = Button(document, "$id-next_step_button")
        previousStepButton = Button(document, "$id-previous_step_button")
    }

    override val markup : String
    get()
    {
        return """
        <div class="mdc-text-field edit" id="$id-button_text"></div>

        ${super.markup}

        <button class="mdc-button edit_stuff" id="$id-create_link_button">Add Link to Procedure & Step</button>

        <div class="procedure-editing-panel-sub-title">
            <span>Link to Previous / Next Step</span>
        </div>

        <button class="mdc-button edit_stuff" id="$id-previous_step_button">Previous Step</button>
        <button class="mdc-button edit_stuff" id="$id-next_step_button">Next Step</button>
        """
    }

    override fun attach()
    {
        super.attach()
        createLinkButton.onclick = { clickedCreateLink() }
        previousStepButton.onclick = { clickedPreviousStep() }
        nextStepButton.onclick = { clickedNextStep() }
    }

    override fun dettach()
    {
        super.dettach()
        previousStepButton.onclick = null
        nextStepButton.onclick = null
        createLinkButton.onclick = null
    }

    override fun setLink(fieldText:String, buttonTitle:String)
    {
        super.setLink(fieldText,buttonTitle)
        buttonText.text = buttonTitle
        buttonText.textChanged?.invoke(buttonTitle)
    }

    fun clickedPreviousStep()
    {
        setLink("step=previous","Previous Step")
    }

    fun clickedNextStep()
    {
        setLink("step=next","Next Step")
    }
}
