package main.kotlin.models.picker

import main.kotlin.data.Handbook
import main.kotlin.models.editor.ProcedureLinkEditor
import main.kotlin.ui.Button
import org.w3c.dom.Document

class ProcedureLinkPicker(document: Document, id:String, handbook: Handbook) : ProcedureLinkEditor(document,id,handbook)
{
    val doneButton: Button = Button(document, "$id-done_button")
    val closeButton = Button(document, "$id-close_button")

    var pickedLink : ((String) -> Unit)? = null

    init
    {
        doneButton.onclick = { clickedDone() }
        closeButton.onclick = { clickedClose() }
        step.textChanged = { clickedCreateLink() }
        procedures.valueChanged = { clickedCreateLink() }
    }

    override val markup: String
    get()
    {
        return """
            <button class="mdc-button quill_popover_close_button" id="$id-close_button"><i class="material-icons mdc-button__icon" aria-hidden="true">close</i></button>
            ${super.markup}
            <button class="mdc-button edit_stuff" id="$id-done_button">Use Link</button>
        """
    }

    fun clickedDone()
    {
        pickedLink?.invoke(field.text)
    }

    fun clickedClose()
    {
        pickedLink?.invoke("")
    }
}
