package main.kotlin.models

import main.kotlin.data.Response
import main.kotlin.ui.Snackbar
import kotlin.browser.document
import kotlin.browser.window

open class BaseScreen
{
    val snackbar = Snackbar(document)


    open fun start()
    {
        var msg = Storage.toast
        if (msg!=null) {
            Storage.toast = null
            showToast(msg)
        }
    }

    fun pushTo(name:String, msg: String? = null)
    {
        pushToHtml("$name.html", msg)
    }

    fun pushTo(name:String, response:Response, fallbackMessage:String)
    {
        pushToHtml("$name.html", response, fallbackMessage)
    }

    fun pushToHtml(html:String, response:Response, fallbackMessage:String)
    {
        var msg = response.msg
        if (msg == null || msg.isEmpty())
            msg = fallbackMessage

        pushToHtml(html, msg)
    }

    fun pushToHtml(html:String, msg: String? = null)
    {
        if (msg != null) 
        {
            Storage.toast = msg
        }

        window.location.href = html
    }

    fun showToast(msg:String)
    {
        snackbar.open(msg)
    }

    fun showToast(response:Response,fallbackMessage:String)
    {
        val msg = response.msg
        if (msg != null && msg.isNotEmpty())
            showToast(msg)
        else
            showToast(fallbackMessage)
    }
}