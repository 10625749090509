package main.kotlin.models

import main.kotlin.comms.Requests
import main.kotlin.data.*
import main.kotlin.ui.*
import org.w3c.dom.Document

class FeedbackDetailsScreen(document:Document,val fid:String) : TopNavBarScreen(document)
{
    override val activeTab: TopNavBarTab get() = TopNavBarTab.Feedback

    val breadcrumbs = Breadcrumbs(document,"page_breadcrumbs", arrayOf(Breadcrumb("Feedback","feedback.html")))

    val details = Div(document,"feedback_details")
    val message = Div(document,"feedback_message")
    private val comments = Div(document,"feedback_comments")
    private val commentsEmpty = Div(document,"feedback_comments_empty")
    private val newComment = EditTextArea(document,"new_comment","Enter comments here",rows=3)
    private val newCommentButton = Button(document,"create_new_comment_button")
    private val replies = Div(document,"feedback_reply")
    private val repliesEmpty = Div(document,"feedback_reply_empty")
    private val newReply = EditTextArea(document,"new_reply","Enter reply here",rows=3)
    private val newReplyButton = Button(document,"create_new_reply_button")
    private val deleteButton = Button(document,"delete_button",tooltip="Delete this feedback")
    private val resolveButton = Button(document,"resolve_button",tooltip="Resolve this feedback")

    private val kMessage = "Message"
    private val kUser = "User"
    private val kStatus = "Status"
    private val kResolved = "Resolved"
    private val kTime = "Time"
    private val kShip = "Vessel"

    val alert = Alert(document)

    var feedback : FeedbackDetails? = null
    var shipName : String? = null

    init
    {
        deleteButton.onclick = { delete() }
        resolveButton.onclick = { resolve() }
        newCommentButton.onclick = { addComment() }
        newReplyButton.onclick = { addReply() }

        commentsEmpty.hidden = true
        repliesEmpty.hidden = true

        deleteButton.hidden = true
        resolveButton.hidden = true
    }

    override fun start()
    {
        super.start()

        loading = false

        refresh()
    }

    fun refresh()
    {
        loading = true
        Requests.getFeedback(this,fid)
        {
            loading = false
            refreshed(it)
        }
    }

    fun delete(confirmed:Boolean=false)
    {
        if (confirmed)
        {
            loading = true
            Requests.deleteFeedback(this, fid)
            {
                loading = false
                if (it.error != null)
                {
                    showToast(it, "Failed to delete feedback")
                }
                else
                {
                    pushTo("feedback", it, "Feedback deleted!")
                }
            }
        }
        else
        {
            alert.open("Delete Feedback", "Are you sure you would like to delete this feedback?", arrayOf(AlertAction("cancel", "Cancel"), AlertAction("delete", "Delete")))
            {
                if (it == "delete") {
                    delete(true)
                }
            }
        }
    }

    private fun resolve()
    {
        loading = true
        Requests.resolveFeedback(this, fid)
        {
            loading = false
            if (it.error != null)
            {
                showToast(it, "Failed to resolve feedback")
            }
            else
            {
                showToast(it, "Feedback resolved!")
                refresh()
            }
        }
    }

    private fun addComment()
    {
        val msg = newComment.text.trim()
        if (msg.isBlank())
        {
            showToast("Comment is blank, nothing to add, sorry")
        }
        else
        {
            loading = true
            Requests.addCommentToFeedback(this, fid, msg)
            {
                loading = false
                if (it.error != null)
                {
                    showToast(it, "Failed to add comment")
                }
                else
                {
                    newComment.text = ""
                    showToast(it, "Comment added!")
                    refreshed(it.item,false)
                }
            }
        }
    }

    private fun addReply()
    {
        val msg = newReply.text.trim()
        if (msg.isBlank())
        {
            showToast("Reply is blank, nothing to add, sorry")
        }
        else
        {
            loading = true
            Requests.addReplyToFeedback(this, fid, msg)
            {
                loading = false
                if (it.error != null)
                {
                    showToast(it, "Failed to add reply")
                }
                else
                {
                    newReply.text = ""
                    showToast(it, "Reply added!")
                    refreshed(it.item,false)
                }
            }
        }
    }

    private fun loadShip(shipId:String)
    {
        Requests.getShip(this,shipId)
        {
            val ship = it.ship
            if (ship!=null)
            {
                shipName = ship.name
            }
            else
            {
                shipName = feedback?.ship ?: "???"
            }
            reloadData()
        }
    }

    fun refreshed(it:FeedbackDetailsResponse)
    {
        refreshed(it.item,true)
    }

    fun refreshed(item:FeedbackDetails?,shouldLoadShipDetails:Boolean)
    {
        feedback = item
        reloadData()
        if (shouldLoadShipDetails && item != null)
            loadShip(item.ship)
    }

    fun reloadData()
    {
        val item = feedback
        if (item != null)
        {
            resolveButton.hidden = item.status != FeedbackStatus.Submitted
            deleteButton.hidden = false

            var text = ""
            text += "<b>$kUser</b>: ${item.by}<br/>"
            text += "<b>$kTime</b>: ${item.timestamp?.display}<br/>"
            text += "<b>$kStatus</b>: ${item.status.title}<br/>"
            val resolved = item.resolved
            if (item.status == FeedbackStatus.Resolved && resolved != null)
                text += "<b>$kResolved</b>: ${resolved.description}<br/>"
            text += "<br/>"
            text += "<b>$kShip</b>: ${shipName ?: "Loading..."}<br/><br/>"

            details.html = text
            message.html = "<b>$kMessage</b>:<br/><p>${item.text.replace("\n","<br/>")}</p>"

            reloadComments()
            reloadReply()
        }
        else
        {
            details.text = "Not Found"
            message.text = ""
            resolveButton.hidden = true
            deleteButton.hidden = true
        }
    }

    private val Event.commentTitle : String
    get()
    {
        if (timestamp != null)
        {
            return "$by (${timestamp.display})"
        }
        return by
    }

    private val Reply.commentTitle : String
        get()
        {
            if (timestamp != null)
            {
                return "$by (${timestamp.display})"
            }
            return by
        }

    private fun reloadComments()
    {
        val messages = feedback?.comments ?: arrayOf()

        commentsEmpty.hidden = messages.isNotEmpty()
        comments.hidden = messages.isEmpty()

        var html = ""
        for (comment in messages)
        {
            var msg = comment.comment ?: continue
            msg = msg.replace("\n","<br/>")

            html += """
                <div class="feedback_comment">
                    <div class="feedback_comment_title">${comment.commentTitle}</div>
                    <div class="feedback_comment_message">$msg</div>
                </div>
            """
        }

        comments.html = html
    }

    private fun reloadReply()
    {
        val replies = feedback?.reply ?: arrayOf()

        repliesEmpty.hidden = replies.isNotEmpty()
        this.replies.hidden = replies.isEmpty()

        var html = ""
        for (reply in replies)
        {
            var msg = reply.text ?: continue
            msg = msg.replace("\n","<br/>")

            html += """
                <div class="feedback_reply">
                    <div class="feedback_reply_title">${reply.commentTitle}</div>
                    <div class="feedback_reply_message">$msg</div>
                </div>
            """
        }

        this.replies.html = html
    }
}
