package main.kotlin.ui

import org.w3c.dom.*
import org.w3c.dom.events.Event
import kotlin.dom.addClass
import kotlin.dom.removeClass

open class Span(document: Document,id:String,visibleDisplayStyle:String="block") : Element(document,id,visibleDisplayStyle)
{
    override val view : HTMLSpanElement = document.getElementById(id) as HTMLSpanElement
}
