package main.kotlin.models

import main.kotlin.comms.Requests
import org.w3c.dom.Document
import org.w3c.dom.HTMLDivElement

class IndexScreen(document:Document) : AuthScreen()
{
    val loader : HTMLDivElement = document.getElementById("page_loader") as HTMLDivElement

    override fun start()
    {
        super.start()

        loader.hidden = true

        if (isAuthenticated)
        {
            val id = id!!
            Requests.getUser(this,id)
            {
                val user = it.user
                if (user != null)
                {
                    pushTo("handbooks")
                }
            }
        }
    }
}