package main.kotlin.models.editor

import main.kotlin.data.*
import main.kotlin.models.AuthScreen
import main.kotlin.ui.*
import org.w3c.dom.Document

class ProcedureSettingsEditor(document: Document, override val handbook: Handbook) : ContainerEditorPanel, ProcedureRequirementsPanel
{
    val id = "procedure_settings"
    override val container = Div(document, id)
    override val panelType : ContainerEditorPanelType get() { return ContainerEditorPanelType.Settings }
    val name = EditTextRich(document,handbook, "name", "Enter procedure/checklist name", required = true, config=EditTextRich.Config(linksAllowed = false, indentsAllowed = false, formattingAllowed = false))
    val chapter = ProcedureChapterSelector(document, "chapter", "Chapter", arrayOf(), true,tooltip="Select which chapter this checklist or procedure belongs to")
    val section = ProcedureSectionSelector(document, "section", "Section", arrayOf(),tooltip="Select which section this checklist or procedure belongs to")
    val spawnable = Switch(document, "spawnable", "Repeatable",tooltip="Specify if this checklist should be possible to complete multiple times during an operation")
    val optional = Switch(document, "optional", "Optional",tooltip="Specify if this checklist should be optional or required")
    val unsequential = Switch(document, "unsequential", "Unsequential",tooltip="Specify whether it should be possible to complete steps in the checklist unsequentially")
    val type = ProcedureTypeSelector(document, "type", "Type",tooltip="Specify type")
    val closeButton = Button(document, "$id-close_button","Close Procedure/Checklist settings panel")
    val linked = ProcedureSelector(document,"linked","Linked",arrayOf(),tooltip="Link this checklist to another procedure, or the procedure to another checklist, in order to enable easy navigation menu between them. Note that checklists can only be linked to procedures and visa versa.")
    val order = EditText(document, "order", "Order", required = true,tooltip = "Specify the number representing the order of the checklist or procedure within a chapter/section")
    val completionMode = CompletionModeSelector(document, "completion_mode", "Completion Mode",tooltip="Specify if a user should be asked to supply a compulsory or optional comment when completing the checklist")

    override val specifics = ProcedureSpecificsEditor(document, id, "Procedure/Checklist")
    override val requirementsAppliesTo : String get() { return type.selected.title.toLowerCase() }
    override var options = ProcedureOptions()

    var delegate : ProcedureEditorDelegate? = null

    var currentChapter : String? = null
    var currentSection : String? = null

    var currentLinked : String = ""

    fun update(@Suppress("UNUSED_PARAMETER") screen:AuthScreen, data:Procedure)
    {
        dettach()

        chapter.setChapters(options.chapters.toTypedArray())

        currentChapter = data.chapter
        currentSection = data.section
        currentLinked = data.linked ?: ""

        reloadChapterAndSection(false)

        name.hint = "Name of ${data.type.title}"

        name.text = data.name
        spawnable.checked = data.spawnable
        optional.checked = data.optional
        unsequential.checked = data.unsequential
        type.selected = data.type

        order.text = "" + data.order

        if (data.type == ProcedureType.Checklist)
        {
            completionMode.hidden = false
            completionMode.selected = data.completionMode
        }
        else
        {
            completionMode.hidden = true
        }

        if(data.name.isNotEmpty()) type.disabled = true

        reloadLinked()
        reloadRequirements(data)

        attach()
    }

    fun reloadChapterAndSection(dettach:Boolean)
    {
        if (dettach)
            dettach()

        val selectedChapter = options.chapters.find { it.name == currentChapter }
        chapter.selected = selectedChapter

        val sections = selectedChapter?.sections?.filter { !it.isFallbackSection }

        if (sections != null && sections.isNotEmpty())
        {
            section.setSections(sections.toTypedArray())
            val selectedSection = sections.find { it.name == currentSection }
            section.selected = selectedSection
            section.hidden = false
        }
        else
        {
            section.selected = null
            section.hidden = true
        }

        val procedures = chapters[selectedChapter?.name]
        linked.setProcedures(procedures?.filter { it.parent.isNullOrEmpty() }?.toTypedArray() ?: arrayOf())
        linked.selected = procedures?.find { it.id == currentLinked }

        if (dettach)
            attach()
    }

    var chapters : MutableMap<String,Array<ProcedureCard>> = mutableMapOf()

    fun reloadLinked()
    {
        val proc = delegate?.procedure
        val id = proc?.id
        val currentType = proc?.type
        chapters.clear()
        for (chapter in options.chapters)
        {
            val list = chapter.procedures.filter { it.id != id && it.type != currentType }.toMutableList()
            list.add(0,ProcedureCard(id="",name="None",type=ProcedureType.Procedure))
            chapters[chapter.name] = list.toTypedArray()
        }

        reloadChapterAndSection(false)
    }

    fun changed()
    {
        delegate?.changedSettings()
    }

    fun changedChapter()
    {
        currentChapter = chapter.selected?.name
        currentSection = null
        currentLinked = ""
        reloadChapterAndSection(true)
        changed()
    }

	fun changedType()
	{
		val data = delegate?.procedure ?: return
		data.type = type.selected
        if (data.type == ProcedureType.Checklist)
        {
            completionMode.hidden = false
            completionMode.selected = data.completionMode
        }
        else
        {
            completionMode.hidden = true
        }
        changed()
	}

    fun orderChanged()
    {
//        val text = order.text
//        val orderValue = text.toIntOrNull() ?: return
        changed()
    }

    fun clickedEditRequirements()
    {
        val data = delegate?.procedure ?: return
        delegate?.clickedEditRequirements(data)
    }

    fun attach()
    {
        specifics.editButton.onclick = { clickedEditRequirements() }
        name.textChanged = { changed() }
        chapter.valueChanged = { changedChapter() }
        section.valueChanged = { changed() }
        type.valueChanged = { changedType() }
        spawnable.valueChanged = { changed() }
        optional.valueChanged = { changed() }
        unsequential.valueChanged = { changed() }
        linked.valueChanged = { changed() }
        order.textChanged = { orderChanged() }
        completionMode.valueChanged = { changed() }
    }

    fun dettach()
    {
        specifics.editButton.onclick = null
        name.textChanged = null
        chapter.valueChanged = null
        section.valueChanged = null
        type.valueChanged = null
        spawnable.valueChanged = null
        optional.valueChanged = null
        unsequential.valueChanged = null
        linked.valueChanged = null
        order.textChanged = null
        completionMode.valueChanged = null
    }
}