package main.kotlin.ui

import main.kotlin.data.CompletionMode
import org.w3c.dom.Document

private val Array<CompletionMode>.selectorItems : Array<SelectorItem>
get()
{
    val list = mutableListOf<SelectorItem>()

    for (type in this)
    {
        list.add(SelectorItem("${type.toString().toLowerCase()}","${type.title}","${type.value}"))
    }

    return list.toTypedArray()
}

open class CompletionModeSelector(document: Document, id:String, hint:String,tooltip:String?=null) : Selector(document,id,hint,CompletionMode.values().selectorItems,tooltip=tooltip)
{
    var selected : CompletionMode
    get()
    {
        val s = view.value as String
        return CompletionMode.from(s.toInt())
    }
    set(value)
    {
        view.value = value.value.toString()
    }

    fun setModes(modes:Array<CompletionMode>)
    {
        setSelectorItems(modes.selectorItems)
    }
}
