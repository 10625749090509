package main.kotlin.models

import main.kotlin.comms.Requests
import main.kotlin.data.Handbook
import main.kotlin.data.HandbookOperation
import main.kotlin.data.User
import main.kotlin.ui.*
import org.w3c.dom.Document
import org.w3c.dom.asList
import ui.MapSelector
import kotlin.browser.document

class HandbooksScreen(document: Document) : TopNavBarScreen(document) {
    val alert = Alert(document)

    private val publishedHandbooks = HandbooksTilesView(document, "published", "Published", this)
    private val inProgressHandbooks = HandbooksTilesView(document, "in_progress", "In Progress", this)

    private val createButton = Button(document, "create_button", tooltip = "Create a new handbook")
    override val helpTooltip: String
        get() {
            return "Help"
        }
    override val helpPage: String
        get() {
            return "help.html?chapter=handbooks"
        }

    override val activeTab: TopNavBarTab get() = TopNavBarTab.Nothing

    var hbOperations: ArrayList<HandbookOperation> = arrayListOf()
    var allHandbooks: Array<Handbook> = arrayOf()

    val filters = HandbooksFilters(document, "filters")

    override fun start() {
        super.start()

        if (!isAuthenticated)
            return

        createButton.onclick = { create() }

        refresh()
    }

    fun refresh() {
        loading = true

        Requests.getUser(this, id!!)
        {
            val user = it.user
            if (user != null) {
                refreshed(user)
            }
        }

        Requests.listHandbooks(this) {
            
            allHandbooks = it.handbooks
            
            publishedHandbooks.handbooks = it.handbooks.filter { it.published }.toTypedArray()
            inProgressHandbooks.handbooks = it.handbooks.filter { !it.published }.toTypedArray()
            
            Requests.listHandbookOperations(this) { response ->
                hbOperations.addAll(response.handbookOperations)
                filters.setHandbookOperations(hbOperations.toList())
                
                filters.setValueChangeListener(
                    filter = { doFilter() }
                )
                    
                publishedHandbooks.reloadData()
                inProgressHandbooks.reloadData()
                loading = false
            }
        }
    }

    fun refreshed(user: User) {

    }

    fun create() {
        pushTo("handbook")
    }

    private fun doFilter() {
        doOperationsFilter()
        doStatusFilter()
    }

    private fun doStatusFilter() {
        inProgressHandbooks.view.hidden = filters.hbStatusLabel == "Published" || inProgressHandbooks.handbooks.isEmpty()
        publishedHandbooks.view.hidden = filters.hbStatusLabel == "In progress" || publishedHandbooks.handbooks.isEmpty()
    }

    private fun doOperationsFilter() {
        filters.hbOperation?.let { op ->
            val operationID = hbOperations.firstOrNull { it.id == op }?.id

            operationID?.let { selectedOperation ->
                inProgressHandbooks.handbooks =
                    allHandbooks.filter { handbook ->
                        !handbook.published && handbook.operations.contains(selectedOperation)
                    }.toTypedArray()
                publishedHandbooks.handbooks =
                    allHandbooks.filter { handbook ->
                        handbook.published && handbook.operations.contains(selectedOperation)
                    }.toTypedArray()
            }
        } ?: run {
            inProgressHandbooks.handbooks = allHandbooks.filter { !it.published }.toTypedArray()
            publishedHandbooks.handbooks = allHandbooks.filter { it.published }.toTypedArray()
        }
        inProgressHandbooks.reloadData()
        publishedHandbooks.reloadData()
    }
}

class HandbooksTilesView(document: Document, val id: String, val name: String, val screen: HandbooksScreen) :
    TilesViewDelegate {
    val view = Div(document, id)
    val list: TilesView

    init {
        val html = if (id == "published")
            """
                <div class="handbook-section-title">$name</div>
                <div id="list-$id"></div> 
            """
        else
            """
                <div class="handbook-section-title handbook-in-progress-title">$name</div>
                <div id="list-$id"></div> 
            """

        view.html = html

        list = TilesView(document, this, "list-$id", "grid-handbooks")
    }

    var handbooks: Array<Handbook> = arrayOf()

    override val numberOfItems: Int
        get() {
            return handbooks.count()
        }

    private fun getOperationTag(operations: Array<String>): MutableMap<String, String> {
        val operationsMap: MutableMap<String, String> = mutableMapOf()
        for (op in operations) {
            var parts = op.split(" ").filter { it.length > 0 }
            var key = if(parts.size > 1) parts.map { it.first() }.joinToString("").take(2) else op.take(2).toUpperCase()
            operationsMap[op] = key
        }
        return operationsMap
    }

    override fun cellForItem(at: Int): String {
        var tagsString = ""
        val builder = StringBuilder()
        val handbook = handbooks[at]
        val title = handbook.name
        val description = handbook.description
        val onclick = "window.location.href='procedures.html?handbook=${handbook.params}'"
        val icon = handbook.icon.image
        val details = if (handbook.version.isNotBlank()) "v${handbook.version}" else ""
        if (handbook.operations.isNotEmpty()) {
            val allOperations = screen.hbOperations
            var operationList = arrayListOf<String>()
            handbook.operations.forEach { op ->
                val operation = allOperations.firstOrNull { it.id == op }?.name ?: "?"
                operationList.add(operation)
            }
            getOperationTag(operationList.toTypedArray()).forEach { it ->
                builder.append(
                    """<button 
            |class="mdc-button operation"
            |id="operation-${at}-${it.value}"
            |data-tippy="${it.key}">
            |<i class="material-icons mdc-button__icon">${it.value}</i></button>""".trimMargin()
                )
            }
            tagsString = builder.toString()
        }

        if (id == "published")
            return """
                <li class="mdc-image-list__item published" onclick="$onclick">
                    <div class="mdc-image-list__image">
                        <div class="cell_icon" style="background-image:url('$icon');"></div>
                        <span class="cell_title">$title</span>
                        <span class="cell_details">$details</span>
                        <span class="cell_description">$description</span>
                    <div class="operationsWrapper">$tagsString</div>
                    </div>
                </li>
                """
        else
            return """
                <li class="mdc-image-list__item" style="background:#2962FF" onclick="$onclick">
                <div class="mdc-image-list__image">
                    <div class="cell_icon" style="background-image:url('$icon');"></div>
                    <span class="cell_title">$title</span>
                    <span class="cell_details">$details</span>
                    <span class="cell_description">$description</span>
                    <button class="mdc-button edit"><i class="material-icons mdc-button__icon" aria-hidden="true">mode_edit</i></button>
                    <div class="operationsWrapper">$tagsString</div>
                </div>
            </li>
            """
    }

    data class HandbookCell(val handbook: Handbook) {

        fun clear() {

        }
    }

    var cells: MutableList<HandbookCell> = mutableListOf()

    fun reloadData() {
        for (cell in cells)
            cell.clear()
        cells.clear()

        list.reloadData()

        document.getElementsByClassName("operation").asList().forEach { it ->
            var tooltip = it.getAttribute("data-tippy").toString()
            Button(document, it.id, tooltip = tooltip)
        }

        for (handbook in handbooks) {
            val cell = HandbookCell(handbook)
            cells.add(cell)
        }

        view.hidden = handbooks.isEmpty()
    }
}

class HandbooksFilters(document: Document, val id: String) {
    var hbStatusLabel: String? = "All"
    var hbOperation: String? = null

    private var hbStatusLabels: List<String> = listOf("In progress", "Published")
    private var hbOperationList: List<HandbookOperation> = listOf()

    private val hbStatusLabelSelector: StringSelector
    private val hbOperationSelector: MapSelector
    private val container = Div(document, id)

    fun setHandbookOperations(hbOperations: List<HandbookOperation>) {
        this.hbOperationList = hbOperations
        hbOperationSelector.setItems(hbOperations.associateBy(HandbookOperation::id, HandbookOperation::name))
    }

    val markup: String
        get() {
            return """
           <div class="mdc-select" id="${id}_status"></div>
           <div class="mdc-select" id="${id}_operation"></div>  
    """
        }

    init {
        container.html = markup
        hbStatusLabelSelector = StringSelector(document, "${id}_status", "Status", hbStatusLabels)
        hbOperationSelector = MapSelector(document, "${id}_operation", "Operation", hbOperationList.associateBy(HandbookOperation::id, HandbookOperation::name))


    }

    fun setValueChangeListener(filter: () -> Unit) {

        hbStatusLabelSelector.selected = hbStatusLabel
        hbStatusLabelSelector.valueChanged = {
            hbStatusLabel = hbStatusLabelSelector.selected
            filter.invoke()
        }

        hbOperationSelector.selected = hbOperation
        hbOperationSelector.valueChanged = {
            hbOperation = hbOperationSelector.selected
            filter.invoke()
        }
    }

}