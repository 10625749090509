package main.kotlin.models

import main.kotlin.comms.Requests
import main.kotlin.data.Handbook
import main.kotlin.data.IdResponseCode
import main.kotlin.ui.Alert
import main.kotlin.ui.AlertAction
import main.kotlin.ui.TopNavBarTab
import org.w3c.dom.Document

abstract class HandbookBaseScreen(document: Document, val handbook: Handbook,tab:TopNavBarTab?=null) : LeftSectionedScreen(document,tab)
{
    val alert = Alert(document)

    abstract val handbookVersion : String?
    abstract fun refresh()
    abstract fun openHandbook(id:String)

    override fun start()
    {
        super.start()

        val msg = Storage.getStartupToast(StartupToastForScreen.HandbookBaseScreen)
        if (!msg.isNullOrBlank())
        {
            Storage.setStartupToast(null,StartupToastForScreen.HandbookBaseScreen)
            showToast(msg)
        }
    }

    fun revise(version:String?=null)
    {
        val oldVersion = handbookVersion ?: return
        if (version != null)
        {
            loading = true
            Requests.createHandbookRevision(this,handbook.id,version)
            {
                loading = false
                if (it.error != null)
                {
                    showToast(it,"Failed to create handbook revision")
                }
                else
                {
                    val msg = when(it.code)
                    {
                        IdResponseCode.Normal -> "Handbook revision created, now get to work!"
                        IdResponseCode.HandbookRevisionAlreadyExists -> "Handbook revision already exists, opening it up now... (Version change ignored)"
                    }

                    Storage.setStartupToast(msg,StartupToastForScreen.HandbookBaseScreen)
                    handbook.published = false
                    openHandbook(it.id)
                }
            }
        }
        else
        {
            alert.openForInput("Revise Handbook", "Please specify a new version number for the new revision", arrayOf(AlertAction("cancel", "Cancel"), AlertAction("revise", "Revise")),"New Handbook Version")
            {
                if (it == "revise")
                {
                    val hbVersion = alert.inputText
                    if (hbVersion!=null && hbVersion.isNotBlank())
                    {
                        if (hbVersion != oldVersion)
                            revise(hbVersion)
                        else
                            showToast("You cannot use the same version number for the new revision as the old version")
                    }
                    else
                        showToast("Cannot revise the handbook without a new version number")
                }
            }
        }
    }
}