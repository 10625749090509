package main.kotlin.models.editor

import main.kotlin.comms.Requests
import main.kotlin.data.Blurb
import main.kotlin.data.ContainerEditorPanel
import main.kotlin.data.ContainerEditorPanelType
import main.kotlin.models.AuthScreen
import main.kotlin.ui.*
import org.w3c.dom.Document
import kotlin.browser.document

class ProcedureImagesEditor(document:Document,val screen:AuthScreen) : ContainerEditorPanel,TilesViewDelegate
{
    val id = "procedure_images_editor"
    override val container = Div(document, id)
    override val panelType : ContainerEditorPanelType get() { return ContainerEditorPanelType.Images }
    val closeButton = Button(document, "$id-close_button",tooltip="Close images panel")
    val addButton = Button(document, "$id-add_button",tooltip="Upload a new image")
    val deleteButton = Button(document, "$id-delete_button",tooltip="Delete the selected image")
    val list = TilesView(document,this,"$id-list","procedure_images_editor-images", "mdc-image-list mdc-image-list--masonry")
    val dropzone = Dropzone(document,"$id-dropzone", screen)

    var images : List<String> = listOf()

    var currentBlurb : Blurb? = null
    var currentPlacement : BlurbPlacement? = null

    var delegate : ProcedureEditorDelegate? = null

    init
    {
        addButton.onclick = { clickedAddImage() }
        dropzone.succeeded = { uploadFinished() }
        deleteButton.onclick = { clickedDeleteImage() }
    }

    fun update(screen:AuthScreen, blurb: Blurb, placement:BlurbPlacement)
    {
        currentBlurb = blurb
        currentPlacement = placement

        refresh(screen)
    }

    fun refresh(screen:AuthScreen)
    {
        Requests.getImages(screen)
        {
            images = it.images
            reloadData()
        }
    }

    val cells : MutableList<ImageCell> = mutableListOf()

    class ImageCell(id:String)
    {
        val container = Li(document,id)
    }

    fun reloadData()
    {
        for(cell in cells)
            cell.container.onclick = null;
        cells.clear()

        list.reloadData()

        for ((at,image) in images.withIndex())
        {
            val cell = ImageCell("$id-cell_image-$at")
            cell.container.onclick = { clickedCell(at) }
            cells.add(cell)
        }

        reloadSelected()
    }

    fun reloadSelected()
    {
        val selectedClass = "procedure_images_editor-selected_image"
        var hasSelected = false
        for((at,image) in images.withIndex())
        {
            if (image == currentBlurb?.text)
            {
                cells[at].container.addClass(selectedClass)
                hasSelected = true
            }
            else
            {
                cells[at].container.removeClass(selectedClass)
            }
        }
        deleteButton.hidden = !hasSelected
    }

    fun uploadFinished()
    {
        refresh(screen)
    }

    fun clickedAddImage()
    {
        dropzone.hidden = !dropzone.hidden
    }

    fun clickedDeleteImage()
    {
        val id = currentBlurb?.text ?: return
        Requests.deleteImage(screen,id)
        {
            if (it.error != null)
            {
                screen.showToast(it,"Failed to delete image")
            }
            else
            {
                currentBlurb?.text = ""
                refresh(screen)
            }
        }
    }

    fun clickedCell(at:Int)
    {
        val placement = currentPlacement?: return
        val blurb = currentBlurb ?: return
        blurb.text = images[at]
        delegate?.changedBlurb(placement)
        delegate?.reloadData()
        reloadSelected()
    }

    override val numberOfItems: Int get() { return images.count() }

    override fun cellForItem(at:Int) : String
    {
        val image = images[at]
        val selected = image == currentBlurb?.text ?: false
        val selClass = if (selected) "procedure_images_editor-selected_image" else ""
        val markup = """
            <li class="mdc-image-list__item $selClass" id="$id-cell_image-$at">
                <img class="mdc-image-list__image" src="${Config.url}/images/$image">
            </li>
            """
        return markup
    }
}