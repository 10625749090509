package main.kotlin.models

import main.kotlin.comms.Requests
import main.kotlin.ui.Button
import main.kotlin.ui.EditText
import org.w3c.dom.Document

class LoginScreen(document:Document) : LoaderScreen(document)
{
    val email = EditText(document,"email","Email",required=true,autocomplete="username")
    val password = EditText(document,"password","Password","password",required=true,autocomplete="current-password")

    val loginButton = Button(document,"login_button")
    val resetButton = Button(document,"reset_button")

    init
    {
        loginButton.onclick = { login() }
        resetButton.onclick = { resetPassword() }

        password.onKeyUp = {
            if (it.code.toLowerCase() == "enter")
            {
                login()
            }
        }
    }

    override fun start()
    {
        loading = false
    }

    fun login()
    {
        loading = true

        val email = email.text
        val password = password.text

        Requests.login(email,password)
        {
            if (it.error != null)
            {
                loading = false
                showToast(it,"Failed to login")
            }
            else
            {
                storeCredentials(it.id,it.token,it.firstName,it.lastName,it.role)
                pushTo("handbooks")
            }
        }
    }

    fun resetPassword()
    {
        loading = true

        val email = email.text

        Requests.resetPassword(email)
        {

            loading = false
            if (it.error != null)
            {
                showToast(it,"Failed to reset password")
            }
            else
            {
                showToast(it,"Password reset, please check your email!")
            }
        }
    }
}