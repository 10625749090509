package main.kotlin.ui

import main.kotlin.data.InstallationCard
import main.kotlin.data.LoadingSystem
import org.w3c.dom.Document
import ui.MapSelector

val List<InstallationCard>.selectorItems: Array<SelectorItem>
    get() {
        val selectors: MutableList<SelectorItem> = mutableListOf()

        var index = 0
        for (installation in this) {
            selectors.add(SelectorItem("installation-${installation.id}", installation.name, index.toString()))
            index += 1
        }

        return selectors.toTypedArray()
    }

class InstallationSelector(document: Document, id: String, hint: String, var installations: List<InstallationCard>) : Selector(document, id, hint, installations.selectorItems) {
    var selected: InstallationCard?
        get() {
            val s = view.value as? String ?: return null
            if (s.isNullOrEmpty()) return null
            val index = s.toInt()
            if (index >= 0)
                return installations[index]
            return null
        }
        set(value) {
            val index = installations.indexOfFirst { it.name == value?.name }
            view.value = "$index"
        }

    fun setInstallations(installations: List<InstallationCard>) {
        this.installations = installations
        setSelectorItems(installations.selectorItems)
    }
}

open class LoadingSystemSelector(document: Document, id: String, hint: String, loadingSystems: List<LoadingSystem>, mode: SelectorNotSelectedMode = SelectorNotSelectedMode.All)
    : MapSelector(document, id, hint, loadingSystems.associateBy(LoadingSystem::id, LoadingSystem::name), mode) {
    fun setLoadingSystems(loadingSystems: List<LoadingSystem>) {
        setItems(loadingSystems.associateBy(LoadingSystem::id, LoadingSystem::name))
    }
}