package main.kotlin.ui

import org.w3c.dom.Document
import org.w3c.dom.HTMLDivElement
import kotlin.dom.clear
import kotlin.math.ceil

interface GridViewDelegate
{
    fun numberOfItems(section:Int) : Int

    fun numberOfSections() : Int
    {
        return 1
    }

    fun cellForItem(at:Int,padded:Boolean=false) : String
    {
        val onclick = if (padded) "" else onclickForItem(at)
        val name = if (padded) "" else titleForItem(at)
        val details = if (padded) "" else detailForItem(at)
        val progress = if(padded) null else progressForItem(at)
        val icon = if (padded) "" else iconForItem(at)
        var progressStyle = ""
        var progressText = ""
        var progressClass = if(padded) "progress-bar-procedure" else progressBarClass(at)
        var visStyle = if (padded) """style="visibility: hidden;"""" else ""
        if (progress != null)
        {
            progressStyle = """style="width:$progress%;""""
            progressText = "$progress%"
        }
        val blurb = """
    <div class="col" $visStyle>
      <div class="tile with-ripple" onclick="$onclick">
         <div class="progress">
           <div class="$progressClass" $progressStyle"></div>
        </div>
        <div class="tile-body">
          <div class="tile-title">
            <div>$details</div>
            <div class="right">$progressText</div>
            <i class="material-icons right">$icon</i>
          </div>
          <div class="tile-header">$name</div>
        </div>
      </div>
    </div>
            """
        return blurb
    }

    fun sectionTitle(section:Int) : String?
    {
        return null
    }

    fun titleForItem(at:Int) : String
    {
        return ""
    }

    fun detailForItem(at:Int) : String
    {
        return ""
    }

    fun iconForItem(at:Int) : String
    {
        return ""
    }

    fun onclickForItem(at:Int) : String

    fun progressBarClass(at:Int) : String
    {
        return "progress-bar-procedure"
    }

    fun progressForItem(at:Int) : Int?
    {
        return null
    }
}

class GridView(val document: Document,val delegate:GridViewDelegate,id:String)
{
    private val view : HTMLDivElement = document.getElementById(id) as HTMLDivElement

    fun reloadData()
    {
        view.clear()

        val title = delegate.sectionTitle(0) ?: ""

        var html = """
        <div class="row">
            <p class="row-title">$title</p>
        </div>
        """

        val itemCount = delegate.numberOfItems(0)
        val columns = 4
        val rows = ceil(itemCount / columns.toFloat()).toInt()
        val paddedItemCount = rows * columns
        var columnIndex = 0
        var rowIndex = 0
        for (i in 0 until paddedItemCount)
        {
            if (columnIndex == 0)
            {
                html += """<div class="row">"""
            }

            html += delegate.cellForItem(i,i >= itemCount)

            columnIndex += 1
            if (columnIndex >= 4)
            {
                html += "</div>"
                columnIndex = 0
                rowIndex += 1
            }
        }

        html += """

    <script type="text/javascript">

    console.log("Hello alek");

    </script>

        """

        view.innerHTML = html
    }
}