package main.kotlin.models

import main.kotlin.comms.Requests
import main.kotlin.data.*
import main.kotlin.ui.*
import org.w3c.dom.*
import kotlin.browser.window

open class UserScreen(document: Document,private val uid:String) : TopNavBarScreen(document)
{
    override val activeTab: TopNavBarTab get() = TopNavBarTab.Users

    val breadcrumbs = Breadcrumbs(document,"page_breadcrumbs", arrayOf(Breadcrumb("Users","users.html")))

    private val employeeId = EditText(document,"employee_id","Altera Employee Id",required=true,helperTextId = "employee_id_helper_text")

    val email = EditText(document,"email","Email",helperTextId = "email_helper_text")
    val firstName = EditText(document,"firstName","First Name",required=true,helperTextId = "firstName_helper_text")
    val lastName = EditText(document,"lastName","Last Name",required=true,helperTextId = "lastName_helper_text")
    val pin = EditText(document,"pin","Pin",required=true,helperTextId = "pin_helper_text")
    val role : UserRoleSelector = UserRoleSelector(document,"role","Role")

    val navbar = NavBar(document,"page_navbar")
    val saveButton = Button(document,"save_button",tooltip="Save changes to this user")
    val deleteButton = Button(document,"delete_button",tooltip="Delete this user")

    val alert = Alert(document)

    init
    {
        saveButton.onclick = { save() }
        deleteButton.onclick = { delete() }
        employeeId.onblur = { validateEmployeeId() }
        employeeId.textChanged = { validateEmployeeId(); if (it != user?.employeeId) changed() }
        firstName.onblur = { validateFirstName() }
        firstName.textChanged = { validateFirstName(); if (it != user?.firstName) changed() }
        lastName.onblur = { validateLastName() }
        lastName.textChanged = { validateLastName(); if (it != user?.lastName) changed() }
        email.onblur = { validateEmail() }
        email.textChanged = { validateEmail(); if (it != user?.email) changed() }
        pin.onblur = { validatePIN() }
        pin.textChanged = { validatePIN(); if (it != user?.pin) changed() }
        role.valueChanged = { if (role.selectedRole != user?.role) changed() }
    }

    private fun validateEmployeeId() {
        if (employeeId.text.isEmpty()) employeeId.setValidation("Employee ID is required")
        else if (!employeeId.text.matches("^[0-9a-zA-Z]+$".toRegex())) employeeId.setValidation("Employee ID can only contain digits and characters")
        else if (user?.employeeId != employeeId.text && allUsers.any { u -> u.employeeId == employeeId.text }) employeeId.setValidation("Employee ID already in use")
        else employeeId.setValidation("");
    }

    private fun validateFirstName() {
        if (firstName.text.isEmpty()) firstName.setValidation("First Name is required")
        else if (!firstName.text.matches("^[a-zA-ZÅåØøÆæ\\- ]+$".toRegex())) firstName.setValidation("Enter correct format e.g. Alice, Tora Marie, Amelia-Rose");
        else firstName.setValidation("")
    }

    private fun validateLastName() {
        if (lastName.text.isEmpty()) lastName.setValidation("Last name is required")
        else if (!lastName.text.matches("^[a-zA-ZÅåØøÆæ\\- ]+$".toRegex())) lastName.setValidation("Enter correct format e.g. Mark, Smith Jones, Atkinson-Lyod");
        else lastName.setValidation("");
    }

    private fun validatePIN() {
        if (pin.text.isEmpty()) pin.setValidation("PIN is required")
        else if (!pin.text.matches("^[0-9]{4}$".toRegex())) pin.setValidation("Enter four digit number e.g. 1234");
        else pin.setValidation("");
    }

    private fun validateEmail() {
        if (email.text.isNotEmpty() && !email.text.matches("^[a-zA-Z0-9_.-]+@[a-zA-Z-]+\\.[a-zA-Z.]+\$".toRegex())) email.setValidation("Enter email in correct format e.g. xyz@gmail.com");
        else email.setValidation("");
    }

    private fun validateData() {
        validateEmployeeId()
        validateFirstName()
        validateLastName()
        validatePIN()
        validateEmail()
    }

    override fun start()
    {
        super.start()
        refresh()
    }

    open fun refresh()
    {
        saveButton.hidden = false
        loading = true

        Requests.listUsers(this)
        { usersResponse ->
            allUsers = usersResponse.users
            refreshUser()
        }

    }

    private fun refreshUser()
    {
        val id = uid

            id.takeIf {it.isNotEmpty()}?.let{
                Requests.getUser(this,id)
                { userResponse ->

                    val user = userResponse.user
                    if (user != null)
                    {
                            loading = false
                            saveButton.hidden = false
                            refreshed(user)
                            hasChanges = false
                    }
                    else
                    {
                        loading = false
                        content.hidden = true
                        error.hidden = false
                        saveButton.hidden = true
                        error.text = "Failed to load user details"
                    }
            }
        }
    }

    var user : User? = null
    var allUsers : Array<User> = arrayOf()

    fun refreshed(data: User)
    {
        user = data

        navbar.title = data.name

        employeeId.text = data.employeeId?: ""

        firstName.text = data.firstName
        lastName.text = data.lastName
        email.text = data.email
        pin.text = data.pin

        role.selectedRole = data.role
    }

    fun updates(user:User) : ItemUpdates
    {
        val updates = Any().asDynamic()
        var hasChanges = false

        fun addChanged(named:String,current:String)
        {
            updates[named] = current
        }

        fun addIfChanged(named:String,current:String,change:String)
        {
            if (current != change)
            {
                updates[named] = change
                hasChanges = true
            }
        }

        fun addIfChanged(named:String,current:UserRole,change:UserRole)
        {
            if (current != change)
            {
                updates[named] = change.value
                hasChanges = true
            }
        }

        val role = role.selectedRole

        addChanged("document_type",user.documentType)
        addIfChanged("employee_id",user.employeeId?: "",employeeId.text)
        addIfChanged("firstName",user.firstName,firstName.text)
        addIfChanged("lastName",user.lastName,lastName.text)
        addIfChanged("pin",user.pin,pin.text)
        addIfChanged("email",user.email,email.text)
        addIfChanged("role",user.role,role)

        validateData()

        if (!employeeId.isValid)
            return ItemUpdates(error=employeeId.validationMessage)
        if (!firstName.isValid)
            return ItemUpdates(error=firstName.validationMessage)
        if (!lastName.isValid)
            return ItemUpdates(error=lastName.validationMessage)
        if (!email.isValid)
            return ItemUpdates(error=email.validationMessage)
        if (!pin.isValid)
            return ItemUpdates(error=pin.validationMessage)

        if (hasChanges)
            return ItemUpdates(data=updates)
        return ItemUpdates(error="No changes to save")
    }

    open fun save()
    {
        val id = uid
        val user = user ?: return

        val updates = updates(user)
        val data = updates.data
        if (data == null)
        {
            showToast(updates.error ?: "No changes to save")
            return
        }

        loading = true

        Requests.updateUser(this,id,data){

            loading = false
            if (it.error != null)
            {
                showToast(it,"Failed to change user info")
            }
            else
            {
                showToast(it,"User info changed!")
                refresh()
                // TODO: Log out user if he changed himself to a non admin.
            }
        }
    }

    fun delete(confirmed:Boolean=false)
    {
        if (confirmed)
        {
            loading = true
            Requests.deleteUser(this,uid)
            {
                loading = false
                if (it.error != null)
                {
                    showToast(it,"Failed to delete user")
                }
                else
                {
                    showToast(it,"User deleted!")
                    pushTo("users")
                }
            }
        }
        else
        {
            alert.open("Delete User", "Are you sure you would like to delete this user?", arrayOf(AlertAction("cancel", "Cancel"), AlertAction("delete", "Delete")))
            {
                if (it == "delete") {
                    delete(true)
                }
            }
        }
    }

    private fun changed()
    {
        hasChanges = true
    }

    private var _hasChanges : Boolean = false
    var hasChanges : Boolean get() { return _hasChanges } set(value) { _hasChanges = value; updateOnBeforeUnload() }

    open fun updateOnBeforeUnload()
    {
        if (hasChanges)
            window.onbeforeunload = { "There are unsaved changes. Are you sure that you want to leave this page?" }
        else
            window.onbeforeunload = null
    }
}

class CreateUserScreen(document:Document) : UserScreen(document,"")
{
    init
    {
        deleteButton.hidden = true
        role.selectedRole = UserRole.Normal
        navbar.title = "Create User"
    }

    override fun refresh()
    {

        Requests.listUsers(this)
        { usersResponse ->

            allUsers = usersResponse.users
            loading = false
        }
    }

    override fun save()
    {
        val user = User()
        val updates = updates(user)
        val data = updates.data
        if (data == null)
        {
            showToast(updates.error ?: "No changes to save")
            return
        }

        loading = true

        Requests.createUser(this,data)
        {
            loading = false
            if (it.error != null)
            {
                showToast(it,"Failed to create user")
            }
            else
            {
                showToast(it,"User created!")
                pushTo("users")
            }
        }
    }

    override fun updateOnBeforeUnload()
    {

    }
}