package main.kotlin.ui

import org.w3c.dom.Document
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement

class NavBar(val document: Document,val id:String,private val titleLabel:HTMLElement,private val subTitleLabel:HTMLElement?=null)
{
    val view : HTMLDivElement = document.getElementById(id) as HTMLDivElement

    constructor(document:Document,id:String) : this(document,id,document.getElementById("navbar_title") as HTMLElement,document.getElementById("navbar_subtitle") as? HTMLElement)

    init
    {

    }

    var title : String
    get()
    {
        return titleLabel.innerHTML
    }
    set(value)
    {
        titleLabel.innerHTML = value
    }

    var subTitle : String?
    get()
    {
        return subTitleLabel?.innerHTML
    }
    set(value)
    {
        subTitleLabel?.innerHTML = value ?: ""
    }
}
