package main.kotlin.data

data class Event(val uid:String="",val by:String="",val timestamp:UnixTime?=null,val tag:String?=null,val comment:String?=null)
{
    constructor(user:User,timestamp:UnixTime) : this(user.id,user.name,timestamp)

    constructor(dyn:dynamic) : this(dyn.uid as String,dyn.by as String,asUnixTime(dyn.timestamp as? UnixTime),dyn.tag as? String,dyn.comment as? String)

    companion object {
        private fun asUnixTime(value: UnixTime?): UnixTime?
        {
            return if(value != null) {
                if (value >= 1E10) value / 1000
                else value
            } else null
        }
    }

    fun toDynamic() : dynamic
    {
        val updates = Any().asDynamic()
        updates.uid = uid
        updates.by = by
        updates.timestamp = timestamp
        updates.tag = tag
        updates.comment = comment
        return updates
    }

    val description : String
    get()
    {
        if (timestamp != null)
        {
            return "$by at: ${timestamp.display}"
        }
        return by
    }
}

data class Reply(val uid: String="", val by:String="", val timestamp:UnixTime?=null, val text:String?=null, val viewed: Boolean?=null)
{
    constructor(user:User,timestamp:UnixTime) : this(user.id,user.name,timestamp)

    constructor(dyn:dynamic) : this(dyn.uid as String,dyn.by as String,asUnixTime(dyn.timestamp as? UnixTime),dyn.text as? String, dyn.viewed as? Boolean)

    companion object {
        private fun asUnixTime(value: UnixTime?): UnixTime?
        {
            return if(value != null) {
                if (value >= 1E10) value / 1000
                else value
            } else null
        }
    }

    fun toDynamic() : dynamic
    {
        val updates = Any().asDynamic()
        updates.uid = uid
        updates.by = by
        updates.timestamp = timestamp
        updates.text = text
        updates.viewed = viewed
        return updates
    }
}