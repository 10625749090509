package main.kotlin.ui

import main.kotlin.data.BlurbType
import org.w3c.dom.Document

private val Array<BlurbType>.selectorItems : Array<SelectorItem>
get()
{
    val list = mutableListOf<SelectorItem>()

    for (type in this)
    {
        list.add(SelectorItem("${type.toString().toLowerCase()}","${type.title}","${type.value}"))
    }

    return list.toTypedArray()
}

open class BlurbTypeSelector(document: Document, id:String, hint:String, types:Array<BlurbType>) : Selector(document,id,hint,types.selectorItems)
{
    var selected : BlurbType
    get()
    {
        val s = view.value as String
        return BlurbType.from(s.toInt())
    }
    set(value)
    {
        view.value = value.value.toString()
    }

    fun setTypes(types:Array<BlurbType>)
    {
        setSelectorItems(types.selectorItems)
    }
}
