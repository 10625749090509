package main.kotlin.data

import kotlin.js.Json

data class ProcedureWorkflowState(val status:ProcedureWorkflowStatus=ProcedureWorkflowStatus.Unknown, val updated:Event?=null)
{
    constructor(dyn:dynamic) : this(ProcedureWorkflowStatus.from(dyn.status as? Int ?: 0),if (dyn.updated != null) Event(dyn=dyn.updated) else null)
}

interface ProcedureBasics
{
    val id : String
    val name : String
    val type : ProcedureType
}

interface ProcedureWorkflow : ProcedureBasics
{
    var isDraft : Boolean
    var created : Event?
    var state : ProcedureWorkflowState
    var published : Event?
    var parent : String?
    var deleteOnPublish : Boolean
    var completed: MutableList<Event>

    val publishedTimestamp : String
    get()
    {
        val published = published?.timestamp ?: 1546351200.0
        return published.displayPublished
    }

    val publishedMessage : String
    get()
    {
        if (isDraft)
        {
            if (parent == null)
                return "Draft of new ${type.title.toLowerCase()}"
            return "Revision of published ${type.title.toLowerCase()}"
        }
        return "Published $publishedTimestamp"
    }

    val createdBy : String
    get()
    {
        return created?.by ?: ""
    }

    val createdAt : String
    get()
    {
        return created?.timestamp?.displayPublished ?: ""
    }

    val updatedBy : String
    get()
    {
        return state.updated?.by ?: ""
    }

    val updatedAt : String
    get()
    {
        return state?.updated?.timestamp?.displayPublished ?: ""
    }

    val stateName : String
    get()
    {
        return state.status.name(parent)
    }

    val stateCss : String
    get()
    {
        if (parent == null)
        {
            return when (state.status)
            {
                ProcedureWorkflowStatus.Unknown -> "draft"
                ProcedureWorkflowStatus.Submitted -> "draft_submitted"
                ProcedureWorkflowStatus.Rejected -> "draft_rejected"
                ProcedureWorkflowStatus.Approved -> "draft_approved"
            }
        }
        else
        {
            return when (state.status)
            {
                ProcedureWorkflowStatus.Unknown -> "revision"
                ProcedureWorkflowStatus.Submitted -> "revision_submitted"
                ProcedureWorkflowStatus.Rejected -> "revision_rejected"
                ProcedureWorkflowStatus.Approved -> "revision_approved"
            }
        }
    }

    val stateSummary : String
    get()
    {
        if (!isDraft)
            return publishedMessage

        val thing = if (parent == null) "draft" else "revision"
        var summary = when (state.status)
        {
            ProcedureWorkflowStatus.Unknown -> "This $thing was created by $createdBy ($createdAt)"
            ProcedureWorkflowStatus.Submitted -> "This $thing was submitted by $updatedBy ($updatedAt)"
            ProcedureWorkflowStatus.Approved -> "This $thing was approved by $updatedBy ($updatedAt)"
            ProcedureWorkflowStatus.Rejected ->
            {
                val rejection = state.updated?.comment ?: ""
                val msg = "This $thing was rejected by $updatedBy ($updatedAt)"
                if (rejection.isNotBlank())
                    "$msg - $rejection"
                else
                    msg
            }
        }

        if (deleteOnPublish)
            summary += "<br/><br/>This ${type.title} has been marked for deletion. That means when the handbook is published, the ${type.title} will be removed from the handbook."

        return summary
    }

    fun updateWorkflowState(dyn:dynamic)
    {
        val channels = dyn.channels as? Array<String>
        if (channels != null)
        {
            if (channels.count() == 1 && channels.contains("cms"))
                isDraft = true
        }

        if (dyn.state != null)
            state = ProcedureWorkflowState(dyn=dyn.state)

        parent = dyn.parent as? String

        val publ = dyn.published
        if (publ != null)
            published = Event(dyn=publ)

        val cr = dyn.created
        if (cr != null)
            created = Event(dyn=cr)

        val cdr = dyn.completed as? Array<Any>
        if (cdr != null)
        {
            for (hd in cdr)
            {
                completed.add(Event(dyn=hd.asDynamic()))
            }
        }

        deleteOnPublish = dyn.delete_on_publish as? Boolean ?: deleteOnPublish
    }
}

data class ProcedureCard(
        override val id:String="",
        override val name:String="",
        override val type:ProcedureType,
        val chapter:String="",
        val section:String?=null,
        val order:Int=0,
        val progress:Float?=null,
        override var created : Event? = null,
        override var isDraft:Boolean=false,
        override var state:ProcedureWorkflowState=ProcedureWorkflowState(),
        override var parent:String?=null,
        override var published:Event?=null
) : NamedItem, Specificable, ProcedureWorkflow {

    var headers : MutableList<Blurb> = mutableListOf()
    var footers : MutableList<Blurb> = mutableListOf()
    var items : MutableList<ProcedureItem> = mutableListOf()

    constructor(dyn: dynamic) : this(
        dyn.id as String,
        dyn.name as String,
        ProcedureType.from(dyn.type as Int),
        dyn.chapter as? String ?: "",
        dyn.section as? String,
        dyn.order as? Int ?: 0,
        dyn.progress as? Float
    ) {
        updateWorkflowState(dyn)
        updateSpecifics(dyn)

        val hdr = dyn.headers as? Array<Any>
        if (hdr != null)
        {
            var i = 0
            for (hd in hdr)
            {
                headers.add(Blurb(id="$id-procedure_header-$i",dyn=hd.asDynamic()))
                i += 1
            }
        }

        val fdr = dyn.footers as? Array<Any>
        if (fdr != null)
        {
            var i = 0
            for (fd in fdr)
            {
                footers.add(Blurb(id="$id-procedure_footer-$i",dyn=fd.asDynamic()))
                i += 1
            }
        }

        val its = dyn.items as? Array<Any>
        if (its != null)
        {
            var step = 0
            for (it in its)
            {
                val pi = ProcedureItem(dyn=it.asDynamic())
                pi.step = step
                items.add(pi)
                step += 1
            }
        }
    }

    override var ships : List<String> = listOf()
    override var destinations : List<String> = listOf()
    override var classes : List<String> = listOf()
    override var types : List<String> = listOf()
    override var regions : List<String> = listOf()

    override var deleteOnPublish = false
    override var completed: MutableList<Event> = mutableListOf()

    var children : List<ProcedureCard>? = null

    val hasChildren : Boolean
    get()
    {
        if (parent != null) return false
        val kids = children
        if (kids == null || kids.isEmpty())
            return false
        return true
    }
}

data class Procedure(
        override val id:String="",
        override val name:String="",
        override var type:ProcedureType,
        val chapter:String="",
        val section:String?=null,
        override var created : Event? = null,
        override var isDraft:Boolean=false,
        override var state:ProcedureWorkflowState=ProcedureWorkflowState(),
        override var parent:String?=null,
        override var published:Event?=null
) : Specificable,ProcedureWorkflow
{
    var order : Int = 0
    var progress : Int? = null

    var optional : Boolean = false
    var required : Boolean = true
    var spawnable : Boolean = false
    var unsequential : Boolean = false

    var headers : MutableList<Blurb> = mutableListOf()
    var footers : MutableList<Blurb> = mutableListOf()
    var items : MutableList<ProcedureItem> = mutableListOf()

    var linked : String? = null

    override var deleteOnPublish = false
    override var completed: MutableList<Event> = mutableListOf()

    var completionMode : CompletionMode = CompletionMode.CommentNotRequired

    override var ships : List<String> = listOf()
    override var destinations : List<String> = listOf()
    override var classes : List<String> = listOf()
    override var types : List<String> = listOf()
    override var regions : List<String> = listOf()

    var version : Int = 1

    var revisionLog: List<ItemRevision> = listOf()

    constructor(dyn: dynamic) : this(
        dyn.id as String,
        dyn.name as String,
        ProcedureType.from(dyn.type as Int),
        dyn.chapter as? String ?: "",
        dyn.section as? String
    ) {
        updateRevisionLog(dyn)
        updateWorkflowState(dyn)
        updateSpecifics(dyn)

        progress = dyn.progress as? Int
        order = dyn.order as? Int ?: order
        optional = dyn.optional as? Boolean ?: optional
        required = dyn.required as? Boolean ?: required
        spawnable = dyn.spawnable as? Boolean ?: spawnable
        unsequential = dyn.unsequential as? Boolean ?: unsequential

        linked = dyn.linked as? String

        version = dyn.version as? Int ?: 1

        val cm = dyn.completion_mode as? Int
        if (cm != null)
            completionMode = CompletionMode.from(cm)

        val hdr = dyn.headers as? Array<Any>
        if (hdr != null)
        {
            var i = 0
            for (hd in hdr)
            {
                headers.add(Blurb(id="$id-procedure_header-$i",dyn=hd.asDynamic()))
                i += 1
            }
        }

        val fdr = dyn.footers as? Array<Any>
        if (fdr != null)
        {
            var i = 0
            for (fd in fdr)
            {
                footers.add(Blurb(id="$id-procedure_footer-$i",dyn=fd.asDynamic()))
                i += 1
            }
        }

        val its = dyn.items as? Array<Any>
        if (its != null)
        {
            var step = 0
            for (it in its)
            {
                val pi = ProcedureItem(dyn=it.asDynamic())
                pi.step = step
                items.add(pi)
                step += 1
            }
        }
    }

    val isCompleted : Boolean
    get()
    {
        return completed.count() > 0
    }

    private fun updateRevisionLog(dyn: dynamic) {
        var revisionLog = dyn.revision_log as? Array<Any>

        if (revisionLog != null) {
            var itemRevisions: MutableList<ItemRevision> = mutableListOf()
            for (item in revisionLog) {
                var itemRevision = ItemRevision(item.asDynamic())
                itemRevisions.add(itemRevision)
            }

            this.revisionLog = itemRevisions.toList()
        }
    }
}

enum class ProcedureType(val value:Int)
{
    Procedure(0),
    Checklist(1);

    companion object
    {
        fun from(value: Int) : ProcedureType
        {
            when (value)
            {
                0 -> return Procedure
                1 -> return Checklist
            }
            return Procedure
        }
    }

    val title : String
    get()
    {
        return when (this)
        {
            Procedure -> "Procedure"
            Checklist -> "Checklist"
        }
    }
}

data class ProcedureItem(val id:String="",var name:String="") : Specificable
{
    var action = ProcedureAction("Complete","Complete")
    val completed : MutableList<Event> = mutableListOf()
    var headers : MutableList<Blurb> = mutableListOf()
    var blurbs : MutableList<Blurb> = mutableListOf()
    override var destinations : List<String> = listOf()
    override var ships : List<String> = listOf()
    override var classes : List<String> = listOf()
    override var types : List<String> = listOf()
    override var regions : List<String> = listOf()

    var status: String? = null
    var by: String? = null
    var timestamp: UnixTime? = null;

    var deleted : Boolean? = null

    constructor(dyn: dynamic) : this(dyn.id as String, dyn.name as String) {
        val del = dyn.deleted
        if (del != null)
            deleted = del as Boolean

        val acd = dyn.action
        if (acd != null)
            action = ProcedureAction(dyn=acd)

        updateSpecifics(dyn)

        val cdr = dyn.completed as? Array<Any>
        if (cdr != null)
        {
            for (hd in cdr)
            {
                completed.add(Event(dyn=hd.asDynamic()))
            }
        }

        val hdr = dyn.headers as? Array<Any>
        if (hdr != null)
        {
            var i = 0
            for (hd in hdr)
            {
                val blurb = Blurb(id="$id-header-$i",dyn=hd.asDynamic())
                headers.add(blurb)
                i += 1
            }
        }

        val bdr = dyn.blurbs as? Array<Any>
        if (bdr != null)
        {
            var i = 0
            for (hd in bdr)
            {
                val blurb = Blurb(id="$id-header-$i",dyn=hd.asDynamic())
                blurbs.add(blurb)
                i += 1
            }
        }
    }

    fun toDynamic() : dynamic
    {
        val updates = Any().asDynamic()
        updates.id = id
        updates.name = name
        updates.action = action.toDynamic()
        updates.completed = completed.map { it.toDynamic() }
        updates.headers = headers.map { it.toDynamic() }
        updates.blurbs = blurbs.map { it.toDynamic() }
        updates.destinations = destinations.toTypedArray()
        updates.ships = ships.toTypedArray()
        updates.classes = classes.toTypedArray()
        updates.types = types.toTypedArray()
        updates.regions = regions.toTypedArray()
        deleted?.let {updates.deleted = deleted}
        return updates
    }

    var step : Int = 0

    val isCompleted : Boolean
    get()
    {
        return completed.count() > 0
    }

    val isNotApplicable : Boolean
    get()
    {
        val ev = completed.firstOrNull() ?: return false
        return ev.tag == "n/a"
    }

    fun clear()
    {
        completed.clear()
        for (blurb in blurbs)
        {
            blurb.clear()
        }
        for (blurb in headers)
        {
            blurb.clear()
        }
    }

    fun subtitle(showFull: Boolean) : String
    {
        val completed = completed.firstOrNull()
        val timestamp = completed?.timestamp ?: return ""

        var action = action.past.toUpperCase()

        val time = " (${timestamp.displayCompleted})"

        var comment = ""

        if (completed.tag == "n/a")
        {
            action = "NOT APPLICABLE"
            val cm = completed.comment
            if (!cm.isNullOrBlank())
                comment = "<br/>With Comment: '$cm'"
        }
        return if (showFull) "$action by ${completed.by}$time$comment"
        else "$action$comment"
    }
}

data class ProcedureAction(var verb:String="",var past:String="",var notApplicable:NotApplicableMode=NotApplicableMode.NotEnabled)
{
    constructor(dyn:dynamic) : this(dyn.verb as String,dyn.past as String)
    {
        val cm = dyn.na as? Int
        if (cm != null)
            notApplicable = NotApplicableMode.from(cm)
    }

    fun toDynamic() : dynamic
    {
        if (verb.isEmpty() || past.isEmpty())
        {
            if (notApplicable != NotApplicableMode.NotEnabled)
            {
                val updates = Any().asDynamic()
                updates.na = notApplicable.value
                return updates
            }

            return null
        }
        val updates = Any().asDynamic()
        updates.verb = verb
        updates.past = past
        if (notApplicable != NotApplicableMode.NotEnabled)
            updates.na = notApplicable.value
        return updates
    }
}

interface ProcedureConfig
{
    val handbook : Handbook
    val tripId:String?
    get()
    {
        return null
    }

    val trip : ShipTrip
    get()
    {
        return Placeholder.shared.trip
    }
}

class ProcedureOptions() : SpecificableInfoProvider
{
    val installations : MutableList<InstallationCard> = mutableListOf()
    val ships : MutableList<Ship> = mutableListOf()
    val classes : MutableList<ShipClass> = mutableListOf()
    val types : MutableList<ShipType> = mutableListOf()
    val regions : MutableList<ShipRegion> = mutableListOf()
    val chapters : MutableList<ProcedureChapter> = mutableListOf()
    val procedures : MutableList<ProcedureCard> = mutableListOf()

    private val shipMap : MutableMap<String,Ship> = mutableMapOf()
    private val installationMap : MutableMap<String,InstallationCard> = mutableMapOf()
    private val classesMap : MutableMap<String ,ShipClass> = mutableMapOf()
    private val typesMap : MutableMap<String ,ShipType> = mutableMapOf()
    private val regionsMap : MutableMap<String ,ShipRegion> = mutableMapOf()

    constructor(dyn:dynamic) : this()
    {
        val shipsList = dyn.ships as? Array<Any>
        if (shipsList != null)
        {
            for (it in shipsList)
            {
                val item = it.asDynamic()
                val ship = Ship(dyn=item)
                ships.add(ship)
                shipMap[ship.id] = ship
            }
        }

        val installationsList = dyn.installations as? Array<Any>
        if (installationsList != null)
        {
            for (it in installationsList)
            {
                val item = it.asDynamic()
                val installation = InstallationCard(dyn=item)
                installations.add(installation)
                installationMap[installation.id] = installation
            }
        }

        val typesList = dyn.types as? Array<String>
        if (typesList != null)
        {
            for (it in typesList){
                val item = it.asDynamic()
                val vesselType = ShipType(dyn = item)
                types.add(vesselType)
                typesMap[vesselType.id] = vesselType
            }
        }

        val classesList = dyn.classes as? Array<String>
        if (classesList != null)
        {
            for (it in classesList){
                val item = it.asDynamic()
                val vesselClass = ShipClass(dyn = item)
                classes.add(vesselClass)
                classesMap[vesselClass.id] = vesselClass
            }
        }

        val regionsList = dyn.regions as? Array<String>
        if (regionsList != null)
        {
            for (it in regionsList){
                val item = it.asDynamic()
                val vesselRegion = ShipRegion(dyn = item)
                regions.add(vesselRegion)
                regionsMap[vesselRegion.id] = vesselRegion
            }
        }

        val list = dyn.procedures as? Array<Any>
        if (list != null)
        {
            for (it in list)
            {
                val dyn = it.asDynamic()
                val card = ProcedureCard(dyn=dyn)
                procedures.add(card)
            }
        }

        val settings = HandbookSettingsResponse.fromJson(obj=dyn.settings as Json)
        chapters.addAll(ProceduresResponse.chapters(procedures,settings,includeEmptyChapters = true, includeOld = false))
    }

    override fun getShip(id:String) : Ship?
    {
        return shipMap[id]
    }

    override fun getInstallation(id:String) : InstallationCard?
    {
        return installationMap[id]
    }

    override fun getVesselType(id: String): IdentifiedItem? {
        return typesMap[id]
    }

    override fun getVesselClass(id: String): IdentifiedItem? {
        return classesMap[id]
    }

    override fun getRegion(id: String): IdentifiedItem? {
        return regionsMap[id]
    }
}

class ProcedureChapter(val name:String)
{
    var sections : MutableList<ProcedureSection> = mutableListOf()

    companion object
    {
        var fallbackSectionTitle = "Procedures"
    }

    init
    {
    }

    constructor(name:String,procedures:List<ProcedureCard>) : this(name)
    {
        val procs : MutableMap<String,MutableList<ProcedureCard>> = mutableMapOf()
        val fallbacks : MutableMap<String,Boolean> = mutableMapOf()

        for (proc in procedures)
        {
            val sectionName = if (proc.section != null && proc.section.isNotEmpty()) proc.section else fallbackSectionTitle
            fallbacks[sectionName] = proc.section.isNullOrEmpty()
            val procList = procs[sectionName] ?: mutableListOf()
            procList.add(proc)
            procs[sectionName] = procList
        }

        for (pr in procs)
        {
            val section = ProcedureSection(pr.key,pr.value)
            section.isFallbackSection = fallbacks.get(section.name) ?: false
            sections.add(section)
        }
    }

    constructor(dyn:dynamic) : this(dyn.name as String)
    {
        val sects = dyn.sections as? Array<String>
        if (sects != null)
        {
            for (sectionName in sects)
            {

                val section = ProcedureSection(sectionName,mutableListOf())
                sections.add(section)
            }
        }
    }

    val procedures : List<ProcedureCard>
    get()
    {
        val all : MutableList<ProcedureCard> = mutableListOf()
        val procs = sections.map { it.procedures }
        for (proc in procs)
            all.addAll(proc)
        return all
    }
}

data class ProcedureSection(val name:String, val procedures:MutableList<ProcedureCard>, var header : Blurb? = null, var conditional : ConditionalRequirements? = null)
{
    var isFallbackSection = false
}


data class ItemRevision(var id: String, var status: String, var by: String, var timestamp: UnixTime?=null)
{
    constructor(dyn:dynamic) : this(dyn.id as String, dyn.status as String, dyn.by as String, dyn.timestamp as? UnixTime){

    }
}