package main.kotlin.ui

import main.kotlin.comms.CredentialProvider
import org.w3c.dom.Document
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.events.Event

class Dropzone(document: Document, id:String, credentials: CredentialProvider, visibleDisplayStyle:String="block") : Element(document,id,visibleDisplayStyle)
{
    override val view : HTMLDivElement = document.getElementById(id) as HTMLDivElement

    val url : String = "${Config.url}/images"

    init
    {
        view.innerHTML = """
            <form action="$url" id="$id-form" class="dropzone needsclick">
                <div class="dz-message needsclick">
                    Drop files here or click to upload.
                </div>

            </form>
"""
    }

    val dropzone : dynamic = getJS("$id-form",url, credentials.authorizationHeader)

    private fun getJS(id:String,url:String,authorization:String) : dynamic
    {
        val obj = js("""
    (function(){
        return new Dropzone(document.getElementById(id), { url: url, headers: {'Authorization':authorization}});
    }())
    """)
        return obj
    }

    private var _succeeded : ((Event)->dynamic)? = null
    var succeeded : ((Event)->dynamic)?
    get()
    {
        return _succeeded
    }
    set(value)
    {
        _succeeded = value
        dropzone.on("success", _succeeded)
    }
}
