package main.kotlin.ui

import org.w3c.dom.Document

class Snackbar(document: Document)
{
    val container = Div(document,"snackbar")
    private val view : dynamic

    val markup : String
    get()
    {
        return """

    <div class="mdc-snackbar__surface">
        <div class="mdc-snackbar__label" role="status" aria-live="polite">
        </div>
        <div class="mdc-snackbar__actions">
            <button type="button" class="mdc-button mdc-snackbar__action">OK</button>
        </div>
    </div>

 """
    }

    private fun getView(id:String) : dynamic
    {
        val obj = js("""
    (function(){
        return new mdc.snackbar.MDCSnackbar(document.getElementById(id));
    }())
    """)
        return obj
    }

    init
    {
        container.view.innerHTML = markup
        container.addClass("mdc-snackbar")
        view = getView("snackbar")
    }

    fun open(msg:String)
    {
        view.labelText = msg
        view.open()
    }
}
