package main.kotlin.ui

import main.kotlin.models.SettingsPopover
import main.kotlin.models.TopNavBarScreen
import org.w3c.dom.Document
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.Node
import org.w3c.dom.events.Event
import kotlin.browser.window

enum class TopNavBarTab
{
    Nothing,
    Users,
    Ships,
    Installations,
    Manage,
    Feedback,
    Help;

    val searchHint : String?
    get()
    {
        return when(this)
        {
            Nothing -> null
            Help -> null
            Users -> "Search" // do not add Users as a word here as it will cause autofill bug in chrome to appear
            Ships -> "Search vessels"
            Feedback -> "Search feedback"
            Installations -> "Search installations"
            Manage -> "Manage"
        }
    }
}

class TopNavBarUserIcon(val document:Document,val id:String)
{
    val container = Div(document,id)
    val circle = Div(document,"$id-circle")
    val initials = Div(document,"$id-initials")

    var hidden : Boolean
    get() { return container.hidden }
    set(value) { container.hidden = value }
}

class TopNavBar(val document:Document, val screen: TopNavBarScreen,activeTab:TopNavBarTab) : TabBarDelegate
{
    val view : HTMLDivElement = document.getElementById("topnavbar") as HTMLDivElement

    val searchbar : BaseEditText

    val tabs : TabBar

    val user : TopNavBarUserIcon

    private val settingsPopover : SettingsPopover

    val showingPopover : Boolean
    get()
    {
        return !settingsPopover.container.hidden
    }

    fun hidePopover()
    {
        showSettings(false)
    }

    fun showSettings(show:Boolean)
    {
        if (show)
        {
            fun handleDocEvent(ev: Event)
            {
                if (settingsPopover.container.view.contains(ev.target as? Node) || user.container.view.contains(ev.target as? Node))
                {

                }
                else
                {
                    hidePopover()
                }
            }

            document.body?.onmousedown =
            {
                handleDocEvent(it)
            }

            document.body?.onkeydown =
            {
                handleDocEvent(it)
            }
        }
        else
        {
            document.body?.onmousedown = null
            document.body?.onkeydown = null
        }
        settingsPopover.container.hidden = !show
    }

    init
    {
        view.innerHTML = """

            <div id="topnavbar-tabs" style="height:100%;"></div>
                <div style="display:none">
                    <div id="topnavbar-tab-users" style="display:none;"></div>
                    <div id="topnavbar-tab-ships" style="display:none;"></div>
                    <div id="topnavbar-tab-installations" style="display:none;"></div>
                    <div id="topnavbar-tab-manage" style="display:none;"></div>
                    <div id="topnavbar-tab-feedback" style="display:none;"></div>
                </div>
            <div class="topnavbar-user-icon-container" id="topnavbar-user-icon">
                <div class="topnavbar-user-icon-circle" id="topnavbar-user-icon-circle">
                    <div class="topnavbar-user-icon-initials" id="topnavbar-user-icon-initials"></div>
                </div>
            </div>
            <div id="settings_popover" class="settings_popover"></div>
            """

        val hint = screen.searchHint

        searchbar = BaseEditText(document,"topnavbar-search",hint)

        val index = when(activeTab)
        {
            TopNavBarTab.Nothing -> -1
            TopNavBarTab.Users -> 0
            TopNavBarTab.Ships -> 1
            TopNavBarTab.Installations -> 2
            TopNavBarTab.Manage -> 3
            TopNavBarTab.Feedback -> 4
            TopNavBarTab.Help -> 5
        }

        tabs = TabBar(document, "topnavbar-tabs",arrayOf(
                Tab("topnavbar-tab-users","Users"),
                Tab("topnavbar-tab-ships","Vessels"),
                Tab("topnavbar-tab-installations","Installations"),
                Tab("topnavbar-tab-manage","Manage"),
                Tab("topnavbar-tab-feedback","Feedback"),
                Tab("topnavbar-tab-help","Help")
        ),index,"topnavbar")

        tabs.delegate = this

        user = TopNavBarUserIcon(document,"topnavbar-user-icon")
        user.initials.text = ""
        user.container.onclick = { clickedSettings() }

        settingsPopover = SettingsPopover(document,"settings_popover",screen)
    }

    override fun clickedTab(id: String)
    {
        when
        {
            id.endsWith("users") -> window.location.href = "users.html"
            id.endsWith("ships") -> window.location.href = "ships.html"
            id.endsWith("installations") -> window.location.href = "installations.html"
            id.endsWith("manage") -> window.location.href = "manage.html"
            id.endsWith("feedback") -> window.location.href = "feedback.html"
            id.endsWith("help") -> window.location.href = "help.html"
        }
    }

    fun clickedSettings()
    {
        if (settingsPopover.container.hidden)
            showSettings(true)
        else
            showSettings(false)
    }
}
