package extensions

fun String.getLastPathComponent() : String
{
    val components = split("/")
    return components.lastOrNull() ?: return ""
}

fun String.getLink(start:Int) : String
{
    val sub = substring(start)
    var end = 0
    for (c in sub)
    {
        if (c.isWhitespace())
            break
        end += 1
    }
    return sub.substring(0,end)
}
