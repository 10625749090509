package main.kotlin.data

data class User(
    val id: String = "",
    var firstName: String = "",
    var lastName: String = "",
    var email: String = "",
    var pin: String = "",
    var role: UserRole = UserRole.Unknown,
    var shipMaster: String? = null,
    var employeeId: String? = null,
    var documentType: String = "user"
) : NamedItem {
    constructor(dyn: dynamic) : this(
        dyn.id as String,
        dyn.firstName as String,
        dyn.lastName as String,
        dyn.email as? String ?: "",
        dyn.pin as? String ?: "",
        UserRole.from(dyn.role as Int),
        dyn.ship_master as? String,
        dyn.employee_id as? String ?: "",
        dyn.document_type as? String ?: ""
    )

    override val name: String
        get() {
            return "$firstName $lastName"
        }

    val isAdmin: Boolean
        get() {
            return role.isAdmin
        }
}

enum class UserRole(val value: Int) {
    Unknown(-1),
    Inactive(0),
    Normal(1),
    Admin(2),
    SuperAdmin(3),
    VesselMaster(4),
    Inspector(5);

    companion object {
        fun from(value: Int): UserRole {
            when (value) {
                0 -> return Inactive
                1 -> return Normal
                2 -> return Admin
                3 -> return SuperAdmin
                4 -> return VesselMaster
                5 -> return Inspector
            }
            return Inactive
        }
    }

    val title: String
        get() {
            return when (this) {
                Inactive -> "Inactive"
                Normal -> "Normal"
                Admin -> "Admin"
                SuperAdmin -> "Super Admin"
                VesselMaster -> "Vessel Master"
                Inspector -> "Inspector"
                else -> ""
            }
        }

    val isAdmin: Boolean
        get() {
            return this == Admin || this == SuperAdmin
        }
}
