package main.kotlin.ui

import main.kotlin.data.ProcedureChapter
import org.w3c.dom.Document

private val Array<ProcedureChapter>.selectorItems : Array<SelectorItem>
get()
{
    val list = mutableListOf<SelectorItem>()

    for ((index,chapter) in this.withIndex())
    {
        list.add(SelectorItem("${chapter.name.toLowerCase()}",chapter.name,"$index"))
    }

    return list.toTypedArray()
}

open class ProcedureChapterSelector(document: Document, id:String, hint:String, var chapters:Array<ProcedureChapter>,required:Boolean=false,tooltip:String?=null) : Selector(document,id,hint,chapters.selectorItems,required,tooltip)
{
    var selected : ProcedureChapter?
    get()
    {
        val s = view.value as? String ?: return null
        if (s.isNullOrEmpty()) return null
        val index = s.toInt()
        if (index >= 0)
            return chapters[index]
        return null
    }
    set(value)
    {
        val index = chapters.indexOfFirst { it.name == value?.name }
        view.value = "$index"
    }

    fun setChapters(chapters:Array<ProcedureChapter>)
    {
        this.chapters = chapters
        setSelectorItems(chapters.selectorItems)
    }
}
