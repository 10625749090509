package main.kotlin.models

import main.kotlin.ui.Div
import main.kotlin.ui.ErrorView
import org.w3c.dom.Document

open class LoaderScreen(document: Document) : AuthScreen()
{
    val loader = Div(document,"page_loader")
    open val content = Div(document,"page_content")
    val error = ErrorView(document,"page_error")

    private var _loading : Boolean = false
    open var loading : Boolean
    get()
    {
        return _loading
    }
    set(value)
    {
        _loading = value
        loader.hidden = !value
        content.hidden = value
    }

    init
    {
        error.hidden = true
    }
}