package main.kotlin.ui

import main.kotlin.data.FeedbackStatus
import org.w3c.dom.Document

class FeedbackStatusSelector(document:Document,id:String,hint:String) : Selector(document,id,hint,arrayOf(
        SelectorItem("all","All","-1"),
        //SelectorItem("created",FeedbackStatus.Created.title,"1"),
        SelectorItem("submitted",FeedbackStatus.Submitted.title,"2"),
        SelectorItem("resolved",FeedbackStatus.Resolved.title,"3")
))
{
    var selected : FeedbackStatus?
    get()
    {
        val s = view.value as String
        val i = s.toInt()
        if (i == -1)
            return null
        return FeedbackStatus.from(i)
    }
    set(value)
    {
        val v = value?.value ?: -1
        view.value = v.toString()
    }
}