package main.kotlin.models

import main.kotlin.comms.CredentialProvider
import main.kotlin.data.UserRole

data class CurrentUser(val id:String,val token:String,val firstName:String,val lastName:String,val role:UserRole)
{
    val initials : String?
    get()
    {
        val fn = firstName.firstOrNull() ?: return null
        val ln = lastName.firstOrNull() ?: return fn.toString()
        return "$fn$ln"
    }

    val name : String
    get()
    {
        return "$firstName $lastName"
    }
}

open class AuthScreen : BaseScreen(), CredentialProvider
{
    var currentUser : CurrentUser? = null
    override val id : String? get() { return currentUser?.id }
    override val token : String? get() { return currentUser?.token }

    init
    {
        loadCredentials()
    }

    override fun start()
    {
        super.start()
        if (!isAuthenticated)
            pushTo("login")
    }

    val isAuthenticated : Boolean
    get()
    {
        val id = currentUser?.id ?: return false
        val token = currentUser?.token ?: return false
        return id.isNotEmpty() && token.isNotEmpty()
    }

    fun loadCredentials()
    {
        currentUser = null
        val id = Storage.id ?: return
        val token = Storage.token ?: return
        val firstName = Storage.firstName ?: return
        val lastName = Storage.lastName ?: return
        val roleS = Storage.role ?: return
        if (roleS.isEmpty()) return
        val roleI = roleS.toIntOrNull() ?: return
        val role = UserRole.from(roleI)
        currentUser = CurrentUser(id,token,firstName,lastName,role)
    }

    fun storeCredentials(id:String,token:String,firstName:String,lastName:String,role:Int)
    {
        currentUser = CurrentUser(id,token,firstName,lastName,UserRole.from(role))
        Storage.id = id
        Storage.token = token
        Storage.firstName = firstName
        Storage.lastName = lastName
        Storage.role = "$role"
    }

    fun clearCredentials()
    {
        currentUser = null
        Storage.clear()
    }
}