package main.kotlin.ui

import main.kotlin.data.ProcedureType
import main.kotlin.data.UserRole
import org.w3c.dom.Document

class ProcedureTypeSelector(document:Document, id:String, hint:String,tooltip:String?=null) : Selector(document,id,hint,arrayOf(
        SelectorItem("procedure","Procedure","0"),
        SelectorItem("checklist","Checklist","1")
),tooltip=tooltip)
{
    var selected : ProcedureType
    get()
    {
        val s = view.value as String
        return ProcedureType.from(s.toInt())
    }
    set(value)
    {
        view.value = value.value.toString()
    }
}