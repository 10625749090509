package main.kotlin.ui

import org.w3c.dom.Document
import org.w3c.dom.events.Event

class IconButton(val document: Document, id:String)
{
    val view : dynamic = document.getElementById(id)

    var onclick : ((Event)->dynamic)?
    get()
    {
        return view.onclick
    }
    set(value)
    {
        view.onclick = value
    }

    var icon : String
    get()
    {
        return view.innerHTML
    }
    set(value)
    {
        view.innerHTML = value
    }
}
