import org.w3c.dom.get
import kotlin.browser.window

class Config
{
    companion object
    {
        val environment : String
        get()
        {
            return window["config"].environment as String
        }

        val url : String
        get()
        {
            return window["config"].url as String
        }

        val apps : Array<dynamic>
        get()
        {
            return window["config"].apps as Array<dynamic>
        }
    }
}