package main.kotlin.ui

import org.w3c.dom.Document
import org.w3c.dom.HTMLElement
import org.w3c.dom.asList
import org.w3c.dom.events.Event
import kotlin.dom.addClass
import kotlin.dom.clear
import kotlin.dom.removeClass

abstract class Element(val document: Document,val id:String, val visibleDisplayStyle:String="block",var tooltip:String?=null)
{
    abstract val view : HTMLElement

    private var _tippy: dynamic? = null;

    protected fun didInit()
    {
        updateTooltip()
    }

    fun setTooltip(tooltip: String?)
    {
        this.tooltip = tooltip
        updateTooltip()
    }

    private fun updateTooltip()
    {
        val tt = tooltip
        val name = "data-tippy"
        when {
            _tippy == null && tt!=null -> { _tippy = initTooltip(id,tt); }
            tt != null ->
            {
                view.setAttribute(name,tt);
                _tippy.setContent(tt);
            }
            _tippy != null ->
            {
                view.removeAttribute(name)
                if (_tippy != null)
                {
                    _tippy.destroy();
                    _tippy = null;
                }
            }
    
        }
    }

    private fun initTooltip(id: String, tip: String)
    {
        return js("""tippy(document.getElementById(id), { 
            content: tip,
            delay:[100,0] 
       });""");
    }

    open val isTooltipEssential : Boolean
    get()
    {
        return true
    }

    fun hasClass(classe:String) : Boolean
    {
        return view.classList.asList().contains(classe)
    }

    fun addClass(classe:String)
    {
        addClasses(arrayOf(classe))
    }

    fun addClasses(classes:Array<String>)
    {
        var list = mutableListOf<String>()

        for (classe in classes)
        {
            view.removeClass(classe)
            list.add(classe)
        }

        val orig = view.classList.asList()
        for (classe in orig)
        {
            view.removeClass(classe)
            list.add(classe)
        }

        for (classe in list)
            view.addClass(classe)
    }

    fun removeClasses(classes:Array<String>)
    {
        var list = mutableListOf<String>()

        for (classe in classes)
        {
            view.removeClass(classe)
            list.add(classe)
        }

        val orig = view.classList.asList()
        for (classe in orig)
        {
            view.removeClass(classe)
            list.add(classe)
        }

        for (classe in list)
        {
            if (classes.contains(classe))
                continue
            view.addClass(classe)
        }
    }

    fun removeClass(classe:String)
    {
        removeClasses(arrayOf(classe))
    }

    fun clear()
    {
        view.clear()
    }

    var onclick : ((Event)->dynamic)?
    get()
    {
        return view.onclick as? (Event)->dynamic
    }
    set(value)
    {
        view.onclick = value
    }

    var hidden : Boolean
    get()
    {
        return view.style.display == "none"
    }
    set(value)
    {
        view.style.display = if (value) "none" else visibleDisplayStyle
    }

    var visibility : Boolean
    get()
    {
        return view.style.visibility == "visible"
    }
    set(value)
    {
        view.style.visibility = if (value) "visible" else "hidden"
    }

    var html : String
    get()
    {
        return view.innerHTML
    }
    set(value)
    {
        view.innerHTML = value
    }

    var text : String
    get()
    {
        return view.innerText
    }
    set(value)
    {
        view.innerText = value
    }

    var opacity : String
    get()
    {
        return view.style.opacity
    }
    set(value)
    {
        view.style.opacity = value
    }
}
