package main.kotlin.models

import main.kotlin.comms.Requests
import main.kotlin.ui.*
import org.w3c.dom.Document

class SettingsPopover(document:Document,val id:String,val screen:AuthScreen)
{
    val container = Div(document,id)
    val content : Div
    val loader : Div

    val password1 : EditText
    val password2 : EditText
    val changePasswordButton : Button

    val logoutButton : Button

    val username : Div
    val userrole : Div

    init
    {
        container.hidden = true

        container.html = markup

        content = Div(document,"$id-content")
        loader = Div(document,"$id-loader")

        loader.hidden = true

        username = Div(document,"$id-user_name"); username.text = screen.currentUser?.name ?: "???"
        userrole = Div(document,"$id-user_role"); userrole.text = screen.currentUser?.role?.title ?: "???"

        password1 = EditText(document,"$id-password1","Password","password",required=true)
        password2 = EditText(document,"$id-password2","Confirm Password","password",required=true)
        changePasswordButton = Button(document,"$id-change_password_button",tooltip="Change your password")
        changePasswordButton.onclick = { changePassword() }

        logoutButton = Button(document,"$id-logout_button",tooltip="Logout from Handbooks CMS")
        logoutButton.onclick = { logout() }
    }

    private var _loading : Boolean = false
    var loading : Boolean
    get()
    {
        return _loading
    }
    set(value)
    {
        _loading = value
        loader.hidden = !value
        content.hidden = value
    }

    val markup : String
    get()
    {
        return """

        <div class="loader" id="$id-loader"></div>

        <div id="$id-content">

            <div class="page_content">
                <div id="$id-user_name" class="username"></div>
                <div id="$id-user_role" class="userrole"></div>
            </div>

            <hr class="divider"/>

            <div class="page_content">

                <div class="mdc-text-field password edit" id="$id-password1"></div>

                <div class="mdc-text-field password edit" id="$id-password2"></div>

                <button class="mdc-button mdc-button with-ripple" id="$id-change_password_button">Change Password</button>
            </div>

            <hr class="divider"/>

            <div class="page_content">
                <button class="mdc-button mdc-button--raised with-ripple" id="$id-logout_button" style="width:100%;">Logout</button>
            </div>
        </div>
        """
    }

    fun logout()
    {
        screen.clearCredentials()
        screen.pushTo("login")
    }

    fun changePassword()
    {
        val password1 = password1.text
        val password2 = password2.text

        loading = true

        Requests.changePassword(screen,password1,password2)
        {
            loading = false
            if (it.error != null)
            {
                screen.showToast(it,"Failed to change password")
            }
            else
            {
                screen.showToast(it,"Password changed!")
            }
        }
    }
}