package main.kotlin.utils

internal fun convertToSHA256(msg:String) : String
{
    return js("""
    (function(){
        var hash = sha256.create();
        hash.update(msg);
        return hash.hex();
    }())
    """).toString()
}

fun String.toSHA256() : String
{
    return convertToSHA256(this).toLowerCase()
}
