package main.kotlin.ui

import main.kotlin.data.*
import org.w3c.dom.Document

interface ProcedureOpenerDelegate
{
    fun openProcedure(card:ProcedureCard)
}

open class ProcedureRevisionsSelector(document: Document, id:String) : Div(document,id,"inline-flex")
{
    val label : Div

    val alert = Alert(document)

    var drafts : Array<ProcedureCard> = arrayOf()

    var delegate : ProcedureOpenerDelegate? = null

    val markup : String
    get()
    {
        return """
           <div id="${id}_current" class="procedure_revisions_selector_label"></div>
           <i class="material-icons mdc-button__icon" aria-hidden="true">expand_more</i>
        """
    }

    init
    {
        html = markup
        label = Div(document,"${id}_current")

        onclick = {clicked()}
    }

    val ProcedureWorkflow.buttonName : String
    get()
    {
        return if (isDraft)
        {
            var createdBy = createdBy
            if (createdBy.isNotBlank())
                createdBy = "($createdBy)"

            if (parent == null)
                "Draft $createdBy".trim()
            else
                "Revision $createdBy".trim()
        }
        else
        {
            "Published version"
        }
    }

    val ProcedureWorkflow.buttonDetails : String
    get()
    {
        return if (isDraft && state.status != ProcedureWorkflowStatus.Unknown)
        {
            return """<span class="procedure_state $stateCss">$stateName</span>"""
        }
        else
        {
            ""
        }
    }

    fun clicked()
    {
        val actions : MutableList<AlertChoice> = mutableListOf()
        for (draft in drafts)
        {
            val detailsMarkup = """
               ${draft.buttonDetails}
            """
            actions.add(AlertChoice(draft.id,draft.buttonName,detailsMarkup))
        }

        alert.openForChoices("Revisions",actions.toTypedArray())
        {
            open(it)
        }
    }

    fun update(procedure:Procedure,drafts:Array<ProcedureCard>)
    {
        this.drafts = drafts
        label.text = procedure.buttonName
    }

    fun open(id:String)
    {
        val procedure = drafts.find { it.id == id } ?: return
        delegate?.openProcedure(procedure)
    }
}
