package main.kotlin.data

import fromBase64
import main.kotlin.utils.ObjectHelper
import toBase64
import kotlin.js.Json

class BaseShip(val id:String,val name:String)
{
    companion object
    {
        fun from(params: String): BaseShip?
        {
            val b64 = decodeURIComponent(params)
            val encodedJson = b64.fromBase64()
            val json = decodeURIComponent(encodedJson)
            val obj = JSON.parse<Json>(json)
            val id = obj["id"] as? String ?: return null
            val name = obj["name"] as? String ?: return null
            return BaseShip(id, name)
        }
    }
}

data class Ship(override val id:String="") : IdentifiedItem
{
    constructor(dyn:dynamic) : this(dyn.id as String)
    {
        documentType = dyn.document_type as? String ?: ""
        name = dyn.name as String
        type = dyn.type as? String ?: ""
        val cl = dyn.`class` as? String
        if (cl != null)
            classe = cl

        val rg = dyn.region as? String
        if (rg != null)
            region = rg

        val vme = dyn.vessel_master_email as? String
        if (vme != null)
            vesselMasterEmail = vme

        val hbs = dyn.handbooks as? Array<String>
        if (hbs != null)
        {
            handbooks.addAll(hbs)
        }

        if (dyn.operations != null)
        {
            val keys = ObjectHelper.getKeys(dyn.operations)
            if (keys != null)
            {
                for (key in keys)
                {
                    val value = dyn.operations[key]
                    val state = ShipOperationState(dyn = value)
                    operations[key] = state
                }
            }
        }

        val disabled = dyn.disabled as? Boolean
        if (disabled != null)
            this.disabled = disabled

        dimensions.update(dyn.dimensions)
        navigation.update(dyn.navigation)
        generalInformation.update(dyn.general_information)
        capacities.update(dyn.capacities)
        tonnagesAndLoadlineInformation.update(dyn.tonnages_and_loadline_information)
        machinerySystems.update(dyn.machinery_systems)
        dynamicPositioning.update(dyn.dynamic_positioning)
        bowLoadingSystem.update(dyn.bow_loading_system)
        cargoAndBallastEquipment.update(dyn.cargo_and_ballast_equipment)
        environmental.update(dyn.environmental)
        design.update(dyn.design)
    }

    val params : String
    get()
    {
        val d = Any().asDynamic()
        d.id = id
        d.name = name
        val json = encodeURIComponent(JSON.stringify(d))
        val b64 = json.toBase64()
        return encodeURIComponent(b64)
    }

    override var name = ""
    var type = ""
    var classe = ""
    var region = ""
    var disabled = false
    var documentType = ""

    var vesselMasterEmail = ""

    var dimensions = ShipSection("dimensions")

    var navigation = ShipSection("navigation")

    var handbooks : MutableList<String> = mutableListOf()

    var generalInformation = ShipSection("general_information")

    var capacities = ShipSection("capacities")

    var tonnagesAndLoadlineInformation = ShipSection("tonnages_and_loadline_information")

    var machinerySystems = ShipMachinerySystem("machinery_systems")

    var dynamicPositioning = ShipDynamicPositioning("dynamic_positioning")

    var bowLoadingSystem = ShipSection("bow_loading_system")

    var cargoAndBallastEquipment = ShipSection("cargo_and_ballast_equipment")

    var environmental = ShipSection("environmental")

    var design = ShipSection("design")

    fun supports(handbook:String) : Boolean
    {
        return handbooks.contains(handbook)
    }

    var operations = HashMap<String,ShipOperationState>()

    fun state(handbook:Handbook?) : ShipOperationState?
    {
        return state(handbook?.id)
    }

    fun state(handbookId:String?) : ShipOperationState?
    {
        val id = handbookId ?: return null
        var st = operations[id]
        if (st != null) { return st }
        st = ShipOperationState()
        operations[id] = st
        return st
    }
}

open class ShipActiveOperation
{
    constructor()

    constructor(dyn:dynamic)
    {
        val handbookId = dyn.handbook_id as? String
        val started = dyn.initiated
        if (started!=null)
            initiated = Event(dyn=started)

        destination = dyn.destination as? String
        destinationName = dyn.destination_name as? String
        trip = dyn.trip as? String
        loads = dyn.loads as? Int ?: 0
        voyageNumber = dyn.voyage_number as? String
    }

    var handbookId : String? = null
    var initiated : Event? = null
    var destination : String? = null
    var destinationName : String? = null
    var voyageNumber : String? = null

    var trip : String? = null
    var loads = 0

    fun asHandbookParams(handbook: Handbook): String {
            val d = Any().asDynamic()
            d.id = this.handbookId ?: handbook.id
            d.name = handbook.name
            d.published = handbook.published
            d.tripId = this.trip
            if (handbook.operationName != null)
                d.operationName = handbook.operationName
            if (handbook.shipId != null)
                d.shipId = handbook.shipId
            if (handbook.shipName != null)
                d.shipName = handbook.shipName
            if (handbook.completed != null)
                d.completed = handbook.completed
            val json = encodeURIComponent(JSON.stringify(d))
            val b64 = json.toBase64()
            return encodeURIComponent(b64)
        }
}

open class ShipOperationState
{
    constructor()

    constructor(dyn:dynamic)
    {
        val started = dyn.initiated
        if (started!=null)
            initiated = Event(dyn=started)

        destination = dyn.destination as? String
        destinationName = dyn.destination_name as? String
        trip = dyn.trip as? String
        loads = dyn.loads as? Int ?: 0
        voyageNumber = dyn.voyage_number as? String
        updateActive(dyn.active)
    }

    var initiated : Event? = null
    var destination : String? = null
    var destinationName : String? = null
    var voyageNumber : String? = null

    var trip : String? = null
    var loads = 0 // Don't clear this
    var activeOperations : MutableList<ShipActiveOperation> = mutableListOf()

    fun clear()
    {
        initiated = null
        destination = null
        trip = null
        voyageNumber = null
    }

    fun updateActive(dyn:dynamic)
    {
        val ops = dyn as? Array<Any>
        if (ops != null)
        {
            for (op in ops){
                activeOperations.add(ShipActiveOperation(dyn=op.asDynamic()))
            }
        }
    }

    fun getLoadCount() : Int {
        val sorted = activeOperations.sortedByDescending { it.initiated?.timestamp ?: 0.0}
        val latestActive = sorted.firstOrNull()
        if (latestActive != null) 
            return latestActive.loads
        return loads
    }
}

data class ShipType_old(override val name:String) : NamedItem
data class ShipClass_old(override val name:String) : NamedItem
data class ShipRegion_old(override val name:String) : NamedItem

data class ShipClass(override val id:String="", val vessel_class: String=""): IdentifiedItem
{
    override val name: String
        get() = vessel_class

    constructor(dyn: dynamic) : this(dyn.id as String, dyn.vessel_class as String)
    {

    }
}

data class ShipType(override val id:String="", var vessel_type: String=""): IdentifiedItem
{
    override val name: String
        get() = vessel_type

    constructor(dyn: dynamic) : this(dyn.id as String, dyn.vessel_type as String)
    {

    }
}

data class ShipRegion(override val id:String="", var vessel_region: String="") : IdentifiedItem
{
    override val name: String
        get() = vessel_region

    constructor(dyn: dynamic) : this(dyn.id as String, dyn.vessel_region as String)
    {

    }
}

data class LoadingSystem(override val id:String="", override var name: String="") : IdentifiedItem
{
    constructor(dyn: dynamic) : this(dyn.id as String, dyn.name as String)
    {

    }
}

data class HandbookOperation(override val id:String="", override var name: String="") : IdentifiedItem
{
    constructor(dyn: dynamic) : this(dyn.id as String, dyn.name as String)
    {

    }
}

data class ShipTrip(val ship:Ship,val id:String,val destination:Installation?)

open class ShipSection(val name: String)
{
    var data : MutableMap<String,String> = mutableMapOf()

    open fun update(dyn:dynamic?) {
        data.clear()
        add("", dyn)
    }

    protected fun add(prefix: String, dyn: dynamic) {
        var p = ""
        if (prefix != "") p = "$prefix."
        val dyn = dyn ?: return
        val keys = ObjectHelper.getKeys(dyn) ?: return

        for (key in keys) {
            val v = dyn[key] as? String ?: continue
            data["$p$key"] = v
        }
    }
}

class ShipMachinerySystem(name: String) : ShipSection(name)
{
    val thrusters = ShipThrusters()

    override fun update(dyn:dynamic?)
    {
        super.update(dyn=dyn)
        thrusters.update(dyn=dyn?.thrusters)
        //add("main_engines", dyn?.main_engines)
        //add("auxiliary_engines", dyn?.auxiliary_engines)
        //add("propellers", dyn?.propellers)
    }
}

class ShipThrusters
{
    val thruster : MutableList<ShipThruster> = mutableListOf()

    val hasThruster : Boolean
    get()
    {
        return thruster.isNotEmpty()
    }

    fun update(dyn:dynamic?)
    {
        thruster.clear()

        val thrdr = dyn as? Array<Any>
        if (thrdr != null)
        {
            for (th in thrdr)
            {
                thruster.add(ShipThruster(dyn=th.asDynamic()))
            }
        }
    }

    fun toDynamic() : dynamic
    {
        //val updates = Any().asDynamic()
        val updates = thruster.map { it.toDynamic() }.asDynamic()
        return updates
    }
}

class ShipDynamicPositioning(name: String) : ShipSection(name)
{
    val dpBuoys = ShipDbBuoys()
    val referenceSystems = ShipReferenceSystems()
    val gpSystems = ShipGPSs()
    val hprs = ShipHPRs()
    val hains = ShipHains()

    override fun update(dyn:dynamic?) {
        super.update(dyn = dyn)
        dpBuoys.update(dyn=dyn?.dp_buoys)
        referenceSystems.update(dyn= dyn?.reference_systems)
        gpSystems.update(dyn=dyn?.gps)
        hprs.update(dyn=dyn?.hpr)
        hains.update(dyn=dyn?.hain)
    }
}

class ShipDbBuoys
{
    val dpbuoy : MutableList<ShipDPBuoy> = mutableListOf()

    fun update(dyn:dynamic?)
    {
        dpbuoy.clear()

        val dpbuoydr = dyn as? Array<Any>
        if (dpbuoydr != null)
        {
            for (dp in dpbuoydr)
            {
                dpbuoy.add(ShipDPBuoy(dyn=dp.asDynamic()))
            }
        }
    }

    fun toDynamic() : dynamic
    {
        //val updates = Any().asDynamic()
        val updates = dpbuoy.map { it.toDynamic() }.asDynamic()
        return updates
    }

}

class ShipReferenceSystems
{
    val referencesystem : MutableList<ShipReferenceSystem> = mutableListOf()

    fun update(dyn:dynamic?)
    {
        referencesystem.clear()

        val refdr = dyn as? Array<Any>
        if (refdr != null)
        {
            for (ref in refdr)
            {
                referencesystem.add(ShipReferenceSystem(dyn=ref.asDynamic()))
            }
        }
    }

    fun toDynamic() : dynamic
    {
        val updates = referencesystem.map { it.toDynamic() }.asDynamic()
        return updates
    }
}

class ShipGPSs
{
    val GPS : MutableList<ShipGPS> = mutableListOf()

    fun update(dyn:dynamic?)
    {
        GPS.clear()

        val gpsdr = dyn as? Array<Any>
        if (gpsdr != null)
        {
            for (gps in gpsdr)
            {
                GPS.add(ShipGPS(dyn=gps.asDynamic()))
            }
        }
    }

    fun toDynamic() : dynamic
    {
        val updates = GPS.map { it.toDynamic() }.asDynamic()
        return updates
    }
}

class ShipHPRs
{
    val HPR : MutableList<ShipHPR> = mutableListOf()

    fun update(dyn:dynamic?)
    {
        HPR.clear()

        val hprdr = dyn as? Array<Any>
        if (hprdr != null)
        {
            for (hpr in hprdr)
            {
                HPR.add(ShipHPR(dyn=hpr.asDynamic()))
            }
        }
    }

    fun toDynamic() : dynamic
    {
        val updates = HPR.map { it.toDynamic() }.asDynamic()
        return updates
    }
}

class ShipHains
{
    val hain : MutableList<ShipHain> = mutableListOf()

    fun update(dyn:dynamic?)
    {
        hain.clear()

        val haindr = dyn as? Array<Any>
        if (haindr != null)
        {
            for (hain in haindr)
            {
                this.hain.add(ShipHain(dyn=hain.asDynamic()))
            }
        }
    }

    fun toDynamic() : dynamic
    {
        val updates = hain.map { it.toDynamic() }.asDynamic()
        return updates
    }
}

data class ShipThruster(var name:String="",var maker : String = "",var type : String = "")
{
    constructor(dyn:dynamic) : this(
        dyn.name as? String ?: "",
        dyn.maker as? String ?: "",
        dyn.type as? String ?: ""
    )

    fun toDynamic() : dynamic
    {
        val updates = Any().asDynamic()
        updates.name = name
        updates.maker = maker
        updates.type = type
        return updates
    }
}

data class ShipDPBuoy(var location:String="", var type : String = "")
{
    constructor(dyn:dynamic) : this(
        dyn.location as? String ?: "",
        dyn.type as? String ?: ""
    )

    fun toDynamic() : dynamic
    {
        val updates = Any().asDynamic()
        updates.location = location
        updates.type = type
        return updates
    }
}

data class ShipReferenceSystem(var type:String="", var maker : String = "", var antennas : String = "",var coverage : String = "")
{
    constructor(dyn:dynamic) : this(
        dyn.type as? String ?: "",
        dyn.maker as? String ?: "",
        dyn.antennas as? String ?: "",
        dyn.coverage as? String ?: ""

    )

    fun toDynamic() : dynamic
    {
        val updates = Any().asDynamic()
        updates.type = type
        updates.maker = maker
        updates.antennas = antennas
        updates.coverage = coverage
        return updates
    }
}

data class ShipGPS(var name:String="", var type:String="", var maker : String = "", var transmitters : String = "",var signals : String = "")
{
    constructor(dyn:dynamic) : this(
        dyn.name as? String ?: "",
        dyn.type as? String ?: "",
        dyn.maker as? String ?: "",
        dyn.transmitters as? String ?: "",
        dyn.signals as? String ?: ""

    )

    fun toDynamic() : dynamic
    {
        val updates = Any().asDynamic()
        updates.name = name
        updates.type = type
        updates.maker = maker
        updates.transmitters = transmitters
        updates.signals = signals
        return updates
    }
}

data class ShipHPR(var name:String="", var type:String="", var maker : String = "", var transducer : String = "")
{
    constructor(dyn:dynamic) : this(
        dyn.name as? String ?: "",
        dyn.type as? String ?: "",
        dyn.maker as? String ?: "",
        dyn.transducer as? String ?: ""
    )

    fun toDynamic() : dynamic
    {
        val updates = Any().asDynamic()
        updates.name = name
        updates.type = type
        updates.maker = maker
        updates.transducer = transducer
        return updates
    }
}

data class ShipHain(var type:String="", var maker : String = "", var imu : String = "")
{
    constructor(dyn:dynamic) : this(
        dyn.type as? String ?: "",
        dyn.maker as? String ?: "",
        dyn.imu as? String ?: ""
    )

    fun toDynamic() : dynamic
    {
        val updates = Any().asDynamic()
        updates.type = type
        updates.maker = maker
        updates.imu = imu
        return updates
    }
}