package main.kotlin.ui

import main.kotlin.data.Ship
import main.kotlin.data.ShipClass
import main.kotlin.data.ShipRegion
import main.kotlin.data.ShipType
import org.w3c.dom.Document
import ui.MapSelector

val List<Ship>.selectorItems: Array<SelectorItem>
    get() {
        val selectors: MutableList<SelectorItem> = mutableListOf()

        var index = 0
        for (ship in this) {
            selectors.add(SelectorItem("ship-${ship.id}", ship.name, index.toString()))
            index += 1
        }

        return selectors.toTypedArray()
    }

class ShipSelector(document: Document, id: String, hint: String, var ships: List<Ship>) : Selector(document, id, hint, ships.selectorItems) {
    var selected: Ship?
        get() {
            val s = view.value as? String ?: return null
            if (s.isNullOrEmpty()) return null
            val index = s.toInt()
            if (index >= 0)
                return ships[index]
            return null
        }
        set(value) {
            val index = ships.indexOfFirst { it.name == value?.name }
            view.value = "$index"
        }

    fun setShips(ships: List<Ship>) {
        this.ships = ships
        setSelectorItems(ships.selectorItems)
    }
}

fun List<String>.selectorItems(mode: SelectorNotSelectedMode = SelectorNotSelectedMode.All): Array<SelectorItem> {
    val selectors: MutableList<SelectorItem> = mutableListOf()

    when (mode) {
        SelectorNotSelectedMode.All -> selectors.add(SelectorItem("all", "All", "-1"))
        SelectorNotSelectedMode.None -> selectors.add(SelectorItem("none", "None", "-1"))
        SelectorNotSelectedMode.Empty -> {}
    }

    var index = 0
    for (string in this) {
        selectors.add(SelectorItem("ship-${string.toLowerCase()}", string, index.toString()))
        index += 1
    }

    return selectors.toTypedArray()
}

open class StringSelector(document: Document, id: String, hint: String, var strings: List<String>, val mode: SelectorNotSelectedMode = SelectorNotSelectedMode.All) : Selector(document, id, hint, strings.selectorItems(mode)) {
    var selected: String?
        get() {
            val s = view.value as? String ?: return null
            if (s.isNullOrEmpty()) return null
            val index = s.toInt()
            if (index >= 0)
                return strings[index]
            return null
        }
        set(value) {
            val v = if (value == null) -1 else strings.indexOfFirst { it == value }
            view.value = v.toString()
        }

    fun setStrings(classes: List<String>) {
        this.strings = classes
        setSelectorItems(classes.selectorItems(mode))
    }
}

class ShipTypeSelector(document: Document, id: String, hint: String, types: List<ShipType>, mode: SelectorNotSelectedMode = SelectorNotSelectedMode.All)
    : MapSelector(document, id, hint, types.associateBy(ShipType::id, ShipType::vessel_type), mode) {
    fun setTypes(types: List<ShipType>) {
        setItems(types.associateBy(ShipType::id, ShipType::vessel_type))
    }
}

class ShipClassSelector(document: Document, id: String, hint: String, classes: List<ShipClass>, mode: SelectorNotSelectedMode = SelectorNotSelectedMode.All)
    : MapSelector(document, id, hint, classes.associateBy(ShipClass::id, ShipClass::vessel_class), mode) {
    fun setClasses(classes: List<ShipClass>) {
        setItems(classes.associateBy(ShipClass::id, ShipClass::vessel_class))
    }
}

open class ShipRegionSelector(document: Document, id: String, hint: String, regions: List<ShipRegion>, mode: SelectorNotSelectedMode = SelectorNotSelectedMode.All)
    : MapSelector(document, id, hint, regions.associateBy(ShipRegion::id, ShipRegion::vessel_region), mode) {
    fun setRegions(regions: List<ShipRegion>) {
        setItems(regions.associateBy(ShipRegion::id, ShipRegion::vessel_region))
    }
}