package main.kotlin.models

import main.kotlin.comms.Requests
import main.kotlin.data.*
import main.kotlin.ui.*
import org.w3c.dom.Document

class InstallationsScreen(document:Document) : TopNavBarScreen(document), TilesViewDelegate, FilterByNameModel
{
    override val activeTab: TopNavBarTab get() = TopNavBarTab.Installations

    val grid = TilesView(document,this,"grid", "grid-installations")

    override val searchButton = Button(document,"search_button",tooltip=activeTab.searchHint)
    val createButton = Button(document,"create_button",tooltip="Create a new installation")
    val filters = InstallationsFilters(document,"filters")

    override val helpTooltip : String get() { return "Help" }
    override val helpPage : String get() { return "help.html?chapter=installations" }

    override fun start()
    {
        super.start()

        loading = false

        createButton.onclick = { create() }

        Requests.listLoadingSystems(this) { response ->
            //var loadingSystems = response.loadingSystems.map { it.name }.toList()
            loadingSystems = response.loadingSystems
            filters.setLoadingSystems(loadingSystems.toList())
            filters.start(this)
            {
                changedFilter()
            }
        }


        refresh()
    }

    fun create()
    {
        pushTo("installation")
    }

    fun refresh()
    {
        loading = true
        Requests.listInstallations(this)
        {
            loading = false
            refreshed(it)
        }
    }

    var allinstallations : Array<InstallationCard> = arrayOf()
    var installations : Array<InstallationCard> = arrayOf()
    var loadingSystems : Array<LoadingSystem> = arrayOf()

    override fun searchTextChanged(text:String)
    {
        filters.query = text
        changedFilter()
    }

    private fun changedFilter()
    {
        val shouldFilter = filters.query.isNotEmpty() || filters.loadingSystem != null || filters.region != null || filters.statusLabel != null

        if (!shouldFilter)
            installations = allinstallations
        else
        {
            val query = filters.query.toLowerCase()
            fun includeItem(item:InstallationCard) : Boolean
            {
                val hasQ = matchesSearch(query,item)
                var hasStatus = true
                if (filters.statusLabel != null) {
                    when (filters.statusLabel) {
                        "Enabled" -> {
                            hasStatus = item.disabled == false || item.disabled == undefined
                        }
                        "Disabled" -> {
                            hasStatus = item.disabled == true
                        }
                        "All" -> {
                            hasStatus = item.disabled == true || item.disabled == false || item.disabled == undefined
                        }
                        else -> {
                            console.log("Invalid label")
                        }
                    }
                }

                val hasLoadingSystem = if (filters.loadingSystem != null) item.loadingSystem.equals(filters.loadingSystem, ignoreCase = true) else true
                val hasRegion = if (filters.region != null) item.region.equals(filters.region, ignoreCase = true) else true
                return hasQ && hasStatus && hasLoadingSystem && hasRegion
            }
            installations = allinstallations.filter { includeItem(it) }.toTypedArray()
        }
        grid.reloadData()
    }

    fun refreshed(it:InstallationsResponse)
    {
        allinstallations = it.installations
        searchTextChanged(if (searching) topnavbar.searchbar.text else "")
    }

    override val numberOfItems: Int get() { return installations.count() }

    override fun cellForItem(at:Int) : String
    {
        val it = installations[at]
        val onclick = "window.location.href='installation.html?id=${it.id}'"
        val title = it.name
        val details = loadingSystems.firstOrNull { ls -> ls.id == it.loadingSystem}?.name ?: "Missing loading system"
        val disabled = it.disabled

        if (disabled == true)
            return """
    <li class="mdc-image-list__item" onclick="$onclick">
        <div class="mdc-image-list__image">
            <div class="cell_top_bar disabled"></div>
            <i class="material-icons disabled">place</i>
            <span class="cell_title disabled">$title</span>
            <span class="cell_details disabled">$details</span>
        </div>
    </li>"""

        return """
    <li class="mdc-image-list__item" onclick="$onclick">
        <div class="mdc-image-list__image">
            <div class="cell_top_bar"></div>
            <i class="material-icons">place</i>
            <span class="cell_title">$title</span>
            <span class="cell_details">$details</span>
        </div>
    </li>"""
    }
}

class InstallationsFilters(document:Document,val id:String)
{
    var query : String = ""
    var statusLabel : String? = "Enabled"
    var loadingSystem : String? = null
    var region : String? = null

    var statusLabels : MutableList<String> = mutableListOf(
        "Enabled",
        "Disabled"
    )

    var loadingSystems : List<LoadingSystem> = listOf()

    private val statusLabelSelector : StringSelector
    private val loadingSystemSelector : LoadingSystemSelector
    private val regionSelector : InstallationRegionSelector
    private val container = Div(document,id)



    fun setLoadingSystems(loadingSystems: List<LoadingSystem>)
    {
        this.loadingSystems = loadingSystems
        loadingSystemSelector.setLoadingSystems(loadingSystems)
    }

    fun start(screen:AuthScreen,changedFilter:()->Unit)
    {
        statusLabelSelector.selected = statusLabel
        statusLabelSelector.valueChanged = {statusLabel = statusLabelSelector.selected; changedFilter.invoke()}

        loadingSystemSelector.selected = loadingSystem
        loadingSystemSelector.valueChanged = { loadingSystem = loadingSystemSelector.selected; changedFilter.invoke() }

        regionSelector.selected = region
        regionSelector.valueChanged = { region = regionSelector.selected; changedFilter.invoke() }

        regionSelector.start(screen)
    }

    var hidden : Boolean
    get()
    {
        return container.hidden
    }
    set(value)
    {
        container.hidden = value
        if (!value)
        {
            statusLabelSelector.selected = statusLabel
            loadingSystemSelector.selected = loadingSystem
            regionSelector.selected = region
        }
    }

    val markup : String
    get()
    {
        return """
       <div class="mdc-select" id="${id}-status"></div>
       <div class="mdc-select" id="${id}-loading_system"></div>
       <div class="mdc-select" id="${id}-region"></div>
    """
    }

    init
    {
        container.html = markup
        statusLabelSelector = StringSelector(document, "${id}-status", "Status", statusLabels)
        loadingSystemSelector = LoadingSystemSelector(document,"${id}-loading_system","Loading System",loadingSystems)
        regionSelector = InstallationRegionSelector(document,"${id}-region","Region", SelectorNotSelectedMode.All)
    }
}