package main.kotlin.data

enum class TemplateType(val value:String)
{
    None("none"),
    ShipName("ship.name"),
    DestinationName("destination.name"),
    LoadingSystem("destination.loading_system"),
    ShootingPosition("destination.additional_info.shooting_position");

    val title : String
    get()
    {
        return when(this)
        {
            None -> "Nothing selected"
            ShipName -> "Name of Vessel"
            DestinationName -> "Name of Destination"
            LoadingSystem -> "Loading System of Destination"
            ShootingPosition -> "Shooting Position of Destination"
        }
    }

    val templateString : String
    get()
    {
        if (value.isEmpty()) return ""
        if (value == "none") return ""
        return "{{$value}}"
    }

    companion object
    {
        fun from(value: String): TemplateType
        {
            val all = values()
            for (type in all)
            {
                if (type.value == value)
                    return type
            }
            return None
        }
    }
}

enum class CompletionMode(val value:Int)
{
    CommentNotRequired(0),
    CommentRequired(1),
    CommentOptional(2);

    companion object
    {
        fun from(value: Int) : CompletionMode
        {
            when (value)
            {
                0 -> return CommentNotRequired
                1 -> return CommentRequired
                2 -> return CommentOptional
            }
            return CommentNotRequired
        }
    }

    val title : String
    get()
    {
        return when(this)
        {
            CommentNotRequired -> "Comment not required"
            CommentRequired -> "Comment Compulsory"
            CommentOptional -> "Comment Optional"
        }
    }
}

enum class NotApplicableMode(val value:Int)
{
    NotEnabled(0),
    CommentNotRequired(1),
    CommentRequired(2),
    CommentOptional(3);

    companion object
    {
        fun from(value: Int) : NotApplicableMode
        {
            when (value)
            {
                0 -> return NotEnabled
                1 -> return CommentNotRequired
                2 -> return CommentRequired
                3 -> return CommentOptional
            }
            return NotEnabled
        }
    }

    val title : String
    get()
    {
        return when(this)
        {
            NotEnabled -> "Not Used"
            CommentNotRequired -> "Comment not required"
            CommentRequired -> "Comment Compulsory"
            CommentOptional -> "Comment Optional"
        }
    }
}

enum class ProcedureWorkflowStatus(val value:Int)
{
    Unknown(0),
    Submitted(1),
    Rejected(2),
    Approved(3);

    companion object
    {
        fun from(value: Int) : ProcedureWorkflowStatus
        {
            when (value)
            {
                0 -> return Unknown
                1 -> return Submitted
                2 -> return Rejected
                3 -> return Approved
            }
            return Unknown
        }
    }

    val title : String
    get()
    {
        return when(this)
        {
            Unknown -> "Unknown"
            Submitted -> "Submitted"
            Rejected -> "Rejected"
            Approved -> "Approved"
        }
    }

    fun name(parent:String?) : String
    {
//    if (parent == null)
//    {
//        return when (this)
//        {
//            ProcedureWorkflowState.Unknown -> "Draft"
//            ProcedureWorkflowState.Submitted -> "Draft submitted"
//            ProcedureWorkflowState.Rejected -> "Draft Disapproved"
//            ProcedureWorkflowState.Approved -> "Draft Approved"
//        }
//    }
//    else
//    {
//        return when (this)
//        {
//            ProcedureWorkflowState.Unknown -> "Revision in progress"
//            ProcedureWorkflowState.Submitted -> "Revision proposed"
//            ProcedureWorkflowState.Rejected -> "Revision disapproved"
//            ProcedureWorkflowState.Approved -> "Revision approved"
//        }
//    }
        if (parent == null)
        {
            return when (this)
            {
                Unknown -> "Draft"
                Submitted -> "Submitted"
                Rejected -> "Rejected"
                Approved -> "Approved"
            }
        }
        else
        {
            return when (this)
            {
                Unknown -> "Revision"
                Submitted -> "Submitted"
                Rejected -> "Rejected"
                Approved -> "Approved"
            }
        }
    }
}

// Deprecated
enum class OperationType(val value:Int)
{
    None(0),
    Navigation(1),
    OffshoreLoading(2);

    companion object
    {
        fun from(value: Int) : OperationType
        {
            when (value)
            {
                1 -> return Navigation
                2 -> return OffshoreLoading
            }
            return None
        }
    }

    val snakeCase : String
    get()
    {
        return when(this)
        {
            None -> "none"
            Navigation -> "navigation"
            OffshoreLoading -> "offshore_loading"
        }
    }
}

enum class HandbookIcon(val value:Int)
{
    Teekay(0),
    Compass(1),
    GroundedOilrig(2),
    FloatingOilrig(3),
    Propeller(4),
    Cogwheel(5),
    Helicopter(6),
    Cargo(7),
    ShipToShip(8),
    Training(9),
    Drills(10),
    BowLoadingSystem(11),
    DPChangeOfWatch(12),
    DPEmergencyTraining(13);
  
    companion object
    {
        fun from(value: Int) : HandbookIcon
        {
            when (value)
            {
                0 -> return Teekay
                1 -> return Compass
                2 -> return GroundedOilrig
                3 -> return FloatingOilrig
                4 -> return Propeller
                5 -> return Cogwheel
                6 -> return Helicopter
                7 -> return Cargo
                8 -> return ShipToShip
                9 -> return Training
                10 -> return Drills
                11 -> return BowLoadingSystem
                12 -> return DPChangeOfWatch
                13 -> return DPEmergencyTraining
            }
            return Teekay
        }
    }

    val title : String
    get()
    {
        return when(this)
        {
            Teekay -> "Altera"
            Compass -> "Compass"
            GroundedOilrig -> "Grounded Oilrig"
            FloatingOilrig -> "Floating Oilrig"
            Propeller -> "Propeller"
            Cogwheel -> "Cogwheel"
            Helicopter -> "Helicopter"
            Cargo -> "Cargo"
            ShipToShip -> "Ship to Ship"
            Training -> "Training"
            Drills -> "Drills"
            BowLoadingSystem -> "Bow Loading System"
            DPChangeOfWatch -> "DP Change of Watch"
            DPEmergencyTraining -> "DP Emergency Training"
        }
    }

    val image : String
    get()
    {
        val path = "static/media"
        return when(this)
        {
            Teekay -> "$path/icon-handbook-altera.svg"
            Compass -> "$path/icon-handbook-compass.svg"
            GroundedOilrig -> "$path/icon-handbook-grounded_oilrig.svg"
            FloatingOilrig -> "$path/icon-handbook-floating_oilrig.svg"
            Propeller -> "$path/icon-handbook-propeller.svg"
            Cogwheel -> "$path/icon-handbook-cogwheel.svg"
            Helicopter -> "$path/icon-handbook-helicopter.svg"
            Cargo -> "$path/icon-handbook-cargo.svg"
            ShipToShip -> "$path/icon-handbook-ship-to-ship.svg"
            Training -> "$path/icon-handbook-training.svg"
            Drills -> "$path/icon-handbook-drills.svg"
            BowLoadingSystem -> "$path/icon-handbook-bow-loading-system.svg"
            DPChangeOfWatch -> "$path/icon-handbook-dp-change-of-watch.svg"
            DPEmergencyTraining -> "$path/icon-handbook-dp-emergency-training.svg"
        }
    }
}

enum class HandbookFeature(val value:String)
{
    Unknown("unknown"),
    Loadable("loadable");

    companion object
    {
        fun from(value: String) : HandbookFeature
        {
            when (value)
            {
                "unknown" -> return Unknown
                "loadable" -> return Loadable
            }
            return Unknown
        }
    }
}

enum class APITaskStatus(val value:Int)
{
    Unknown(0),
    Pending(1),
    Started(2),
    Retry(3),
    Failure(4),
    Success(5);

    companion object
    {
        fun from(value:Int) : APITaskStatus
        {
            when (value)
            {
                0 -> return Unknown
                1 -> return Pending
                2 -> return Started
                3 -> return Retry
                4 -> return Failure
                5 -> return Success
            }
            return Unknown
        }
    }

    val title : String
    get()
    {
        return when(this)
        {
            Unknown -> "Unknown"
            Pending -> "Pending"
            Started -> "Started"
            Retry -> "Retry"
            Failure -> "Failure"
            Success -> "Success"
        }
    }
}

enum class IdResponseCode(val value:Int)
{
    Normal(0),
    HandbookRevisionAlreadyExists(1);

    companion object
    {
        fun from(value:Int) : IdResponseCode
        {
            when (value)
            {
                0 -> return Normal
                1 -> return HandbookRevisionAlreadyExists
            }
            return Normal
        }
    }
}
