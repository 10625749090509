package main.kotlin.utils

class ObjectHelper
{
    companion object {

        fun getKeys(dyn: dynamic): Array<String>? {
            val obj = js("""
    (function(){
        return Object.keys(dyn);
    }())
    """)
            return obj as? Array<String>
        }

    }

}