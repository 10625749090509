package main.kotlin.models.editor

import main.kotlin.data.*

import main.kotlin.ui.*
import org.w3c.dom.Document
import kotlin.browser.document

interface ProcedureChoicesEditorDelegate
{
    fun clickedBlurbChoice(choice:BlurbChoice,blurb:Blurb)
    fun changedBlurbChoice(choice:BlurbChoice)
    fun clickedDeleteChoice(choice:BlurbChoice)
    fun clickedAddBlurbToChoice(choice:BlurbChoice)
    fun clickedRemoveBlurbFromChoice(choice:BlurbChoice,blurb:Blurb)
}

class ProcedureChoicesEditor(document:Document,val id:String, override val handbook:Handbook) : ContainerPanel,ProcedureConfig
{
    override val container = Div(document, id)
    val diamondsContainer = Div(document, "$id-diamonds")

    var current : Blurb? = null
    var choices : List<BlurbChoice> = listOf()

    var delegate : ProcedureChoicesEditorDelegate? = null

    var options : ProcedureOptions = ProcedureOptions()
    var procedure : Procedure? = null

    fun reload(data:Blurb)
    {
        current = data
        choices = data.choices
        reloadData()
    }

    val diamonds : MutableList<DiamondEditor> = mutableListOf()

    class DiamondEditor(val id:String,val choice:BlurbChoice,val config:ProcedureConfig,val options:ProcedureOptions,val procedure:Procedure?)
    {
        val container : Div = Div(document,id)

        val nameContainer : Div
        val name : EditTextRich
        val blurbs : MutableList<DiamondBlurbCell> = mutableListOf()

        val buttons : Div
        val addBlurbButton : Button
        val deleteChoiceButton : Button

        val hr : Hr

        init
        {
            container.html = markup

            buttons = Div(document,"$id-buttons")
            addBlurbButton = Button(document,"$id-add_blurb_button",tooltip="Add Content to this Diamond")
            deleteChoiceButton = Button(document,"$id-delete_choice_button",tooltip="Delete this Diamond")

            nameContainer = Div(document,"$id-name-container")
            name = EditTextRich(document,config.handbook,"$id-name","Text",required=true)
            name.text = choice.title
            name.reload(options,procedure)
            nameContainer.view.onmouseenter = { hovering(true) }
            nameContainer.view.onmouseleave = { hovering(false) }

            hr = Hr(document,"$id-divider")

            for ((i,blurb) in choice.blurbs.withIndex())
            {
                val div = DiamondBlurbCell("$id-blurb-$i")
                blurbs.add(div)
            }
        }

        val markup : String
        get()
        {
            var html = """
                <div class="diamond-editor-diamond-container" id="$id-name-container">
                <div class="edit" id="$id-name"></div>
                <div id="$id-buttons" class="diamond-editor-diamond-buttons" style="display:none;">
                    <button class="mdc-button circle_button" id="$id-delete_choice_button"><i class="material-icons mdc-button__icon" aria-hidden="true">close</i></button>
                    <button class="mdc-button circle_button" id="$id-add_blurb_button"><i class="material-icons mdc-button__icon" aria-hidden="true">add</i></button>
                </div>
                </div>
            """
            val config = BlurbMarkupConfig(config,step=null,placement=null)
            config.disableGotoLinks = true
            for ((i,blurb) in choice.blurbs.withIndex())
            {
                html += """
                    <div class="diamond-editor-blurb-container">
                       <div id='$id-blurb-$i' class="diamond-editor-blurb">${blurb.markup(config)}</div>
                       <div class="diamond-editor-blurb-buttons">
                            <button class="mdc-button circle_button" id="$id-blurb-$i-delete_blurb_button"><i class="material-icons mdc-button__icon" aria-hidden="true">close</i></button>
                       </div>
                    </div>
                    """
            }

            html += """<hr id="$id-divider" class="diamond-editor-divider"/>"""

            return html
        }

        fun clear()
        {
            container.onclick = null
            for (blurb in blurbs)
                blurb.clear()
            name.textChanged = null
            addBlurbButton.onclick = null
            deleteChoiceButton.onclick = null
            nameContainer.view.onmouseenter = null
            nameContainer.view.onmouseleave = null
            blurbs.clear()
        }

        private fun hovering(isHovering:Boolean)
        {
            buttons.hidden = !isHovering
        }
    }

    class DiamondBlurbCell(id:String)
    {
        val container = Div(document,id,tooltip="Edit Diamond Content")
        val deleteButton = Button(document,"$id-delete_blurb_button",tooltip="Delete Diamond Content")

        fun clear()
        {
            container.onclick = null
            deleteButton.onclick = null
        }
    }

    fun reloadData()
    {
        for (cell in diamonds)
            cell.clear()
        diamonds.clear()

        var html = ""
        for ((at,choice) in choices.withIndex())
        {
            html += """<div id="$id-diamond-editor-$at"></div>"""
        }

        diamondsContainer.html = html

        for ((at,choice) in choices.withIndex())
        {
            val cell = DiamondEditor("$id-diamond-editor-$at",choice,this,options,procedure)
            cell.deleteChoiceButton.onclick = { clickedDeleteChoice(at) }
            cell.addBlurbButton.onclick = { clickedAddBlurb(at) }
            cell.name.textChanged = { choice.title = it; changedBlurbChoice(at) }

            for ((i,blurb) in cell.blurbs.withIndex())
            {
                blurb.container.onclick = { clickedChoiceBlurb(choice,choice.blurbs[i]) }
                blurb.deleteButton.onclick = { clickedDeleteChoiceBlurb(choice,choice.blurbs[i]) }
            }

            cell.hr.hidden = at == choices.lastIndex

            diamonds.add(cell)
        }
    }

    fun clickedChoiceBlurb(choice:BlurbChoice,blurb:Blurb)
    {
        delegate?.clickedBlurbChoice(choice,blurb)
    }

    fun clickedDeleteChoiceBlurb(choice:BlurbChoice,blurb:Blurb)
    {
        delegate?.clickedRemoveBlurbFromChoice(choice,blurb)
    }

    fun clickedDeleteChoice(at:Int)
    {
        val choice = choices[at]
        delegate?.clickedDeleteChoice(choice)
    }

    fun clickedAddBlurb(at:Int)
    {
        val choice = choices[at]
        delegate?.clickedAddBlurbToChoice(choice)
    }

    fun changedBlurbChoice(at:Int)
    {
        val choice = choices[at]
        delegate?.changedBlurbChoice(choice)
    }
}
