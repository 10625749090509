package main.kotlin.models

import main.kotlin.ui.Button
import main.kotlin.ui.Div
import main.kotlin.ui.TopNavBar
import main.kotlin.ui.TopNavBarTab
import org.w3c.dom.Document
import kotlin.browser.window
import org.w3c.dom.events.KeyboardEvent

abstract class TopNavBarScreen(document: Document,private val tab:TopNavBarTab?=null) : LoaderScreen(document)
{
    val topnavbar = TopNavBar(document, this,activeTab = tab?: activeTab)
    val topLogo = Div(document,"logo-container","flex")
    val topSearchContainer = Div(document,"search-container","flex")

    abstract val activeTab : TopNavBarTab
    open val searchButton : Button? get() { return null }
    open val closeSearchButton = Button(document,"close_search_button",tooltip = "Close Search")
    open val helpButton : Button?
    open val helpTooltip : String get() { return "Help" }
    open val helpPage : String get() { return "help.html" }

    open val searchHint : String get() { return (tab?: activeTab).searchHint ?: "" }

    init
    {
        val helpButtonId = "help_button"
        val element = document.getElementById(helpButtonId)
        if (element != null)
            helpButton = Button(document,helpButtonId,tooltip=helpTooltip)
        else
            helpButton = null
    }

    override fun start()
    {
        super.start()

        topnavbar.user.initials.text = currentUser?.initials ?: ""

        var searchButton = searchButton
        if (searchButton != null)
        {
            topnavbar.searchbar.textChanged = {
                searching = true
                searchTextChanged(it)
            }

            window.onkeyup = { keyboardEvent(it as KeyboardEvent) }

            searchButton?.onclick = {
                clickedSearch()
            }
            closeSearchButton.onclick = {
                searching = false
                searchTextChanged("")
            }
        }

        var helpButton = helpButton
        if (helpButton != null)
        {
            helpButton.onclick = {
                pushToHtml(helpPage)
            }
        }
    }

    val changerKeys = arrayOf("a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z","æ","ø","å","!","?")

    fun keyboardEvent(it:KeyboardEvent)
    {
        if (topnavbar.showingPopover)
        {
            if (it.code == "Escape")
                topnavbar.hidePopover()
            return
        }
//        console.log(it.code + " " + it.charCode + " " + it.key)
        val key = it.key.toLowerCase()
        if (it.code == "Escape")
        {
            if (searching)
            {
                searching = false
                searchTextChanged("")
            }
        }
        else if (!searching && !key.isEmpty() && changerKeys.contains(key))
        {
            searching = true
            topnavbar.searchbar.focus()
            topnavbar.searchbar.text = topnavbar.searchbar.text + it.key
            searchTextChanged(topnavbar.searchbar.text)
        }
    }

    fun clickedSearch()
    {
        searching = !searching
        if (!searching)
        {
            searchTextChanged("")
        }
        else
        {
            searchTextChanged(topnavbar.searchbar.text)
            topnavbar.searchbar.focus()
        }
    }

    private var _searching = false
    open var searching : Boolean
    get()
    {
        return _searching
    }
    set(value)
    {
        _searching = value
        if (value)
        {
            topSearchContainer.hidden = false
            topnavbar.tabs.hidden = true
            searchButton?.hidden = true
            topnavbar.user.hidden = true
            topLogo.hidden = true
        }
        else
        {
            topSearchContainer.hidden = true
            topnavbar.tabs.hidden = false
            topnavbar.user.hidden = false
            searchButton?.hidden = false
            topLogo.hidden = false
        }
    }

    open fun searchTextChanged(text:String)
    {
        console.log("Text changed to " + text)
    }
}
