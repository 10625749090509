package main.kotlin.ui

import org.w3c.dom.Document
import org.w3c.dom.HTMLDivElement

class Breadcrumbs(val document: Document, val id:String,crumbs:Array<Breadcrumb>)
{
    private val container : HTMLDivElement = document.getElementById(id) as HTMLDivElement

    init
    {
        update(crumbs)
    }

    fun update(crumbs:Array<Breadcrumb>)
    {
        val list = crumbs.map { """<a href="${it.link}">${it.name}</a>""" }
        val html = list.joinToString(" / ")
        container.innerHTML = html
    }
}

data class Breadcrumb(val name:String,val link:String)
