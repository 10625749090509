package main.kotlin.data

import main.kotlin.utils.ObjectHelper

data class Installation(val id:String="")
{
    constructor(dyn:dynamic) : this(dyn.id as String)
    {
        documentType = dyn.document_type as? String ?: ""
        name = dyn.name as String
        fullName = dyn.full_name as? String
        region = dyn.region as? String
        loadingSystem = dyn.loading_system as? String

        /*val ls = dyn.loading_system as? String
        if (ls != null)
            loadingSystem = InstallationLoadingSystem.from(ls)*/

        info.update(dyn.info)
        environmental.update(dyn.environmental)
        communication.update(dyn.communication)
        telemetry.update(dyn.telemetry)
        mooring.update(dyn.mooring)
        standbyVessel.update(dyn.standby_vessel)
        positionLimits.update(dyn.position_limits)
        areas.update(dyn.areas)
        additionalInfo.update(dyn.additional_info)
        referenceSystem.update(dyn.reference_system)

        val disabled = dyn.disabled as? Boolean
        if (disabled != null)
            this.disabled = disabled
    }

    var documentType = "installation"
    var name = ""
    var fullName : String? = null
    var region : String? = null
    var loadingSystem : String? = null
    //var loadingSystem : InstallationLoadingSystem = InstallationLoadingSystem.Unknown

    var info = InstallationSection("info")
    var environmental = InstallationSection("environmental")
    var communication = InstallationSection("communication")
    var telemetry = InstallationSection("telemetry")
    var mooring = InstallationSection("mooring")
    var standbyVessel = InstallationSection("standby_vessel")
    var areas = InstallationSection("areas")
    var positionLimits = InstallationPositionLimits("position_limits")
    var additionalInfo = InstallationSection("additional_info")
    var referenceSystem = InstallationReferenceSystem("reference_system")

    var disabled = false
}

data class InstallationCard(override val id:String="") : IdentifiedItem
{
    override var name = ""
    var fullName : String? = null
    //var loadingSystem : InstallationLoadingSystem = InstallationLoadingSystem.Unknown
    var loadingSystem : String? = null
    var region : String? = null
    var disabled : Boolean? = false

    constructor(dyn:dynamic) : this(dyn.id as String)
    {
        name = dyn.name as String
        fullName = dyn.full_name as? String
        region = dyn.region as? String
        //val ls = dyn.loading_system as? String
        /*if (ls != null)
            loadingSystem = InstallationLoadingSystem.from(ls)*/
        loadingSystem = dyn.loading_system as? String
        disabled = dyn.disabled as? Boolean
    }
}

enum class InstallationLoadingSystem(val value:String)
{
    Unknown("unknown"),
    Tandem("tandem"),
    OLS("ols"),
    SAL("sal");

    companion object
    {
        fun from(value:String) : InstallationLoadingSystem
        {
            when (value)
            {
                "unknown" -> return Unknown
                "tandem" -> return Tandem
                "ols" -> return OLS
                "sal" -> return SAL
            }
            return Unknown
        }
    }

    val title : String
    get()
    {
        return when (this)
        {
            Unknown -> "Unknown"
            Tandem -> "Tandem"
            OLS -> "OLS"
            SAL -> "SAL"
        }
    }
}

open class InstallationSection(val name:String)
{
    var data : MutableMap<String,String> = mutableMapOf()

    var headers : MutableList<Blurb> = mutableListOf()
    var footers : MutableList<Blurb> = mutableListOf()

    open fun update(dyn:dynamic?)
    {
        data.clear()
        val dyn = dyn ?: return
        val keys = ObjectHelper.getKeys(dyn) ?: return

        for (key in keys)
        {
            val v = dyn[key] as? String ?: continue
            data[key] = v
        }

        headers.clear()
        val hdr = dyn.headers as? Array<Any>
        if (hdr != null)
        {
            var i = 0
            for (hd in hdr)
            {
                headers.add(Blurb(id="${name.hashCode()}-header-$i",dyn=hd.asDynamic()))
                i += 1
            }
        }

        footers.clear()
        val fdr = dyn.footers as? Array<Any>
        if (fdr != null)
        {
            var i = 0
            for (fd in fdr)
            {
                footers.add(Blurb(id="${name.hashCode()}-footer-$i",dyn=fd.asDynamic()))
                i += 1
            }
        }
    }

    val shootingPosition : String?
    get()
    {
        return data["shooting_position"]
    }
}

class InstallationReferenceSystem(name:String) : InstallationSection(name)
{
    val darps = InstallationDARPS()
    val transponders = InstallationTransponders()

    override fun update(dyn:dynamic?)
    {
        super.update(dyn=dyn)

        darps.update(dyn=dyn?.darps)
        transponders.update(dyn=dyn?.transponders)
    }
}

class InstallationDARPS
{
    val darps1 = InstallationDARPSEntry()
    val darps2 = InstallationDARPSEntry()

    val tdma1 = InstallationDARPSEntry()
    val tdma2 = InstallationDARPSEntry()

    fun update(dyn:dynamic?)
    {
        darps1.update(dyn=dyn?.darps1)
        darps2.update(dyn=dyn?.darps2)
        tdma1.update(dyn=dyn?.tdma1)
        tdma2.update(dyn=dyn?.tdma2)
    }

    fun toDynamic() : dynamic
    {
        val updates = Any().asDynamic()
        updates.darps1 = darps1.toDynamic()
        updates.darps2 = darps2.toDynamic()
        if (tdma1.name.isNotEmpty() && tdma1.value.isNotEmpty())
            updates.tdma1 = tdma1.toDynamic()
        if (tdma2.name.isNotEmpty() && tdma2.value.isNotEmpty())
            updates.tdma2 = tdma2.toDynamic()
        return updates
    }
}

class InstallationDARPSEntry
{
    var name : String = ""
    var value : String = ""

    fun update(dyn:dynamic?)
    {
        name = dyn?.name as? String ?: ""
        value = dyn?.value as? String ?: ""
    }

    fun toDynamic() : dynamic
    {
        val updates = Any().asDynamic()
        updates.name = name
        updates.value = value
        return updates
    }
}

class InstallationTransponders
{
    val position : MutableList<InstallationTransponder> = mutableListOf()
    val other : MutableList<InstallationTransponder> = mutableListOf()

    val hasTransponders : Boolean
    get()
    {
        return position.count() > 0 || other.count() > 0
    }

    fun update(dyn:dynamic?)
    {
        position.clear()
        other.clear()

        val dyn = dyn ?: return

        val posdr = dyn.position as? Array<Any>
        if (posdr != null)
        {
            for (pos in posdr)
            {
                position.add(InstallationTransponder(dyn=pos.asDynamic()))
            }
        }

        val otherdr = dyn.other as? Array<Any>
        if (otherdr != null)
        {
            for (oth in otherdr)
            {
                other.add(InstallationTransponder(dyn=oth.asDynamic()))
            }
        }
    }

    fun toDynamic() : dynamic
    {
        val updates = Any().asDynamic()
        updates.position = position.map { it.toDynamic() }
        updates.other = other.map { it.toDynamic() }
        return updates
    }
}

data class InstallationTransponder(var name:String="",var ch : String = "",var no : String = "")
{
    constructor(dyn:dynamic) : this(
            dyn.name as? String ?: "",
            dyn.ch as? String ?: "",
            dyn.no as? String ?: "")

    fun toDynamic() : dynamic
    {
        val updates = Any().asDynamic()
        updates.name = name
        updates.ch = ch
        updates.no = no
        return updates
    }
}

class InstallationPositionLimits(name:String) : InstallationSection(name)
{
    val sections : MutableList<Section> = mutableListOf()

    override fun update(dyn:dynamic?)
    {
        super.update(dyn=dyn)

        val dyn = dyn ?: return

        val otherdr = dyn.sections as? Array<Any>
        if (otherdr != null)
        {
            for (oth in otherdr)
            {
                sections.add(Section(dyn=oth.asDynamic()))
            }
        }
    }

    data class Section(
            var description : String = "",
            var hose_pickup_sector_limits : String = "",
            var crude_oil_pressure_yellow : String = "", var crude_oil_pressure_yellow_name : String = "",
            var crude_oil_pressure_red : String = "", var crude_oil_pressure_red_name : String = "",
            var hawser_tension_yellow : String = "", var hawser_tension_yellow_name : String = "",
            var hawser_tension_red : String = "", var hawser_tension_red_name : String = "",
            var hose_tension_yellow : String = "", var hose_tension_yellow_name : String = "",
            var hose_tension_red : String = "", var hose_tension_red_name : String = "",
            var heading_limitation_yellow : String = "",
            var heading_limitation_red : String = "",
            var inner_yellow : String = "",
            var inner_red : String = "",
            var normal_green : String = "",
            var outer_yellow : String = "",
            var outer_red : String = "",
            var pasd_1 : String = "",
            var pasd_2 : String = "",
            var sector_yellow : String = "",
            var sector_red : String = ""
    )
    {
        constructor(dyn: dynamic) : this(
                dyn.description as? String ?: "",
                dyn.hose_pickup_sector_limits as? String ?: "",
                dyn.crude_oil_pressure_yellow as? String ?: "", dyn.crude_oil_pressure_yellow_name as? String ?: "",
                dyn.crude_oil_pressure_red as? String ?: "", dyn.crude_oil_pressure_red_name as? String ?: "",
                dyn.hawser_tension_yellow as? String ?: "", dyn.hawser_tension_yellow_name as? String ?: "",
                dyn.hawser_tension_red as? String ?: "", dyn.hawser_tension_red_name as? String ?: "",
                dyn.hose_tension_yellow as? String ?: "", dyn.hose_tension_yellow_name as? String ?: "",
                dyn.hose_tension_red as? String ?: "", dyn.hose_tension_red_name as? String ?: "",
                dyn.heading_limitation_yellow as? String ?: "",
                dyn.heading_limitation_red as? String ?: "",
                dyn.inner_yellow as? String ?: "",
                dyn.inner_red as? String ?: "",
                dyn.normal_green as? String ?: "",
                dyn.outer_yellow as? String ?: "",
                dyn.outer_red as? String ?: "",
                dyn.pasd_1 as? String ?: "",
                dyn.pasd_2 as? String ?: "",
                dyn.sector_yellow as? String ?: "",
                dyn.sector_red as? String ?: ""
        )

        fun toDynamic(): dynamic
        {
            val updates = Any().asDynamic()
            updates.description = description
            updates.hose_pickup_sector_limits = hose_pickup_sector_limits
            updates.crude_oil_pressure_yellow = crude_oil_pressure_yellow
            updates.crude_oil_pressure_red = crude_oil_pressure_red
            updates.hawser_tension_yellow = hawser_tension_yellow
            updates.hawser_tension_red = hawser_tension_red
            updates.heading_limitation_red = heading_limitation_red
            updates.heading_limitation_yellow = heading_limitation_yellow
            updates.hose_tension_yellow = hose_tension_yellow
            updates.hose_tension_red = hose_tension_red
            updates.inner_red = inner_red
            updates.inner_yellow = inner_yellow
            updates.normal_green = normal_green
            updates.outer_red = outer_red
            updates.outer_yellow = outer_yellow
            updates.pasd_1 = pasd_1
            updates.pasd_2 = pasd_2
            updates.sector_yellow = sector_yellow
            updates.sector_red = sector_red
            return updates
        }
    }
}
