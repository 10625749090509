package main.kotlin.ui

import org.w3c.dom.Document
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.events.Event
import kotlin.dom.clear

interface TilesViewDelegate
{
    val numberOfItems : Int

    fun cellForItem(at:Int) : String
    {
        val onclick = onclickForItem(at)
        val title = titleMarkupForItem(at)
        val details = detailMarkupForItem(at)
        val icon = iconMarkupForItem(at)
        return """
            <li class="mdc-image-list__item" onclick="$onclick">
                <div class="mdc-image-list__image">
                    $icon
                    $title
                    $details
                </div>
            </li>
            """
    }

    fun titleForItem(at:Int) : String { return "Row $at"}
    fun detailForItem(at:Int) : String { return "" }
    fun onclickForItem(at:Int) : String { return "" }
    fun iconForItem(at:Int) : String { return "info" }

    fun itemDisabled(at:Int) : Boolean { return false }

    fun titleMarkupForItem(at:Int) : String
    {
        val disabled = if (itemDisabled(at)) " disabled" else ""
        return """<span class="cell_title$disabled">${titleForItem(at)}</span>"""
    }

    fun detailMarkupForItem(at:Int) : String
    {
        val disabled = if (itemDisabled(at)) " disabled" else ""
        return """<span class="cell_details$disabled">${detailForItem(at)}</span>"""
    }

    fun iconMarkupForItem(at:Int) : String
    {
        val disabled = if (itemDisabled(at)) " disabled" else ""
        return """<div class="cell_icon$disabled"><i class="material-icons">${iconForItem(at)}</i></div>"""
    }
}

class TilesView(val document: Document,val delegate:TilesViewDelegate,id:String,val layoutClass:String="grid-default",val mdcClass:String="mdc-image-list",val empty:String?=null)
{
    private val view : HTMLDivElement = document.getElementById(id) as HTMLDivElement

    fun hide() {
        view.hidden = true
    }

    fun show() {
        view.hidden = false
    }

    fun reloadData()
    {
        view.clear()

        var html = """<ul class="$mdcClass $layoutClass">"""

        val itemCount = delegate.numberOfItems

        for (i in 0 until itemCount)
        {
            html += delegate.cellForItem(i)
        }

        html += "</ul>"

        if (itemCount == 0 && empty != null)
        {
            html = """
<div class="grid-default-empty">$empty</div>
"""
        }

        view.innerHTML = html
    }
}