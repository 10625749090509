package main.kotlin.ui

import main.kotlin.data.Handbook
import main.kotlin.data.Placeholder
import main.kotlin.data.ProcedureCard
import main.kotlin.data.ProcedureSection
import org.w3c.dom.Document

private val Array<ProcedureCard>.selectorItems : Array<SelectorItem>
get()
{
    val list = mutableListOf<SelectorItem>()

    for ((index,procedure) in this.withIndex())
    {
        val name : MutableList<String> = mutableListOf()
        if (procedure.id.isBlank())
        {
            name.add(procedure.name)
        }
        else
        {
            name.add(procedure.chapter)
            val section = procedure.section
            if (section != null && section.isNotBlank())
                name.add(procedure.section)
            name.add(Handbook.tailorProcedureText(procedure.name, Placeholder.shared.trip))
        }

        list.add(SelectorItem("${procedure.name.toLowerCase()}",name.joinToString(" / "),"$index"))
    }

    return list.toTypedArray()
}

open class ProcedureSelector(document: Document, id:String, hint:String, var procedures:Array<ProcedureCard>,tooltip:String?=null) : Selector(document,id,hint,procedures.selectorItems,tooltip=tooltip)
{
    var selected : ProcedureCard?
    get()
    {
        val s = view.value as? String ?: return null
        if (s.isNullOrEmpty()) return null
        val index = s.toInt()
        if (index >= 0)
            return procedures[index]
        return null
    }
    set(value)
    {
        val index = procedures.indexOfFirst { it.name == value?.name }
        view.value = "$index"
    }

    fun setProcedures(procedures:Array<ProcedureCard>)
    {
        this.procedures = procedures
        setSelectorItems(procedures.selectorItems)
    }
}
