package models

import main.kotlin.comms.Requests
import main.kotlin.data.*
import main.kotlin.models.LeftSectionedScreen
import main.kotlin.models.UnsavedChanges
import main.kotlin.models.UserScreen
import main.kotlin.ui.*
import org.w3c.dom.*
import kotlin.browser.document
import kotlin.browser.window

interface ShipScreenSectionContainer {
    fun hint(id: String): String {
        return when (id) {
            "imo_number" -> "IMO Number"
            "call_sign" -> "Call sign"
            "flag" -> "Flag"
            "builder" -> "Builder"
            "hull_number" -> "Hull number"
            "delivery_date" -> "Delivery date"
            "classification_society" -> "Classification society"
            "class_notation" -> "Class notation"

            "length_overall" -> "Length overall (LOA)"
            "length_between_perpendiculars" -> "Length between perpendiculars (LBP)"
            "breath_max" -> "Breath max"
            "keel_to_mast" -> "Keel to mast"
            "keel_to_mast_in_collapsed_condition" -> "Keel to mast in collapsed condition"
            "distance_bow_to_mid_point_manifold" -> "Distance bow to mid-point manifold"
            "distance_stern_to_mid_point_manifold" -> "Distance stern to mid-point manifold"

            "number_of_cargo_tanks" -> "Number of cargo tanks"
            "cargo_tanks" -> "Cargo tanks (98%)"
            "cargo_tanks_inc_slops" -> "Cargo tanks incl slops (98%)"
            "number_of_slop_tanks" -> "Number of slop tanks"
            "slop_tanks" -> "Slop tanks (98%)"
            "fuel_tanks" -> "Fuel tank(s)"
            "gas_tanks" -> "Gas tank(s)"
            "total_volume_of_sbt_tanks" -> "Total volume of SBT tanks (100%)"
            "accommodation" -> "Accommodation"

            "gross_or_net_tonnage" -> "Gross / net tonnage"
            "suez_gross_or_net_tonnage" -> "Suez gross / net tonnage"
            "panama_or_net_tonnage" -> "Panama / net tonnage"
            "deadweight_summer" -> "Deadweight, summer"
            "draft_summer" -> "Draft, summer"
            "freeboard_summer" -> "Freeboard summer"
            "deadweight_normal_ballast" -> "Deadweight normal ballast"
            "draft_normal_ballast" -> "Draft normal ballast"
            "freeboard_normal_ballast" -> "Freeboard normal ballast"

            "system_design" -> "System design"
            "batteries" -> "Batteries"
            "fuel_system" -> "Fuel"
            "boilers" -> "Boilers"
            "fresh_water_generators" -> "Fresh Water Generators"
            "main_engine_total_output" -> "Total output"
            "main_engine_maker" -> "Maker"
            "main_engine_type" -> "Type"
            "auxiliary_engine_total_output" -> "Total output"
            "auxiliary_engine_maker" -> "Maker"
            "auxiliary_engine_type" -> "Type"
            "rudders" -> "Number and name"
            "steering_gears" -> "Number and name"
            "propeller_name" -> "Number and name"
            "propeller_maker" -> "Maker"
            "other" -> "Other"

            "radars" -> "Radars"
            "ecdis" -> "ECDIS"
            "gyro" -> "Gyro"
            "magnetic_compass" -> "Magnetic compass"
            "gps_or_dgps" -> "GPS/DGPS"
            "vdr" -> "VDR"
            "echo_sounder" -> "Echosounder"
            "ais" -> "AIS"
            "bnwas" -> "BNWAS"

            "maker" -> "DP system maker"
            "type" -> "Type"
            "no_of_OS" -> "Number of OS"
            "software" -> "Software"
            "drone" -> "Drone (ECC system)"
            "pasd" -> "PASD installed?"

            "maker" -> "Maker"
            "loading_capacity" -> "Loading capacity"
            "offloading_capacity" -> "Offloading capacity, if installed"
            "fairlead_door" -> "Fairlead door"
            "chain_stopper_capacity" -> "Capacity"
            "chain_stopper_alarm_high" -> "Alarm high"
            "chain_stopper_alarm_high_high" -> "Alarm high high"
            "alarm_setting_low" -> "Alarm setting low"
            "traction_winch_capacity" -> "Capacity"
            "traction_winch_break_holding_capacity" -> "Brake holding"
            "traction_winch_speed" -> "Speed"
            "traction_winch_drag_brake_pump" -> "Drag brake pump"
            "hhw_capacity" -> "Capacity"
            "hhw_brake_holding_capacity" -> "Brake holding capacity"
            "hhw_speed" -> "Speed"
            "coupler_generation" -> "Generation"
            "coupler_rotation" -> "Rotation"
            "coupler_claw_holding_pressure" -> "Coupler claw release"
            "lower_bow_door_type" -> "Lower bow door type: Normal or Service"
            "design_load" -> "Design load"
            "compatibility" -> "Compatibility:"
            "loadcell_for_horizontal_loads" -> "Loadcell for horizontal loads"
            "alarm_setting_for_horizontal_loads" -> "Alarm setting"
            "loadcell_for_vertical_loads" -> "Loadcell for vertical loads"
            "alarm_setting_for_vertical_loads" -> "Alarm setting"
            "pasd_installed" -> "PASD installed"
            "audio_or_visual_alarms" -> "Audio/Visual alarms"
            "crude_oil_pressure_no_of_transmitters" -> "No of transmitters"
            "crude_oil_pressure_alarm_setting" -> "Alarm setting"
            "crude_oil_pressure_voting" -> "Voting: 2 out of 3/Not installed"
            "crude_oil_pressure_offloading_pressure_transmitter" -> "Offloading pressure transmitter?"
            "crude_oil_pressure_alarm_settings" -> "Alarm settings"

            "cargo_pumps" -> "Cargo pumps (number x capacity)"
            "vacuum_stripping_units_number" -> "Vacuum stripping units number"
            "cargo_stripping_pumps" -> "Cargo stripping pumps (number x capacity)"
            "cargo_stripping_educators" -> "Cargo stripping educators (number x capacity)"
            "ballast_pumps" -> "Ballast pumps (number x capacity)"
            "ballast_educator" -> "Ballast educator (number x capacity)"
            "inert_gas_capacity" -> "Inert gas capacity"
            "inert_gas_supplied_by" -> "Inert gas supplied by"

            "fuel" -> "Fuel"
            "voc" -> "VOC"
            "engines" -> "Engines"
            "gas_turbine" -> "Gas turbine"
            "cargo_tanks" -> "Cargo tanks"

            "building_specifications" -> "Building specifications"

            else -> ""
        }
    }
}

open class ShipScreen(document: Document, private val sid: String, private val sname: String) :
    LeftSectionedScreen(document), TableViewDelegate, UnsavedChanges {
    override val activeTab: TopNavBarTab get() = TopNavBarTab.Ships

    val breadcrumbs = Breadcrumbs(
        document, "page_breadcrumbs", arrayOf(
            Breadcrumb("Vessels", "ships.html"), Breadcrumb
                ("${sname.replace("%20", " ")}", "")
        )
    )

    protected val navbar = NavBar(document, "page_navbar")
    private val saveButton = Button(document, "save_button", tooltip = "Save changes to this vessel")
    val deleteButton = Button(document, "delete_button", tooltip = "Delete this vessel")
    val historyButton = Button(document, "history_button", tooltip = "Operation Archive for this vessel")

    val alert = Alert(document)

    private val chaptersList: Array<Tab> = arrayOf(
        Tab("tab-basics", "Basics"),
        Tab("tab-general-information", "General Information"),
        Tab("tab-dimensions", "Dimensions"),
        Tab("tab-capacities", "Capacities"),
        Tab("tab-tonnages-and-loadline-information", "Tonnages & Loadline Information"),
        Tab("tab-machinery-systems", "Machinery Systems"),
        Tab("tab-navigation", "Navigation"),
        Tab("tab-dynamic-positioning", "Dynamic Positioning"),
        Tab("tab-bow-loading-system", "Bow Loading System"),
        Tab("tab-cargo-and-ballast-equipment", "Cargo & Ballast Equipment"),
        Tab("tab-environmental", "Environmental"),
        Tab("tab-design", "Design"),
        Tab("tab-enabled-handbooks", "Enabled Handbooks"),
        Tab("tab-active-operations", "Active Operations")
    )

    val chapters = TableView(document, this, "chapters")
    private var currentChapterId: String? = "tab-basics"

    private val basics = ShipScreenBasicsSection(document, "basics")

    private val generalInformation = ShipScreenSection(
        document, "general_information", arrayOf(
            "imo_number",
            "call_sign",
            "flag",
            "builder",
            "hull_number",
            "delivery_date",
            "classification_society",
            "class_notation"
        ), arrayOf("class_notation")
    )

    private val dimensions = ShipScreenSection(
        document, "dimensions", arrayOf(
            "length_overall",
            "length_between_perpendiculars",
            "breath_max",
            "keel_to_mast",
            "keel_to_mast_in_collapsed_condition",
            "distance_bow_to_mid_point_manifold",
            "distance_stern_to_mid_point_manifold"
        )
    )

    private val capacities = ShipScreenSection(
        document, "capacities", arrayOf(
            "number_of_cargo_tanks",
            "cargo_tanks",
            "cargo_tanks_inc_slops",
            "number_of_slop_tanks",
            "slop_tanks",
            "fuel_tanks",
            "gas_tanks",
            "total_volume_of_sbt_tanks",
            "accommodation"
        )
    )

    private val tonnagesAndLoadlineInformation = ShipScreenSection(
        document, "tonnages_and_loadline_information", arrayOf(
            "gross_or_net_tonnage",
            "suez_gross_or_net_tonnage",
            "panama_or_net_tonnage",
            "deadweight_summer",
            "draft_summer",
            "freeboard_summer",
            "deadweight_normal_ballast",
            "draft_normal_ballast",
            "freeboard_normal_ballast"
        )
    )

    private val machinerySystems = ShipScreenMachinerySystemSection(
        document, "machinery_systems", arrayOf(
            "system_design",
            "batteries",
            "fuel_system",
            "boilers",
            "fresh_water_generators",
            "main_engine_total_output",
            "main_engine_maker",
            "main_engine_type",
            "auxiliary_engine_total_output",
            "auxiliary_engine_maker",
            "auxiliary_engine_type",
            "rudders",
            "steering_gears",
            "propeller_name",
            "propeller_maker",
            "other"
        ), arrayOf(
            "system_design",
            "other"
        )
    )

    val navigation = ShipScreenSection(
        document, "navigation", arrayOf(
            "radars",
            "ecdis",
            "gyro",
            "magnetic_compass",
            "gps_or_dgps",
            "vdr",
            "echo_sounder",
            "ais",
            "bnwas"
        )
    )

    private val dynamicPositioning = ShipScreenDynamicPositioningSection(
        document, "dynamic_positioning", arrayOf(
            "maker",
            "type",
            "no_of_OS",
            "software",
            "drone",
            "pasd"
        )
    )

    private val bowLoadingSystem = ShipScreenSection(
        document, "bow_loading_system", arrayOf(
            "maker",
            "loading_capacity",
            "offloading_capacity",
            "fairlead_door",
            "chain_stopper_capacity",
            "chain_stopper_alarm_high",
            "chain_stopper_alarm_high_high",
            "alarm_setting_low",
            "traction_winch_capacity",
            "traction_winch_break_holding_capacity",
            "traction_winch_speed",
            "traction_winch_drag_brake_pump",
            "hhw_capacity",
            "hhw_brake_holding_capacity",
            "hhw_speed",
            "coupler_generation",
            "coupler_rotation",
            "coupler_claw_holding_pressure",
            "lower_bow_door_type",
            "design_load",
            "compatibility",
            "loadcell_for_horizontal_loads",
            "alarm_setting_for_horizontal_loads",
            "loadcell_for_vertical_loads",
            "alarm_setting_for_vertical_loads",
            "pasd_installed",
            "audio_or_visual_alarms",
            "crude_oil_pressure_no_of_transmitters",
            "crude_oil_pressure_alarm_setting",
            "crude_oil_pressure_voting",
            "crude_oil_pressure_offloading_pressure_transmitter",
            "crude_oil_pressure_alarm_settings"
        )
    )

    private val cargoAndBallastEquipment = ShipScreenSection(
        document, "cargo_and_ballast_equipment", arrayOf(
            "cargo_pumps",
            "vacuum_stripping_units_number",
            "cargo_stripping_pumps",
            "cargo_stripping_educators",
            "ballast_pumps",
            "ballast_educator",
            "inert_gas_capacity",
            "inert_gas_supplied_by"
        )
    )

    private val environmental = ShipScreenSection(
        document, "environmental", arrayOf(
            "fuel",
            "voc",
            "engines",
            "gas_turbine",
            "cargo_tanks"
        )
    )

    private val design = ShipScreenSection(
        document, "design", arrayOf(
            "building_specifications"
        ), arrayOf(
            "building_specifications"
        )
    )

    private val enabledHandbooks = ShipScreenEnabledHandbookSection(document, "handbooks")

    private val activeOperations = ShipScreenActiveOperationSection(document, "operations")

    init {
        saveButton.onclick = { save() }
        deleteButton.onclick = { delete() }
        historyButton.onclick = { history() }
        basics.name.onblur = { basics.validateName() }
        basics.vesselMasterEmail.onblur = { basics.validateEmail() }
    }

    override fun start() {
        super.start()

        for (cell in cells)
            cell.li.onclick = null
        cells.clear()

        chapters.reloadData()

        for (chapter in chaptersList) {
            val chapterId = chapter.id
            val cell = ChapterCell(chapterId)
            cell.li.onclick = { clickedChapter(chapterId) }
            cells.add(cell)
        }

        reloadCurrentChapter()

        Requests.listShipTypes2(this) { shipTypesRes ->
            basics.shipTypes = shipTypesRes.shipTypes.toList()
            val sel = basics.typeSelector.selected
            basics.typeSelector.setTypes(basics.shipTypes)
            basics.typeSelector.selected = sel

            Requests.listShipClasses2(this) { shipClassesRes ->
                basics.shipClasses = shipClassesRes.shipClasses.toList()
                val sel = basics.classSelector.selected
                basics.classSelector.setClasses(basics.shipClasses)
                basics.classSelector.selected = sel

                Requests.listRegions2(this) { shipRegionsRes ->
                    basics.shipRegions = shipRegionsRes.shipRegions.toList()
                    val sel = basics.regionSelector.selected
                    basics.regionSelector.setRegions(basics.shipRegions)
                    basics.regionSelector.selected = sel

                    Requests.listHandbooks(this) { hbResponse ->
                        enabledHandbooks.refreshed(null, hbResponse.handbooks.filter { it.published }.toTypedArray())
                        refresh()
                    }

                }
            }
        }
    }

    open fun refresh() {
        val id = sid

        loading = true

        Requests.getShipAndActiveOperationHandbooks(this, id)
        {
            loading = false
            val ship = it.ship
            if (ship != null) {
                handbooks = it.handbooks.filter { it.published }.toTypedArray()
                saveButton.hidden = false

                refreshed(ship)

                enabledHandbooks.refreshed(ship, handbooks)

                activeOperations.refreshed(ship, handbooks)

                hasChanges = false
            } else {
                content.hidden = true
                error.hidden = false
                saveButton.hidden = true
                error.text = "Failed to load vessel info"
            }
        }
    }

    fun changed() {
        hasChanges = true
    }

    private var _hasChanges: Boolean = false
    var hasChanges: Boolean
        get() {
            return _hasChanges
        }
        set(value) {
            _hasChanges = value; updateOnBeforeUnload()
        }

    open fun updateOnBeforeUnload() {
        if (hasChanges)
            window.onbeforeunload = { "There are unsaved changes. Are you sure that you want to leave this page?" }
        else
            window.onbeforeunload = null
    }

    var ship: Ship? = null
    var handbooks: Array<Handbook> = arrayOf()

    fun refreshed(data: Ship) {
        ship = data

        navbar.title = data.name

        basics.update(data)

        generalInformation.update(data.generalInformation)

        dimensions.update(data.dimensions)

        capacities.update(data.capacities)

        tonnagesAndLoadlineInformation.update(data.tonnagesAndLoadlineInformation)

        machinerySystems.update(data.machinerySystems)

        dynamicPositioning.update(data.dynamicPositioning)

        bowLoadingSystem.update(data.bowLoadingSystem)

        cargoAndBallastEquipment.update(data.cargoAndBallastEquipment)

        design.update(data.design)

        environmental.update(data.environmental)

        navigation.update(data.navigation)
    }

    fun updates(ship: Ship): ItemUpdates {
        val updates = Any().asDynamic()
        var hasChanges = false

        fun addIfChanged(named: String, current: String, change: String) {
            if (current != change) {
                updates[named] = change
                hasChanges = true
            }
        }

        fun addIfChanged(named: String, current: List<String>, change: List<String>) {
            if (current != change) {
                updates[named] = change.toTypedArray()
                hasChanges = true
            }
        }

        fun addIfChanged(named: String, current: Boolean, change: Boolean) {
            if (current != change) {
                updates[named] = change
                hasChanges = true
            }
        }

        fun addIfChanged(current: ShipSection, change: ShipScreenSection) {
            var usedKeys: MutableList<String> = mutableListOf()
            for (cur in current.data) {
                val currentValue = cur.value
                val changeValue = change.fields[cur.key]?.text ?: ""
                addIfChanged("${current.name}.${cur.key}", currentValue, changeValue)
                usedKeys.add(cur.key)
            }

            val others = change.fields.keys.subtract(usedKeys)
            for (key in others) {
                val named = "${current.name}.$key"
                val changedValue = change.fields[key]?.text ?: ""
                updates[named] = changedValue
                hasChanges = true
            }
        }

        fun <T> hasChanged(current:List<T>,change:List<T>) : Boolean
        {
            if (current.count() != change.count()) return true
            if (current != change) return true
            return false
        }

        fun addIfChangedM(current: ShipMachinerySystem, change: ShipScreenMachinerySystemSection) {
            addIfChanged(current, change)
            if (hasChanged(current.thrusters.thruster, change.thruster.list)) {
                val data = change.thruster.list.map { it.toDynamic() }.asDynamic()
                updates["${current.name}.thrusters"] = data
                hasChanges = true
            }
        }

        fun addIfChangedDP(current: ShipDynamicPositioning, change: ShipScreenDynamicPositioningSection) {
            addIfChanged(current, change)
            if (hasChanged(current.dpBuoys.dpbuoy, change.dpBuoy.list)) {
                val data = change.dpBuoy.list.map { it.toDynamic() }.asDynamic()
                updates["${current.name}.dp_buoys"] = data
                hasChanges = true
            }

            if (hasChanged(current.referenceSystems.referencesystem, change.referenceSystem.list)) {
                val data = change.referenceSystem.list.map { it.toDynamic() }.asDynamic()
                updates["${current.name}.reference_systems"] = data
                hasChanges = true
            }

            if (hasChanged(current.gpSystems.GPS, change.gps.list)) {
                val data = change.gps.list.map { it.toDynamic() }.asDynamic()
                updates["${current.name}.gps"] = data
                hasChanges = true
            }

            if (hasChanged(current.hprs.HPR, change.hpr.list)) {
                val data4 = change.hpr.list.map { it.toDynamic() }.asDynamic()
                updates["${current.name}.hpr"] = data4
                hasChanges = true
            }

            if (hasChanged(current.hains.hain, change.hain.list)) {
                val data5 = change.hain.list.map { it.toDynamic() }.asDynamic()
                updates["${current.name}.hain"] = data5
                hasChanges = true
            }
        }

        addIfChanged("document_type", ship.documentType, "ship")
        addIfChanged("name", ship.name, basics.name.text)
        addIfChanged("type", ship.type, basics.typeSelector.selected.toString())
        addIfChanged("class", ship.classe, basics.classSelector.selected.toString())
        addIfChanged("region", ship.region, basics.regionSelector.selected.toString())
        addIfChanged("vessel_master_email", ship.vesselMasterEmail, basics.vesselMasterEmail.text)
        addIfChanged("disabled", ship.disabled, basics.disabled.checked)
        addIfChanged(ship.generalInformation, generalInformation)
        addIfChanged(ship.dimensions, dimensions)
        addIfChanged(ship.capacities, capacities)
        addIfChangedDP(ship.dynamicPositioning, dynamicPositioning)
        addIfChanged(ship.bowLoadingSystem, bowLoadingSystem)
        addIfChanged(ship.tonnagesAndLoadlineInformation, tonnagesAndLoadlineInformation)
        addIfChangedM(ship.machinerySystems, machinerySystems)
        addIfChanged(ship.navigation, navigation)
        addIfChanged(ship.cargoAndBallastEquipment, cargoAndBallastEquipment)
        addIfChanged(ship.environmental, environmental)
        addIfChanged(ship.design, design)

        val handbooks: MutableList<String> = mutableListOf()
        for (handbookSwitch in enabledHandbooks.handbookSwitches) {
            val handbookId = handbookSwitch.id.removePrefix("handbook-")
            if (handbookSwitch.checked)
                handbooks.add(handbookId)
        }
        addIfChanged("handbooks", ship.handbooks, handbooks)

        basics.validateData()

        if (!basics.name.isValid)
            return ItemUpdates(error = basics.name.validationMessage)

        if(!basics.vesselMasterEmail.isValid)
            return ItemUpdates(error = basics.vesselMasterEmail.validationMessage)

        if (hasChanges)
            return ItemUpdates(data = updates)
        return ItemUpdates(error = "No changes to save")
    }

    open fun save() {
        val id = sid
        val ship = ship ?: return

        val updates = updates(ship)
        val data = updates.data
        if (data == null) {
            showToast(updates.error ?: "No changes to save")
            return
        }

        loading = true

        Requests.updateShip(this, id, data) {
            loading = false
            if (it.error != null) {
                showToast(it, "Failed to change vessel info")
            } else {
                showToast(it, "Vessel info changed!")
                refresh()
            }
        }
    }

    fun delete(confirmed: Boolean = false) {
        if (confirmed) {
            loading = true
            Requests.deleteShip(this, sid)
            {
                loading = false
                if (it.error != null) {
                    showToast(it, "Failed to delete vessel")
                } else {
                    showToast(it, "Vessel deleted!")
                    pushTo("ships")
                }
            }
        } else {
            alert.open(
                "Delete Vessel",
                "Are you sure you would like to delete this vessel?",
                arrayOf(AlertAction("cancel", "Cancel"), AlertAction("delete", "Delete"))
            )
            {
                if (it == "delete") {
                    delete(true)
                }
            }
        }
    }

    fun history() {
        val s = ship?.params ?: return
        pushToHtml("ship_history.html?ship=$s")
    }

    var cells: MutableList<ChapterCell> = mutableListOf()

    class ChapterCell(val id: String) {
        val li = Li(document, "cell-$id")
        val label = Span(document, "cell-$id-label")
    }

    fun clickedChapter(id: String) {
        println("Clicked $id")
        currentChapterId = id
        reloadCurrentChapter()
    }

    fun reloadCurrentChapter() {
        for (cell in cells) {
            val shouldShow = currentChapterId == cell.id
            if (shouldShow)
                cell.label.addClasses(arrayOf("current-selection"))
            else
                cell.label.removeClasses(arrayOf("current-selection"))

            val div = Div(document, cell.id)
            div.hidden = !shouldShow
        }
    }

    override val numberOfRows: Int
        get() {
            return chaptersList.count()
        }

    override fun cellForRow(at: Int): String {
        val chapter = chaptersList[at]
        return """
        <li class="mdc-list-item" id="cell-${chapter.id}">
            <span class="mdc-list-item__text" id="cell-${chapter.id}-label">${chapter.name}</span>
        </li>
        """
    }
}

class ShipScreenBasicsSection(document: Document, val id: String) : UnsavedChanges {
    val name = EditText(document, "name", "Name", required = true, helperTextId = "name_helper_text")
    var shipTypes: List<ShipType> = listOf()
    val typeSelector = ShipTypeSelector(document, "type", "Type", shipTypes, SelectorNotSelectedMode.None)
    var shipClasses: List<ShipClass> = listOf()
    val classSelector = ShipClassSelector(document, "class", "Class", shipClasses, SelectorNotSelectedMode.None)
    var shipRegions: List<ShipRegion> = listOf()
    val regionSelector = ShipRegionSelector(document, "region", "Region", shipRegions, SelectorNotSelectedMode.None)
    val disabled = Switch(
        document,
        "disabled",
        "Disabled",
        tooltip = "Disabled vessels will not show up when selecting which vessel the SeaPad application is assigned to"
    )

    val vesselMasterEmail = EditText(
        document,
        "vessel_master",
        "Vessel Master Email",
        tooltip = "If this is set, users are able to reset a forgotten pin code, which is sent to this email address",
        helperTextId = "email_helper_text"
    )

    fun update(data: Ship) {
        name.textChanged = null
        typeSelector.valueChanged = null
        classSelector.valueChanged = null
        regionSelector.valueChanged = null
        disabled.valueChanged = null
        vesselMasterEmail.textChanged = null

        name.text = data.name
        typeSelector.selected = data.type
        classSelector.selected = data.classe
        regionSelector.selected = data.region
        disabled.checked = data.disabled
        vesselMasterEmail.text = data.vesselMasterEmail

        name.textChanged = { validateName(); fieldChanged(it) }
        typeSelector.valueChanged = { hasUnsavedChanges = true }
        classSelector.valueChanged = { hasUnsavedChanges = true }
        regionSelector.valueChanged = { hasUnsavedChanges = true }
        disabled.valueChanged = { hasUnsavedChanges = true }
        vesselMasterEmail.textChanged = { validateEmail(); fieldChanged(it) }
    }

    private fun fieldChanged(text: String) {
        hasUnsavedChanges = true
    }

    fun validateName() {
        if (name.text.isEmpty()) name.setValidation("Name is required")
        else if (!name.text.matches("^[a-zA-Z.ÅåØøÆæ0-9\\- ]+$".toRegex())) name.setValidation("Enter name in correct format e.g. Altera Infra Vessel 2.0");
        else name.setValidation("");
    }

    fun validateEmail() {
        if (vesselMasterEmail.text.isNotEmpty() && !vesselMasterEmail.text.matches("^[a-zA-Z0-9_.-]+@[a-zA-Z-]+\\.[a-zA-Z.]+\$".toRegex())) vesselMasterEmail.setValidation("Enter email in correct format e.g. xyz@alterainfra.com");
        else vesselMasterEmail.setValidation("");
    }

    fun validateData() {
        validateName()
        validateEmail()
    }

}

class ShipScreenEnabledHandbookSection(document: Document, val id: String) : UnsavedChanges {
    private val handbooksContainer = Div(document, "tab-enabled-handbooks")
    val handbookSwitches: MutableList<Switch> = mutableListOf()

    var ship: Ship? = null
    var handbooks: Array<Handbook> = arrayOf()

    fun refreshed(ship: Ship?, handbooks: Array<Handbook>) {
        this.ship = ship
        this.handbooks = handbooks
        reloadHandbooks()
    }

    private fun reloadHandbooks() {
        handbookSwitches.clear()

        val data = ship
        /*if (data == null) {
            handbooksContainer.html = ""
            return
        }*/

        var html = """<div class="page_fields-title">Enabled Handbooks</div>"""

        html += """<div class="page_fields_improved">"""

        for (handbook in handbooks) {
            html += """<div id="handbook-${handbook.id}"></div>"""
        }
        html += "</div>"
        handbooksContainer.html = html

        for (handbook in handbooks) {
            val switch = Switch(document, "handbook-${handbook.id}", handbook.descriptiveName)
            if (data != null) {
                switch.checked = data.supports(handbook.id)
                switch.disabled = data.operations[handbook.id]?.trip != null
                switch.valueChanged = { hasUnsavedChanges = true }
            }
            handbookSwitches.add(switch)
        }
    }

}

class ShipScreenActiveOperationSection(document: Document, val id: String) : UnsavedChanges {
    private val activeOperations: HTMLDivElement = document.getElementById("tab-active-operations") as HTMLDivElement

    var ship: Ship? = null
    var handbooks: Array<Handbook> = arrayOf()

    fun refreshed(ship: Ship, handbooks: Array<Handbook>) {
        this.ship = ship
        this.handbooks = handbooks
        reloadActiveOperations()
    }

    fun reloadActiveOperations() {
        var html = ""

        val ship = ship

        if (ship == null) {
            activeOperations.innerHTML = html
            return
        }

        for (handbook in handbooks.filter { ship.supports(it.id) }) {
            val state = ship.state(handbook.id) ?: ShipOperationState()

            html += """<div class="ship-active_operation_section">"""

            html += """<div class="ship-active_operation_section_title">${handbook.name}</div>"""

            if (handbook.featureFlags.contains(HandbookFeature.Loadable)) {
                html += "<p><b>Load Count: </b>${state.getLoadCount()}</p>"
            }

            val activeOperations = state.activeOperations 

            if (activeOperations.any()) {
                activeOperations.forEach { operation ->
                    html += """<div class="ship-active_operation_section-operation">"""
                    html += "<p><b>Operation Started: </b>${operation?.initiated?.description}</p>"
                    val voyageNumber = operation.voyageNumber
                    if (voyageNumber != null) {
                        html += "<p><b>Voyage Number: </b>$voyageNumber</p>"
                    }
                    val destination = operation.destinationName ?: operation.destination
                    if (destination != null) {
                        html += "<p><b>Destination: </b>$destination</p>"
                    }
                    val trip = operation.trip
                    if (trip != null) {
                        val hb = handbook
                        hb.shipId = ship.id
                        hb.shipName = ship.name
                        hb.tripId = trip
                        hb.operationName = operation.destinationName ?: operation.voyageNumber
                        html += """<a href="procedures.html?handbook=${operation.asHandbookParams(hb)}">View Procedures / Checklists</a>"""
                    }
                    html += "</div>"
                }
            } else {
                html += "<p>No active operations</p>"
            }

            html += "</div>"
        }

        if (html.isNotEmpty())
            html = """<div class="ship-section_title">Current Operations</div>$html"""
        activeOperations.innerHTML = html
    }

}

open class ShipScreenSection(
    document: Document,
    val name: String,
    val ids: Array<String>,
    areas: Array<String> = arrayOf()
) : ProcedureConfig, ShipScreenSectionContainer, UnsavedChanges {
    var fields: MutableMap<String, TextField> = mutableMapOf()

    override val handbook = Handbook("whatever", "Whatever", true)

    init {
        for (id in ids) {
            val field = if (areas.contains(id)) EditTextArea(document, "$name.$id", hint(id)) else EditText(
                document,
                "$name.$id",
                hint(id)
            )
            fields[id] = field
        }
    }

    open fun update(section: ShipSection) {
        for (item in fields) {
            item.value.textChanged = null
            val v = section.data[item.key]
            item.value.text = v ?: ""
            item.value.textChanged = { fieldChanged(it) }
        }
    }

    private fun fieldChanged(text: String) {
        hasUnsavedChanges = true
    }
}

class ShipScreenMachinerySystemSection(
    document: Document,
    name: String,
    ids: Array<String>,
    areas: Array<String> = arrayOf()
) : ShipScreenSection(document, name, ids, areas) {

    class ThrustersField(document: Document, val name: String, private val field_name: String) : TableViewDelegate,
        UnsavedChanges {
        val table = TableView(document, this, "thrusters")
        val list: MutableList<ShipThruster> = mutableListOf()
        val cells: MutableList<ThrusterCell> = mutableListOf()

        private val thrusterName = EditText(document, "thruster.name", "Thruster name")
        private val thrusterMaker = EditText(document, "thruster.maker", "Maker")
        private val thrusterType = EditText(document, "thruster.type", "Type")
        val createButton = Button(document, "thruster.create_button")

        init {
            createButton.onclick = { createThruster() }
        }

        private fun createThruster() {
            val name = thrusterName.text
            val maker = thrusterMaker.text
            val type = thrusterType.text

            if (name.isEmpty()) return

            val thruster = ShipThruster(name, maker, type)
            list.add(thruster)

            reloadData()

            hasUnsavedChanges = true

            thrusterName.text = ""
            thrusterMaker.text = ""
            thrusterType.text = ""
        }

        fun update(data: List<ShipThruster>) {
            list.clear()
            list.addAll(data)

            reloadData()
        }

        fun reloadData() {
            table.reloadData()
            reloadCells()
        }

        private fun clickedThruster(at: Int) {
            for ((index, cell) in cells.withIndex()) {
                if (index != at)
                    cell.content.hidden = true
                else
                    cell.content.hidden = !cell.content.hidden
            }
        }

        private fun openThruster(at: Int) {
            for ((index, cell) in cells.withIndex()) {
                cell.content.hidden = index != at
            }
        }

        private fun clickedDelete(at: Int) {
            list.removeAt(at)
            reloadData()
            hasUnsavedChanges = true
        }

        private fun clickedUp(at: Int) {
            val section = list.removeAt(at)
            list.add(at - 1, section)
            reloadData()
            openThruster(at - 1)
            hasUnsavedChanges = true
        }

        private fun clickedDown(at: Int) {
            val section = list.removeAt(at)
            list.add(at + 1, section)
            reloadData()
            openThruster(at + 1)
            hasUnsavedChanges = true
        }

        private fun reloadCells() {
            cells.map { it.cleanup() }
            cells.clear()

            for ((index, _) in list.withIndex()) {
                val cell = ThrusterCell(name, field_name, index)
                cell.cell.onclick = { clickedThruster(index) }
                cell.deleteButton.onclick = { clickedDelete(index) }
                if (index != 0) {
                    cell.upButton.onclick = { clickedUp(index) }
                } else {
                    cell.upButton.hidden = true
                }
                if (index != list.lastIndex) {
                    cell.downButton.onclick = { clickedDown(index) }
                } else {
                    cell.downButton.hidden = true
                }
                cells.add(cell)
            }
        }

        override fun cellForRow(at: Int): String {
            val thruster = list[at]
            return ThrusterCell.markup(thruster, name, field_name, at)
        }

        override val numberOfRows: Int
            get() {
                return list.count()
            }


    }

    val thruster = ThrustersField(document, name, "thrusters")

    fun update(data: ShipMachinerySystem) {
        super.update(data)
        thruster.update(data.thrusters.thruster)
    }
}

class ThrusterCell(name: String, field_name: String, at: Int) {
    val cellId: String = "cell-thrusters-$at"
    val cell = Li(document, "$cellId-cell")
    val content = Div(document, "$cellId-content", "inline-block")
    val deleteButton = Button(document, "$cellId-delete_button")
    val upButton = Button(document, "$cellId-up_button")
    val downButton = Button(document, "$cellId-down_button")

    fun cleanup() {
        deleteButton.onclick = null
        upButton.onclick = null
        downButton.onclick = null
        cell.onclick = null
    }

    companion object {
        fun markup(thruster: ShipThruster, name: String, field_name: String, at: Int): String {
            val contentStyle = "style='display:none;'"

            return """

            <li class="mdc-list-item" id="cell-thrusters-$at-cell">
                <span class="mdc-list-item__text">
                    <span class="mdc-list-item__primary-text">Name: ${thruster.name}</span>
                    <span class="mdc-list-item__secondary-text">Maker: ${thruster.maker} , Type: ${thruster.type}</span>
                    <div id="cell-thrusters-$at-content" $contentStyle>
                        <div class="thruster-cell-buttons">
                            <button class="mdc-button" id="cell-thrusters-$at-delete_button"><i class="material-icons mdc-button__icon" aria-hidden="true">delete</i>Delete</button>
                            <button class="mdc-button" id="cell-thrusters-$at-up_button"><i class="material-icons mdc-button__icon" aria-hidden="true">arrow_upward</i>Up</button>
                            <button class="mdc-button" id="cell-thrusters-$at-down_button"><i class="material-icons mdc-button__icon" aria-hidden="true">arrow_downward</i>Down</button>
                        </div>
                    </div>

                </span>
            </li>
            """
        }
    }
}

class ShipScreenDynamicPositioningSection(
    document: Document,
    name: String,
    ids: Array<String>,
    areas: Array<String> = arrayOf()
) : ShipScreenSection(document, name, ids, areas) {
    class DPBuoysField(document: Document, val name: String, private val field_name: String) : TableViewDelegate,
        UnsavedChanges {
        val table = TableView(document, this, "dpbuoys")
        val list: MutableList<ShipDPBuoy> = mutableListOf()
        val cells: MutableList<DPBuoyCell> = mutableListOf()

        private val dpLocation = EditText(document, "dpbuoy.location", "Location")
        private val dpType = EditText(document, "dpbuoy.type", "Type")
        val createButton = Button(document, "dpbuoy.create_button")

        init {
            createButton.onclick = { createDPBuoy() }
        }

        private fun createDPBuoy() {
            val location = dpLocation.text
            val type = dpType.text

            if (location.isEmpty()) return

            val dpBouy = ShipDPBuoy(location, type)
            list.add(dpBouy)

            reloadData()

            hasUnsavedChanges = true

            dpLocation.text = ""
            dpType.text = ""
        }

        fun update(data: List<ShipDPBuoy>) {
            list.clear()
            list.addAll(data)

            reloadData()
        }

        fun reloadData() {
            table.reloadData()
            reloadCells()
        }

        private fun clickedDPBuoy(at: Int) {
            for ((index, cell) in cells.withIndex()) {
                if (index != at)
                    cell.content.hidden = true
                else
                    cell.content.hidden = !cell.content.hidden
            }
        }

        private fun openDPBuoy(at: Int) {
            for ((index, cell) in cells.withIndex()) {
                cell.content.hidden = index != at
            }
        }

        private fun clickedDelete(at: Int) {
            list.removeAt(at)
            reloadData()
            hasUnsavedChanges = true
        }

        private fun clickedUp(at: Int) {
            val section = list.removeAt(at)
            list.add(at - 1, section)
            reloadData()
            openDPBuoy(at - 1)
            hasUnsavedChanges = true
        }

        private fun clickedDown(at: Int) {
            val section = list.removeAt(at)
            list.add(at + 1, section)
            reloadData()
            openDPBuoy(at + 1)
            hasUnsavedChanges = true
        }

        private fun reloadCells() {
            cells.map { it.cleanup() }
            cells.clear()

            for ((index, _) in list.withIndex()) {
                val cell = DPBuoyCell(name, field_name, index)
                cell.cell.onclick = { clickedDPBuoy(index) }
                cell.deleteButton.onclick = { clickedDelete(index) }
                if (index != 0) {
                    cell.upButton.onclick = { clickedUp(index) }
                } else {
                    cell.upButton.hidden = true
                }
                if (index != list.lastIndex) {
                    cell.downButton.onclick = { clickedDown(index) }
                } else {
                    cell.downButton.hidden = true
                }
                cells.add(cell)
            }
        }

        override fun cellForRow(at: Int): String {
            val dpBouy = list[at]
            return DPBuoyCell.markup(dpBouy, name, field_name, at)
        }

        override val numberOfRows: Int
            get() {
                return list.count()
            }
    }

    class ReferenceSystemField(document: Document, val name: String, private val field_name: String) :
        TableViewDelegate, UnsavedChanges {
        val table = TableView(document, this, "referencesystems")
        val list: MutableList<ShipReferenceSystem> = mutableListOf()
        val cells: MutableList<ReferenceSystemCell> = mutableListOf()

        private val refType = EditText(document, "refsystem.type", "Type")
        private val refMaker = EditText(document, "refsystem.maker", "Maker")
        private val refAntennas = EditText(document, "refsystem.antennas", "Antenna(s)")
        private val refcoverageSector = EditText(document, "refsystem.coveragesector", "Coverage sector")
        val createButton = Button(document, "refsystem.create_button")

        init {
            createButton.onclick = { createRefSystem() }
        }

        private fun createRefSystem() {
            val type = refType.text
            val maker = refMaker.text
            val antennas = refAntennas.text
            val coverage = refcoverageSector.text

            if (type.isEmpty()) return

            val referenceSystem = ShipReferenceSystem(type, maker, antennas, coverage)
            list.add(referenceSystem)

            reloadData()

            hasUnsavedChanges = true

            refType.text = ""
            refMaker.text = ""
            refAntennas.text = ""
            refcoverageSector.text = ""
        }

        fun update(data: List<ShipReferenceSystem>) {
            list.clear()
            list.addAll(data)

            reloadData()
        }

        fun reloadData() {
            table.reloadData()
            reloadCells()
        }

        private fun clickedRefSystem(at: Int) {
            for ((index, cell) in cells.withIndex()) {
                if (index != at)
                    cell.content.hidden = true
                else
                    cell.content.hidden = !cell.content.hidden
            }
        }

        private fun openRefSystem(at: Int) {
            for ((index, cell) in cells.withIndex()) {
                cell.content.hidden = index != at
            }
        }

        private fun clickedDelete(at: Int) {
            list.removeAt(at)
            reloadData()
            hasUnsavedChanges = true
        }

        private fun clickedUp(at: Int) {
            val section = list.removeAt(at)
            list.add(at - 1, section)
            reloadData()
            openRefSystem(at - 1)
            hasUnsavedChanges = true
        }

        private fun clickedDown(at: Int) {
            val section = list.removeAt(at)
            list.add(at + 1, section)
            reloadData()
            openRefSystem(at + 1)
            hasUnsavedChanges = true
        }

        private fun reloadCells() {
            cells.map { it.cleanup() }
            cells.clear()

            for ((index, _) in list.withIndex()) {
                val cell = ReferenceSystemCell(name, field_name, index)
                cell.cell.onclick = { clickedRefSystem(index) }
                cell.deleteButton.onclick = { clickedDelete(index) }
                if (index != 0) {
                    cell.upButton.onclick = { clickedUp(index) }
                } else {
                    cell.upButton.hidden = true
                }
                if (index != list.lastIndex) {
                    cell.downButton.onclick = { clickedDown(index) }
                } else {
                    cell.downButton.hidden = true
                }
                cells.add(cell)
            }
        }

        override fun cellForRow(at: Int): String {
            val referenceSystem = list[at]
            return ReferenceSystemCell.markup(referenceSystem, name, field_name, at)
        }

        override val numberOfRows: Int
            get() {
                return list.count()
            }
    }

    class GPSField(document: Document, val name: String, private val field_name: String) : TableViewDelegate,
        UnsavedChanges {
        val table = TableView(document, this, "gps")
        val list: MutableList<ShipGPS> = mutableListOf()
        val cells: MutableList<GPSCell> = mutableListOf()

        private val GPSName = EditText(document, "gps.name", "Name")
        private val GPSType = EditText(document, "gps.type", "Type")
        private val GPSMaker = EditText(document, "gps.maker", "Maker")
        private val GPSTransmitters = EditTextArea(document, "gps.transmitters", "Transmitters")
        private val GPSSignals = EditTextArea(document, "gps.signals", "Correction signals")
        val createButton = Button(document, "gps.create_button")

        init {
            createButton.onclick = { createGPS() }
        }

        private fun createGPS() {
            val name = GPSName.text
            val type = GPSType.text
            val maker = GPSMaker.text
            val transmitters = GPSTransmitters.text
            val signals = GPSSignals.text

            if (name.isEmpty()) return

            val gps = ShipGPS(name, type, maker, transmitters, signals)
            list.add(gps)

            reloadData()

            hasUnsavedChanges = true

            GPSName.text = ""
            GPSType.text = ""
            GPSMaker.text = ""
            GPSTransmitters.text = ""
            GPSSignals.text = ""
        }

        fun update(data: List<ShipGPS>) {
            list.clear()
            list.addAll(data)

            reloadData()
        }

        fun reloadData() {
            table.reloadData()
            reloadCells()
        }

        private fun clickedGPS(at: Int) {
            for ((index, cell) in cells.withIndex()) {
                if (index != at)
                    cell.content.hidden = true
                else
                    cell.content.hidden = !cell.content.hidden
            }
        }

        private fun openGPS(at: Int) {
            for ((index, cell) in cells.withIndex()) {
                cell.content.hidden = index != at
            }
        }

        private fun clickedDelete(at: Int) {
            list.removeAt(at)
            reloadData()
            hasUnsavedChanges = true
        }

        private fun clickedUp(at: Int) {
            val section = list.removeAt(at)
            list.add(at - 1, section)
            reloadData()
            openGPS(at - 1)
            hasUnsavedChanges = true
        }

        private fun clickedDown(at: Int) {
            val section = list.removeAt(at)
            list.add(at + 1, section)
            reloadData()
            openGPS(at + 1)
            hasUnsavedChanges = true
        }

        private fun reloadCells() {
            cells.map { it.cleanup() }
            cells.clear()

            for ((index, _) in list.withIndex()) {
                val cell = GPSCell(name, field_name, index)
                cell.cell.onclick = { clickedGPS(index) }
                cell.deleteButton.onclick = { clickedDelete(index) }
                if (index != 0) {
                    cell.upButton.onclick = { clickedUp(index) }
                } else {
                    cell.upButton.hidden = true
                }
                if (index != list.lastIndex) {
                    cell.downButton.onclick = { clickedDown(index) }
                } else {
                    cell.downButton.hidden = true
                }
                cells.add(cell)
            }
        }

        override fun cellForRow(at: Int): String {
            val gps = list[at]
            return GPSCell.markup(gps, name, field_name, at)
        }

        override val numberOfRows: Int
            get() {
                return list.count()
            }

    }

    class HPRField(document: Document, val name: String, private val field_name: String) : TableViewDelegate,
        UnsavedChanges {
        val table = TableView(document, this, "hpr")
        val list: MutableList<ShipHPR> = mutableListOf()
        val cells: MutableList<HPRCell> = mutableListOf()

        private val HPRName = EditText(document, "hpr.name", "Name")
        private val HPRType = EditText(document, "hpr.type", "Type")
        private val HPRMaker = EditText(document, "hpr.maker", "Maker")
        private val HPRTransducer = EditText(document, "hpr.transducer", "Transducer")
        val createButton = Button(document, "hpr.create_button")

        init {
            createButton.onclick = { createHPR() }
        }

        private fun createHPR() {
            val name = HPRName.text
            val type = HPRType.text
            val maker = HPRMaker.text
            val transducer = HPRTransducer.text

            if (name.isEmpty()) return

            val gps = ShipHPR(name, type, maker, transducer)
            list.add(gps)

            reloadData()

            hasUnsavedChanges = true

            HPRName.text = ""
            HPRType.text = ""
            HPRMaker.text = ""
            HPRTransducer.text = ""
        }

        fun update(data: List<ShipHPR>) {
            list.clear()
            list.addAll(data)

            reloadData()
        }

        fun reloadData() {
            table.reloadData()
            reloadCells()
        }

        private fun clickedHPR(at: Int) {
            for ((index, cell) in cells.withIndex()) {
                if (index != at)
                    cell.content.hidden = true
                else
                    cell.content.hidden = !cell.content.hidden
            }
        }

        private fun openHPR(at: Int) {
            for ((index, cell) in cells.withIndex()) {
                cell.content.hidden = index != at
            }
        }

        private fun clickedDelete(at: Int) {
            list.removeAt(at)
            reloadData()
            hasUnsavedChanges = true
        }

        private fun clickedUp(at: Int) {
            val section = list.removeAt(at)
            list.add(at - 1, section)
            reloadData()
            openHPR(at - 1)
            hasUnsavedChanges = true
        }

        private fun clickedDown(at: Int) {
            val section = list.removeAt(at)
            list.add(at + 1, section)
            reloadData()
            openHPR(at + 1)
            hasUnsavedChanges = true
        }

        private fun reloadCells() {
            cells.map { it.cleanup() }
            cells.clear()

            for ((index, _) in list.withIndex()) {
                val cell = HPRCell(name, field_name, index)
                cell.cell.onclick = { clickedHPR(index) }
                cell.deleteButton.onclick = { clickedDelete(index) }
                if (index != 0) {
                    cell.upButton.onclick = { clickedUp(index) }
                } else {
                    cell.upButton.hidden = true
                }
                if (index != list.lastIndex) {
                    cell.downButton.onclick = { clickedDown(index) }
                } else {
                    cell.downButton.hidden = true
                }
                cells.add(cell)
            }
        }

        override fun cellForRow(at: Int): String {
            val hpr = list[at]
            return HPRCell.markup(hpr, name, field_name, at)
        }

        override val numberOfRows: Int
            get() {
                return list.count()
            }
    }

    class HainField(document: Document, val name: String, private val field_name: String) : TableViewDelegate,
        UnsavedChanges {
        val table = TableView(document, this, "hain")
        val list: MutableList<ShipHain> = mutableListOf()
        val cells: MutableList<HainCell> = mutableListOf()

        private val hainType = EditText(document, "hain.type", "Type")
        private val hainMaker = EditText(document, "hain.maker", "Maker")
        private val hainIMU = EditText(document, "hain.imu", "IMU")
        val createButton = Button(document, "hain.create_button")

        init {
            createButton.onclick = { createHain() }
        }

        private fun createHain() {
            val type = hainType.text
            val maker = hainMaker.text
            val imu = hainIMU.text

            if (type.isEmpty()) return

            val hain = ShipHain(type, maker, imu)
            list.add(hain)

            reloadData()

            hasUnsavedChanges = true

            hainType.text = ""
            hainMaker.text = ""
            hainIMU.text = ""
        }

        fun update(data: List<ShipHain>) {
            list.clear()
            list.addAll(data)

            reloadData()
        }

        fun reloadData() {
            table.reloadData()
            reloadCells()
        }

        private fun clickedHain(at: Int) {
            for ((index, cell) in cells.withIndex()) {
                if (index != at)
                    cell.content.hidden = true
                else
                    cell.content.hidden = !cell.content.hidden
            }
        }

        private fun openHain(at: Int) {
            for ((index, cell) in cells.withIndex()) {
                cell.content.hidden = index != at
            }
        }

        private fun clickedDelete(at: Int) {
            list.removeAt(at)
            reloadData()
            hasUnsavedChanges = true
        }

        private fun clickedUp(at: Int) {
            val section = list.removeAt(at)
            list.add(at - 1, section)
            reloadData()
            openHain(at - 1)
            hasUnsavedChanges = true
        }

        private fun clickedDown(at: Int) {
            val section = list.removeAt(at)
            list.add(at + 1, section)
            reloadData()
            openHain(at + 1)
            hasUnsavedChanges = true
        }

        private fun reloadCells() {
            cells.map { it.cleanup() }
            cells.clear()

            for ((index, _) in list.withIndex()) {
                val cell = HainCell(name, field_name, index)
                cell.cell.onclick = { clickedHain(index) }
                cell.deleteButton.onclick = { clickedDelete(index) }
                if (index != 0) {
                    cell.upButton.onclick = { clickedUp(index) }
                } else {
                    cell.upButton.hidden = true
                }
                if (index != list.lastIndex) {
                    cell.downButton.onclick = { clickedDown(index) }
                } else {
                    cell.downButton.hidden = true
                }
                cells.add(cell)
            }
        }

        override fun cellForRow(at: Int): String {
            val hain = list[at]
            return HainCell.markup(hain, name, field_name, at)
        }

        override val numberOfRows: Int
            get() {
                return list.count()
            }
    }

    val dpBuoy = DPBuoysField(document, name, "dpbuoys")

    val referenceSystem = ReferenceSystemField(document, name, "referencesystems")

    val gps = GPSField(document, name, "gps")

    val hpr = HPRField(document, name, "hpr")

    val hain = HainField(document, name, "hain")

    fun update(data: ShipDynamicPositioning) {
        super.update(data)
        dpBuoy.update(data.dpBuoys.dpbuoy)
        referenceSystem.update(data.referenceSystems.referencesystem)
        gps.update(data.gpSystems.GPS)
        hpr.update(data.hprs.HPR)
        hain.update(data.hains.hain)
    }
}

class DPBuoyCell(name: String, field_name: String, at: Int) {
    val cellId: String = "cell-dpbuoys-$at"
    val cell = Li(document, "$cellId-cell")
    val content = Div(document, "$cellId-content", "inline-block")
    val deleteButton = Button(document, "$cellId-delete_button")
    val upButton = Button(document, "$cellId-up_button")
    val downButton = Button(document, "$cellId-down_button")

    fun cleanup() {
        deleteButton.onclick = null
        upButton.onclick = null
        downButton.onclick = null
        cell.onclick = null
    }

    companion object {
        fun markup(dpBuoy: ShipDPBuoy, name: String, field_name: String, at: Int): String {
            val contentStyle = "style='display:none;'"

            return """
                <li class="mdc-list-item" id="cell-dpbuoys-$at-cell">
                <span class="mdc-list-item__text">
                    <span class="mdc-list-item__primary-text">Location: ${dpBuoy.location}</span>
                    <span class="mdc-list-item__secondary-text">Type: ${dpBuoy.type}</span>
                    <div id="cell-dpbuoys-$at-content" $contentStyle>
                        <div class="dpbuoy-cell-buttons">
                            <button class="mdc-button" id="cell-dpbuoys-$at-delete_button"><i class="material-icons mdc-button__icon" aria-hidden="true">delete</i>Delete</button>
                            <button class="mdc-button" id="cell-dpbuoys-$at-up_button"><i class="material-icons mdc-button__icon" aria-hidden="true">arrow_upward</i>Up</button>
                            <button class="mdc-button" id="cell-dpbuoys-$at-down_button"><i class="material-icons mdc-button__icon" aria-hidden="true">arrow_downward</i>Down</button>
                        </div>
                    </div>

                </span>
            </li>
            """
        }
    }
}

class ReferenceSystemCell(name: String, field_name: String, at: Int) {
    val cellId: String = "cell-referencesystems-$at"
    val cell = Li(document, "$cellId-cell")
    val content = Div(document, "$cellId-content", "inline-block")
    val deleteButton = Button(document, "$cellId-delete_button")
    val upButton = Button(document, "$cellId-up_button")
    val downButton = Button(document, "$cellId-down_button")

    fun cleanup() {
        deleteButton.onclick = null
        upButton.onclick = null
        downButton.onclick = null
        cell.onclick = null
    }

    companion object {
        fun markup(refSystem: ShipReferenceSystem, name: String, field_name: String, at: Int): String {
            val contentStyle = "style='display:none;'"

            return """
                <li class="mdc-list-item" id="cell-referencesystems-$at-cell">
                <span class="mdc-list-item__text">
                    <span class="mdc-list-item__primary-text">Type: ${refSystem.type}</span>
                    <span class="mdc-list-item__secondary-text">Maker: ${refSystem.maker}, Antennas: ${refSystem.antennas}, Coverage sector: ${refSystem.coverage}</span>
                    <div id="cell-referencesystems-$at-content" $contentStyle>
                        <div class="referencesystem-cell-buttons">
                            <button class="mdc-button" id="cell-referencesystems-$at-delete_button"><i class="material-icons mdc-button__icon" aria-hidden="true">delete</i>Delete</button>
                            <button class="mdc-button" id="cell-referencesystems-$at-up_button"><i class="material-icons mdc-button__icon" aria-hidden="true">arrow_upward</i>Up</button>
                            <button class="mdc-button" id="cell-referencesystems-$at-down_button"><i class="material-icons mdc-button__icon" aria-hidden="true">arrow_downward</i>Down</button>
                        </div>
                    </div>

                </span>
            </li>
                """
        }
    }
}

class GPSCell(name: String, field_name: String, at: Int) {
    val cellId: String = "cell-gps-$at"
    val cell = Li(document, "$cellId-cell")
    val content = Div(document, "$cellId-content", "inline-block")
    val deleteButton = Button(document, "$cellId-delete_button")
    val upButton = Button(document, "$cellId-up_button")
    val downButton = Button(document, "$cellId-down_button")

    fun cleanup() {
        deleteButton.onclick = null
        upButton.onclick = null
        downButton.onclick = null
        cell.onclick = null
    }

    companion object {
        fun markup(gps: ShipGPS, name: String, field_name: String, at: Int): String {
            val contentStyle = "style='display:none;'"

            return """
                <li class="mdc-list-item" id="cell-gps-$at-cell">
                <span class="mdc-list-item__text">
                    <span class="mdc-list-item__primary-text">Name: ${gps.name}</span>
                    <span class="mdc-list-item__secondary-text">Type: ${gps.type}, Maker: ${gps.maker}, Transmitters: ${gps.transmitters}, Correction signals: ${gps.signals}</span>
                    <div id="cell-gps-$at-content" $contentStyle>
                        <div class="gps-cell-buttons">
                            <button class="mdc-button" id="cell-gps-$at-delete_button"><i class="material-icons mdc-button__icon" aria-hidden="true">delete</i>Delete</button>
                            <button class="mdc-button" id="cell-gps-$at-up_button"><i class="material-icons mdc-button__icon" aria-hidden="true">arrow_upward</i>Up</button>
                            <button class="mdc-button" id="cell-gps-$at-down_button"><i class="material-icons mdc-button__icon" aria-hidden="true">arrow_downward</i>Down</button>
                        </div>
                    </div>

                </span>
            </li>
                """
        }
    }


}

class HPRCell(name: String, field_name: String, at: Int) {
    val cellId: String = "cell-hpr-$at"
    val cell = Li(document, "$cellId-cell")
    val content = Div(document, "$cellId-content", "inline-block")
    val deleteButton = Button(document, "$cellId-delete_button")
    val upButton = Button(document, "$cellId-up_button")
    val downButton = Button(document, "$cellId-down_button")

    fun cleanup() {
        deleteButton.onclick = null
        upButton.onclick = null
        downButton.onclick = null
        cell.onclick = null
    }

    companion object {
        fun markup(hpr: ShipHPR, name: String, field_name: String, at: Int): String {
            val contentStyle = "style='display:none;'"

            return """
                <li class="mdc-list-item" id="cell-hpr-$at-cell">
                <span class="mdc-list-item__text">
                    <span class="mdc-list-item__primary-text">Name: ${hpr.name}</span>
                    <span class="mdc-list-item__secondary-text">Type: ${hpr.type}, Maker: ${hpr.maker}, Transducer: ${hpr.transducer}</span>
                    <div id="cell-hpr-$at-content" $contentStyle>
                        <div class="gps-cell-buttons">
                            <button class="mdc-button" id="cell-hpr-$at-delete_button"><i class="material-icons mdc-button__icon" aria-hidden="true">delete</i>Delete</button>
                            <button class="mdc-button" id="cell-hpr-$at-up_button"><i class="material-icons mdc-button__icon" aria-hidden="true">arrow_upward</i>Up</button>
                            <button class="mdc-button" id="cell-hpr-$at-down_button"><i class="material-icons mdc-button__icon" aria-hidden="true">arrow_downward</i>Down</button>
                        </div>
                    </div>

                </span>
            </li>
                """
        }
    }
}

class HainCell(name: String, field_name: String, at: Int) {
    val cellId: String = "cell-hain-$at"
    val cell = Li(document, "$cellId-cell")
    val content = Div(document, "$cellId-content", "inline-block")
    val deleteButton = Button(document, "$cellId-delete_button")
    val upButton = Button(document, "$cellId-up_button")
    val downButton = Button(document, "$cellId-down_button")

    fun cleanup() {
        deleteButton.onclick = null
        upButton.onclick = null
        downButton.onclick = null
        cell.onclick = null
    }

    companion object {
        fun markup(hain: ShipHain, name: String, field_name: String, at: Int): String {
            val contentStyle = "style='display:none;'"

            return """
                <li class="mdc-list-item" id="cell-hain-$at-cell">
                <span class="mdc-list-item__text">
                    <span class="mdc-list-item__primary-text">Type: ${hain.type}</span>
                    <span class="mdc-list-item__secondary-text">Maker: ${hain.maker}, IMU: ${hain.imu}</span>
                    <div id="cell-hain-$at-content" $contentStyle>
                        <div class="hain-cell-buttons">
                            <button class="mdc-button" id="cell-hain-$at-delete_button"><i class="material-icons mdc-button__icon" aria-hidden="true">delete</i>Delete</button>
                            <button class="mdc-button" id="cell-hain-$at-up_button"><i class="material-icons mdc-button__icon" aria-hidden="true">arrow_upward</i>Up</button>
                            <button class="mdc-button" id="cell-hain-$at-down_button"><i class="material-icons mdc-button__icon" aria-hidden="true">arrow_downward</i>Down</button>
                        </div>
                    </div>

                </span>
            </li>
                """
        }
    }
}

class CreateShipScreen(document: Document) : ShipScreen(document, "", "") {
    init {
        deleteButton.hidden = true
        historyButton.hidden = true
        navbar.title = "Create Vessel"
    }

    override fun refresh() {
        loading = false
    }

    override fun save() {
        val ship = Ship()
        val updates = updates(ship)
        val data = updates.data
        if (data == null) {
            showToast(updates.error ?: "No changes to save")
            return
        }

        loading = true

        Requests.createShip(this, data)
        {
            loading = false
            if (it.error != null) {
                showToast(it, "Failed to create vessel")
            } else {
                showToast(it, "Vessel created!")
                hasChanges = false
                pushTo("ships")
            }
        }
    }
}