package main.kotlin.ui

import main.kotlin.data.*
import org.w3c.dom.Document

val List<ArchivedOperation>.selectorItems : Array<SelectorItem>
    get()
    {
        val selectors : MutableList<SelectorItem> = mutableListOf()

        var index = 0
        for (operation in this)
        {
            var name = operation.voyageNumber
            if (name==null) name = operation.destinationName
            if (name==null) name = operation.handbookName;
            selectors.add(SelectorItem("operation-${operation.id}",name ,index.toString()))
            index += 1
        }

        return selectors.toTypedArray()
    }

class OperationSelector(document:Document, id:String, hint:String,var operations:List<ArchivedOperation>) : Selector(document,id,hint,operations.selectorItems)
{
    var selected : ArchivedOperation?
        get()
        {
            val s = view.value as? String ?: return null
            if (s.isNullOrEmpty()) return null
            val index = s.toInt()
            if (index >= 0)
                return operations[index]
            return null
        }
        set(value)
        {
            val index = operations.indexOfFirst { it.handbookName == value?.handbookName }
            view.value = "$index"
        }

    fun setOperations(operations: List<ArchivedOperation>)
    {
        this.operations = operations
        setSelectorItems(operations.selectorItems)
    }
}
