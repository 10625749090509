package main.kotlin.ui

import org.w3c.dom.Document
import org.w3c.dom.HTMLUListElement
import kotlin.dom.clear

interface TableViewDelegate
{
    val numberOfRows : Int

    fun cellForRow(at:Int) : String
    {
        val onclick = onclickForRow(at)
        val name = titleForRow(at)
        val details = detailForRow(at)
        val markup = """
            <li class="mdc-list-item" onclick="$onclick">
                <span class="mdc-list-item__text">
                    <span class="mdc-list-item__primary-text">$name</span>
                    <span class="mdc-list-item__secondary-text">$details</span>
                </span>
            </li>
            """
        return markup
    }

    fun titleForRow(at:Int) : String { return "Row $at"}
    fun detailForRow(at:Int) : String { return "" }
    fun onclickForRow(at:Int) : String { return "" }
}

class TableView(val document:Document,val delegate:TableViewDelegate,id:String)
{
    val view = Ul(document,id)
    private val list : dynamic
    private fun getView(id:String) : dynamic
    {
        val obj = js("""
    (function(){
        return mdc.list.MDCList.attachTo(document.getElementById(id));
    }())
    """)
        return obj
    }

    init
    {
        list = getView(id)
    }

    fun reloadData()
    {
        view.clear()

        var html = ""

        val rowCount = delegate.numberOfRows
        for (i in 0 until rowCount)
        {
            html += delegate.cellForRow(i)
        }

        view.html = html
    }

    var hidden : Boolean
    get()
    {
        return view.hidden
    }
    set(value)
    {
        view.hidden = value
    }
}