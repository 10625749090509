package main.kotlin.data

data class URL(val string:String)
{
    val searchParams : Map<String,String>
    val pathComponents : List<String>
    val query : String?
    val scheme : String?
    val host : String?

    init
    {
        val map : MutableMap<String,String> = mutableMapOf()
        val components : MutableList<String> = mutableListOf()

        var base = string
        val q = string.indexOf("?")
        if (q != -1)
        {
            val qs = string.substring(q+1)
            query = qs
            val parts = qs.split("&")
            for (part in parts)
            {
                val eqs = part.split("=")
                if (eqs.count() == 2)
                {
                    map[eqs[0]] = eqs[1]
                }
            }

            base = string.substring(0,q)
        }
        else
        {
            query = null
        }

        val s = base.indexOf("://")
        if (s!=-1)
        {
            scheme = base.substring(0,s)
            base = base.substring(s+3)
        }
        else
        {
            scheme = null
        }

        val p = base.indexOf("/")
        if (p!=-1)
        {
            val ps = base.substring(p+1)
            host = base.substring(0,p)
            val parts = ps.split("/")
            components.addAll(parts)
        }
        else
        {
            host = null
        }

        searchParams = map
        pathComponents = components
    }

    val lastPathComponent : String
    get()
    {
        return pathComponents.lastOrNull() ?: ""
    }
}
