package main.kotlin.ui

import main.kotlin.data.ProcedureSection
import org.w3c.dom.Document

private val Array<ProcedureSection>.selectorItems : Array<SelectorItem>
get()
{
    val list = mutableListOf<SelectorItem>()

    for ((index,section) in this.withIndex())
    {
        list.add(SelectorItem("${section.name.toLowerCase()}",section.name,"$index"))
    }

    return list.toTypedArray()
}

open class ProcedureSectionSelector(document: Document, id:String, hint:String, var sections:Array<ProcedureSection>,tooltip:String?=null) : Selector(document,id,hint,sections.selectorItems,tooltip=tooltip)
{
    var selected : ProcedureSection?
    get()
    {
        val s = view.value as? String ?: return null
        if (s.isNullOrEmpty()) return null
        val index = s.toInt()
        if (index >= 0)
            return sections[index]
        return null
    }
    set(value)
    {
        val index = sections.indexOfFirst { it.name == value?.name }
        view.value = "$index"
    }

    fun setSections(sections:Array<ProcedureSection>)
    {
        this.sections = sections
        setSelectorItems(sections.selectorItems)
    }
}
