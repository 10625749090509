package main.kotlin.models.editor

import main.kotlin.data.ContainerEditorPanel
import main.kotlin.data.ContainerEditorPanelType
import main.kotlin.ui.Div
import org.w3c.dom.Document

class ProcedureDefaultsPanel(document:Document) : ContainerEditorPanel
{
    override val container = Div(document, "procedure_default")
    override val panelType : ContainerEditorPanelType get() { return ContainerEditorPanelType.Default }
}
