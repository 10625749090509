package main.kotlin.ui

import main.kotlin.data.UserRole
import org.w3c.dom.Document

class UserRoleSelector(document:Document,id:String,hint:String) : Selector(document,id,hint,arrayOf(
        SelectorItem("none",UserRole.Inactive.title,"0"),
        SelectorItem("normal",UserRole.Normal.title,"1"),
        SelectorItem("vessel_master",UserRole.VesselMaster.title,"4"),
        SelectorItem("admin",UserRole.Admin.title,"2"),
        SelectorItem("super_admin",UserRole.SuperAdmin.title,"3"),
        SelectorItem("inspector", UserRole.Inspector.title, "5")
))
{
    var selectedRole : UserRole
    get()
    {
        val s = view.value as String
        return UserRole.from(s.toInt())
    }
    set(value)
    {
        view.value = value.value.toString()
    }
}

class AllUserRolesSelector(document:Document,id:String,hint:String) : Selector(document,id,hint,arrayOf(
        SelectorItem("all","All","-1"),
        SelectorItem("none",UserRole.Inactive.title,"0"),
        SelectorItem("normal",UserRole.Normal.title,"1"),
        SelectorItem("vessel_master",UserRole.VesselMaster.title,"4"),
        SelectorItem("admin",UserRole.Admin.title,"2"),
        SelectorItem("super_admin",UserRole.SuperAdmin.title,"3"),
        SelectorItem("inspector", UserRole.Inspector.title, "5")
))
{
    var selectedRole : UserRole?
    get()
    {
        val s = view.value as String
        val i = s.toInt()
        if (i == -1)
            return null
        return UserRole.from(i)
    }
    set(value)
    {
        val v = value?.value ?: -1
        view.value = v.toString()
    }
}

class ActiveUserRolesSelector(document:Document,id:String,hint:String) : Selector(document,id,hint,arrayOf(
    SelectorItem("all","All","-1"),
    SelectorItem("normal",UserRole.Normal.title,"1"),
    SelectorItem("vessel_master",UserRole.VesselMaster.title,"4"),
    SelectorItem("admin",UserRole.Admin.title,"2"),
    SelectorItem("super_admin",UserRole.SuperAdmin.title,"3"),
    SelectorItem("inspector", UserRole.Inspector.title, "5")
))
{
    var selectedRole : UserRole?
        get()
        {
            val s = view.value as String
            val i = s.toInt()
            if (i == -1)
                return null
            return UserRole.from(i)
        }
        set(value)
        {
            val v = value?.value ?: -1
            view.value = v.toString()
        }
}