package main.kotlin.ui

import org.w3c.dom.Document
import org.w3c.dom.HTMLDivElement

class Switch(val document: Document,val id:String,val text:String,tooltip:String?=null)
{
    private val container : Div = Div(document,id,tooltip=tooltip)
    private val field : dynamic
    private val checkbox : dynamic
    private val input : dynamic

    init
    {
        container.html = markup
        val view = getView(id)
        field = view.field
        checkbox = view.checkbox
        input = view.input
    }

    val markup : String
    get()
    {
        return """
        <div class="custom-checkbox-container">
        <div class="mdc-form-field" id="$id-field">
          <label for="$id-checkbox-input">$text</label>
          <div class="mdc-checkbox" id="$id-checkbox">
            <input type="checkbox" id="$id-checkbox-input" class="mdc-checkbox__native-control" />
            <div class="mdc-checkbox__background">
              <svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
            <path class="mdc-checkbox__checkmark-path"
                  fill="none"
                  d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
          </svg>
              <div class="mdc-checkbox__mixedmark"></div>
            </div>
          </div>
        </div>
        </div>
        """
    }

    private fun getView(id:String) : dynamic
    {
        val obj = js("""
    (function(){
        var checkboxId = "" + id + "-checkbox";
        var fieldId = "" + id + "-field";
        var inputId = "" + id + "-checkbox-input";
        var checkbox = new mdc.checkbox.MDCCheckbox(document.getElementById(checkboxId));
        var formField = new mdc.formField.MDCFormField(document.getElementById(fieldId));
        var input = document.getElementById(inputId);
        formField.input = checkbox;
        var ret = {};
        ret.checkbox = checkbox;
        ret.field = formField;
        ret.input = input;
        return ret;
    }())
    """)
        return obj
    }

    var hidden : Boolean
    get()
    {
        return container.hidden
    }
    set(value)
    {
        container.hidden = value
    }

    var checked : Boolean
    get()
    {
        return checkbox.checked as Boolean
    }
    set(value)
    {
        checkbox.checked = value
    }

    var disabled : Boolean
    get()
    {
        return checkbox.disabled
    }
    set(value)
    {
        checkbox.disabled = value
    }

    private var _valueChanged :  (()->Unit)? = null
    var valueChanged : (()->Unit)?
    get()
    {
        return _valueChanged
    }
    set(value)
    {
        _valueChanged = value

        input.onchange = null

        if (value == null) return

        input.onchange = {
            _valueChanged?.invoke()
        }

    }
}
