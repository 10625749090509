package main.kotlin.models

import main.kotlin.comms.Requests
import main.kotlin.data.*
import main.kotlin.ui.*
import org.w3c.dom.Document

class UsersScreen(document:Document) : TopNavBarScreen(document),TilesViewDelegate, FilterByNameModel
{
    override val activeTab: TopNavBarTab get() = TopNavBarTab.Users

    val list = TilesView(document,this,"list")

    override val searchButton = Button(document,"search_button",tooltip=activeTab.searchHint)
    val createButton = Button(document,"create_button",tooltip="Create a new user")
    private val navTitle = Div(document,"navbar_title","inline")
    val filters = UsersFilters(document,"filters")

    override val helpTooltip : String get() { return "Help" }
    override val helpPage : String get() { return "help.html?chapter=users" }

    override fun start()
    {
        super.start()

        loading = false

        createButton.onclick = { create() }

        filters.start()
        {
            changedFilter()
        }

        refresh()
    }

    fun create()
    {
        pushTo("user")
    }

    fun refresh()
    {
        loading = true
        Requests.listUsers(this)
        {
            loading = false
            refreshed(it)
        }
    }

    var allusers : Array<User> = arrayOf()
    var users : Array<User> = arrayOf()

    override var searching : Boolean
    get()
    {
        return super.searching
    }
    set(value)
    {
        super.searching = value
        navTitle.hidden = searching
    }

    override fun searchTextChanged(text:String)
    {
        filters.query = text
        changedFilter()
    }

    private fun changedFilter()
    {
        val shouldFilter = filters.query.isNotEmpty() || filters.statusLabel != null || filters.role != null

        if (!shouldFilter)
            users = allusers
        else
        {
            val query = filters.query.toLowerCase()
            fun includeItem(item: User) : Boolean {
                val hasQ = matchesSearch(query, item)
                var hasStatus = true
                if (filters.statusLabel != null) {
                    when (filters.statusLabel) {
                        "Active" -> {
                            hasStatus = item.role !== UserRole.Inactive
                        }
                        "Inactive" -> {
                            hasStatus = item.role == UserRole.Inactive
                        }
                        "All" -> {
                            hasStatus = item.role == UserRole.Inactive || item.role !== UserRole.Inactive
                        }
                        else -> { }
                    }
                }
                val hasRole = if (filters.role != null) item.role == filters.role else true
                return hasQ && hasStatus && hasRole
            }
            users = allusers.filter { includeItem(it) }.toTypedArray()
        }
        list.reloadData()
    }

    fun refreshed(it:UsersResponse)
    {
        allusers = it.users
        searchTextChanged(if (searching) topnavbar.searchbar.text else "")
    }

    override val numberOfItems: Int get() { return users.count() }

    override fun titleForItem(at: Int): String { return users[at].name }

    override fun detailForItem(at: Int): String { return users[at].role.title }

    override fun iconForItem(at:Int) : String { return "account_circle" }

    override fun itemDisabled(at:Int) : Boolean { return users[at].role.title == "Inactive" }

    override fun onclickForItem(at: Int): String
    {
        val uid = users[at].id
        return "window.location.href='user.html?id=$uid'"
    }
}

class UsersFilters(document:Document,val id:String)
{
    var query : String = ""
    var statusLabel : String? = "Active"
    var role : UserRole? = null
    private var statusLabels : List<String> = listOf("Active", "Inactive")

    private val statusLabelSelector : StringSelector
    private val roleSelector : ActiveUserRolesSelector
    private val container = Div(document,id)

    fun start(changedFilter:()->Unit)
    {
        statusLabelSelector.selected = statusLabel
        statusLabelSelector.valueChanged = {statusLabel = statusLabelSelector.selected; changedFilter.invoke() }

        roleSelector.selectedRole = role
        roleSelector.valueChanged = { role = roleSelector.selectedRole; changedFilter.invoke() }
    }

    var hidden : Boolean
    get()
    {
        return container.hidden
    }
    set(value)
    {
        container.hidden = value
        if (!value)
        {
            statusLabelSelector.selected = statusLabel
            roleSelector.selectedRole = role
        }
    }

    val markup : String
    get()
    {
        return """
            <div class="mdc-select edit" id="${id}_status"></div>
           <div class="mdc-select edit" id="${id}_role"></div>
        """
    }

    init
    {
        container.html = markup
        statusLabelSelector = StringSelector(document, "${id}_status", "Status", statusLabels)
        roleSelector = ActiveUserRolesSelector(document,"${id}_role","Role")
    }
}