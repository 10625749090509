package main.kotlin.models

import main.kotlin.comms.Requests
import main.kotlin.data.*
import main.kotlin.ui.*
import org.w3c.dom.Document

class ShipsScreen(document:Document) : TopNavBarScreen(document),TilesViewDelegate,FilterByNameModel
{
    override val activeTab: TopNavBarTab get() = TopNavBarTab.Ships

    val list = TilesView(document,this,"list")

    override val searchButton = Button(document,"search_button",tooltip=activeTab.searchHint)
    val createButton = Button(document,"create_button",tooltip="Create a new vessel")
    val filters = ShipsFilters(document,"filters")

    override val helpTooltip : String get() { return "Help" }
    override val helpPage : String get() { return "help.html?chapter=ships" }

    override fun start()
    {
        super.start()

        loading = false

        createButton.onclick = { create() }

        Requests.listShipClasses2(this)
        {
            var shipClasses = it.shipClasses.toList()
            filters.setShipClasses(shipClasses)

            Requests.listShipTypes2(this)
            {
                shipTypes = it.shipTypes
                filters.setShipTypes(shipTypes.toList())

                Requests.listRegions2(this)
                {
                    var shipRegions = it.shipRegions.toList()
                    filters.setRegions(shipRegions)
                    filters.start()
                    {
                        changedFilter()
                    }
                }
                refresh()
            }
        }
    }

    fun create()
    {
        pushTo("ship")
    }

    fun refresh()
    {
        loading = true

        Requests.listShips(this)
        {
            loading = false
            refreshed(it)
        }
    }

    var allships : Array<Ship> = arrayOf()
    var ships : Array<Ship> = arrayOf()
    var shipTypes: Array<ShipType> = arrayOf()

    override fun searchTextChanged(text:String)
    {
        filters.query = text
        changedFilter()
    }

    private fun changedFilter()
    {
        val shouldFilter = filters.query.isNotEmpty() || filters.statusLabel != null || filters.type != null || filters.classe != null || filters.region != null

        if (!shouldFilter)
            ships = allships
        else
        {
            val query = filters.query.toLowerCase()
            fun includeItem(item:Ship) : Boolean
            {
                val hasQ = matchesSearch(query,item)
                var hasStatus : Boolean = true
                if (filters.statusLabel != null) {
                    when (filters.statusLabel) {
                        "Enabled" -> {
                            hasStatus = item.disabled == false || item.disabled == undefined
                        }
                        "Disabled" -> {
                            hasStatus = item.disabled == true
                        }
                        "All" -> {
                            hasStatus = item.disabled == true || item.disabled == false || item.disabled == undefined
                        }
                        else -> {
                            console.log("Invalid label")
                        }
                    }
                }
                val hasType = if (filters.type != null) item.type.equals(filters.type, ignoreCase = true) else true
                val hasClass = if (filters.classe != null) item.classe.equals(filters.classe, ignoreCase = true) else true
                val hasRegion = if (filters.region != null) item.region.equals(filters.region, ignoreCase = true) else true
                return hasQ && hasStatus && hasType && hasClass && hasRegion
            }
            ships = allships.filter { includeItem(it) }.toTypedArray()
        }
        list.reloadData()
    }

    fun refreshed(it:ShipsResponse)
    {
        allships = it.ships
        searchTextChanged(if (searching) topnavbar.searchbar.text else "")
    }

    override val numberOfItems: Int get() { return ships.count() }

    override fun itemDisabled(at:Int) : Boolean { return ships[at].disabled }

    override fun titleForItem(at:Int) : String
    {
        return ships[at].name
    }

    override fun detailForItem(at: Int): String
    {
        val type = ships[at].type
        return shipTypes.firstOrNull { ls -> ls.id == type}?.name ?: type
    }

    override fun iconForItem(at:Int) : String
    {
        return "directions_boat"
    }

    override fun onclickForItem(at: Int): String
    {
        val sid = ships[at].id
        val sname = ships[at].name
        return "window.location.href='ship.html?id=$sid&name=$sname'"
    }
}

class ShipsFilters(document:Document,val id:String)
{
    var query : String = ""
    var statusLabel : String? = "Enabled"
    var type : String? = null
    var classe : String? = null
    var region : String? = null
    private var statusLabels : List<String> = listOf("Enabled", "Disabled")
    private var shipClasses : List<ShipClass> = listOf()
    private var shipTypes : List<ShipType> = listOf()
    private var regions : List<ShipRegion> = listOf()

    private val statusLabelSelector : StringSelector
    private val typeSelector : ShipTypeSelector
    private val classSelector : ShipClassSelector
    private val regionSelector : ShipRegionSelector
    private val container = Div(document,id)

    fun setShipClasses(shipClasses: List<ShipClass>)
    {
        this.shipClasses = shipClasses
        classSelector.setClasses(shipClasses)
    }

    fun setShipTypes(shipTypes: List<ShipType>)
    {
        this.shipTypes = shipTypes
        typeSelector.setTypes(shipTypes)
    }

    fun setRegions(regions: List<ShipRegion>)
    {
        this.regions = regions
        regionSelector.setRegions(regions)
    }

    fun start(changedFilter:()->Unit)
    {
        statusLabelSelector.selected = statusLabel
        statusLabelSelector.valueChanged = {statusLabel = statusLabelSelector.selected; changedFilter.invoke() }

        typeSelector.selected = type
        typeSelector.valueChanged = { type = typeSelector.selected; changedFilter.invoke() }

        classSelector.selected = classe
        classSelector.valueChanged = { classe = classSelector.selected; changedFilter.invoke() }

        regionSelector.selected = region
        regionSelector.valueChanged = { region = regionSelector.selected; changedFilter.invoke() }
    }

    var hidden : Boolean
    get()
    {
        return container.hidden
    }
    set(value)
    {
        container.hidden = value
        if (!value)
        {
            statusLabelSelector.selected = statusLabel
            typeSelector.selected = type
            classSelector.selected = classe
            regionSelector.selected = region
        }
    }

    val markup : String
    get()
    {
        return """
       <div class="mdc-select" id="${id}_status"></div>
       <div class="mdc-select" id="${id}_type"></div>
       <div class="mdc-select" id="${id}_class"></div>
       <div class="mdc-select" id="${id}_region"></div>
    """
    }

    init
    {
        container.html = markup
        statusLabelSelector = StringSelector(document, "${id}_status", "Status", statusLabels)
        typeSelector = ShipTypeSelector(document,"${id}_type","Type", shipTypes)
        classSelector = ShipClassSelector(document,"${id}_class","Class", shipClasses)
        regionSelector = ShipRegionSelector(document,"${id}_region","Region", regions)
    }
}