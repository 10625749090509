package main.kotlin.models

import main.kotlin.comms.Requests
import main.kotlin.data.*
import main.kotlin.ui.*
import main.kotlin.ui.Element
import org.w3c.dom.*
import toBase64
import kotlin.browser.document
import kotlin.browser.window
import kotlin.js.Promise
import kotlin.math.roundToInt

val d = Any().asDynamic()

val Handbook.activeTab : TopNavBarTab
get()
{
    if (shipId != null)
        return TopNavBarTab.Ships
    return TopNavBarTab.Nothing
}

private val Handbook.breadcrumbs : Array<Breadcrumb>
get()
{
    val shipId = shipId
    if (shipId != null) {
        d.id = shipId
        d.name = shipName
        val json = encodeURIComponent(JSON.stringify(d))
        val b64 = json.toBase64()
        val params = encodeURIComponent(b64)
        return if (completed != null)
            arrayOf(
                Breadcrumb("Vessels", "ships.html"),
                Breadcrumb(shipName ?: shipId, "ship.html?id=${shipId}&name=${shipName}"),
                Breadcrumb("Operation Archive", "ship_history.html?ship=${params}"),
                Breadcrumb("$operationName - $name", "")
            )
            else arrayOf(
                Breadcrumb("Vessels", "ships.html"),
                Breadcrumb(shipName ?: shipId, "ship.html?id=${shipId}&name=${shipName}"),
                Breadcrumb("$operationName - $name", "")
            )
        }
    return arrayOf(Breadcrumb("Handbooks", "handbooks.html"))
}

fun ProcedureCard.regionRequirementsTooltip(provider:SpecificableInfoProvider) : String
{
    val thing = type.title.toLowerCase()
    return regionRequirementsTooltip(thing,provider)
}

fun ProcedureCard.shipRequirementsTooltip(provider:SpecificableInfoProvider) : String
{
    val thing = type.title.toLowerCase()
    return shipRequirementsTooltip(thing,provider)
}

fun ProcedureCard.destinationRequirementsTooltip(provider:SpecificableInfoProvider) : String
{
    val thing = type.title.toLowerCase()
    return destinationRequirementsTooltip(thing,provider)
}

interface ProcedureScreenInfoProvider : ProcedureConfig,SpecificableInfoProvider
{

}

class ProceduresScreen(document:Document, handbook:Handbook) : HandbookBaseScreen(document,handbook,handbook.activeTab),ProcedureScreenInfoProvider,TableViewDelegate,FilterByNameModel
{
    override val activeTab: TopNavBarTab get() = handbook.activeTab
    override val searchHint : String get() { return "Search All Procedures & Checklists" }
    override val searchButton = Button(document,"search_button",tooltip=searchHint)

    val breadcrumbs = Breadcrumbs(document,"page_breadcrumbs", handbook.breadcrumbs)

    val navbar = NavBar(document,"page_navbar")
    val navButtons = Div(document,"page_navbar_buttons","inline-flex")
    val state = Div(document,"handbook_state",visibleDisplayStyle = "inline-flex")
    val settingsButton = Button(document,"settings_button",tooltip="Handbook Settings")
    val createButton = Button(document,"create_button",tooltip="Create a new Procedure or Checklist")
    val publishButton = Button(document,"publish_button",tooltip="Publish this handbook")
    val reviseButton = Button(document,"revise_button",tooltip="Create a new revision of this handbook")
    val chapters = TableView(document,this,"chapters")
    val grids = Div(document,"grids")
    val leftContainer = Div(document,"left-container")

    override val helpTooltip : String get() { return "Help" }
    override val helpPage : String get() { return "help.html?chapter=handbooks&section=handbook" }

    val duplicateButton = Button(document, "duplicate_button", "Duplicate this handbook")

    private val searchContainer = Div(document,"search")
    private val searchResults : HandbookChapterSection

    private val exportButton = Button(document, "export_button",tooltip="Export checklists in this handbook to PDF")

    private var currentChapterId : String? = null

    private val emptyChapters = "There are no chapters in this handbook yet"
    private val emptyChapter = "This chapter is empty"
    private val emptySection = "This section is empty"
    private val emptySearchResults = "No results found"

    private var canPublish = false
    private var showPublishButton = false
    private var showReviseButton = false
    private var showCreateButton = false

    override val tripId:String?

    init
    {
        currentChapterId = Storage.getHandbookChapter(handbook.id)

        settingsButton.onclick = { clickedSettings() }

        tripId = handbook.tripId

        if (tripId == null)
        {
            createButton.onclick = { create() }
            publishButton.onclick = { publish() }
            reviseButton.onclick = { revise() }
            exportButton.hidden = true
            duplicateButton.hidden = !handbook.published
            duplicateButton.onclick = { duplicate(handbook,this) }
        }
        else
        {
            createButton.hidden = true
            exportButton.hidden = false
            duplicateButton.hidden = handbook.published
            exportButton.onclick = { exportToPdf() }
        }

        publishButton.disabled = true
        publishButton.hidden = !showPublishButton
        reviseButton.hidden = !showReviseButton
        createButton.hidden = !showCreateButton

        navbar.title = "${handbook.name} Handbook"

        val chapterId = "search"
        val sectionId = "results"
        val sectionName = "Search Results"
        var html = """<div id="tab-$chapterId" style="display:block;">"""
        html += """<div id="grid-$chapterId-$sectionId"></div>"""
        html += "</div>"

        searchContainer.view.innerHTML = html
        searchResults = HandbookChapterSection(kotlin.browser.document, "grid-$chapterId-$sectionId", sectionName, this, arrayOf(),emptySearchResults,false)
        searchContainer.hidden = true
    }

    override var loading : Boolean
    set(value)
    {
        //loader.hidden = !value
        super.loading = value
        navButtons.hidden = value
    }
    get()
    {
        return super.loading
    }

    override var searching : Boolean
    get() { return super.searching }
    set(newValue)
    {
        super.searching = newValue
        grids.hidden = searching
        searchContainer.hidden = !searching
        createButton.hidden = searching || !showCreateButton
        settingsButton.hidden = searching
        exportButton.hidden = searching
        publishButton.hidden = searching || !showPublishButton
        reviseButton.hidden = searching || !showReviseButton
        leftContainer.hidden = searching || data.chapters.isEmpty()
    }

    override fun searchTextChanged(text:String)
    {
        val procedures = data.allProcedures
        var actual : List<ProcedureCard> = procedures
        if (text.isNotEmpty())
        {
            val query = text.toLowerCase()
            actual = procedures.filter { matchesSearch(query,it) }
        }
        searchResults.procedures = actual.toTypedArray()
        searchResults.reloadData()
    }

    override fun start()
    {
        super.start()

        refresh()
    }

    fun create()
    {
        pushToHtml("procedure.html?handbook=${handbook.params}")
    }

    var installations : MutableMap<String,InstallationCard> = mutableMapOf()
    var ships : MutableMap<String,Ship> = mutableMapOf()
    var regions : MutableMap<String, ShipRegion> = mutableMapOf()
    var shipTypes : MutableMap<String, ShipType> = mutableMapOf()
    var shipClasses : MutableMap<String, ShipClass> = mutableMapOf()

    override fun getShip(id:String) : Ship?
    {
        return ships[id]
    }

    override fun getInstallation(id:String) : InstallationCard?
    {
        return installations[id]
    }

    override fun getRegion(id: String): ShipRegion? {
        return regions[id]
    }

    override fun getVesselClass(id: String): ShipClass? {
        return shipClasses[id]
    }

    override fun getVesselType(id: String): ShipType? {
        return shipTypes[id]
    }

    override fun refresh()
    {
        loading = true

        fun loadProcedures()
        {
            Requests.listProcedures(this,handbook.id,tripId)
            { resp ->
                loading = false
                refreshed(resp)
            }
        }


        fun loadRegions(){
            Requests.listRegions2(this){
                for (region in it.shipRegions)
                    regions[region.id] = region


            }
        }

        fun loadShipClasses(){
            Requests.listShipClasses2(this){
                for (shipClass in it.shipClasses)
                    shipClasses[shipClass.id] = shipClass


            }
        }

        fun loadShipTypes(){
            Requests.listShipTypes2(this){
                for (type in it.shipTypes){
                    shipTypes[type.id] = type

                }
            }
        }

        fun loadShips()
        {
            Requests.listShips(this)
            {
                for (ship in it.ships)
                    ships[ship.id] = ship
                loadProcedures()
            }

        }

        fun loadInstallations()
        {
            Requests.listInstallations(this)
            {
                for (installation in it.installations)
                    installations[installation.id] = installation
                loadShips()
            }
        }

        loadInstallations()
        loadRegions()
        loadShipClasses()
        loadShipTypes()
    }

    fun publish(version:String?=null)
    {
        if (version != null)
        {
            loading = true
            Requests.publishHandbook(this, handbook.id, version)
            {
                loading = false
                if (it.error != null)
                {
                    showToast(it, "Failed to publish handbook")
                }
                else
                {
                    openHandbook(it, "Handbook published!")
                }
            }
        }
        else
        {
            alert.openForInput("Publish Handbook", "Please verify the handbook version number", arrayOf(AlertAction("cancel", "Cancel"), AlertAction("publish", "Publish")),"Handbook Version",handbookVersion)
            {
                if (it == "publish")
                {
                    val hbVersion = alert.inputText
                    if (hbVersion!=null && hbVersion.isNotBlank())
                        publish(hbVersion)
                    else
                        showToast("Cannot publish the handbook without a version number")
                }
            }
        }
    }

    var data : ProceduresResponse = ProceduresResponse()

    open val handbookName = data.handbook?.descriptiveName ?: handbook.descriptiveName

    override val handbookVersion : String?
    get()
    {
        return data.handbook?.version
    }

    val ProcedureChapter.chapterId : String
    get()
    {
        return name.toLowerCase().replace(" ","_")
    }

    val ProcedureSection.sectionId : String
    get()
    {
        return name.toLowerCase().replace(" ","_")
    }

    fun refreshed(it:ProceduresResponse)
    {
        data = it

        leftContainer.hidden = data.chapters.isEmpty()

        val parent = it.parent
        if (it.handbook == null && handbook.id.startsWith("rev__") && parent != null)
        {
            Storage.setStartupToast("Handbook moved!",StartupToastForScreen.HandbookBaseScreen)

            openHandbook(parent)
        }
        else
        {
            reloadData()
        }
    }

    fun reloadData()
    {
        val chap = currentChapterId
        if (chap == null || chap.isNullOrBlank())
            currentChapterId = data.chapters.firstOrNull()?.chapterId

        var html = """<div style="margin-top: 12px;"></div>"""

        for (chapter in data.chapters)
        {
            val chapterId = chapter.chapterId

            val shouldShow = currentChapterId == chapterId

            html += if (shouldShow)
            {
                """<div id="tab-$chapterId" style="display:block;">"""
            }
            else
            {
                """<div id="tab-$chapterId" style="display:none;">"""
            }

            val hasMultipleSections = chapter.sections.count() > 1

            for (section in chapter.sections)
            {
                if (hasMultipleSections)
                    html += """<div class="procedure-section-title">${section.name}</div>"""

                val sectionId = section.sectionId

                val header = section.header
                if (header != null)
                {
                    val markup = BlurbsView.markup(this, listOf(header),BlurbPlacement.Headers())
                    html += """<div id="procedure_headers">$markup</div>"""
                }

                html += """<div id="grid-$chapterId-$sectionId"></div>"""
            }

            if (chapter.sections.isEmpty())
            {
                html += """
<div class="grid-default-empty">$emptyChapter</div>
"""
            }

            html += "</div>"
        }

        if (data.chapters.isEmpty())
        {
            html = """
<div class="grid-default-empty">$emptyChapters</div>
"""
        }

        grids.view.innerHTML = html

        for (cell in cells)
            cell.li.onclick = null
        cells.clear()

        chapters.reloadData()

        for (chapter in data.chapters)
        {
            val chapterId = chapter.chapterId

            val cell = ChapterCell(chapterId)
            cell.li.onclick = { clickedChapter(chapterId) }
            cells.add(cell)

            val emptyText : String = if (chapter.sections.count() > 1) emptySection else emptyChapter

            for (section in chapter.sections)
            {
                val sectionId = section.sectionId
                var sectionName = ""
                if (chapter.sections.count() > 1)
                    sectionName = section.name
                val chapterSection = HandbookChapterSection(document, "grid-$chapterId-$sectionId", sectionName, this, section.procedures.toTypedArray(),emptyText)
                chapterSection.reloadData()
            }
        }
        reloadTitle()
        reloadCurrentChapter()
        reloadWorkflowButtons()
        reloadState()
        console.log(data.handbook?.chapters)
    }

    private fun reloadTitle()
    {
//        val handbookName = data.handbook?.descriptiveName ?: handbook.descriptiveName
        val version = data.handbook?.version ?: "1"
        navbar.title = "$handbookName Handbook"
        var subTitle = if (version.isBlank())
            ""
        else
            "Version $version"

        if (tripId != null)
        {
            val completedEvent = data.handbook?.completed?.description
            val completedComment = data.handbook?.completedComment 
            val abortedComment = data.handbook?.abortedComment
            val otherEvents = data.handbook?.events ?: listOf()

            subTitle = "$subTitle - This is a historic handbook for an active or archived operation"
            if (!completedEvent.isNullOrBlank())
                subTitle += "<br/>Completed by $completedEvent"
            if (!completedComment.isNullOrBlank()){
                subTitle += "<br/>Completed with comment: $completedComment"
            }
            if (!abortedComment.isNullOrBlank()){
                subTitle += "<br/>Aborted with comment: $abortedComment"
            }
            for (event in otherEvents) {
                if (!event.comment.isNullOrBlank()){
                    var commentParts = event.comment!!.split(";").toList()
                    if (commentParts.size==1) {
                    commentParts = event.comment.split("\n").toList()
                    }

                    val from = commentParts[0].replace("Reset from: ","")
                    val to = commentParts[1].replace("Reset to: ","")
                    val comment = commentParts[2].replace("Comment: ","")
                    subTitle += "<br/></br>Reset from $from to $to </br>by ${event.description} with comment: $comment"
                }
            }
        }

        navbar.subTitle = subTitle
    }

    private fun reloadState()
    {
        val handbook = data.handbook

        val all = data.allProcedures
        val draftProcedures = all.filter { it.isDraft }.count()
        val approvedProcedures = all.filter { it.state.status == ProcedureWorkflowStatus.Approved }.count()
//        val rejectedProcedures = all.filter { it.state.status == ProcedureWorkflowStatus.Rejected }.count()
//        val submittedProcedures = all.filter { it.state.status == ProcedureWorkflowStatus.Submitted }.count()

        val readyToPublish = draftProcedures == approvedProcedures && draftProcedures != 0

        if (handbook != null && (!handbook.isPublished || draftProcedures > 0))
        {
            if (readyToPublish)
                state.text = "Ready to publish"
            else
                state.text = "In Progress"
            state.removeClasses(arrayOf("draft","draft_submitted","draft_rejected","draft_approved","revision","revision_submitted","revision_rejected","revision_approved","published"))
            state.addClass(if (readyToPublish) "revision_approved" else "draft")
            state.hidden = false
        }
        else if (handbook != null && tripId == null)
        {
            state.text = "Published"
            state.removeClasses(arrayOf("draft","draft_submitted","draft_rejected","draft_approved","revision","revision_submitted","revision_rejected","revision_approved","published"))
            state.addClass("published")
            state.hidden = false
        }
        else
        {
            state.text = ""
            state.hidden = true
        }
    }

    private fun reloadWorkflowButtons()
    {
        showPublishButton = false
        showReviseButton = false
        showCreateButton = false

        val handbook = data.handbook
        if (tripId == null && handbook != null)
        {
            if (handbook.isPublished)
            {
                showReviseButton = true
            }
            else
            {
                showPublishButton = data.role == UserRole.SuperAdmin
                showCreateButton = true
            }
        }

        val chapters = data.chapters.map { it.procedures }
        if (chapters.isEmpty())
            canPublish = false
        else
        {
            val drafts = chapters.flatten().filter { it.isDraft }
            canPublish = true
            for (proc in drafts)
            {
                if (proc.state.status != ProcedureWorkflowStatus.Approved)
                {
                    canPublish = false
                    break
                }
            }
        }

        publishButton.disabled = !canPublish
        publishButton.hidden = !showPublishButton
        reviseButton.hidden = !showReviseButton
        createButton.hidden = !showCreateButton
    }

    var cells : MutableList<ChapterCell> = mutableListOf()

    class ChapterCell(val id:String)
    {
        val li = Li(document,"cell-$id")
        val label = Span(document, "cell-$id-label")
    }

    fun clickedChapter(id:String)
    {
        currentChapterId = id
        Storage.setHandbookChapter(handbook.id,id)
        reloadCurrentChapter()
    }

    fun reloadCurrentChapter()
    {
        for (cell in cells)
        {
            val shouldShow = currentChapterId == cell.id
            if (shouldShow)
                cell.label.addClasses(arrayOf("current-selection"))
            else
                cell.label.removeClasses(arrayOf("current-selection"))

            val div = Div(document,"tab-${cell.id}")
            div.hidden = !shouldShow
        }
    }

    fun clickedSettings()
    {
        window.location.href = "handbook.html?handbook=" + handbook.params
    }

    private fun openHandbook(it:IdResponse, fallbackMessage:String)
    {
        val hb = handbook
        hb.id = it.id
        pushToHtml(hb.link, it, fallbackMessage)
    }

    override fun openHandbook(id:String)
    {
        val hb = handbook
        hb.id = id
        pushToHtml(hb.link)
    }
    override val numberOfRows: Int get() { return data.chapters.count() }

    override fun cellForRow(at: Int): String
    {
        val chapter = data.chapters[at]
        var isNewChapter = data.handbook?.chapters?.any { ch -> ch.name == chapter.name}
        var oldChapterStyle = if (isNewChapter == false) "style='text-decoration:line-through'"
        else ""

        return """
        <li class="mdc-list-item" id="cell-${chapter.chapterId}">
            <span class="mdc-list-item__text" id="cell-${chapter.chapterId}-label" ${oldChapterStyle}>${chapter.name}</span>
        </li>
        """
    }

    private fun duplicate(handbook:Handbook,screen:ProceduresScreen)
    {
        screen.loading = true

        Requests.duplicateHandbook(screen,handbook.id)
        {
            if (it.error == null)
            {
                screen.loading = false
                pushTo("handbooks", "Handbook ${handbook.descriptiveName} duplicated!")
            }
            else
            {
                screen.loading = false
                screen.showToast(it,"Failed to duplicate handbook!")
            }
        }
    }

    private fun exportToPdf() {
        val choices = data.allProcedures.map { proc -> AlertChoice(proc.id, proc.name, "") }

        val title = """
            Select checklists that you want to download<br/><br/>
            <input type="checkbox" id="chk_1" name="chk_1" value="check_format">
            <label for="chk_1"> Download with the sensitive information</label><br>
            <input type="checkbox" id="chk_2" name="chk_2" value="check_format">
            <label for="chk_2"> Download with expanded steps</label><br>
        """
                

        alert.openForMultipleChoices(title, choices.toTypedArray(), arrayOf(AlertAction("cancel", "CANCEL"), AlertAction("download", "DOWNLOAD TO PDF")))
        { action ->
            var procedureIds = mutableListOf<String>()
            var pageUrls = mutableListOf<String>()
            val check = document.getElementById("chk_1") as HTMLInputElement
            val check2 = document.getElementById("chk_2") as HTMLInputElement

            if (action == "download")
            {
                loading = true
                val selectedChecklists = alert.selectedItems
                selectedChecklists.forEach { proc ->
                    procedureIds.add(proc)
                }

                procedureIds.forEach { procId ->
                    pageUrls.add("procedure.html?id=${procId}&handbook=${handbook.params}&trip=$tripId")
                }

                val urlsArray = pageUrls.toTypedArray()
                var orderById  = selectedChecklists.withIndex().associate { (index, it) -> it to index }
                var checklistsToPrint = data.fullProcedures.filter { proc -> proc.id in selectedChecklists }.sortedBy { orderById[it.id] }

                js("appendHeaderToPrint()");

                var printHtml = js("initPrint(urlsArray[0])") as Promise<Element>

                printHtml.then { el ->
                    var procedureScreen: ProcedureScreen? = null;

                    checklistsToPrint.forEach { procedure ->
                        if (procedureScreen == null)
                        {
                            procedureScreen = ProcedureScreen(document, handbook, procedure.id, tripId)
                        }

                        procedureScreen?.refreshed(procedure)

                        if (check.checked) {
                            procedureScreen?.showSensitive?.checked = true
                            procedureScreen?.toggleProcedureContent()
                        }
                        if (check2.checked){
                            procedureScreen?.expandAllSteps(true)
                        } else {
                            procedureScreen?.expandAllSteps(false)
                        }

                        js("appendToPrint()");
                    }

                    var promise = js("completePrint()") as Promise<*>
                    promise.then {
                        js("location.reload()");
                    }
                }
            }
        }
    }
}

class HandbookChapterSection(document:Document,val gridId:String,val name:String,val config:ProcedureScreenInfoProvider,procedures:Array<ProcedureCard>,val empty:String,val combineRevisions:Boolean=true) : TilesViewDelegate
{
    val grid = TilesView(document,this,gridId,"grid-procedures",empty=empty)

    val handbook : Handbook = config.handbook
    val tripId : String? = config.tripId
    val trip : ShipTrip = config.trip

    var procedures:Array<ProcedureCard>

    init
    {
        val comparator = compareBy <ProcedureCard> {it.order}.thenBy {it.completed.firstOrNull()?.timestamp ?: 0.0}
        if (combineRevisions)
        {
            val children : MutableMap<String,MutableList<ProcedureCard>> = mutableMapOf()

            for (procedure in procedures)
            {
                val pid = procedure.parent ?: continue
                val list = children[pid] ?: mutableListOf()
                list.add(procedure)
                children[pid] = list
            }

            if (children.isEmpty())
            {
                
                val procs = procedures.filter { !it.hasChildren }.sortedWith(comparator)
                this.procedures = procs.toTypedArray()
            }
            else
            {
                val procs : MutableList<ProcedureCard> = mutableListOf()
                val ids : MutableSet<String> = mutableSetOf()
                for (procedure in procedures)
                {
                    if (procedure.hasChildren) continue

                    val pid = procedure.id
                    val ppid = procedure.parent

                    if (ids.contains(pid)) continue
                    if (ppid != null && ids.contains(ppid)) continue

                    val id = ppid ?: pid

                    val list = children[id]
                    if (list == null)
                    {
                        procs.add(procedure)
                        ids.add(id)
                        ids.add(pid)
                    }
                    else
                    {
                        list.sortBy { it.created?.timestamp ?: 0.0 }
                        val p = list.firstOrNull() ?: continue
                        procs.add(p)
                        ids.add(p.id)
                        ids.add(p.parent!!)
                    }
                }
                
                procs.sortWith(comparator)
                this.procedures = procs.toTypedArray()
            }
        }
        else
        {
            val procs = procedures.sortedWith(comparator)
            this.procedures = procs.toTypedArray()
        }
    }

    class ReqPopoverTest(val id:String,item:ProcedureCard,config:ProcedureScreenInfoProvider)
    {
        val region = Div(document,"$id-region",tooltip=item.regionRequirementsTooltip(config))
        val ship = Div(document,"$id-ship",tooltip=item.shipRequirementsTooltip(config))
        val destination = Div(document,"$id-destination",tooltip=item.destinationRequirementsTooltip(config))
    }

    fun reloadData()
    {
        grid.reloadData()

        for ((at,proc) in procedures.withIndex())
        {
            ReqPopoverTest("$gridId-cell_req_$at",proc,config)
        }
    }

    // GridView

    fun stateMarkup(item:ProcedureCard) : String
    {
        if (tripId == null)
        {
            return if (item.isDraft)
            {
                val msg = """<span class="cell_status_msg">${item.publishedMessage}</span>"""
                """<span class="cell_state ${item.stateCss}">${item.stateName}</span>$msg"""
            }
            else
            {
                """<span class="cell_status_msg">${item.publishedMessage}</span>"""
            }
        }

        val completedTime = item.completed.firstOrNull()?.timestamp?.displayCompleted
        if (completedTime != null) {
            return """<span class="cell_status_msg">Completed: $completedTime</span>"""
        }
        return ""
    }

    private fun requirementsMarkup(item:ProcedureCard,at:Int) : String
    {
        val activeShipIcon = item.ships.isNotEmpty() || item.classes.isNotEmpty() || item.types.isNotEmpty()
        val activeRegionIcon = item.regions.isNotEmpty()
        val activeDestinationIcon = item.destinations.isNotEmpty()

        return """
        <div class="cell_requirements">
            <div id="$gridId-cell_req_$at-region"><i class="material-icons ${if(activeRegionIcon) "active" else ""}">${Icons.regions}</i></div>
            <div id="$gridId-cell_req_$at-ship"><i class="material-icons ${if(activeShipIcon) "active" else ""}">${Icons.ships}</i></div>
            <div id="$gridId-cell_req_$at-destination"><i class="material-icons ${if(activeDestinationIcon) "active" else ""}">${Icons.destinations}</i></div>
        </div>"""
    }

    override val numberOfItems : Int get() { return procedures.count() }

    override fun cellForItem(at:Int) : String
    {
        val item = procedures[at]
        val onclick = onclickForItem(at)
        val name = Handbook.tailorProcedureText(item.name,trip)
        val progress = item.progress?.roundToInt()
        var progressStyle = ""
        var progressText = ""
        if (progress != null && progress > 0)
        {
            progressText = "$progress%"
        }
        if (progress != null)
        {
            progressStyle = """style="width:$progress%;""""
        }

        val details = item.type.title

        val state = stateMarkup(item)
        val requirements = requirementsMarkup(item,at)

        var brandNewClass = ""
        if (item.isDraft && item.parent == null)
        {
            brandNewClass = " brand_new"
        }

        if (item.type == ProcedureType.Checklist)
        {
            val icon = if (progressText.isEmpty())
                """<i class="material-icons">list</i>"""
            else
                """<span class="cell_percentage">$progressText</span>"""

            return """
<li class="mdc-image-list__item$brandNewClass" onclick="$onclick">
    <div class="mdc-image-list__image">
        <div class="cell_top_bar cell_top_bar_checklist_bg"></div>
        <div class="cell_top_bar cell_top_bar_checklist_fg" $progressStyle></div>
        $icon
        <span class="cell_title">$name</span>
        <span class="cell_details">$details</span>
        $state
        $requirements
    </div>
</li>"""
        }
        else
        {
            return """
<li class="mdc-image-list__item procedure$brandNewClass" onclick="$onclick">
      <div class="mdc-image-list__image">
        <div class="cell_top_bar"></div>
        <i class="material-icons">find_in_page</i>
        <span class="cell_title">$name</span>
        <span class="cell_details">$details</span>
        $state
        $requirements
    </div>
</li>"""
        }
    }

    override fun onclickForItem(at: Int): String
    {
        val pid = procedures[at].id
        if (tripId != null)
            return "window.location.href='procedure.html?id=$pid&handbook=${handbook.params}&trip=$tripId'"
        return "window.location.href='procedure.html?id=$pid&handbook=${handbook.params}'"
    }
}