package main.kotlin.data

data class ArchivedOperation(
    val id:String = "",
    val operations:Array<String> = arrayOf(),
    val shipId:String = "",
    val tripId:String = "",
    val handbookId:String? = null,
    val handbookName:String? = null,
    val handbookVersion:String? = null,
    val destinationId:String? = null,
    val destinationName:String? = null,
    val voyageNumber:String? = null,
    val completion:Event = Event(),
    val aborted:Event? = Event(),
    val initiation:Event = Event()
)
{
    constructor(dyn:dynamic) : this(
        id = dyn.id as String,
        operations = dyn.operations as? Array<String> ?: arrayOf(),
        shipId = dyn.ship_id as String,
        tripId = dyn.trip_id as String,
        handbookId = dyn.handbook_id as? String,
        handbookName = dyn.handbook_name as? String,
        handbookVersion = dyn.handbook_version as? String,
        destinationId = dyn.destination_id as? String,
        destinationName = dyn.destination_name as? String,
        voyageNumber = dyn.voyage_number as? String,
        completion = Event(dyn=dyn.completion),
        aborted = if (dyn.aborted != null) Event(dyn = dyn.aborted) else null,
        initiation = Event(dyn=dyn.initiation)
    )

    val operationName: String
    get()
    {
        var name = voyageNumber
        if (name==null) name = destinationName
        if (name==null) name = handbookName;
        if (name==null) name = "NULL"
        return name
    }
}
