package main.kotlin.ui

import org.w3c.dom.Document
import org.w3c.dom.HTMLButtonElement

class Button(document: Document, id:String,tooltip:String?=null) : Element(document,id,"inline-block",tooltip)
{
    override val view : HTMLButtonElement = document.getElementById(id) as HTMLButtonElement

    init
    {
        didInit()
    }

    var title : String
    get()
    {
        return view.innerText
    }
    set(value)
    {
        view.innerText = value
    }

    var disabled : Boolean
    get()
    {
        return view.disabled
    }
    set(value)
    {
        view.disabled = value
    }
}
