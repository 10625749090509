package main.kotlin.data

data class FeedbackItem(
    val id:String="",
    val ship:String="",
    val text:String="",
    val uid:String="",
    val by:String,
    val timestamp: UnixTime? = 0.0,
    val status:FeedbackStatus=FeedbackStatus.Unknown)
{
    var resolved : Event? = null

    constructor(dyn:dynamic) : this(dyn.id as String,dyn.ship as String,dyn.text as String,dyn.uid as String,dyn.by as String ,
        asUnixTime(dyn.timestamp as UnixTime),FeedbackStatus.from(dyn.status as Int))
    {
        val r = dyn.resolved
        if (r != null)
            resolved = Event(dyn=r)
    }

    companion object {
        private fun asUnixTime(value: UnixTime): UnixTime?
        {
            return if(value != null) {
                if (value >= 1E10) value / 1000
                else value
            } else null
        }
    }
}

data class FeedbackDetails(
    val id:String="",
    val ship:String="",
    val text:String="",
    val uid:String="",
    val by:String,
    val timestamp: UnixTime? = 0.0,
    val status:FeedbackStatus=FeedbackStatus.Unknown)
{
    var resolved : Event? = null

    var comments : Array<Event> = arrayOf()

    var reply : Array<Reply> = arrayOf()

    constructor(dyn:dynamic) : this(dyn.id as String,dyn.ship as String,dyn.text as String,dyn.uid as String,dyn.by as String ,
        asUnixTime(dyn.timestamp as UnixTime),FeedbackStatus.from(dyn.status as Int))
    {
        val r = dyn.resolved
        if (r != null)
            resolved = Event(dyn=r)

        val comms : MutableList<Event> = mutableListOf()
        val cdr = dyn.comments as? Array<Any>
        if (cdr != null)
        {
            for (hd in cdr)
            {
                comms.add(Event(dyn=hd.asDynamic()))
            }
        }
        comments = comms.toTypedArray()

        val reps : MutableList<Reply> = mutableListOf()
        val rep = dyn.reply as? Array<Any>
        if (rep != null)
        {
            for (rp in rep)
            {
                reps.add(Reply(dyn=rp.asDynamic()))
            }
        }
        reply = reps.toTypedArray()
    }

    companion object {
        private fun asUnixTime(value: UnixTime): UnixTime?
        {
            return if(value != null) {
                if (value >= 1E10) value / 1000
                else value
            } else null
        }
    }
}

enum class FeedbackStatus(val value:Int)
{
    Unknown(0),
    Draft(1),
    Submitted(2),
    Resolved(3);

    companion object
    {
        fun from(value: Int) : FeedbackStatus
        {
            when (value)
            {
                0 -> return Unknown
                1 -> return Draft
                2 -> return Submitted
                3 -> return Resolved
            }
            return Unknown
        }
    }

    val title : String
    get()
    {
        return when (this)
        {
            Unknown -> "Unknown"
            Draft -> "Draft"
            Submitted -> "Submitted"
            Resolved -> "Resolved"
        }
    }
}