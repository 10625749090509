package main.kotlin.ui

import org.w3c.dom.CustomEvent
import org.w3c.dom.Document
import kotlin.browser.document

data class AlertAction(val tag:String,val text:String)
data class AlertChoice(val tag:String,val text:String,val detail:String)

class Alert(document:Document)
{
    private val container = Div(document,"alert")

    private var view : dynamic? = null

    private var title : String = ""
    private var msg : String = ""
    private var callback : ((String)->Unit)? = null
    private var actions : Array<AlertAction> = arrayOf()
    private var choices : Array<AlertChoice> = arrayOf()
    private var multipleChoice: Boolean = false;
    private val checklistSwitches : MutableList<Switch> = mutableListOf()

    private var input = false
    private var inputHint : String? = null
    private var inputField : EditText? = null

    var inputText : String? = null

    val selectedItems : Array<String>
    get()
    {
        var result = mutableListOf<String>()
        for(c in checklistSwitches) {
            if(c.checked)  result.add(c.id)
        }

        return result.toTypedArray()
    }

    private val markup : String
    get()
    {
        if (choices.isNotEmpty() && !multipleChoice)
            return choicesMarkup

        var buttonsMarkup = ""
        for(action in actions)
        {
            buttonsMarkup += """
<button type="button" class="mdc-button mdc-dialog__button" data-mdc-dialog-action="${action.tag}">
  <span class="mdc-button__label">${action.text}</span>
</button>
            """
        }

        var inputMarkup = ""
        if (input)
        {
            inputMarkup = """
               <div class="mdc-text-field alert_input" id="alert_input_field"></div><br/>
            """
        }

        var multipleChoiceMarkup = """<div class="page_fields_improved">""";
        if (multipleChoice) {
            for(v in choices)
            {
                multipleChoiceMarkup += """<div id="${v.tag}"></div>"""
            }
            multipleChoiceMarkup += "</div>"
        }
        return """
<div class="mdc-dialog"
role="alertdialog"
aria-modal="true"
aria-labelledby="my-dialog-title"
aria-describedby="my-dialog-content">
  <div class="mdc-dialog__container">
    <div class="mdc-dialog__surface">
      <!-- Title cannot contain leading whitespace due to mdc-typography-baseline-top() -->
      <h2 class="mdc-dialog__title" id="my-dialog-title">$title</h2>
      <div class="mdc-dialog__content" id="my-dialog-content">
        $msg
        $inputMarkup
        $multipleChoiceMarkup
      </div>
      <footer class="mdc-dialog__actions">
        $buttonsMarkup
      </footer>
    </div>
  </div>
  <div class="mdc-dialog__scrim"></div>
</div>

 """
    }

    private val choicesMarkup : String
    get()
    {
        var html = ""

        for (v in choices.withIndex())
        {
//            val tabindex = if (v.index == 0) """ tabindex="0"""" else ""
            val tabindex = ""

            html += """
            <li class="mdc-list-item"$tabindex data-mdc-dialog-action="${v.value.tag}">
                <span class="mdc-list-item__text">${v.value.text}${v.value.detail}</span>
            </li>
            """
        }

        return """
           <div class="mdc-dialog"
     role="alertdialog"
     aria-modal="true"
     aria-labelledby="my-dialog-title"
     aria-describedby="my-dialog-content">
  <div class="mdc-dialog__container">
    <div class="mdc-dialog__surface">
      <!-- Title cannot contain leading whitespace due to mdc-typography-baseline-top() -->
      <h2 class="mdc-dialog__title" id="my-dialog-title">$title</h2>
      <div class="mdc-dialog__content" id="my-dialog-content">
        <ul class="mdc-list mdc-list--avatar-list">
            $html
          <!-- ... -->
        </ul>
      </div>
    </div>
  </div>
  <div class="mdc-dialog__scrim"></div>
</div>
        """
    }

    private fun getView() : dynamic
    {
        val obj = js("""
    (function(){
        return new mdc.dialog.MDCDialog(document.querySelector('.mdc-dialog'));
    }())
    """)
        return obj
    }

    private fun getInputField() : EditText?
    {
        if (!input)
            return null
        val field = EditText(document,"alert_input_field",inputHint ?: "")
        field.text = inputText ?: ""
        return field
    }

    fun open(title:String,msg:String,actions:Array<AlertAction>,callback:(String)->Unit)
    {
        open(title,msg,actions,arrayOf(),false, false,null,null,callback)
    }

    fun openForInput(title:String,msg:String,actions:Array<AlertAction>,hint:String,current:String?=null,callback:(String)->Unit)
    {
        open(title,msg,actions,arrayOf(),false, true,hint,current,callback)
    }

    fun openForChoices(title:String,choices:Array<AlertChoice>,callback:(String)->Unit)
    {
        open(title,"", arrayOf(),choices,false, false,null,null,callback)
    }

    fun openForMultipleChoices(title: String, choices:Array<AlertChoice>, actions:Array<AlertAction>, callback: (String) -> Unit)
    {
        open(title, "", actions, choices, true, false, null, null, callback)
    }

    private fun open(title:String,msg:String,actions:Array<AlertAction>,choices:Array<AlertChoice>,multipleChoice: Boolean, input:Boolean,inputHint:String?,inputCurrent:String?,callback:(String)->Unit)
    {
        this.title = title
        this.msg = msg
        this.actions = actions
        this.choices = choices
        this.multipleChoice = multipleChoice
        this.callback = callback
        this.input = input
        this.inputText = null
        this.inputHint = inputHint
        this.inputText = inputCurrent

        val cb : ((CustomEvent?) -> Unit) = {
            val detail = it?.detail.asDynamic()
            closed(detail?.action as? String)
        }

        container.view.innerHTML = markup
        view = getView()
        inputField = getInputField()
        inputField?.textChanged = { inputText = it }

        if(multipleChoice) {
            for(c in choices)
            {
                val switch = Switch(document,"${c.tag}",c.text)
                switch.checked = true
                checklistSwitches.add(switch)
            }
        }

        view.listen("MDCDialog:closed",cb)
        view?.open()
    }

    private fun closed(action:String?)
    {
        val act = action ?: "unknown"
        console.log("Closed with action $act")
        if (action != null)
            callback?.invoke(action)
        cleanup()
    }

    fun close()
    {
        view?.close()
        cleanup()
    }

    private fun cleanup()
    {
        container.view.innerHTML = ""
        view = null
        title = ""
        msg = ""
        actions = arrayOf()
        callback = null
        checklistSwitches.clear()
    }
}
